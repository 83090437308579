import React, { useState, useEffect, useRef } from 'react';
import { Marker, MarkerClusterer, OverlayView } from '@react-google-maps/api';
import {
	zoomIndividualLevel,
	ZOOM_LEVEL_DISTRIBUTIONS,
	ASSET_TYPE_POLE,
	ASSET_TYPE_STRUCTURE,
	ASSET_TYPE_LINE,
	MARKER_ICONS_ASSETS,
	CUSTOM_MARKER_ICONS,
	ZOOM_LEVEL_TRANSMISSIONS,
	INSPECTION_TYPE_TRANSMISSION,
	INSPECTION_TYPE_TREE
} from '../../../../Constants';
import c1 from '../../../../assets/MapMarkers/clusterMap/m1.png';
import c2 from '../../../../assets/MapMarkers/clusterMap/m2.png';
import c3 from '../../../../assets/MapMarkers/clusterMap/m3.png';
import c4 from '../../../../assets/MapMarkers/clusterMap/m4.png';
import c5 from '../../../../assets/MapMarkers/clusterMap/m5.png';
import outageMarkerGray from '../../../../assets/MapMarkers/outageMap/outageMarkerGray.svg';
import outageMarkerBlue from '../../../../assets/MapMarkers/outageMap/outageMarkerBlue.svg';
import blueMarker from '../../../../assets/MapMarkers/blue-marker.png';
import InfoWindowWrapper from './InfoWindow';

function MapMarkers({
	mapClick,
	statuses,
	map,
	inspectionList,
	townsSummary,
	highlighted,
	selectMarker,
	outageEvents,
	showEvents,
	showInspections,
	showTransmissionAssets,
	showDistributionAssets,
	inspectionTypes,
	userPosition,
	assets,
	assetHighlighted,
	videosByAsset
}) {
	const [outageEventId, setOutageEventId] = useState();
	const [openInfoWindowSw, setopenInfoWindowSw] = useState(false);
	const [infoWindowIndex, setInfoWindowIndex] = useState({});
	const [infoWindowAssetsData, setInfoWindowAssetsData] = useState(assetHighlighted?.id || false);

	const timeout = useRef(null);

	// Makes the map refresh after status or inspection type changed
	useEffect(() => {
		map && map.setZoom(map.zoom);
	}, [statuses, inspectionTypes, map]);

	useEffect(() => {
		setInfoWindowAssetsData(false);
	}, [mapClick]);

	useEffect(() => {
		assetHighlighted && assetHighlighted.id && setInfoWindowAssetsData(assetHighlighted.id);
	}, [assetHighlighted]);

	const setIndex = (id,isInspectionMarker, outageEventId) => {
		setOutageEventId(outageEventId);
		if (isInspectionMarker)
			selectMarker(id);
	};

	const showInfoWindow = (id,isInspectionMarker) => {
		setopenInfoWindowSw(true);
		setInfoWindowIndex({id, isInspectionMarker});
	};

	const closeInfoWindow = () => {
		setopenInfoWindowSw(false);
		setInfoWindowIndex({});
		setOutageEventId(null);
	};

	// Get the icon depending the damage type for single damage, or total count of damages
	const getCustomIcon = damageTypes => {		
		if (damageTypes) { 
			switch (damageTypes.length) {
				case  1:
					return CUSTOM_MARKER_ICONS[damageTypes[0].name];
				case  2:
					return CUSTOM_MARKER_ICONS['2'];
				case  3:
					return CUSTOM_MARKER_ICONS['3'];
				case  4:
					return CUSTOM_MARKER_ICONS['4'];
				case 5:
					return CUSTOM_MARKER_ICONS['5'];
				default:
					break;
				}
		}
	};

	// Depending the amount of inspections per cluster, show the corresponding icon
	const getCustomClusterIcon = inspectionSummaries => {
		const count = getInspectionSummaryCount(inspectionSummaries);
		if (count < 10) {
			return c1;
		} else if (count >= 10 && count < 100) {
			return c2;
		} else if (count >= 100 && count < 1000) {
			return c3;
		} else if (count >= 1000 && count < 5000) {
			return c4;
		} else {
			return c5;
		}
	};

	// Custom calculator method, count total of inspections or total of pins depending on zoom level
	const customClusterCalculation = (markers, numStyles) => {
		if (markers && markers.length) {
			let inspectionsCount = 0;
			if (markers && Array.isArray(markers)) {
				if (zoomIndividualLevel < map.zoom) {
					inspectionsCount = markers.length;
				} else {
					markers.filter(({label}) => label && label.text).forEach(({label}) => {            
						inspectionsCount += parseInt(label.text);
					});
				}
			}
			
			var index = 0;
			var dv = inspectionsCount;
			while (dv !== 0) {
				dv = parseInt(dv / 10, 10);
				index++;
			}
		
			index = Math.min(index, numStyles);
			return {
				text: inspectionsCount,
				index: index
			};
		}
	};

	// Outage icon with colors according number of markers
	const outageSummary = count => {    
		const getColor = () => {
			if (count < 10) {
				return '#018CFF';
			} else if (count >= 10 && count < 100) {
				return '#FFBF00';
			} else if (count >= 100 && count < 1000) {
				return '#FC0000';
			} else if (count >= 1000 && count < 5000) {
				return '#FF00ED';
			} else {
				return '#9C00FF';
			}
		};
		return `<svg id="Multiple_Outages" data-name="Multiple Outages" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><circle class="cls-1" cx="12.5" cy="12.5" r="11.25" fill="#fff" stroke="#fff" strokeMiterlimit="10" /><path class="cls-2" d="M23.33,12.5A10.83,10.83,0,0,0,12.5,1.67V4.92a7.58,7.58,0,0,1,7.58,7.58Z" fill="${getColor()}" /><path class="cls-3" d="M4.92,12.5A7.58,7.58,0,0,1,12.5,4.92V1.67A10.83,10.83,0,0,0,1.67,12.5Z" fill="${getColor()}" /><path class="cls-4" d="M12.5,23.33A10.83,10.83,0,0,0,23.33,12.5H20.08a7.58,7.58,0,0,1-7.58,7.58Z" fill="${getColor()}" /><path class="cls-5" d="M12.5,20.08A7.58,7.58,0,0,1,4.92,12.5H1.67A10.83,10.83,0,0,0,12.5,23.33Z" fill="${getColor()}" /></svg>`;
	};

	// Custom outage icons
	const customClusterOutagesStyles = [
		{ textColor: 'rgba(0, 0, 0, 0.87);', height: 35, url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(outageSummary(1)), width: 35 },
		{ textColor: 'rgba(0, 0, 0, 0.87);', height: 35, url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(outageSummary(11)), width: 35 },
		{ textColor: 'rgba(0, 0, 0, 0.87);', height: 40, url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(outageSummary(101)), width: 40 },
		{ textColor: 'rgba(0, 0, 0, 0.87);', height: 45, url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(outageSummary(1001)), width: 45 },
		{ textColor: 'rgba(0, 0, 0, 0.87);', height: 50, url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(outageSummary(5001)), width: 50 }
	];

	const getInspectionSummaryCount = inspectionsSummary => {    
		let count = 0;
		if (inspectionsSummary && inspectionsSummary.length > 0) {
			inspectionsSummary.forEach(inspectionSummary => {
				if (statuses.length > 0) {
					statuses.forEach(status => {
						let inspectionCount = [];
						if (inspectionTypes.length === 0)
							inspectionCount = inspectionSummary.counts && inspectionSummary.counts.find(ic => ic.status === status);
						else
							inspectionCount = inspectionSummary.counts && inspectionSummary.counts.find(ic => ic.status === status && inspectionTypes.includes(inspectionSummary.type));

						if (inspectionCount)
							count = count + inspectionCount.count;
					});
				} else {
					// If no status selected sum all inspections from the summary
					count = count + inspectionSummary.counts.reduce((a, b) => a + (b.count || 0), 0);
				}     
			});
		} 
		return count;
	};

	const getOutageEventsSummaryCount = outageEventsSummary => {
		let outageSummaryCount = 0;
		if (outageEventsSummary && outageEventsSummary.length > 0 ) { 
			outageEventsSummary.forEach(({status,count }) => {     
				if (status === 'New')   
					outageSummaryCount = count;
			});
		}
		return outageSummaryCount;
	};

	const getCountFromTownSummary = (townSummary, inspections) => {
		let countLabel;
		if (inspections) {
			countLabel = getInspectionSummaryCount(townSummary.inspectionsSummary);
		} else {
			countLabel = getOutageEventsSummaryCount(townSummary.outageEventsSummary);
		}
		return countLabel;
	};

	// Method to render summarized inspections and outages
	const renderSummarizedIcons = (clusterer, townsSummary, inspections) => {
		if (!townsSummary) {
			return null;
		}

		return townsSummary.map((townSummary, index) => {
			if (!townSummary.location && !townSummary.townName) {       
				return null;
			}

			let countLabel = getCountFromTownSummary(townSummary, inspections);
			if (countLabel <= 0) {
				return null;
			}
			
			return <Marker
				tracksViewChanges={false}
				noRedraw={true}
				key={townSummary.townName + index}
				clickable={true}
				position={{
					lat: townSummary.location.coordinates[1],
					lng: townSummary.location.coordinates[0],
				}}
				labelAnchor={new window.google.maps.Point(0, 0)}
				label={{
					text: String(countLabel),
					color: '#000',
					fontSize: '11px',
					fontWeight: 'bold'
				}}
				zIndex={inspections ? 998 : 997}
				clusterer={clusterer}
				icon={inspections 
					? getCustomClusterIcon(townSummary.inspectionsSummary)
					: {
						url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(outageSummary(countLabel)),
						scaledSize: new window.google.maps.Size(35, 35),
					}
				}
				onClick={() => {
					map.setZoom(zoomIndividualLevel + 1);
					map.panTo({
						lat: townSummary.location.coordinates[1],
						lng: townSummary.location.coordinates[0],
					});
				}} 
			/>;
		});
	};
	// Method to render individual inspections
	const renderIndividualInspections = (inspectionList, clusterer, highlighted) => 
		inspectionList && Array.isArray(inspectionList) && inspectionList.map((inspection, index) => {
			if ((inspection.location && inspection.id) && inspection.inspectionType !== INSPECTION_TYPE_TRANSMISSION) {
				const iconHighlighted = (highlighted && highlighted.id === inspection.id) || null;
				return <Marker
					tracksViewChanges={false}
					noRedraw={true}
					clusterer={clusterer}
					key={inspection.id + index}
					clickable={true}
					position={{
						lat: inspection.location.coordinates[1],
						lng: inspection.location.coordinates[0],
					}}
					icon={!iconHighlighted ? {
						path: getCustomIcon(inspection?.damageTypes ? inspection.damageTypes : [{'name': `${inspection?.inspectionType} Inspection`}]),
						fillColor: inspection.inspectionType === INSPECTION_TYPE_TREE ? 'green' : 'red',
						fillOpacity: 1,
						scale: 1.5,
						strokeColor: '#fff',
						strokeWeight: 1,
						anchor: new window.google.maps.Point(12, 20),
					} : null}
					zIndex={iconHighlighted ? 998 : 997 }
					onClick={() => showInfoWindow(inspection.id)}
					onMouseOver={() => {
						selectMarker(inspection.id);
					}}
				>
					{openInfoWindowSw && infoWindowIndex.id === inspection.id && 
						<InfoWindowWrapper inspection={{ 
							notes: inspection.notes,
							position:{
								lat: inspection.location.coordinates[1],
								lng: inspection.location.coordinates[0],
							}}}
						/>}
				</Marker>;
			} else {
				return null;
			}
		});

	const checkAssetHasInspections = (asset, inspectionList) => inspectionList.some( inspection => inspection.lineId === asset.lineId && inspection.structureId === asset.structureId);

	const renderAssets = () => assets && Array.isArray(assets) && assets.map((asset, index) => {
		const isDistribution = asset.type !== ASSET_TYPE_LINE && asset.type !== ASSET_TYPE_STRUCTURE;
		const distributionAlternativeColor = isDistribution && asset.type !== ASSET_TYPE_POLE;
		const iconPath = isDistribution
			? distributionAlternativeColor 
				? MARKER_ICONS_ASSETS.distributionDevice
				: MARKER_ICONS_ASSETS.distributionPole
			: MARKER_ICONS_ASSETS.transsmission;

		asset.hasInspection = checkAssetHasInspections(asset, inspectionList);

		return asset.location && asset.type
			? <Marker
				tracksViewChanges={false}
				noRedraw={true}
				key={asset.id  + index}
				position={{
					lat: asset.location.coordinates[1],
					lng: asset.location.coordinates[0],
				}}
				icon={{
					path: iconPath,
					fillColor: isDistribution
						? distributionAlternativeColor
							? 'red'
							: asset.devices && asset.devices.length ? 'orange' : 'darkgray'
						: asset.hasInspection
							? '#f87171'
							: 'gray',
					fillOpacity: 1,
					scale: isDistribution
						? distributionAlternativeColor ? 1.5 : .8
						: 0.05,
					strokeColor: distributionAlternativeColor 
						? '#fff' 
						:  asset.hasInspection 
							? '#ef4444'
							:'#666',
					strokeWeight: 1,
					anchor: distributionAlternativeColor
						? new window.google.maps.Point(12, 20)
						: new window.google.maps.Point(12, 15)
				}}
				onClick={() => setInfoWindowAssetsData(asset.id )}
				zIndex={distributionAlternativeColor ? 999 : 998}
			>
				{infoWindowAssetsData && infoWindowAssetsData === asset.id && 
				<InfoWindowWrapper
					asset={{asset, distributionAlternativeColor, isDistribution, zoom: map.zoom, videosByAsset}}
					setInfoWindowAssetsData={setInfoWindowAssetsData}
				/>}
			</Marker>
			:  null;
	});

	// Method to render the highlighted marker on summarized view zoom level and individual zoom level
	const showHighlightedMarker = highlighted => (map && map.zoom > zoomIndividualLevel) || highlighted.inspectionType === INSPECTION_TYPE_TRANSMISSION
		? <Marker
			noClustererRedraw={true}
			position={{
				lat: highlighted.location.coordinates[1],
				lng: highlighted.location.coordinates[0],
			}}             
			zIndex={999}
		/>
		: <OverlayView
			position={{
				lat: highlighted.location.coordinates[1],
				lng: highlighted.location.coordinates[0],
			}}
			mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
			zIndex={9999}
		>
			<div style={{zIndex: 9999}}>
				<Marker
					tracksViewChanges={false}
					noRedraw={true}         
					position={{
						lat: highlighted.location.coordinates[1],
						lng: highlighted.location.coordinates[0],
					}}                  
					zIndex={999}
					icon={{
						path: getCustomIcon(highlighted?.damageTypes ? highlighted.damageTypes : [{'name': `${highlighted?.inspectionType} Inspection`}]),
						fillColor: highlighted.inspectionType ===  INSPECTION_TYPE_TREE ? 'green' : 'red',
						fillOpacity: 1,
						scale: 1.5,
						strokeColor: '#fff',
						strokeWeight: 1,
						anchor: new window.google.maps.Point(12, 20),
					}}
				/>
			</div>
		</OverlayView>;

	// Method to render individual outage events
	const renderIndividualOutageEvents = clusterer => 
		outageEvents && Array.isArray(outageEvents) && outageEvents.map((outage, index) => 
			outage.assetLocation && outage.eventId && outage.assetId && outage.id && 
				<>
					<Marker
						tracksViewChanges={false}
						noRedraw={true}
						clusterer={clusterer}
						key={outage.id + index}
						icon={{
							url: outageEventId === outage.eventId ? outageMarkerBlue : outageMarkerGray,
							scaledSize: new window.google.maps.Size(30, 30),
						}}
						clickable={true}
						onClick={() => setIndex(outage.id, false, outage.eventId)}
						onMouseOver={() => {
							timeout.current && clearTimeout(timeout.current);
							showInfoWindow(outage.id, false, outage.eventId);
						}}
						onMouseOut={() => {
							timeout.current && clearTimeout(timeout.current);
							timeout.current = setTimeout(() => closeInfoWindow(), 1000);
						}}
						position={{             
							lat: outage.assetLocation.coordinates[1],
							lng: outage.assetLocation.coordinates[0],
						}}
					/>
					{openInfoWindowSw && infoWindowIndex.id === outage.id && outage.assetLocation.coordinates && (            
						<div
							onMouseOver = {() => {
								timeout.current && clearTimeout(timeout.current);
								showInfoWindow(outage.id, false, outage.eventId);
							}}
							onMouseOut={() => {
								timeout.current && clearTimeout(timeout.current);
								timeout.current = setTimeout(() => closeInfoWindow(), 1000);
							}}
						>
							<InfoWindowWrapper outage={outage} />
						</div>
					)}
				</>
		);
		
	const renderUserLocation = () => <Marker
		noClustererRedraw={true}
		position={{
			lat: userPosition[0],
			lng: userPosition[1],
		}}
		zIndex={999}
		icon={{
			url: blueMarker,
			scaledSize: new window.google.maps.Size(50, 50),
			anchor: { x: 25, y: 25 },
		}}
	/>;

	return <>
		{/*****  VISUAL INSPECTIONS  *****/
			showInspections	? map && map.zoom > zoomIndividualLevel
					// Individual inspections
					? inspectionList && inspectionList.length > 0
						? <MarkerClusterer
							calculator={(markers, numStyles) => customClusterCalculation(markers, numStyles)}
						>
							{clusterer => renderIndividualInspections(inspectionList, clusterer, highlighted) || null}
						</MarkerClusterer>
						: null
					// Sumarized inspections
					: townsSummary && townsSummary.length > 0          
						? <MarkerClusterer
							calculator={(markers, numStyles) => customClusterCalculation(markers, numStyles)}
						>
							{clusterer => renderSummarizedIcons(clusterer, townsSummary, true) || null}            
						</MarkerClusterer>
						: null
				: null
		}
		{/* Highlighted inspection with custom damage icon */}
		{highlighted && highlighted.location && showHighlightedMarker(highlighted)}    
		{/***** OMS EVENTS *****/    
			showEvents
				? map && map.zoom > zoomIndividualLevel
					// Individual OMS events
					? outageEvents && outageEvents.length > 0
						? <MarkerClusterer
							styles={customClusterOutagesStyles}
							calculator={(markers, numStyles) => customClusterCalculation(markers, numStyles)}
							ignoreHidden={true}
						>
							{clusterer => renderIndividualOutageEvents(clusterer) || null}
						</MarkerClusterer>
						: null
				// Summarized OMS Events
					: townsSummary && townsSummary.length > 0
						? map && map.zoom >= zoomIndividualLevel - 2
							? renderSummarizedIcons(null, townsSummary, false)
							: <MarkerClusterer
								styles={customClusterOutagesStyles}
								calculator={(markers, numStyles) => customClusterCalculation(markers, numStyles)}
							>
								{clusterer => renderSummarizedIcons(clusterer, townsSummary, false) || null}
							</MarkerClusterer>            
						: null
				: null
		}
		{userPosition && renderUserLocation()}
		{
		/***** ASSETS *****/    
			((showDistributionAssets && map && map.zoom >= ZOOM_LEVEL_DISTRIBUTIONS) 
			|| (showTransmissionAssets && map && map.zoom >= ZOOM_LEVEL_TRANSMISSIONS)) 
			&& assets && assets.length > 0 && renderAssets()
		}
	</>;
}
export default MapMarkers;