import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InspectionsDataContext from '../../../context/InspectionsData/InspectionsDataContext';
import UsersService from '../../../services/UsersService';
import WorkItemsService from '../../../services/WorkItemsService';
import { useSnackbar } from 'notistack';
import { showSnackbar } from '../../../Utils';

const useStyle = makeStyles((theme) => ({
	editIcon: {
		cursor: 'pointer',
		fontSize: 20,
		color: theme.palette.primary.main,
	},
	editUser: {
		cursor: 'pointer',
		borderBottom: '1px dashed #000',
		color: theme.palette.primary.main,
	},
	disableEditUser: {
		cursor: 'point',
		borderBottom: 'none',
		color: theme.palette.primary.main,
	}
}));

const UserAssignment = ({
	item,
	webView = false,
	updateList,
	workQueueId,
	workQueueList,
	setEditingAssignedToParam,
	editingAssignedToParam,
	disabled,
	...props
}) => {
	const classes = useStyle();
	const [users, setUsers] = useState([]);
	const [editingAssignedTo, setEditingAssignedTo] = useState(false);
	const [selectAssignedUser, setSelectAssignedUser] = useState(null);
	const { getContextUsersAccepted, setContextUsersAccepted } = useContext(
		InspectionsDataContext
	);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (editingAssignedToParam) {
			editAssignedTo(editingAssignedToParam);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editingAssignedToParam]);

	const onBlurEditing = (workItem) => {
		if (selectAssignedUser) {
			const userSelected = users.filter(
				(user) => user.fullName === selectAssignedUser
			);
			const patchWorkItem = {
				id: workItem.id,
				workQueueId,
				assignedUserId: null,
				assignedUserName: null,
				status: workItem.status,
			};

			if (userSelected.length > 0) {
				patchWorkItem.assignedUserId = userSelected[0].id;
				patchWorkItem.assignedUserName = selectAssignedUser;
			}

			WorkItemsService.update(patchWorkItem).then((response) => {
				if (response && response.status === 200) {
					setEditingAssignedTo(false);
					!webView && setEditingAssignedToParam(false);
					setSelectAssignedUser(null);
					// Get the index of the element just updated
					const index = workQueueList.findIndex(
						(item) => item.id === workItem.id
					);
					const workQueueListCopy = [...workQueueList];
					workQueueListCopy[index].assignedUser = response.data.assignedUser;
					updateList(workQueueListCopy);
					if (webView) {
						props.userUpdated(response.data.dateUpdated);
					} else {
						showSnackbarMessage('Work Item updated successfully', 'success');
					}
				} else {
					showSnackbarMessage(
						'An error has occurred while updating the Work Item',
						'error'
					);
				}
			});
		} else {
			setEditingAssignedTo(false);
			if (setEditingAssignedToParam) setEditingAssignedToParam(false);
		}
	};

	const editAssignedTo = async (id) => {
		const usersAcceptedTerms = await getContextUsersAccepted();
		// if not in context,  get from API and store it
		if (!usersAcceptedTerms) {
			await UsersService.getAll()
				.then((items) => {
					if (items && items.data) {
						setContextUsersAccepted(items.data);
						setUsers(items.data);
						setEditingAssignedTo(id);
						if (setEditingAssignedToParam) setEditingAssignedToParam(id);
					}
				})
				.catch(() => {
					setContextUsersAccepted(null);
					showSnackbarMessage('Failed to load users, please refresh', 'error');
				});
		} else {
			setUsers(usersAcceptedTerms);
			setEditingAssignedTo(id);
			if (setEditingAssignedToParam) setEditingAssignedToParam(id);
		}
	};

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	return (editingAssignedToParam === item.id ||
		editingAssignedTo === item.id) &&
		users.length > 0 ? (
			<Autocomplete
				options={users.map(({ fullName }) => fullName)}
				onChange={(event, value) => {
					setSelectAssignedUser(value);
				}}
				disabled = {disabled}
				value={
					selectAssignedUser ||
				(item.assignedUser && item.assignedUser.name
					? item.assignedUser.name
					: '')
				}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Users"
						fullWidth
						onBlur={() => onBlurEditing(item)}
						autoFocus
					/>
				)}
				style={{ minWidth: 150, width: '100%' }}
			/>
		) : webView ? (
			item.assignedUser && item.assignedUser.name ? (
				<label
					className={` ${!disabled ? classes.editUser : classes.disableEditUser}`}
					onClick={() => !disabled && editAssignedTo(item.id)}
				>
					{item.assignedUser.name}
				</label>
			) : (
				disabled ? <></> : <Edit
					className={classes.editIcon}
					onClick={() => editAssignedTo(item.id)}
				/>
			)
		) : null;
};

export default UserAssignment;
