import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import LineProgressCell from '../LineProgressCell';
import WorkItemsService from '../../../services/WorkItemsService';
import Slider from '@material-ui/core/Slider';
import CloseIcon from '@material-ui/icons/Close';
import { showSnackbar } from '../../../Utils';
import { useSnackbar } from 'notistack';

const useStyle = makeStyles(() => ({
	cursorPointer: {
		cursor: 'pointer',
	},
	sliderContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	fontICon: {
		fontSize: '16px',
		cursor: 'pointer',
	},
}));
const statuses = [
	{
		value: '0',
		label: '',
	},
	{
		value: '25',
		label: '',
	},
	{
		value: '50',
		label: '',
	},
	{
		value: '75',
		label: '',
	},
	{
		value: '100',
		label: '',
	},
];
const getStatus = (workItem) => Number(workItem.status.replace('%', '') || 0);
const WorkItemsStatus = ({
	workitem,
	workQueues,
	workQueueId,
	webView = false,
	disabled = false,
	...props
}) => {
	const classes = useStyle();
	const [enableStatuses, setEnableStatuses] = useState(false);
	const [statusSelected, setStatusSelected] = useState(getStatus(workitem));
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		setStatusSelected(getStatus(workitem));
		return () => {
			setEnableStatuses(false);
			setStatusSelected(0);
		};
	}, [workitem]);

	const onChangeLinearProgress = () => {
		setEnableStatuses(true);
	};

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	const onChangeSelect = (e) => {
		const status = `${e}%`;
		const _workItem = {
			id: workitem.id,
			workQueueId,
			assignedUserId: workitem.assignedUser ? workitem.assignedUser.id : null,
			assignedUserName: workitem.assignedUser
				? workitem.assignedUser.name
				: null,
			status,
		};

		WorkItemsService.update(_workItem)
			.then((response) => {
				if (response && response.status === 200) {
					const index = workQueues.findIndex((item) => item.id === workitem.id);
					const _workQueues = [...workQueues];
					_workQueues[index].status = response.data.status;
					props.updateList(_workQueues);
					setEnableStatuses(false);

					if (webView) {
						props.userUpdated(response.data.dateUpdated);
					} else {
						showSnackbarMessage('Work Item updated successfully', 'success');
					}
				} else {
					setEnableStatuses(false);
					showSnackbarMessage(
						'An error has occurred while updating the Work Item',
						'error'
					);
				}
			})
			.catch(() => {
				showSnackbarMessage(
					'An error has occurred while updating the Work Item',
					'error'
				);
			});
	};

	return (
		<>
			{enableStatuses ? (
				<>
					<div className={classes.sliderContainer}>
						<Slider
							value={statusSelected}
							defaultValue={getStatus(workitem)}
							onChange={(event, newValue) => setStatusSelected(newValue)}
							min={0}
							max={100}
							step={25}
							marks={statuses}
							valueLabelDisplay="on"
							size="small"
							valueLabelFormat={(value) => `${value}`}
							style={{ width: '80%' }}
							onChangeCommitted={(e, value) => onChangeSelect(value)}
							disabled = {disabled}
						/>
						<CloseIcon
							className={classes.fontICon}
							onClick={() => setEnableStatuses(false)}
						/>
					</div>
				</>
			) : (
				<LineProgressCell
					className={classes.cursorPointer}
					onClick={onChangeLinearProgress}
					status={workitem.status}
				/>
			)}
		</>
	);
};

export default WorkItemsStatus;
