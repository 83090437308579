import { Grid, makeStyles } from "@material-ui/core";
import{ default as MaterialSlider} from "@material-ui/core/Slider";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { onlyNumericValuesOnInutTypeNumber } from "../../../../Utils";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 16,
    color: "#000",
  },
  sliderContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: "0 !important",
  },
  inputDistance: {
    "& input": {
      textAlign: "center",
    },
    marginBottom: "30px",
  },
}));

const Slider = ({
  treeSize,
  editableFields,
  onChangeHandler,
  title,
  format=""
}) => {
  const classes = useStyles();
  return (
    <>
      <p className={classes.label}>{title}</p>
      <Grid container direction="row" spacing={2}>
        <Grid item md={1} xs={2}>
          <TextField
            inputProps={{ 'data-testid': "inputSliderId" }}
            fullWidth
            onKeyDown={(e) => {
              onlyNumericValuesOnInutTypeNumber(e); // Avoid "e", "+", "-" and space
            }}
            onChange={(e) => {
              const inputValue = e.target.value;
              // If number is > 100, return 100
              onChangeHandler(inputValue > 100 ? 100 : inputValue);
            }}
            type="number"
            min="0"
            value={treeSize ? Number(treeSize) : 0}
            className={classes.inputDistance}
            disabled={!editableFields}
          />
        </Grid>
        <Grid item xs={10} md={11} className={classes.sliderContainer}>
          <MaterialSlider
            value={treeSize ? Number(treeSize) : 0}
            onChange={(event, newValue) => onChangeHandler(newValue)}
            getAriaValueText={(value) => `${value}${format}`}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => `${value}${format}`}
            disabled={!editableFields}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Slider;
