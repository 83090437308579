import React from "react";
import { formatDate, getToday, isDateInRange, showSnackbar } from "../../Utils";
import Table from '../Shared/Table';
import { INSPECTION_TYPE_DAMAGE, INSPECTION_TYPE_TREE } from "../../Constants/";
import DateTimePickerFromToFilter from "../Shared/Table/DateTimePickerFromToFilter";
import { getLookups, redirectToDetailPage } from "../../Utils/table";
import { useSnackbar } from "notistack";

const lookupKeys = ["inspectionType","status","issueType","stateName"];
let lookups = {
  inspectionType: {},
  status: {},
  issueType: {},
  stateName: {},
};
const InspectionsReportsTable = ({ inspections }) => {
  const { enqueueSnackbar } = useSnackbar();
  lookups = getLookups(inspections, lookupKeys);
  inspections.forEach(function (el) {
    if (el.damageTypes && el.damageTypes.length) {
      el.damageTypes.forEach((damageType) => {
        lookups.issueType = {...lookups.issueType , [damageType.name]: damageType.name };
      });
    }
  });
  
  const columns = [
    { title: 'Type', field: 'inspectionType', lookup: lookups.inspectionType },
    { title: 'Status', field: 'status', lookup: lookups.status },
    { title: 'Event Id', field: 'eventId', headerStyle: { textAlign: "left"} , cellStyle: { textAlign: "left"} },
    { 
      title: 'Issue Type',
      field: 'issueType',
      render: rowData => rowData.inspectionType === INSPECTION_TYPE_DAMAGE
        ? rowData.damageTypes && rowData.damageTypes.map(damageType => damageType.name).join(", ")
        : rowData.issueType,
      lookup: lookups.issueType,
      customFilterAndSearch: (filter, rowData) => {        
        if (filter.length && rowData.inspectionType === INSPECTION_TYPE_DAMAGE) {
          return rowData.damageTypes.some(item => typeof filter === 'object' ?  
            filter.includes(item.name):  
            item.name.toLowerCase().includes(filter.toLowerCase()))
        } else if (rowData.inspectionType === INSPECTION_TYPE_TREE) {
          return filter.includes(rowData.issueType)
        } else {
          return rowData.issueType && filter.includes(rowData.issueType)
        }
      }      
    },
    { title: 'State', field: 'stateName', lookup: lookups.stateName },
    { title: 'Town', field: 'townName' },
    { title: 'Created By', field: 'createdBy' },
    {
      title: 'Created On',
      field: 'dateCreated',
      render: rowData => formatDate(rowData.dateCreated),
      type: "datetime",
      customFilterAndSearch: (filter, rowData) => isDateInRange(filter, rowData.dateCreated),
      filterComponent: (props) => {
        const { columnDef, onFilterChanged } = props 
        return <DateTimePickerFromToFilter 
          onFilterChanged={(e,a) => onFilterChanged(e,a)} 
          columnDef={columnDef} 
          locale={props.dateTimePickerLocalization}
        />
      }
    }
  ]
  
  const exportCsv = (allColumns, allData) => {
    if (allData.length > 0) {
      try {
        const csvRows = []
        // get headers
        const keys = ["inspectionType", "status", "eventId", "circuitId", "timeCallReceived","dateArrived", "address", "onPrivateProperty", "isArboristNeeded","isGroundingNeeded", "issueType","failureMode","treeCondition","treeSpecies","construction", "preFailureDistance", "trimZoneTreePosition","treeSize","poleNumber", "notes", "comments", "createdBy","dateCreated","updatedBy","dateUpdated"];
        const headers = ["Type", "Status", "Event ID", "Circuit ID", "Time Call Received from Dispatch/Supervisor","Date Arrived", "Address", "Private Property?", "Arborist Follow Up Needed?", "Grounding Needed?", "Issue Type", "Failure Mode", "Tree Condition", "Tree Species", "Construction","Pre Failure Distance in Feet", "Trim Zone Tree Position", "Tree Size", "Pole Number", "Additional Inspection details", "Comments","Created By", "Created Date", "Updated By", "Updated Date"]
        csvRows.push(headers.join(','))
        
        for (const row of allData) {
          const values = keys.map(key => {
            let value
            if (key === "timeCallReceived" ||  key === "dateArrived" || key === "dateCreated" || key === "dateUpdated") {
              value = formatDate(row[key])
            } else if (key === "onPrivateProperty" || key === "isArboristNeeded" || key === "isGroundingNeeded"){
              value = row[key] === undefined ?  undefined : row[key] ? "Yes" : "No"
            } else if (key === "issueType" && row.inspectionType === INSPECTION_TYPE_DAMAGE) {
              value = row.damageTypes.map(damageType => damageType.name).join(", ")
            } else {
              value = row[key]
            }
            let escaped = ('' + value).replace(/"/g, "'").replace(/'/g, "'")
            return escaped === "undefined" ? `""` :`"${escaped}"`
          })
            
          csvRows.push(values.join(','))
        }
          
        const csvRowsString = csvRows.join('\n')
        const blob = new Blob([csvRowsString], {type: "text/csv"}) 
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.setAttribute("href", url)
        a.setAttribute("download", 'Inspections ' + getToday('YYYY-MM-DDTHHmmss') + '.csv')
        document.body.appendChild(a)
        a.click()
          
      } catch (err) {
        showSnackbarMessage("An error occurred exporting the data", "error")
      }
    } else {
      showSnackbarMessage("The page contains no data to export", "warning")
    }    
  }

  const onRowClick = ({ id, inspectionType, partitionKey }) => {
    redirectToDetailPage(id, inspectionType, partitionKey);
  };

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
  }

  return (
    <Table
      style={{width: "95%"}}
      columns={columns}
      data={inspections}
      title=""
      options={{
        search: true,
        filtering: true,
        pageSize: 10,
        showSelectAllCheckbox: false,
        selection: false,
        headerStyle: {
          color: '#007749',
          fontWeight: 'bold'
        },
        exportButton: true,
        exportCsv,
        exportAllData: true,
        pageSizeOptions: [5, 10, 25]
      }}
      onRowClick= {onRowClick}      
    />);
};

export default InspectionsReportsTable