import React from "react";
import {default as MaterialButton} from "@material-ui/core/Button";

const Button = ({
  label,
  icon,
  variant="contained",
  color="default",
  classname,
  styles, 
  disabled=false,
  type="button",
  id = "muButton",
  ...props
}) => {
  const defaultProps = {
    variant,
    onClick: props.onClick ? e => props.onClick(e) : null,    
    startIcon: icon,
    color,
    className: classname,
    disabled,
    type
  };
  return <MaterialButton style={styles} {...defaultProps} id={id} data-testid={id}>{label}</MaterialButton>;
};

export default Button;
