import React, { useEffect, useReducer } from 'react';
import InspectionsDataContext from './InspectionsDataContext';
import InspectionsDataReducer from './InspectionsDataReducer';
import DamageTypesService from '../../services/DamageTypesService';
import IssueTypesServices from '../../services/IssueTypesServices';
import StatusService from '../../services/StatusService';
import {
	SET_DAMAGE_TYPES,
	SET_STATUS,
	SET_FILTERS,
	SET_SORTING,
	SET_MAP,
	SET_INSPECTIONS_PER_PAGE,
	SET_IDB_INSPECTION_INSTANCE,
	SET_USERS_ACCEPTED_ACKNOWLEDGEMENT,
	SET_ISSUE_TYPES,
} from '../types';
import {
	DAMAGE_TYPES_OFFLINE,
	ISSUE_TYPES_OFFLINE,
	INSPECTION_STATUSES_OFFLINE,
	INSPECTION_TYPE_DAMAGE,
	INSPECTION_TYPE_TREE,
} from '../../Constants';

const InspectionsDataState = ({ apiMapLoad, ...props }) => {
	const initialState = {
		type: [INSPECTION_TYPE_DAMAGE, INSPECTION_TYPE_TREE],
		damageTypes: [],
		issueTypes: [],
		status: [],
		filters: {
			type: [],
			createdBy: '',
			submittedFrom: null,
			submittedTo: null,
			damageTypes: [],
			status: [],
			viewPort: '',
			circuitId: '',
			lineIds: '',
			asset: '',
			address: '',
		},
		sortingBy: {
			sortby: '',
			sortorder: 'desc',
		},
		map: {
			defaultCenter: {
				lat: 43.1542067,
				lng: -72.3950527,
			},
			zoom: 8,
			apiMapLoad: false,
		},
		inspectionPerPage: 10,
		backgroundSync: false,
		iDBInstance: null,
		usersAcceptedAcknowledgement: null
	};
	const [inspectionsDataState, dispatch] = useReducer(
		InspectionsDataReducer,
		initialState
	);

	useEffect(() => {
		setContextDamageTypes();
		setContextStatus();
		setContextIssueTypes();
	}, []);

	useEffect(() => {
		setContextMap({
			...inspectionsDataState.map,
			apiMapLoad,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiMapLoad]);

	const getContextState = () => {
		return inspectionsDataState;
	};

	const setContextDamageTypes = async () => {
		DamageTypesService.getAll()
			.then((items) => {
				if (items && items.length > 0) {
					dispatch({
						type: SET_DAMAGE_TYPES,
						payload: { damageTypes: items.data },
					});
				} else {
					dispatch({
						type: SET_DAMAGE_TYPES,
						payload: { damageTypes: DAMAGE_TYPES_OFFLINE },
					});
				}
			})
			.catch(() => {
				dispatch({
					type: SET_DAMAGE_TYPES,
					payload: { damageTypes: DAMAGE_TYPES_OFFLINE },
				});
			});
	};

	const setContextIssueTypes = async () => {
		IssueTypesServices.getAll()
			.then((items) => {
				if (items && items.length > 0) {
					dispatch({
						type: SET_ISSUE_TYPES,
						payload: { issueTypes: items.data },
					});
				} else {
					dispatch({
						type: SET_ISSUE_TYPES,
						payload: { issueTypes: ISSUE_TYPES_OFFLINE },
					});
				}
			})
			.catch(() => {
				dispatch({
					type: SET_ISSUE_TYPES,
					payload: { issueTypes: ISSUE_TYPES_OFFLINE },
				});
			});
	};

	const setContextStatus = async () => {
		StatusService.getAll()
			.then((items) => {
				if (items && items.length > 0) {
					dispatch({
						type: SET_STATUS,
						payload: { status: items.data },
					});
				} else {
					dispatch({
						type: SET_STATUS,
						payload: { status: INSPECTION_STATUSES_OFFLINE },
					});
				}
			})
			.catch(() => {
				dispatch({
					type: SET_STATUS,
					payload: { status: INSPECTION_STATUSES_OFFLINE },
				});
			});
	};

	const setContextFilters = (parameters) => {
		dispatch({
			type: SET_FILTERS,
			payload: { filters: parameters },
		});
	};

	const setContextSorting = (sortingBy) => {
		dispatch({
			type: SET_SORTING,
			payload: { sortingBy: sortingBy },
		});
	};

	const setContextMap = (mapConfiguration) => {
		dispatch({
			type: SET_MAP,
			payload: { map: mapConfiguration },
		});
	};

	const setContextInspectionsPerPage = (inspectionsPerPage) => {
		dispatch({
			type: SET_INSPECTIONS_PER_PAGE,
			payload: { inspectionPerPage: inspectionsPerPage },
		});
	};

	const setContextIDInstance = (iDBInstance) => {
		dispatch({
			type: SET_IDB_INSPECTION_INSTANCE,
			payload: { iDBInstance: iDBInstance },
		});
	};

	const getContextUsersAccepted = async () => {
		return inspectionsDataState.usersAcceptedAcknowledgement;
	};

	const setContextUsersAccepted = (items) => {
		dispatch({
			type: SET_USERS_ACCEPTED_ACKNOWLEDGEMENT,
			payload: { usersAcceptedAcknowledgement: items },
		});
	};

	return (
		<InspectionsDataContext.Provider
			value={{
				getContextState,
				inspectionsDataState,
				setContextFilters,
				setContextSorting,
				setContextMap,
				setContextInspectionsPerPage,
				setContextIDInstance,
				getContextUsersAccepted,
				setContextUsersAccepted,
			}}
		>
			{props.children}
		</InspectionsDataContext.Provider>
	);
};
export default InspectionsDataState;
