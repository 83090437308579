import React, { useEffect, useContext, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import * as exifr from 'exifr';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ProgressBar from '../Shared/ProgressBar';
import Button from '../Shared/atoms/Button';
import Select from '../Shared/atoms/Select';
import TextFieldComponent from '../Shared/atoms/TextInput';
import TreeFormFields from './TreeFormFields';
import DamageFormFields from './DamageFormFields';
import {
	offlineInspectionsDbTag,
	IMAGE_STATUS_PENDING_TO_SYNC,
	pendingUploadsItemsStatuses,
	IMAGE_STATUS_SYNCED,
	INSPECTION_TYPE_TREE,
	INSPECTION_TYPE_DAMAGE,
	INSPECTION_TYPE_TRANSMISSION,
	LOCALSTORAGE_CURRENT_WORK_ITEM,
	ROLES,
	FF_TRANSMISSION_INSPECTION_FORM,
	LOADING_STYLES,
	WORK_QUEUE_TYPE_TRANSMISSION,
	offlineImagesDbTag,
	INSPECTION_TYPE_MANHOLE,
	SECONDARY_CABLES_RADIAL,
	SECONDARY_CABLES_NETWORK_SYSTEMS,
	GRADE_OPTIONS_LABELS,
	MANHOLE_FORM_STRUCTURAL_SECTION,
	WORK_QUEUE_TYPE_MANHOLE
} from '../../Constants';
import TransmissionContainer from './Transmission';
import ManholeContainer, {showDetailInput} from './Manhole';
import ImagesControl from '../Shared/ImagesControl';
import PhotoGuidelines from './PhotoGuidelines';
import InspectionsService from '../../services/InspectionsService';
import ImageService from '../../services/ImageService';
import ImageContext from '../../context/Image/ImageContext';
import InspectionsDataContext from '../../context/InspectionsData/InspectionsDataContext';
import {
	GetUserLocation,
	getNewLocation,
	insertDataInBrowserDatabaseAndGetCount,
	isArrayNotEmpty,
	validRoles,
	setDefaultInspectionType,
	getStoreObjctOfIDB,
} from '../../Utils';
import AddressAutocomplete from '../Shared/AddressAutocomplete';
import { reverseGeocode } from '../../Utils/geocode';

const useStyles = makeStyles((theme) => ({
	inspectionFormContainer: {
		position: 'relative',
		width: '100%',
	},
	form: {
		maxWidth: '100%',
		margin: theme.spacing(1),
		marginTop: theme.spacing(3),
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.dark,
	},
	blankImage: {
		maxWidth: 260,
		height: 200,
		objectFit: 'cover',
		margin: '0 auto',
		borderRadius: 5,
	},
	containerImage: {
		display: 'flex',
		justifyContent: 'center',
	},
	buttonContainer: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			justifyContent: 'flex-end',
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
}));

const treeFormInitialValue = {
	inspectionType: 'Damage',
	notes: '',
	eventId: '',
	timeCallReceived: new Date(),
	dateArrived: new Date(),
	address: '',
	treeSpecies: '',
	treeCondition: '',
	failureMode: '',
	issueType: 'Tree',
	construction: '',
	preFailureDistance: 0,
	trimZoneTreePosition: '',
	treeSize: '',
	isArboristNeeded: false,
	isGroundingNeeded: false,
	onPrivateProperty: false,
};

const InspectionForm = ({ userRoles, setInspectionsOffline }) => {
	const [editing, setEditing] = useState({ status: false });
	const [damageTypesOptions, setDamageTypesOptions] = useState([]);
	const [damageTypes, setDamageTypes] = useState([]);
	const [issueTypesOptions, setIssueTypesOptions] = useState([]);
	const [issueTypes, setIssueTypes] = useState([]);
	const [poleNumber, setPoleNumber] = useState('');
	const [circuitID, setCircuitID] = useState('');
	const [workItemID, setWorkItemID] = useState('');
	const [workQueueID, setWorkQueueID] = useState('');
	const [devices, setDevices] = useState([]);
	const [assetLocation, setAssetLocation] = useState(null);
	const [devicesList, setDevicesList] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [images, setImages] = useState({ urls: [], names: [] });
	const [multipleInspections, setMultipleInspections] = useState(false);
	const [inspectionType, setInspectionType] = useState(INSPECTION_TYPE_DAMAGE);
	const [notes, setNotes] = useState('');
	const [treeInspectionForm, setTreeInspectionForm] = useState({});
	const [isTreeFormValid, setIsTreeFormValid] = useState(false);
	const [resetTreeForm, setResetTreeForm] = useState(false);
	const [poleId, setPoleId] = useState(null);
	const [workQueueName, setWorkQueueName] = useState('');
	const [workQueueType, setWorkQueueType] = useState('');
	const [transmissionFromWorkItem, setTransmissionFromWorkItem] = useState(null);
	const [manholeFromWorkItem, setManholeFromWorkItem] = useState(null);	
	const [inspection, setInspection] = useState(treeFormInitialValue);
	const [types, setTypes] = useState([]);
	const [selectedCardinalPoints, setSelectedCardinalPoints] = useState([]);
	const {
		getContextImages,
		state,
		resetContextImage,
		setGpsLocation,
		setPendingImages,
	} = useContext(ImageContext);
	const { getContextState, inspectionsDataState } = useContext(
		InspectionsDataContext
	);
	const classes = useStyles();
	const history = useHistory();
	const browserRedirectLocation = useLocation();
	const ff_transmission_inspection_form = useFlags()[FF_TRANSMISSION_INSPECTION_FORM];
	const { enqueueSnackbar } = useSnackbar();
	let editableFields = true;
	let isTreeInspectionDetail = false;
	
	
	useEffect(() => {		
		if (validRoles([ROLES.treeInspectionContributor], userRoles).length > 0) {
			setInspectionType(INSPECTION_TYPE_TREE);
		} else if(validRoles([ROLES.transmissionInspectionContributor], userRoles).length > 0) {
			setInspectionType(INSPECTION_TYPE_TRANSMISSION);
		} else if(validRoles([ROLES.manholeInspectionContributor], userRoles).length > 0) {
			setInspectionType(INSPECTION_TYPE_MANHOLE);
		}		

		if (!browserRedirectLocation?.assetFromVideo?.location && (!browserRedirectLocation.currentTransmission && !browserRedirectLocation.currentWorkItem)) {
			getUserLocation();
		}
		getCurrentWorkQueue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const inspectionsData = getContextState();
		if (damageTypesOptions.length === 0)
			setDamageTypesOptions(inspectionsData.damageTypes || []);
		if (issueTypesOptions.length === 0){
			let _issueTypes = [];
			if(inspectionsData.issueTypes && inspectionsData.issueTypes.length > 0){
				_issueTypes= inspectionsData.issueTypes.map(issueType => ({...issueType, status: 'New'}) );
			}
			setIssueTypesOptions(_issueTypes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionsDataState]);

	useEffect(() => {
		const currentWorkItems = JSON.parse(
			localStorage.getItem(LOCALSTORAGE_CURRENT_WORK_ITEM)
		) || { workItemType: '' };
		const defaultInspectionType = setDefaultInspectionType(
			userRoles,
			ff_transmission_inspection_form,
			currentWorkItems
		);
		setTypes([...defaultInspectionType.types]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ff_transmission_inspection_form]);

	const setAddressInputFromCoordinates = async (latitude,longitude) => { 
		try {
			const address = await reverseGeocode(latitude, longitude);
			onChangeHandler('address', address);
		} catch (error) {
			showSnackbarMessage(
				'An error ocurred getting the address',
				'warning'
			);
		}
	 };
	const getUserLocation = () => {
		GetUserLocation()
			.then((response) => {
				if (!response.error && response.position && response.position.coords) {
					const location = {
						latitude: response.position.coords.latitude,
						longitude: response.position.coords.longitude,
					};
					setGpsLocation(location);
					setAddressInputFromCoordinates(location.latitude, location.longitude);
				}
			})
			.catch((error) => {
				if (error.message.code === 1) {
					setGpsLocation({});
					showSnackbarMessage('Location not shared', 'error');
				} else {
					setGpsLocation({});
					showSnackbarMessage('An error occurred', 'error');
				}
			});
	};

	const getCurrentWorkQueue = () => {
		let workItemLocalStorage = JSON.parse(
			localStorage.getItem(LOCALSTORAGE_CURRENT_WORK_ITEM)
		);
		let circuitIdLocalStorage =
			workItemLocalStorage && workItemLocalStorage.circuitId !== ''
				? workItemLocalStorage.circuitId
				: '';
		let workQueueIdLocalStorage =
			workItemLocalStorage && workItemLocalStorage.workQueueId !== ''
				? workItemLocalStorage.workQueueId
				: '';
		let workQueueNameLocalStorage =
			workItemLocalStorage && workItemLocalStorage.workQueue !== ''
				? workItemLocalStorage.workQueue
				: '';
		setWorkQueueType(workItemLocalStorage?.workItemType || false);
		if (
			browserRedirectLocation.currentWorkItem &&
			browserRedirectLocation.currentWorkItem.id
		) {
			const { currentWorkItem } = browserRedirectLocation;
			setCircuitID(
				currentWorkItem.circuitId && currentWorkItem.circuitId !== ''
					? currentWorkItem.circuitId
					: circuitIdLocalStorage
			);
			setDevicesList(currentWorkItem.devices);
			setWorkItemID(currentWorkItem.workItemId);
			setWorkQueueID(
				currentWorkItem.workQueueId && currentWorkItem.workQueueId !== ''
					? currentWorkItem.workQueueId
					: workQueueIdLocalStorage
			);
			setAssetLocation(currentWorkItem.location);
			updateLocation({ latitude: currentWorkItem.location.lat, longitude: currentWorkItem.location.lng });
			setPoleNumber(currentWorkItem.pole);
			setPoleId(
				currentWorkItem.devices && currentWorkItem.devices.length > 0
					? currentWorkItem.id
					: null
			);
			setWorkQueueName(
				currentWorkItem.workQueue && currentWorkItem.workQueue !== ''
					? currentWorkItem.workQueue
					: workQueueNameLocalStorage
			);
			setWorkQueueType(INSPECTION_TYPE_DAMAGE);
			if (currentWorkItem && currentWorkItem.devices) {
				const defaultCurrentWorkItem = currentWorkItem.devices[0];
				if (defaultCurrentWorkItem && defaultCurrentWorkItem.noPole) {
					setDevices([
						`${defaultCurrentWorkItem.type} - ${defaultCurrentWorkItem.id}`,
					]);
				}
			}
		} else if ( // Transmission
			browserRedirectLocation.currentTransmission &&
			browserRedirectLocation.currentTransmission.lineId
		) {
			setInspectionType(INSPECTION_TYPE_TRANSMISSION);
			const { currentTransmission } = browserRedirectLocation;
			setTransmissionFromWorkItem(currentTransmission);
			setWorkQueueName(
				currentTransmission.workQueue?.name || workQueueNameLocalStorage
			);
			setWorkQueueID(
				currentTransmission.workQueue?.workQueueId || workQueueIdLocalStorage
			);
			setWorkQueueType(WORK_QUEUE_TYPE_TRANSMISSION);
		} else if (// Manhole
			browserRedirectLocation.currentTransmission &&
			browserRedirectLocation.currentTransmission.id
		) {
			setInspectionType(INSPECTION_TYPE_MANHOLE);
			const { currentTransmission } = browserRedirectLocation;
			const assetLocation = { lng: currentTransmission.location.coordinates[0], lat: currentTransmission.location.coordinates[1] };
			setWorkQueueName(currentTransmission.workQueue?.name || workQueueNameLocalStorage);
			setWorkQueueID(currentTransmission.workQueue?.workQueueId || workQueueIdLocalStorage);
			setWorkQueueType(WORK_QUEUE_TYPE_MANHOLE);
			setManholeFromWorkItem(currentTransmission);
			setAssetLocation(assetLocation);
			setWorkItemID(currentTransmission.workItemId);
		} else if (browserRedirectLocation.assetFromVideo) {
			// If user comes from asset video
			const {assetFromVideo} = browserRedirectLocation;
			const assetLocation = { lng: assetFromVideo.location.coordinates[0], lat: assetFromVideo.location.coordinates[1] };
			setCircuitID(assetFromVideo.circuitId);
			setPoleNumber(assetFromVideo.poleNumber);
			setDevicesList(assetFromVideo.devices);
			setAssetLocation(assetLocation);
			updateLocation({ latitude: location.lat, longitude: location.lng });
		}
	};

	function validateForm() {
		const checkForEmpty = value => value === '' || value === null || value === undefined
		let error = false;
		if (inspectionType === INSPECTION_TYPE_DAMAGE) {
			if (issueTypes.length === 0 || damageTypes.length === 0) {
				error = true;
			}
		} else if (inspectionType === INSPECTION_TYPE_TREE ) {
			error = !isTreeFormValid;
		} else if (inspectionType === INSPECTION_TYPE_MANHOLE) {
			if (checkForEmpty(inspection.state) ||
				checkForEmpty(inspection.town) ||
				checkForEmpty(inspection.manholeNumber) ||
				checkForEmpty(inspection.address) ||
				// YES / NO section
				checkForEmpty(inspection.strayVoltage) ||
				(inspection.strayVoltage === 'true' && checkForEmpty(inspection.strayVoltageNotes)) ||
				checkForEmpty(inspection.vactor) ||
				(inspection.vactor === 'true' && checkForEmpty(inspection.vactorNotes)) ||
				checkForEmpty(inspection.gas) ||
				(inspection.gas === 'true' && checkForEmpty(inspection.gasNotes)) ||
				checkForEmpty(inspection.yellowStream) ||
				(inspection.yellowStream === 'true' && checkForEmpty(inspection.yellowStreamNotes)) ||
				checkForEmpty(inspection.asbestos) ||
				(inspection.asbestos === 'true' && checkForEmpty(inspection.asbestosNotes)) ||
				checkForEmpty(inspection.manholeCoverType) ||
				checkForEmpty(inspection.manholeTagReplaced) ||
				(inspection.manholeTagReplaced === 'true' && checkForEmpty(inspection.manholeTagReplacedNotes)) ||				
				checkForEmpty(inspection.repairOnLocation) ||
				(inspection.repairOnLocation === 'true' && checkForEmpty(inspection.repairOnLocationNotes)) ||				
				checkForEmpty(inspection.followUpWorkNeeded) ||
				(inspection.followUpWorkNeeded === 'true' && checkForEmpty(inspection.equipmentOutageRequired)) ||
				(inspection.followUpWorkNeeded === 'true' && checkForEmpty(inspection.permitRequired)) ||
				(inspection.followUpWorkNeeded === 'true' && checkForEmpty(inspection.followUpWorkNeededNotes)) ||
				checkForEmpty(inspection.isSecondaryCables) ||
				(inspection.secondaryCablesType, inspection.isSecondaryCables === 'true' && checkForEmpty(inspection.secondaryCablesType)) ||
				(inspection.secondaryCablesRadial, inspection.secondaryCablesType === SECONDARY_CABLES_RADIAL && checkForEmpty(inspection.secondaryCablesRadial)) ||
				(inspection.secondaryCablesRadialWorkDetailFollowUp + ' ' + inspection.secondaryCablesRadialWorkDetailQuantity, showDetailInput(inspection.secondaryCablesRadial) && (checkForEmpty(inspection.secondaryCablesRadialWorkDetailFollowUp) || checkForEmpty(inspection.secondaryCablesRadialWorkDetailQuantity))) ||
				(inspection.secondaryCablesNetworkSystemWorkCompletedQuantity, inspection.secondaryCablesType === SECONDARY_CABLES_NETWORK_SYSTEMS && checkForEmpty(inspection.secondaryCablesNetworkSystemWorkCompletedQuantity)) ||
				(inspection.jointMeasurements === undefined || inspection.jointMeasurements === null) ||
				(inspection.cableSections === undefined || inspection.cableSections === null)
			) {
				error = true;
			}

			const validateDynamicFields = (section, fields) => {
				inspection[section].map(section => {
					fields.map(field => {
						if (checkForEmpty(section[field])) 
							error = true;
					})			
				});
			}

			if (Array.isArray(inspection.jointMeasurements)) {
				validateDynamicFields('jointMeasurements', ['cableMeasurement', 'cableLocation', 'cableFeederID']);
			}

			if (Array.isArray(inspection.cableSections)) {
				validateDynamicFields('cableSections', ['referenceTemperature', 'cableEntering', 'joint', 'cableExiting']);
			}

			if (Array.isArray(inspection.equipmentSections)) {
				validateDynamicFields('equipmentSections', ['equipmentScanned', 'temperature', 'referenceTemperature']);
			}


			MANHOLE_FORM_STRUCTURAL_SECTION.map(structuralOption => {
				if (checkForEmpty(inspection[structuralOption.valueName]) || (inspection[structuralOption.valueName] === 'true' && checkForEmpty(inspection[structuralOption.notes]))) {
					error = true;
				}
			});

			GRADE_OPTIONS_LABELS.map(gradedOption => {			
				if (checkForEmpty(inspection[gradedOption.valueName]) || (showDetailInput(inspection[gradedOption.valueName]) && (checkForEmpty(inspection[gradedOption.detailQuantity]) || checkForEmpty(inspection[gradedOption.detailFollowUp])))) {
					error = true;
				}
			});
		}
		return error;
	}

	function resetForm() {
		resetContextImage();
		setEditing({ status: false });
		setNotes('');
		setIsTreeFormValid(false);
		setMultipleInspections(true);
		setCircuitID('');
		if (inspectionType === INSPECTION_TYPE_DAMAGE) {
			setPoleNumber('');
			setDevicesList([]);
			setIssueTypes([]);
			setDamageTypes([]);
			setWorkQueueName('');
		} else {
			setResetTreeForm(true);
			setResetTreeForm(false);
		}
		getUserLocation();
	}

	function showSnackbarMessage(message, type) {
		enqueueSnackbar(message, {
			variant: type,
		});
	}

	async function setInspectionData(isSubmit) {
		setSubmitting(true);
		if (!validateForm()) {
			let location = null;
			let coordinates;
			let inspectionImages = [];
			let imagesSavedOffline = [];
			if (images.names.length > 0) {
				images.names.forEach((image, index) => {
					if (window.navigator.onLine) {
						if (image.name !== '') {
							image.cardinalDirection = selectedCardinalPoints[index] || 'None';
							inspectionImages.push(image);
						} else if (image.file) {
							let promises = ImageService.createInspectionImages(
								[image.file],
								image.coordinates
							);
							imagesSavedOffline.push(promises);
						}
					} else {
						image.cardinalDirection = selectedCardinalPoints[index] || 'None';
						inspectionImages.push(image);
					}
				});
			} else {
				inspectionImages = inspectionType === INSPECTION_TYPE_MANHOLE ? [{ name: '', cardinalDirection: 'None'}] : [{ name: '' }];
			}

			// send images saved offline
			if (imagesSavedOffline.length > 0) {
				let responsePromises = await Promise.all(imagesSavedOffline);
				let names = [];

				if (responsePromises.length > 0) {
					names = responsePromises
						.filter((image) => image[0].name !== '')
						.map((image, index) => {
							return { name: image[0].name, cardinalDirection: selectedCardinalPoints[index] || 'None' };
						});
				}
				inspectionImages = [...inspectionImages, ...names];
			}

			if (assetLocation) {
				coordinates = [assetLocation.lng, assetLocation.lat];
			} else {
				// Get Lat long from browser
				const { gpsLocation } = getContextImages();
				if (
					gpsLocation &&
					gpsLocation.longitude &&
					gpsLocation.latitude &&
					!multipleInspections
				) {
					coordinates = [gpsLocation.longitude, gpsLocation.latitude];
				} else {
					const newLocation = await getNewLocation();
					if (newLocation) {
						setGpsLocation({
							latitude: newLocation.latitude,
							longitude: newLocation.longitude,
						});
						coordinates = [newLocation.longitude, newLocation.latitude];
					} else {
						// Get image Lat long from exif data
						if (images.urls.length > 0) {
							let result = await exifr.gps(images.urls.reverse()[0]);
							if (result) {
								coordinates = [result.longitude, result.latitude];
							}
						}
					}
				}
			}

			if (coordinates) {
				location = {
					type: 'Point',
					coordinates,
				};
			}

			let inspectionData = {};
			if (inspectionType === INSPECTION_TYPE_DAMAGE) {
				const damageTypesObj = [];
				damageTypes.map((damageType) =>
					damageTypesObj.push({ name: damageType })
				);
				
				inspectionData.IssueTypes = issueTypes;
				inspectionData.damageTypes = damageTypesObj;
				inspectionData.poleNumber = poleNumber;

				if (devices.length > 0) {
					inspectionData.assets = devices.map((deviceSelected) => {
						const device = deviceSelected.split(' - ');
						return (
							isArrayNotEmpty(device) && { id: device[1], type: device[0] }
						);
					});
				}
				if (poleId) {
					inspectionData.PoleId = poleId;
				}
				if (workItemID) {
					inspectionData.WorkItemId = workItemID;
				}
				if (workQueueID) {
					inspectionData.WorkQueue = { name: workQueueName, id: workQueueID };
				}
			} else if (inspectionType === INSPECTION_TYPE_MANHOLE) {
				inspectionData = { ...inspection };
				if (workQueueID) {
					inspectionData.WorkQueue = { name: workQueueName, id: workQueueID };
				}
				if (workItemID) {
					inspectionData.manholeId = workItemID;
				}
			} else {
				inspectionData = { ...treeInspectionForm };
			}
			inspectionData.inspectionType = inspectionType;
			inspectionData.address = inspection.address;
			inspectionData.notes = notes;
			inspectionData.status = isSubmit ? 1 : 0;
			inspectionData.location = location;
			inspectionData.images = inspectionImages;
			if (circuitID) {
				inspectionData.circuitId = circuitID;
			}
			return inspectionData;
		} else {
			return false;
		}
	}

	function handleSubmit(event, isSubmit) {
		event.preventDefault();
		const inspectionDataPromise = setInspectionData(isSubmit);
		inspectionDataPromise
			.then((inspectionData) => {
				if (inspectionData) {
					if (!navigator.onLine) {
						if (editing.id) {
							inspectionData.id = editing.id;
							inspectionData.partitionKey = editing.partitionKey;
						}
						inspectionData.dateCreated = moment.utc(new Date()).format();
						registerOffLineData(inspectionData);
						resetForm(event);
						setSubmitting(false);
					} else {
						if (isSubmit && editing.status) {
							// Edit current inspection after save
							inspectionData.id = editing.id;
							inspectionData.partitionKey = editing.partitionKey;
							InspectionsService.edit(inspectionData)
								.then((resp) => {
									if (resp) {
										resetForm(event);
										showSnackbarMessage(
											'Inspection submitted successfully.',
											'success'
										);
									}
								})
								.catch(() => {
									showSnackbarMessage(
										'An error occurred updating the inspection.',
										'warning'
									);
								});
							setSubmitting(false);
							resetForm(event);
							history.push('/Inspections/_/asset');
						} else {
							// Create new Inspection (submit or save)
							InspectionsService.create(inspectionData)
								.then((response) => {
									if (response.data) {
										let snackbarMessage = 'Inspection submitted successfully.';
										// If submit was pressed, reset form
										if (isSubmit) {
											// If inspection has images pending or not
											if (response.data.images?.length > 0) {
												let pendingImagesIndex = [];
												const inspectionImagesNotSynced = [];
												// Loop through inspection images, if pending to sync store the index
												let pendingToSyncImages = false;
												response.data.images.forEach((image, index) => {
													if (image.status === IMAGE_STATUS_PENDING_TO_SYNC) {
														const _image = image;
														_image.status = pendingUploadsItemsStatuses[2];
														inspectionImagesNotSynced.push(_image);
														pendingImagesIndex.push(index);
														if (!pendingToSyncImages)
															pendingToSyncImages = true;
													} else {
														inspectionImagesNotSynced.push(image);
													}
												});
												// At least one image pending to sync
												if (pendingToSyncImages) {
													// Store inspection if there is at least one image not synced yet
													const inspectionSaved = response.data;
													inspectionSaved.images = inspectionImagesNotSynced;
													registerOffLineData({ ...inspectionSaved });

													// keep blobs generated previously on the state so the user can see its image on the list before they get synced
													const pendingImagesUrlsAndNames = [];
													pendingImagesIndex.map((index) =>
														pendingImagesUrlsAndNames.push({
															url: state.urls[index],
															name: state.names[index].name,
														})
													);
													// save pending images name and url in state
													setPendingImages(pendingImagesUrlsAndNames);
													// delete those who are already synced if any
													deleteImagesFromDB(
														response.data.images.filter(
															(image) => image.status === IMAGE_STATUS_SYNCED
														)
													);
												} else {
													// All images synced, delete them from indexed DB and do not store inspection in DB
													deleteImagesFromDB(response.data.images);
												}
											}
											resetForm(event);
											history.push('/Inspections/_/asset');
										} else {
											// Else, leave form data, set editing to true and store id of the inspection just created
											snackbarMessage = 'Inspection saved successfully.';
											setEditing({
												status: true,
												id: response.data.id,
												partitionKey: response.data.partitionKey,
											});
										}
										showSnackbarMessage(snackbarMessage, 'success');
									} else {
										showSnackbarMessage(
											'An error occurred while submitting the Inspection. Please try again.',
											'error'
										);
									}
								})
								.catch((error) => {
									if (error.response) {
										showSnackbarMessage(
											'An error occurred while submitting the Inspection. Please try again.',
											'error'
										);
									}
								})
								.finally(() => setSubmitting(false));
						}
					}
				}
			})
			.catch((error) => {
				if (error.response) {
					showSnackbarMessage(
						'An error occurred while submitting the Inspection. Please try again.',
						'error'
					);
				}
			});
	}
	const deleteImagesFromDB = (syncedInspectionImages) => {
		if (syncedInspectionImages?.length > 0) {
			const { iDBInstance } = getContextImages();
			const storeObj = getStoreObjctOfIDB(iDBInstance, offlineImagesDbTag);
			if (storeObj) {
				var cursorRequest = storeObj.openCursor();
				cursorRequest.onsuccess = async function (evt) {
					var cursor = evt.target.result;
					if (cursor?.value) {
						const imagesPendingToDeleteInBrowserDB = cursor.value;
						const imagesOnDBFound = [];
						// Search on each item of index DB for the images belonging to this inspection
						imagesPendingToDeleteInBrowserDB.map((dbImage) =>
							syncedInspectionImages.find((inspectionImage) => {
								const imageOnDBFound = inspectionImage.name === dbImage.name;
								imageOnDBFound && imagesOnDBFound.push(imageOnDBFound);
							})
						);
						// Images in this cursor belong to the inspection
						if (imagesOnDBFound.length > 0) {
							cursor.delete(cursor.key);
						}
						cursor.continue();
					}
				};
			}
		}
	};
	const registerOffLineData = async (inspectionData) => {
		const inspectionsContext = getContextState();
		const offlineInspectionsCount =
			await insertDataInBrowserDatabaseAndGetCount(
				inspectionData,
				offlineInspectionsDbTag,
				inspectionsContext.iDBInstance
			);
		// send updated offline inspections count
		offlineInspectionsCount && setInspectionsOffline(offlineInspectionsCount);
		showSnackbarMessage(
			'You\'re offline, any Inspections submitted will be uploaded when you\'re back online',
			'warning'
		);
	};

	const updateLocation = (location) => {
		setGpsLocation(location);
		setAddressInputFromCoordinates(location.latitude, location.longitude);
	};

	const onChangeType = (e) => {
		setInspectionType(e);
	};

	const onChangeHandler = (name, value) => {
		if (editTreeInspection) {
			const _inspection = { ...inspection, [name]: value };
			setInspection(_inspection);
			editTreeInspection(_inspection);
			isValidForm(validForm(_inspection));
		}
	};

	const onPlaceChange = (place) => {
		const { formatted_address, geometry } = place;
		onChangeHandler('address', formatted_address);
		if (!isTreeInspectionDetail) {
			const location = {
				latitude: geometry.location.lat(),
				longitude: geometry.location.lng(),
			};
			updateLocation(location);
		}
	};

	const editTreeInspection = (treeInspection) => {
		setTreeInspectionForm(treeInspection);
	};

	const isValidForm = (validForm) => {
		setIsTreeFormValid(validForm);
	};

	const validForm = (inspection) => {
		let isValid = true;

		const requiredConditional =
			inspection.issueType === 'Tree' || inspection.issueType === 'Branch';

		if (
			inspection.eventId === '' ||
			inspection.dateArrived === '' ||
			!inspection.dateArrived ||
			inspection.timeCallReceived === '' ||
			!inspection.timeCallReceived ||
			inspection.address === ''
		) {
			isValid = false;
		}
		if (
			requiredConditional &&
			(inspection.failureMode === '' ||
				inspection.treeCondition === '' ||
				inspection.treeSpecies === '' ||
				inspection.trimZoneTreePosition === '')
		) {
			isValid = false;
		}

		return isValid;
	};
	const showAddressAndDetails = inspectionType !== INSPECTION_TYPE_TRANSMISSION && inspectionType !== INSPECTION_TYPE_MANHOLE;

	return (
		<div className={classes.inspectionFormContainer}>
			{submitting && <ProgressBar styles={LOADING_STYLES} />}
			<Container component="div" maxWidth="md">
				<form className={classes.form}>
					{inspectionType !== INSPECTION_TYPE_TRANSMISSION && (
						<ImagesControl
							images={images}
							onImagesChange={(e) => setImages(e)}
							inspectionType={inspectionType}
							selectedCardinalPoints={selectedCardinalPoints}
							setSelectedCardinalPoints={setSelectedCardinalPoints}
							editing={true}
						/>
					)}
					<Grid container spacing={2}>
						{inspectionType === INSPECTION_TYPE_DAMAGE && <PhotoGuidelines />}
						{inspectionType !== INSPECTION_TYPE_TRANSMISSION && (
							<Grid item xs={12}>
								<Select
									selectItem={onChangeType}
									selectOptions={types}
									itemSelected={[inspectionType]}
									label="Type"
									required="true"
									multiple={false}
									helperText={false}
									id="inspectionTypeInput"
									disabled={!!manholeFromWorkItem || !!transmissionFromWorkItem || false}
								/>
							</Grid>
						)}
						{inspectionType === INSPECTION_TYPE_TREE && (
							<TreeFormFields
								editTreeInspection={editTreeInspection}
								circuitID={circuitID}
								setCircuitID={setCircuitID}
								resetForm={resetTreeForm}
								editableFields={editableFields}
								initialValue={treeFormInitialValue}
								inspection={inspection}
								setInspection={setInspection}
								isTreeInspectionDetail={isTreeInspectionDetail}
								onChangeHandler={onChangeHandler}
							/>
						)}
						{inspectionType === INSPECTION_TYPE_DAMAGE && (
							<DamageFormFields
								issueTypesOptions={issueTypesOptions}
								setIssueTypes={setIssueTypes}
								issueTypesSelected={issueTypes}
								damageTypesOptions={damageTypesOptions}
								setDamageTypes={setDamageTypes}
								damageTypes={damageTypes}
								circuitID={circuitID}
								setCircuitID={setCircuitID}
								poleNumber={poleNumber}
								setPoleNumber={setPoleNumber}
								devicesList={
									devicesList && devicesList.length > 0
										? devicesList.map(
											(device) => `${device.type} - ${device.id}`
										)
										: []
								}
								devices={devices}
								setDevices={setDevices}
								workQueueName={
									workQueueType !== INSPECTION_TYPE_TRANSMISSION
										? workQueueName
										: false
								}
							/>
						)}
						{inspectionType === INSPECTION_TYPE_MANHOLE && (
							<ManholeContainer
								inspection={inspection}
								setInspection={setInspection}
								onPlaceChange={onPlaceChange}
								manholeFromWorkItem={manholeFromWorkItem}	
							/>
						)}
						{showAddressAndDetails && <>
							<Grid item xs={12}>
								{navigator.onLine && window.google ? (
									<AddressAutocomplete onPlaceChange={onPlaceChange}>
										<TextFieldComponent
											value={inspection.address}
											label="Address"
											placeholder=""
											disabled={!editableFields}
											onChange={(e) => onChangeHandler('address', e)}
											required={
												inspectionType === INSPECTION_TYPE_TREE ? true : false
											}
										/>
									</AddressAutocomplete>
								) : (
									<TextFieldComponent
										value={inspection.address}
										label="Address"
										placeholder=""
										disabled={!editableFields}
										onChange={(e) => onChangeHandler('address', e)}
										required={
											inspectionType === INSPECTION_TYPE_TREE ? true : false
										}
									/>
								)}
							</Grid>
							<Grid item xs={12}>
								<TextFieldComponent
									id="additionalDetails"
									value={notes}
									label="Additional Inspection details"
									placeholder="Physical Hazards, Notes about the area surrounding"
									onChange={setNotes}
									multiline={true}
									rows="4"
								/>
							</Grid>
						</>}
						{inspectionType !== INSPECTION_TYPE_TRANSMISSION && 
							<Grid item xs={12} className={classes.buttonContainer}>
								<Button
									disabled={validateForm() || submitting}
									variant="outlined"
									color="primary"
									onClick={(event) => handleSubmit(event, false)}
									icon={<SaveTwoToneIcon />}
									label="Save"
									styles={{ margin: '0 1rem 0 0' }}
									id="saveButton"
								/>
								<Button
									type="submit"
									disabled={validateForm() || submitting}
									color="primary"
									icon={<AddOutlinedIcon />}
									onClick={(event) => handleSubmit(event, true)}
									label="Submit"
									id="submitButton"
								/>
							</Grid>}
					</Grid>
					{inspectionType === INSPECTION_TYPE_TRANSMISSION && (
						<TransmissionContainer
							images={images}
							transmissionFromWorkItem={transmissionFromWorkItem}
							inspectionType={inspectionType}
							types={types}
							onChangeType={onChangeType}
							ff_transmission_inspection_form={ff_transmission_inspection_form}
							setImages={setImages}
							submitting={submitting}
							setSubmitting={setSubmitting}
							workQueue={
								workQueueType === INSPECTION_TYPE_TRANSMISSION &&
								workQueueID &&
								workQueueName && { name: workQueueName, id: workQueueID }
							}
						/>
					)}
				</form>
			</Container>
		</div>
	);
};

export default InspectionForm;
