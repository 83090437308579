import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "2rem 0px 0rem 0rem;"
  },
  header: {
    wordBreak: "break-word",
    textAlign: 'center'   
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
  },
}));

const PageHeader = ({ headerText, icon }) => {
  const classes = useStyles();
  return (
    <div
      data-testid="pageheader"
      className={classes.headerContainer}
    >    
      {
      icon ? <Avatar className={classes.avatar}>
        {icon}
      </Avatar> : null
      }
      <Typography component="h1" variant="h5" className={classes.header}>
        {headerText}
      </Typography>
    </div>
  )
}

PageHeader.propTypes = {
  headerText: PropTypes.string
};

PageHeader.defaultProps = {
  headerText: ""
};

export default PageHeader;