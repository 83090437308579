import {
	GET_IMAGE,
	SET_IMAGE,
	UPLOAD_IMAGE,
	RESET_IMAGE,
	SET_BLOB_LIST,
	SET_PROGRESS_BAR,
	SET_LOCATION,
	SET_IDB_IMAGE_INSTANCE,
	SET_IMAGE_TO_DELETE,
	SET_PENDING_IMAGES,
} from './../types';

const ImageReducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
	case GET_IMAGE:
		return {
			...state,
			...payload,
		};
	case SET_IMAGE:
		return {
			...state,
			...payload,
		};
	case UPLOAD_IMAGE:
		return {
			urls: [...state.urls],
			names: [...payload],
			uploadingImages: false,
			progressBar: 0,
			gpsLocation: { ...state.gpsLocation },
			iDBInstance: state.iDBInstance,
			namesToDelete: [...state.namesToDelete],
			pendingImages: [...state.pendingImages],
		};
	case RESET_IMAGE:
		return {
			urls: [],
			names: [],
			blobList: [],
			uploadingImages: false,
			progressBar: 0,
			gpsLocation: { ...state.gpsLocation },
			iDBInstance: state.iDBInstance,
			namesToDelete: [],
			pendingImages: state.pendingImages,
		};
	case SET_BLOB_LIST:
		// eslint-disable-next-line no-case-declarations
		const blobList = [...(state.blobList || []), payload];
		return {
			urls: [],
			names: [],
			blobList,
			uploadingImages: false,
			gpsLocation: { ...state.gpsLocation },
			iDBInstance: state.iDBInstance,
			namesToDelete: [],
			pendingImages: state.pendingImages,
		};
	case SET_PROGRESS_BAR:
		return {
			...state,
			...payload,
		};
	case SET_LOCATION:
		return {
			...state,
			...payload,
		};
	case SET_IDB_IMAGE_INSTANCE:
		return {
			...state,
			...payload,
		};
	case SET_IMAGE_TO_DELETE:
		return {
			...state,
			...payload,
		};
	case SET_PENDING_IMAGES:
		return {
			...state,
			...{ pendingImages: payload },
		};
	default:
		return state;
	}
};

export default ImageReducer;
