import StorageService from './StorageService';
import { callApi } from '../callApi';
import { imagesCompressor } from '../Utils';
import { RESOURCE_IMAGES, RESOURCE_INSPECTIONS } from '../Constants';

class ImageService {
	createInspectionImage(image, coordinates, onUploadProgress) {
		const formData = new FormData();
		formData.append('image', image);
		if (coordinates && coordinates.length > 0) {
			formData.append('latitude', coordinates[0]);
			formData.append('longitude', coordinates[1]);
		}
		return callApi({
			method: 'post',
			url: `${RESOURCE_INSPECTIONS}${RESOURCE_IMAGES}`,
			data: formData,
			contentType: 'multipart/form-data',
			onUploadProgress,
		});
	}

	getBlobList = (files) => {
		if (files) {
			const fileList = new Array(...files);
			const blobList = [];
			fileList.forEach((image) => {
				if (image) blobList.push(URL.createObjectURL(image));
			});
			return blobList;
		}
	};

	createInspectionImages(files, coordinates, onUploadProgress) {
		return new Promise((resolve, reject) => {
			const promiseList = [];
			imagesCompressor(files)
				.then((filesCompressed) => {
					if (files && files.length > 0) {
						for (const file of filesCompressed) {
							promiseList.push(
								this.createInspectionImage(file, coordinates, (event) => {
									if (onUploadProgress) onUploadProgress(event);
								}).then((name) => {
									return name;
								})
							);
						}
						Promise.all(promiseList).then((resp) => {
							if (resp && resp[0] && resp[0].data?.length > 0) {
								const result = resp.map((result) => ({
									name: result ? result.data[0].name : '',
									status: result ? result.data[0].status : '',
								}));
								resolve(result);
							} else {
								reject();
							}
						});
					}
				})
				.catch(() => {});
		});
	}

	getInspectionImages(images, containerName) {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			const promiseNames = [];
			if (images?.length) {
				for (const image of images) {
					if (image.name !== '')
						promiseNames.push(StorageService.getBlob(RESOURCE_IMAGES, containerName, image.name));
				}
				await Promise.all(promiseNames).then((resp) => {
					if (resp) {
						resolve(resp);
					} else {
						reject();
					}
				});
			}
		});
	}

	deleteInspectionImages(idInspection, images, partitionKey) {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			const promiseList = [];
			if (images && images.length > 0) {
				for (const image of images) {
					if (image.name !== '')
						promiseList.push(
							this.deleInspectionImage(idInspection, image.name, partitionKey)
						);
				}
				await Promise.all(promiseList).then((resp) => {
					if (resp) {
						resolve(resp);
					} else {
						reject();
					}
				});
			}
		});
	}

	deleteImage(id) {
		return callApi({ method: 'delete', url: `${RESOURCE_IMAGES}${id}` });
	}

	deleInspectionImage(id, name, partitionKey) {
		return callApi({
			method: 'delete',
			url: `${RESOURCE_INSPECTIONS}Inspections/${id}/images/${name}?partitionKey=${partitionKey}`,
		});
	}
}

export default new ImageService();
