import {
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
import { default as MaterialRadioGroup } from "@material-ui/core/RadioGroup";
import React from "react";
import { Radio } from "../atoms/Radio";

const useStyles = makeStyles((theme) => ({
  required: {
    color: theme.palette.primary.required,
  },
}));

export const RadioGroup = ({
  radios = [],
  title = "",
  requiredFieldMessage = "Required field",
  id,
  row = true,
  onChange,
  required = false,
  value = "",
  disabled = false,
}) => {
  const onChangeRadio = (e) => {
    onChange(e);
  };
  const classes = useStyles();
  return (
    <FormControl>
      <FormLabel id={id}>
        {required && title !== "" && (
          <span className={classes.required}>* </span>
        )}
        {title}
      </FormLabel>
      <MaterialRadioGroup
        aria-labelledby={id}
        name="radio-buttons-group"
        row={row}
        value={value}
        data-test={id}
      >
        {radios.length > 0 &&
          radios.map(({ value, label }) => {
            return (
              <Radio
                key={value}
                value={value}
                control={<Radio />}
                label={label}
                onChangeRadio={onChangeRadio}
                editable={!disabled}
              />
            );
          })}
      </MaterialRadioGroup>
      {required && (!value || value === "") && (
        <FormHelperText
          style={{ marginLeft: "14px" }}
          className={classes.required}
        >
          {requiredFieldMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};
