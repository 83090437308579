import React from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '../../Shared/atoms/Select';

export default function StatusPerIssue({issueTypes, setIssueTypes, disabled = false}) {
	const onChangeIssueTypeStatus = (status, index) => { 
		const issueTypeList= [...issueTypes];
		issueTypeList[index].status=  status;
		setIssueTypes([...issueTypeList]);
	 };
  
	return (
		<>
			{ issueTypes && issueTypes.length > 0 && <Grid item xs={12}>
				{issueTypes.map((issueType, index) => (
					<Grid container key={issueType.id} spacing={2} alignItems='center'>
				    <Grid item xs={12}>
							<Select
								id="idStatusIssueType"
								label={issueType.description}
								selectOptions={[
									'New', 'Assessed', 'QA Review', 'Completed'
								]}
								selectItem={(item) => onChangeIssueTypeStatus(item, index)}
								itemSelected={[issueType.status]}
								required={false}
								multiple={false}
								disabled = {disabled}
							/>
						</Grid>
					</Grid>
				))}
			</Grid>}
		</>
	);
}
