import React, { useEffect, useState } from "react";
import Box from  "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "../atoms/Button";
import Icon from "@material-ui/core/Icon";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import { Typography } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import AcknowledgementService from "../../../services/AcknowledgementService";
import { EventType } from "@azure/msal-browser";
import { useSnackbar } from "notistack";
import { showSnackbar } from "../../../Utils";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AcknowledgementDialog=({
  title,
  buttonTitle,
  msalInstance,
  ...props
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [acknowledgementDescription, setAcknowledgementDescription] = useState(
    ""
  );
  const [acknowledgementVersion, setAcknowledgementVersion] = useState("");
  const { enqueueSnackbar } = useSnackbar();


  const handleConfirm = () => {    
    AcknowledgementService.updateUserAcknowledgement({
      Version: acknowledgementVersion,
      Status: "accepted",
    }).then(() => {
      localStorage.setItem("acknowledgementAccepted", "yes");
      setOpenDialog(false);
    }).catch(()=>{
      showSnackbarMessage("An error occurred accepting the acknowledgement. Please try again", "warning");
    });
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const acknowledgementAccepted = localStorage.getItem(
      "acknowledgementAccepted"
    );
    msalInstance.addEventCallback((event)=>{
      if(event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.LOGOUT_START ){
        localStorage.setItem("acknowledgementAccepted","no");
      }
    })
    if(acknowledgementAccepted !== "yes") {
      setInfoAcknowledgement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const setInfoAcknowledgement = async () => {
    const userAcknowledgement = await AcknowledgementService.getUserAcknowledgement();
    if (userAcknowledgement.length === 0) {
      setAcknowledgementContent();
    } else {
      localStorage.setItem("acknowledgementAccepted","yes");
    }
  };

  const setAcknowledgementContent = () => {    
    AcknowledgementService.getAcknowledgement().then(
      (acknowledgementContent) => {
        if (acknowledgementContent && acknowledgementContent.data) {
          const { description, version } = acknowledgementContent.data[0];
          setAcknowledgementDescription(description);
          setAcknowledgementVersion(version);
          setOpenDialog(true);
        }        
      }
    );
  };

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
  }

  return (
    <div>
      {props.children}
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="acknolwedgement-dialog-slide-title"
        aria-describedby="acknowledgement-dialog-slide-description"
      >
        <DialogContent style={{ minHeight: 110, padding: 0 }}>
          <Typography align="center">
            <Icon>
              <CheckCircleTwoToneIcon
                color="primary"
                style={{ fontSize: "100" }}
              />
            </Icon>
          </Typography>
        </DialogContent>
        <DialogTitle id="acknowledgement-dialog-slide-title" disableTypography>
          <Typography variant="h5" align="center">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="acknowledgement-dialog-slide-description">
            {acknowledgementDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FormGroup>
            <Box align="center">
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={checked}
                      onChange={handleChange}
                      required
                    />
                  }
                  label="I have read and agree to the Safety Acknowledgement."
                />
              </FormControl>
              <FormControl>
                <Button
                  color="primary"
                  label={buttonTitle}
                  onClick={() => handleConfirm()}
                  disabled={!checked}
                />
              </FormControl>
            </Box>
          </FormGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AcknowledgementDialog;
