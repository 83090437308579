import React from 'react';
import { makeStyles } from '@material-ui/core';
import LinearProgressBar from '../../Shared/atoms/LinearProgress';

const useStyle = makeStyles(() => ({
	lineProgress: {
		cursor: 'pointer',
	},
}));

const LineProgressCell = ({ status, ...props }) => {
	const classes = useStyle();

	const onClick = () => {
		props.onClick(status);
	};

	return (
		<div onClick={onClick} className={classes.lineProgress}>
			<LinearProgressBar
				variant="determinate"
				value={status ? Number(status.replace('%', '')) : 0}
			/>
		</div>
	);
};

export default LineProgressCell;
