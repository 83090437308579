import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextFieldComponent from '../../../Shared/atoms/TextInput';
import { RadioGroup } from "../../../Shared/RadioGroup";
import { yesNoRadios, MANHOLE_FORM_STRUCTURAL_SECTION } from '../../../../Constants';

const StructuralSection = ({ inspection, onChangeInput, editing }) => MANHOLE_FORM_STRUCTURAL_SECTION.map(yesNo => 
  <Grid item xs={12} key={yesNo.label}>
    <Grid item xs={12}>
      <RadioGroup
        title={yesNo.label}
        value={'boolean' === typeof inspection[yesNo.valueName] 
          ? inspection[yesNo.valueName] ? 'true' : 'false'
          : inspection[yesNo.valueName]
        }
        id={yesNo.valueName}
        radios={yesNoRadios}
        onChange={(e) =>
          onChangeInput(e.target.value, yesNo.valueName)
        }
        required={true}
        disabled={!editing}
      />
    </Grid>
    {(inspection[yesNo.valueName] === "true" || inspection[yesNo.valueName] == true) && // If yes selected
    <Grid item xs={12}>
      <TextFieldComponent
        value={inspection[yesNo.notes]}
        label={`${yesNo.label} Notes`}
        fullWidth
        variant="outlined"
        type="text"
        placeholder=""
        onChange={(e) =>
          onChangeInput(e, yesNo.notes)
        }
        multiline={true}
        rows="4"
        required={true}
        maxLength="500"
        disabled={!editing}
      />
    </Grid>}
  </Grid>)

export default StructuralSection;