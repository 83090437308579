import React, {useState} from "react";
import ListTwoToneIcon from '@material-ui/icons/ListTwoTone';
import Container from '@material-ui/core/Container';
import PageHeader from "../../Shared/PageHeader";
import ProgressBar from "../../Shared/ProgressBar"
import WorkQueueContent from "../../WorkQueue"

const WorkQueue = (props) => {
  const [loading, setLoading] = useState(true)

  const loadingStyles = {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99
  }
  
  const containerStyles = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: 0,
    alignItems: "center"
  }
  
  return (
    <Container component="div" maxWidth={false} style={containerStyles}>
      {loading && <ProgressBar styles={loadingStyles} />}
      <PageHeader headerText="" icon={<ListTwoToneIcon color="secondary" />}/>
      <WorkQueueContent roles={props.userRoles} setLoading={setLoading} />
    </Container>)
};

export default WorkQueue;
