import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { MARKER_ICONS_ASSETS } from "../../../../Constants";

const useStyles = makeStyles((theme) => ({
   structureIcon: {
      stroke: theme.palette?.primary.main,
     'stroke-width': '10px',
     transform: 'scale(0.05)'
   }
 }));

const StructureIcon = () => {
   const classes = useStyles();

   return( 
      <SvgIcon>
         <path d={MARKER_ICONS_ASSETS.transsmission} className={classes.structureIcon}/> 
      </SvgIcon> 
   );
}
export default StructureIcon;