import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as exifr from 'exifr';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import ImageReducer from './ImageReducer';
import ImageContext from './ImageContext';
import {
	SET_IMAGE,
	RESET_IMAGE,
	UPLOAD_IMAGE,
	SET_BLOB_LIST,
	SET_PROGRESS_BAR,
	SET_LOCATION,
	SET_IDB_IMAGE_INSTANCE,
	SET_IMAGE_TO_DELETE,
	SET_PENDING_IMAGES,
} from './../types';
import ImageService from '../../services/ImageService';
import {
	calculateRequestProgress,
	showSnackbar,
	insertDataInBrowserDatabase,
	getStoreObjctOfIDB,
} from '../../Utils';
import {
	LOCALSTORAGE_CURRENT_WORK_ITEM,
	offlineImagesDbTag,
	offlineInspectionsDbTag,
	IMAGE_STATUS_PENDING_TO_SYNC,
	IMAGE_STATUS_SYNCED,
} from '../../Constants';

const ImageState = (props) => {
	const initialState = {
		urls: [],
		names: [],
		blobList: [],
		uploadingImages: false,
		progressBar: 0,
		gpsLocation: {},
		iDBInstance: null,
		namesToDelete: [],
		pendingImages: [],
	};
	const [state, dispatch] = useReducer(ImageReducer, initialState);
	// eslint-disable-next-line no-unused-vars
	const [stateState, setStateState] = useState(null);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		setStateState(state);
	}, [state]);

	const getContextImages = () => state;
	const onUploadProgress = (loaded) => {
		const progressBar = calculateRequestProgress(loaded);
		dispatch({
			type: SET_PROGRESS_BAR,
			payload: { progressBar },
		});
	};

	const setContextImage = async (files, cameraLocation, editing = false) => {
		let currentWorkItem = JSON.parse(
			localStorage.getItem(LOCALSTORAGE_CURRENT_WORK_ITEM)
		);
		!editing &&
			history.push({ pathname: '/CreateInspection', currentWorkItem });
		const namesList = [];
		const blobList = [];
		const _files = [];
		files.map((file) => {
			const format = file.name.split('.').pop();
			const fileName = `${uuidv4()}.${format}`;
			blobList.push(URL.createObjectURL(file));
			namesList.push({ name: fileName, status: IMAGE_STATUS_PENDING_TO_SYNC });
			// Rename file object with the new name
			const blob = file.slice(0, file.size, `image/${format}`);
			const newFile = new File([blob], fileName, { type: `image/${format}` });
			_files.push(newFile);
		});
		dispatch({
			type: SET_IMAGE,
			payload: {
				urls: [...state.urls, ...blobList],
				names: [...state.names, ...namesList],
				uploadingImages: true,
				pendingImages: state.pendingImages,
			},
		});

		let coordinates;
		// Get Lat long from browser
		if (state.gpsLocation.longitude && state.gpsLocation.latitude) {
			coordinates = [state.gpsLocation.latitude, state.gpsLocation.longitude];
		} else {
			if (cameraLocation) {
				coordinates = [cameraLocation.latitude, cameraLocation.longitude];
			} else if (blobList.length > 0) {
				// Get image Lat long from exif data
				let result = await exifr.gps(blobList[0]);
				if (result) {
					coordinates = [result.latitude, result.longitude];
				}
			}
		}

		if (navigator.onLine) {
			// Insert in browser DB all the images with status pendingToSync
			insertDataInBrowserDatabase(
				namesList,
				offlineImagesDbTag,
				state.iDBInstance
			);
			ImageService.createInspectionImages(_files, coordinates, onUploadProgress)					
				.then((resp) => {
					if (resp.length > 0) {
						// Find the images stored in browser DB and change its status for the returned status
						findImagesOnIndexDBAndUpdateStatus(resp);
						// Find the images stored in state and change its status for the returned status
						setUpdatedStatusForImage(resp);
						if (resp.some((image) => image.name === ''))
							showSnackbarMessage('Photo(s) could not be uploaded', 'error');
					}
				})
				.catch(() => {
					const filesUpdatedStatusError = _files.map((file) => ({
						name: file.name,
						status: 3,
						file,
						coordinates,
					}));
					// Find the images stored in browser DB and change its status to error
					findImagesOnIndexDBAndUpdateStatus(filesUpdatedStatusError);
					showSnackbarMessage('Photo(s) could not be uploaded', 'error');
				});				
		} else {
			const names = [];
			for (const file of _files) {
				names.push({ name: file.name, coordinates, file });
			}
			uploadNameOfImages([...state.names, ...names]);
		}
	};

	const setUpdatedStatusForImage = async (resp) => {
		let theState = false;
		// hacky but get updated state value into an async call
		await setStateState(currentStateState => {
			theState = currentStateState;
			return theState;
		});
		const images = theState.names;
		const updatedNames = [];
		// Find the previously pending to sync image on the list of images and update with service returned value
		images.map(image => {
			let newImage = image;
			resp.map(updatedImage => {
				if (image.name === updatedImage.name) {
					newImage = updatedImage;
				}
			})
			
			updatedNames.push(newImage);
		});
		uploadNameOfImages(updatedNames);
	};

	const findImagesOnIndexDBAndUpdateStatus = (newImages) => {
		const storeObj = getStoreObjctOfIDB(state.iDBInstance, offlineImagesDbTag);
		if (storeObj) {
			var cursorRequest = storeObj.openCursor();
			cursorRequest.onsuccess = async function (evt) {
				var cursor = evt.target.result;
				if (cursor?.value) {

					const imagesPendingToSyncInBrowserDB = cursor.value;
					let browserDBUpdatedStatus = [];
					// Find the images uploaded by name in the indexDBs
					newImages.map((updatedImage) => {

						const pendingToSyncImage = imagesPendingToSyncInBrowserDB.find(
							(image) => image.name === updatedImage.name
						);

						// Update image/s with new status from service
						if (pendingToSyncImage) {
							if (updatedImage.status === 3) {
								// ERROR, push file and coordinates for later attempt
								browserDBUpdatedStatus.push({
									...pendingToSyncImage,
									...{
										status: updatedImage.status,
										file: updatedImage.file,
										coordinates: updatedImage.coordinates,
									},
								});
							} else if (
								updatedImage.status === 4 ||
								updatedImage.status === IMAGE_STATUS_SYNCED
							) {
								browserDBUpdatedStatus.push({
									...pendingToSyncImage,
									...{ status: updatedImage.status, delete: true },
								});
							} else {
								browserDBUpdatedStatus.push({
									...pendingToSyncImage,
									...{ status: updatedImage.status },
								});
							}
						}
					});
					if (browserDBUpdatedStatus.length > 0) {
						// Update image dbIndex value with the new status for each image
						// cursor.update(browserDBUpdatedStatus);
						// delete from images indes DB, store it on the images list for the inspection
						cursor.delete(cursor.key);
						// Find the inspection which this image/s belong to, then update its values
						updateImageStatusForInspection(browserDBUpdatedStatus);
					}
					cursor.continue();
				}
			};
		}
	};

	const updateImageStatusForInspection = (updatedImages) => {
		const inspectionsOffline = [];
		openDBInstance(offlineInspectionsDbTag, (iDBInstance) => {
			const storeObj = getStoreObjctOfIDB(iDBInstance, offlineInspectionsDbTag);
			if (storeObj) {
				var cursorRequest = storeObj.openCursor();
				cursorRequest.onsuccess = async function (evt) {
					let inspectionFound = false;
					var cursor = evt.target.result;
					if (cursor) {
						if (cursor.value) {
							const inspectionToUpdate = cursor.value;
							updatedImages.forEach((updatedImage) => {
								const inspectionImageIndexFound =
									inspectionToUpdate.images.findIndex(
										(outdatedImage) => outdatedImage.name === updatedImage.name
									);
								// If image is in current inspection
								if (inspectionImageIndexFound !== -1) {
									inspectionToUpdate.images[inspectionImageIndexFound] =
										updatedImage;
									inspectionFound = inspectionToUpdate;
								}
							});
							// Update on indexDB with updated status for each image
							inspectionFound && cursor.update(inspectionFound);
							inspectionsOffline.push({
								value: inspectionToUpdate,
								key: cursor,
							});
						}
						cursor.continue();
					} else {
						// No more items on indexDB
						props.setInspectionsOffline(inspectionsOffline);
					}
				};
			}
		});
	};

	// open database
	const openDBInstance = (tag, onsuccess = null) => {
		var indexedDBOpenRequest = window.indexedDB
			? window.indexedDB.open(tag, 1)
			: null;
		if (indexedDBOpenRequest) {
			indexedDBOpenRequest.onupgradeneeded = function () {
				this.result.createObjectStore(`${tag}_requests`, {
					autoIncrement: true,
				});
			};
			indexedDBOpenRequest.onsuccess = function () {
				if (typeof onsuccess == 'function') onsuccess(indexedDBOpenRequest);
			};
		}
	};

	const setPendingImages = (newPendingImages) => {
		const pendingImages = [...state.pendingImages, ...newPendingImages];
		dispatch({
			type: SET_PENDING_IMAGES,
			payload: pendingImages,
		});
	};

	const setContextImageDetail = (images) => {
		dispatch({
			type: SET_IMAGE,
			payload: images,
		});
	};

	const resetContextImage = async () => {
		dispatch({
			type: RESET_IMAGE,
		});
	};

	const setBlobListByInspection = (blobObj) => {
		dispatch({
			type: SET_BLOB_LIST,
			payload: blobObj,
		});
	};

	const getBlobListByInspection = () => state.blobList;

	const editContextImages = (names, urls) => {
		dispatch({
			type: SET_IMAGE,
			payload: { names, urls },
		});
	};

	const setGpsLocation = (gpsLocation) => {
		dispatch({
			type: SET_LOCATION,
			payload: { gpsLocation },
		});
	};

	const uploadNameOfImages = (names) => {
		dispatch({
			type: UPLOAD_IMAGE,
			payload: names,
		});
	};

	const setImageContextIDInstance = (iDBInstance) => {
		dispatch({
			type: SET_IDB_IMAGE_INSTANCE,
			payload: { iDBInstance: iDBInstance },
		});
	};

	const removeImageContextImage = (index) => {
		const updatedNames = state.names.filter((_, i) => i !== index);
		const updatedUrls = state.urls.filter((_, i) => i !== index);
	
	
		dispatch({
			type: SET_IMAGE,
			payload: {
				...state,
				names: updatedNames,
				urls: updatedUrls,
			},
		});
	};	

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	function setContextImagesToDelete(names) {
		dispatch({
			type: SET_IMAGE_TO_DELETE,
			payload: { namesToDelete: names },
		});
	}

	return (
		<ImageContext.Provider
			value={{
				getContextImages,
				setContextImage,
				setContextImageDetail,
				state,
				resetContextImage,
				setBlobListByInspection,
				getBlobListByInspection,
				editContextImages,
				setGpsLocation,
				setImageContextIDInstance,
				setContextImagesToDelete,
				setPendingImages,
				removeImageContextImage,
			}}
		>
			{props.children}
		</ImageContext.Provider>
	);
};
export default ImageState;
