import React, { useEffect, useRef, useState } from 'react';
import imageNotSyncedIcon from './image-not-synced-icon.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import imageNotFound from './image-not-found.jpg';
import imageNotSynced from './image-not-synced.jpg';
import imageProcess from './../../../assets/image_processing.gif';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { isMobile, showSnackbar } from '../../../Utils';
import AlertDialogSlide from '../../Shared/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgressBar from '../atoms/LinearProgress';
import { IMAGE_STATUS_PENDING_TO_SYNC } from '../../../Constants';
import { inspect, createAnnotation, updateAnnotationAccordingToSize } from '../../InspectionDetail/LightboxAI';
import DrawCanvas from '../../InspectionDetail/LightboxAI/DrawCanvas';
import { useSnackbar } from 'notistack';
import { INSPECTION_TYPE_MANHOLE, CARDINAL_POINTS } from '../../../Constants';
import Select from '../atoms/Select';

const useStyles = makeStyles((theme) => ({
	imageBreakpoints: {
		position: 'relative',
		[theme.breakpoints.up('lg')]: {
			height: '400px',
		},
		[theme.breakpoints.down('md')]: {
			height: '300px',
		},
		[theme.breakpoints.up('sm')]: {
			height: '300px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '350px',
		},
	},
	auxImage: {
		objectFit: 'cover',
	},
	imageLoadingContainer: {
		objectFit: 'cover',
		textAlign: 'center',
	},
	imagesInMobile: {},
	imagesCarouselContainer: {
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		'& .carousel .control-arrow, & .carousel.carousel-slider .control-arrow': {
			zIndex: 4
		},
		'& .carousel-root': {
			width: '100%',
			'& .carousel.carousel-slider': {
				margin: '0 auto'
			}
		},
		'& .thumbs-wrapper': {
			display: 'none'
		}
	},
	srlWrapper: {
		display: 'none',
	},
	autoWidth: {
		width: 'auto',
		position: 'relative',
	},
	containerImage: {
		top: 0,
		left: 0,
		position: 'relative',
	},
	deleteIcon: {
		position: 'absolute',
		top: '18px',
		left: '15px',
		cursor: 'pointer',
	},
	loading: {
		height: 10,
		borderTopLeftRadius: 5,
		backgroundColor: theme.palette.primary.dark,
	},
	notSyncIcon: {
		position: 'absolute',
		top: 20,
		left: 20,
		opacity: 0.7,
		width: '30px !important',
		height: '30px !important',
	},
	notSyncIconDetail: {
		top: 28,
		left: 56,
	},
	pendingToSyncImage: {
		height: 300,
    	objectFit: 'cover'
	},
}));

const ImageCarousel = ({
	detectedObject,
	images,
	status,
	showIndicators = false,
	showThumbs = false,
	carouselKey,
	loading = true,
	inspectionId = '',
	partitionKey = '',
	inspectionType = '',
	detail = false,
	height,
	deleteIcon = false,
	uploadingImages,
	loadingAI,
	progressBar,
	imageFixed = false,
	ai,
	setLoading,
	aiResponse,
	setAiResponse,
	imagesIds,
	selectedCardinalPoints,
	setSelectedCardinalPoints,
	editing = false,
	...props
}) => {
	const classes = useStyles();
	const history = useHistory();
	const [mobile, setMobile] = useState(isMobile());
	const [indexCarousel, setIndexCarousel] = useState(0);
	const [imageIndex, setImageIndex] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [annotations, setAnnotations] = useState([]);
	const [dimensionAndPositions, setDimensionAndPositions] = useState([]);
	const [imageIndexInspected, setImageIndexInspected] = useState([]);
	let carousel = useRef(null);
	const timerId = useRef(null);
	const annotationsRef = useRef([]);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		window.addEventListener('resize', () => {
			setMobile(isMobile());
			// cancel and reset timeout if the user still resizing and 500 ms didn't pass
			clearTimeout(timerId.current);
			// After half a second of the last movement start the canvas and annotations resize	
			timerId.current = setTimeout(
				function () {
					updateCanvasAndAnnotationsSize()
				}, 500);
		});
	}, []);

	useEffect(() => {
		if (carousel && props.handleIndex) {
			setIndexCarousel(0);
		}
	}, [images, props]);
	useEffect(() => {
		const inspectCurrentIndex = async () => {			
			if (ai && !aiResponse[indexCarousel]) {
				setLoading(true);
				const _aiResponse = aiResponse;
				_aiResponse[indexCarousel] = {};
				setAiResponse(_aiResponse);
				const _imageIndexInspected = imageIndexInspected;
				const response = await inspect(annotations, dimensionAndPositions[indexCarousel], indexCarousel, imagesIds[indexCarousel]?.name);
				setLoading(false);
				if (response.error) {
					_aiResponse[indexCarousel] = false;
					_imageIndexInspected[indexCarousel] = false;
					showSnackbarMessage('An error occurred during AI Inspection.', 'error');
				} else if (response.annotations){
					_aiResponse[indexCarousel] = response.metadata || {};
					_imageIndexInspected[indexCarousel] = true;
					setAnnotations(response.annotations);
					annotationsRef.current = response.annotations;
				} else {
					_aiResponse[indexCarousel] = {};
					_imageIndexInspected[indexCarousel] = true;
					showSnackbarMessage('No objects detected.', 'warning');
				}
				setImageIndexInspected([..._imageIndexInspected]);
				setAiResponse(_aiResponse);
			} else if (!imageIndexInspected[indexCarousel] && (aiResponse && aiResponse[indexCarousel]) && Object.keys(aiResponse[indexCarousel]).length > 0) { // if it already has a response stored but no annotation, create it for full view
				const _imageIndexInspected = imageIndexInspected;
				_imageIndexInspected[indexCarousel] = true;
				setImageIndexInspected(_imageIndexInspected);				
				const _annotations = createAnnotation(annotations, dimensionAndPositions[indexCarousel], aiResponse[indexCarousel], indexCarousel, imagesIds[indexCarousel]?.name);
				setAnnotations([..._annotations]);
				annotationsRef.current = [..._annotations];
			}
		};
		inspectCurrentIndex();
	}, [ai, indexCarousel]);

	const getCarouselImages = () => {
		const element = document.querySelector('#carouselContainer ul.slider.animated');
		return element && element.getElementsByTagName('img') || [];
	}

	const updateCanvasAndAnnotationsSize = () => {
		const carouselImages = [...getCarouselImages()];
		if (carouselImages && carouselImages.length) {
			// Remove first and last elements of array since they are duplicated images used by the carousel library to work 
			carouselImages.pop();
			carouselImages.shift();
			const updatedDimensionAndPositions = [];
			// Map through all carousel Images and update each element of the canvas size with the new image width and height
			carouselImages.map((image, index) => {
				const dimensionAndPosition = dimensionAndPositions[index];
				dimensionAndPosition.width =  image.clientWidth;
				dimensionAndPosition.height =  image.clientHeight;
				updatedDimensionAndPositions.push(dimensionAndPosition);
				// ref to annotations, since is inside a setTimeout, state is outdated for this function
				if (annotationsRef.current && annotationsRef.current.length) {					
					const updatedAnnotations = []
					annotationsRef.current.map(annotation => {
						const _annotation = annotation;
						// If this annotation is for the current image
						if (_annotation.index === index) {		
							const {currentX,
								currentY,
								currentAnnotationWidth,
								currentAnnotationHeight
							} = updateAnnotationAccordingToSize(
								_annotation.originalAnnotationWidth, // original Width
								_annotation.originalAnnotationHeight, // original Height
								_annotation.originalXPosition, // Original X;
								_annotation.originalYPosition, // Original Y
								dimensionAndPosition);
							_annotation.x =  currentX;
							_annotation.y = currentY;
							_annotation.width = currentAnnotationWidth;
							_annotation.height = currentAnnotationHeight;
						}
						updatedAnnotations.push(_annotation);
					});
					setAnnotations(updatedAnnotations);
				}				
			});
			setDimensionAndPositions(updatedDimensionAndPositions);
		}
	}

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	const goToDetailPage = () => {
		if (inspectionId !== '') {
			history.push(
				`/Inspection/${inspectionId}/${inspectionType}/${partitionKey}`
			);
		}
	};

	const pendingToSyncImage = <img
		src={imageNotSynced}
		className={classes.pendingToSyncImage}
		alt="Image pending sync"
	/>;
	
	const pendingToSyncIcon = <img
		className={`${classes.notSyncIcon} ${
			detail ? classes.notSyncIconDetail : ''
		}`}
		src={imageNotSyncedIcon}
		alt="Image pending sync"
	/>;

	const getImageElements = (images, carousel = false) => {
		return detectedObject ? (
			<div className={classes.containerImage}>
				<img src={`data:image/jpeg;base64,${detectedObject}`} alt="" />
				{deleteIcon ? (
					<IconButton
						onClick={() => openDialogHandle(0)}
						className={classes.deleteIcon}
					>
						<DeleteIcon color="error" />
					</IconButton>
				) : null}
			</div>
		) : (
			images && images.map((image, index) => {
				const pendingToSyncImageStatus = status &&
					status[index] &&
					status[index] === IMAGE_STATUS_PENDING_TO_SYNC;
				const currentImage = <img
					className={detail ? classes.autoWidth : classes.imageBreakpoints}
					height={imageFixed ? '100px' : undefined}
					src={image}
					alt=""
					onLoad={async e => {
						const img = e.target;
						const dimensionAndPosition = { 
							width: img.offsetWidth,
							height: img.offsetHeight,
							naturalHeight: img.naturalHeight,
							naturalWidth: img.naturalWidth,
							index,
							image,
							aiAnnotation: false
						}
						const _dimensionAndPositions = dimensionAndPositions;
						_dimensionAndPositions[index] = dimensionAndPosition;
						setDimensionAndPositions(_dimensionAndPositions);
					}}
				/>;

				return carousel ? (
					<div key={index} className={classes.containerImage}>
						{detail ? <>
							{currentImage}
						</>
							: pendingToSyncImageStatus ? (
								image === IMAGE_STATUS_PENDING_TO_SYNC ? ( // Image not sync for other users
									<img
										src={imageNotSynced}
										className={classes.pendingToSyncImage}
										alt="Image pending sync"
									/>
								) : (
									<div>
										{currentImage}
										{pendingToSyncIcon}
									</div>
								)
							) : (
								currentImage
							)}
						{pendingToSyncImageStatus && detail && pendingToSyncIcon}
						{deleteIcon ? (
							<IconButton
								onClick={() => openDialogHandle(index)}
								className={classes.deleteIcon}
							>
								<DeleteIcon color="error" />
							</IconButton>
						) : null}
					</div>
				) : (
					<img key={index} src={image} alt="" />
				);
			})
		);
	};

	const imageProcessing = () => {
		return (
			<div className={classes.imageLoadingContainer}>
				<img
					style={{ width: '200px', height: '200px' }}
					className={classes.auxImage}
					src={imageProcess}
					alt=""
				/>
			</div>
		);
	};

	const getWidth = () => {
		let width = 'auto';
		if (detail && !mobile) {
			width = '50vh';
		}
		return width;
	};

	const customRenderItem = (index) => {
		setIndexCarousel(index);
		if (props.handleIndex) props.handleIndex(index);
	};

	const openDialogHandle = (index) => {
		setOpenDialog(true);
		setImageIndex(index);
	};

	const deletePhoto = () => {
		props.deletePhotoHandle(imageIndex);
		setIndexCarousel(0);
		setOpenDialog(false);
	};

	return (
		<>
			{!loading ? (
				images ? (
					images.length > 0 ? (
						<Grid container spacing={0}>
							<Grid item xs={12} className={classes.imagesCarouselContainer} id="carouselContainer">
								{ai && dimensionAndPositions[indexCarousel] && <DrawCanvas dimensionAndPosition={dimensionAndPositions[indexCarousel]} currentIndex={indexCarousel} selectShape={null} annotations={annotations} setAnnotations={null} selectedId={null} newAnnotation={null} setNewAnnotation={null} editable={false} />}
								<Carousel
									width={getWidth()}
									autoPlay={false}
									showIndicators={showIndicators}
									showArrows={true}
									showThumbs={showThumbs}
									key={carouselKey}
									dynamicHeight={true}
									onClickItem={goToDetailPage}
									infiniteLoop={true}
									onChange={customRenderItem}
									selectedItem={indexCarousel}
								>
									{getImageElements(images, true)}
								</Carousel>
							</Grid>
							{(uploadingImages || loadingAI) && (
								<Grid item xs={12}>
									<LinearProgressBar
										{...(loadingAI ? '' : { value: progressBar })}
									/>
								</Grid>
							)}
							{inspectionType === INSPECTION_TYPE_MANHOLE && detail &&
							<Grid item xs={12} style={{ margin: '10px auto 0 auto', maxWidth: '50vh' }}>
								<Select            
									selectItem={e => {
										const _selectedCardinalPoints = [...selectedCardinalPoints];
										_selectedCardinalPoints[indexCarousel] = e;
										setSelectedCardinalPoints(_selectedCardinalPoints);
									}} 
									selectOptions={['None'].concat(CARDINAL_POINTS)} 
									itemSelected={selectedCardinalPoints && selectedCardinalPoints[indexCarousel] || ['None']}
									label={`Cardinal point ${indexCarousel + 1} of ${images.length}`}
									multiple={false}
									disabled={!editing}
								/>
							</Grid>}
						</Grid>
					) : (
						<div
							onClick={goToDetailPage}
							className={classes.imageLoadingContainer}
						>
							{status &&
							status[0] &&
							status[0] === IMAGE_STATUS_PENDING_TO_SYNC ? (
									pendingToSyncImage
								) : (
									<img
										style={{ height: height }}
										className={classes.auxImage}
										width={!detail ? '300px' : 'auto'}
										src={imageNotFound}
										alt=""
									/>
								)}
						</div>
					)
				) : (
					imageProcessing()
				)
			) : (
				<>
					{imageProcessing()}
					{uploadingImages && <LinearProgressBar value={progressBar} />}
				</>
			)}
			{detail && (
				<div className={classes.srlWrapper}>
					{getImageElements(images)}
				</div>
			)}
			<AlertDialogSlide
				openDialog={openDialog}
				handleConfirm={deletePhoto}
				title="Delete Photo"
				content="Are you sure you want to delete this photo?"
				handleClickClose={() => {
					setOpenDialog(false);
				}}
			/>
		</>
	);
};

export default ImageCarousel;
