import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UploadImageCarousel from '../Shared/UploadImageCarousel';
import Select from '../Shared/atoms/Select'
import Button from '../Shared/atoms/Button';
import AIService from '../../services/AIService';
import { useSnackbar } from 'notistack';
import { showSnackbar } from '../../Utils';

const useStyles = makeStyles((theme) => ({ 
  formContainer: {
    marginTop: theme.spacing(3)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const Ai = () => {
  const classes = useStyles();
  const [mlModel, setMlModel] = useState(['Pole/Transformer'])
  const [file, setFile] = useState({ names: [], urls: []})
  const [detectedObject, setDetectedObject] = useState(null)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const changeFile = fileObj => {
    setFile(fileObj)
    setDetectedObject(null)
  }

  const validateForm = mlModel === '' || file.urls.length === 0

  const inspect = async () => {
    setLoading(true);
    let _mlModel = mlModel[0] === 'Pole/Transformer' ? 'ModelName_1' :  mlModel[0] === 'Assets-Defects' ? 'uas-inspection-defects' : mlModel[0];
    const detectedObjectResult = await AIService.objectDetection(_mlModel, file.files[0]);
    setLoading(false);
    if (detectedObjectResult && detectedObjectResult.data && detectedObjectResult.data.imageString) {
      if (detectedObjectResult.data.metadata.length === 0)
        showSnackbarMessage('No objects detected.', 'warning');
      setDetectedObject(detectedObjectResult.data.imageString);
    } else {
      showSnackbarMessage('An error occurred during AI Inspection.', 'error');
      setDetectedObject(null);
    }     
  }

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
	}

  return (
    <Grid container alignContent="center" spacing={2} className={classes.formContainer} data-testid="aimodel">      
      <Grid item xs={12}>
        <UploadImageCarousel
          multipleImages={false}
          changeDeletePhoto={changeFile}
          changeFile={changeFile}
          detectedObject={detectedObject}
          loadingProp={loading}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          id="aiModels"
          label="AI Models"
          selectOptions={[
            "Pole/Transformer",
            "Assets-Defects"
          ]}
          selectItem={e => setMlModel([e])}
          itemSelected={mlModel}
          required={true}
          multiple={false}
          disabled={false}
        />
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          id="inspectButton"
          variant="outlined"
          onClick={() => inspect()}         
          label="Inspect"
          color="primary"
          disabled={validateForm}
        />
      </Grid>
    </Grid>
  );
};

export default Ai;
