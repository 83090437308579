import React from 'react';
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Hidden from '@material-ui/core/Hidden';
import MainMenu from "./MainMenu"
import DesktopMenu from "./DesktopMenu"
import MobileMenu from "./MobileMenu"
import { pageTitle } from "../../Constants"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      top: 'auto',
      bottom: 0,
    }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  drawerOpen: {
    width: drawerWidth,
    margin: 0,
    paddingTop: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    margin: 0,
    paddingTop: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  header: {
    wordBreak: "break-word"
  },
  content: {
    width: "100%",
    height: "100vh",
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 70
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    },
  },
  paperContainer: {
    height: "100%",
    [theme.breakpoints.up('sm')]: {
      height: "calc(100% - 20px)",
    },
  }
}));

const drawerWidth = 240;

const NavMenu = ({ username, inspectionsOffline, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();  
  const location = useLocation(); // Get page title from location
  // Main menu
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getPath = () => { 
    let title = "";
    const { pathname } = location;
    if (pathname.includes("/Inspection/")) {
      title = `${pageTitle["Inspection"]} | ${pathname.split("/")[2]}`;
    } else if (pathname.includes("/WorkQueue/edit/")) {
      title = `${pageTitle["/WorkQueue/edit"]} | ${pathname.split("/")[3]}`;
    } else if(pathname.includes("/WorkItems/")) {
      title = `Work Items`;
    } else if(pathname.includes("/Inspections")) {
      title = `Inspections`;
    }else {
      title = pageTitle[location.pathname];
    }
    return title
  }

  return (
    <div data-testid="navMenuComponent">
      { /* Top / bottom bar */ }
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        backgroundcolor="primary"
      >
        <Toolbar>
          {/* Desktop menu*/}
          <Hidden xsDown>
            <DesktopMenu inspectionsOffline={inspectionsOffline} pageTitle={(location.pathname && getPath()) || ''} handleDrawerOpen={handleDrawerOpen} username={username} open={open} />
          </Hidden>
          {/* Mobile menu */}
          <Hidden smUp>
            <MobileMenu inspectionsOffline={inspectionsOffline} username={username} roles={props.roles} />
          </Hidden>
        </Toolbar>
      </AppBar>
      { /* Top / bottom bar - END*/ }

      { /* Left drawer - desktop */ }
      <Hidden xsDown>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <MainMenu open={open} roles={props.roles}/>
        </Drawer>
      </Hidden>
      { /* Left drawer - desktop - END */ }
    </div>
  )
}

NavMenu.propTypes = {
  username: PropTypes.string
};

NavMenu.defaultProps = {
  username: ""
}

export default NavMenu;