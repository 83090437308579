import React from "react";
import TextField from "@material-ui/core/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from "@material-ui/core/styles";

const TextFieldComponent = ({
  label,
  placeholder,
  disabled = false,
  value = "",
  required = false,
  errorMessage= "Required field",
  type = "text",
  multiline = false,
  rows,
  id = "",
  onBlur = null,
  maxLength,
  ...props
}) => {
  const defaultProps = {
    value: value ? value: "",
    variant: "outlined",
    onInput: (e) => props.onChange(e.target.value),
    disabled,
    placeholder
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        width: "100%",
      },
      "& .MuiInputLabel-root": {
        flexDirection: "row-reverse",
        display: "flex",
        "& .MuiInputLabel-asterisk": {
          color: theme.palette.primary.required,
          marginRight: 3
        }
      }
    },
    required: {
      color: theme.palette.primary.required,
      marginLeft: "14px"
    },
  }))

  const classes = useStyles();
  const [hasError, setHasError] = React.useState(false);  

  const handleChange = (e) => {
    setHasError(required && e.target.value.trim() === "");
  };

  const handleBlur = (e) => {
    setHasError(required && e.target.value.trim() === "");
    onBlur && onBlur(e);
  };
  
  return <>
    <TextField
      inputProps={{"data-testid": id, ...maxLength && {maxLength: maxLength}}}
      id={id}
      {...defaultProps}
      {...props}
      type={type}
      required={required}
      label={label}     
      className={classes.root}
      onChange={handleChange}
      onBlur={handleBlur}
      error={required && hasError}
      multiline={multiline}
      rows={rows}
      {...props.style && {style: props.style}}
    />
    {hasError && errorMessage && <FormHelperText className={classes.required}>{errorMessage}</FormHelperText>}
  </>
};

export default TextFieldComponent;
