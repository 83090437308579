import React from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { useState } from 'react';

const AddressAutocomplete = ({ onPlaceChange, ...props }) => {
	const [autocomplete, setAutocomplete] = useState(null);

	const onLoad = (e) => {
		setAutocomplete(e);
	};

	const onPlaceChanged = () => {
		if (autocomplete) {
			const place = autocomplete.getPlace();
			if (place && place.place_id) {
				onPlaceChange(autocomplete.getPlace());
			}
		}
	};

	return (
    <div style={{ width: "100%" }}>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        {props.children}
      </Autocomplete>
    </div>
  );
};

export default AddressAutocomplete;
