import { callApi } from '../callApi';
import { RESOURCE_INSPECTIONS } from '../Constants';

class DamageTypesService {
	getAll() {
		return callApi({ method: 'get', url: `${RESOURCE_INSPECTIONS}damagetypes` });
	}

	create(data) {
		return callApi({ method: 'post', url: `${RESOURCE_INSPECTIONS}damagetypes`, data });
	}

}
export default new DamageTypesService ();