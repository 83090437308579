import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
const useStyle = makeStyles(() => ({
	checkAround: {
		color: '#007749',
		border: '1px solid #007749',
		borderRadius: '5px',
	},
	check: {
		fontSize: '12px',
	},
	label: {
		color: '#007749',
		marginLeft: '3px',
	},
}));

const CountOfInspections = ({ items }) => {
	const classes = useStyle();

	return (
		<Grid container direction="row" alignItems="center">
			<Grid item>
				<Grid
					container
					className={classes.checkAround}
					alignItems="center"
					justifyContent="center"
				>
					<Check className={classes.check} />
				</Grid>
			</Grid>
			<Grid item className={classes.label} data-testid="countOfInspections">
				{items}
			</Grid>
		</Grid>
	);
};

export default CountOfInspections;
