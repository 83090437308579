import React from 'react';
import { render } from 'react-dom';
import './index.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './Components/App/App';
import theme from './theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { StrictMode } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { SnackbarProvider } from 'notistack';
import Grow from '@material-ui/core/Grow';
(async () => {
	const { REACT_APP_LD_KEY } = process.env;

	const LDProvider = await asyncWithLDProvider({
		clientSideID: REACT_APP_LD_KEY,
		reactOptions: {
			useCamelCaseFlagKeys: false,
		},
		user: {
			key: 'SmartInspectUI',
			name: 'SmartInspectUI',
			email: '',
		},
		options: {},
	});

	render(
		<LDProvider>
			<StrictMode>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<SnackbarProvider
						maxSnack={1}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						TransitionComponent={Grow}
					>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</SnackbarProvider>
				</ThemeProvider>
			</StrictMode>
		</LDProvider>,
		document.getElementById('root')
	);
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
