import { RESOURCE_INSPECTIONS } from '../Constants';
import { callApi } from '../callApi';

class StateService {
	async getStates() {
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}states`
		});
	}
	
	async getAssetsStates(assetType) {
		const queryString = assetType ? `?assetType=${assetType}` : '';

		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}AssetsStates${queryString}`
		});
	}
}

export default new StateService();