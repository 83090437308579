import React from "react";
import { FormControlLabel } from "@material-ui/core";
import { default as MaterialRadio  } from "@material-ui/core/Radio";

export const Radio = ({
  value,
  label,
  editable = true,
  onChangeRadio
}) => {
  const onChange = (e) => {
    onChangeRadio(e);
  };
  return (
    <FormControlLabel
      value={value}
      control={<MaterialRadio color="primary" onChange={onChange} />}
      label={label}
      disabled={!editable}
    />
  );
};
