import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '../../../Shared/Table';
import { MANHOLE_SLEEVE_REFERENCE_TABLE_DATA, MANHOLE_SLEEVE_REFERENCE_TABLE_COLUMNS, MANHOLE_TEMPERATURE_DIFFERENTIALS_TABLE_COLUMNS, MANHOLE_TEMPERATURE_DIFFERENTIALS_TABLE_DATA } from '../../../../Constants';

const InformationSection = () => {
	return <>
		<Grid item xs={12}>
			<h3>Sleeve Reference</h3>
			<Table			
				style={{ width: '100%' }}
				columns={MANHOLE_SLEEVE_REFERENCE_TABLE_COLUMNS}
				data={MANHOLE_SLEEVE_REFERENCE_TABLE_DATA}
				title={''}
				options={{
					toolbar: false,
					search: false,
					filtering: false,
					pageSize: 10,
					showSelectAllCheckbox: false,
					selection: false,
					headerStyle: {
						color: '#007749',
						fontWeight: 'bold',
					},
					paging: false
					// pageSizeOptions: [5, 10, 25],
				}}
			/>
		</Grid>
		<Grid item xs={12}>
			<h3>Corrective Actions For Temperature Differentials (Hot Spots)</h3>
			<p>Use the following table to determine the correct corrective action.</p>
			<p style={{ textAlign: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)', borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '5px 0' }}>International Electrical Testing Association Maintenance Testing Specifications (NETA)</p>
			<Table			
				style={{ width: '100%' }}
				columns={MANHOLE_TEMPERATURE_DIFFERENTIALS_TABLE_COLUMNS}
				data={MANHOLE_TEMPERATURE_DIFFERENTIALS_TABLE_DATA}
				title={''}
				options={{
					toolbar: false,
					search: false,
					filtering: false,
					pageSize: 10,
					showSelectAllCheckbox: false,
					selection: false,
					headerStyle: {
						color: '#007749',
						fontWeight: 'bold',
					},
					paging: false
					// pageSizeOptions: [5, 10, 25],
				}}
			/>
		</Grid>
		<Grid item xs={12}>
			<strong>WARNING: If cable temperatures exceed the Emergency Operating Temperature as shown in Cable Voltage Reference table, all work should be suspended in the housing manhole, chamber or vault with the cable energized and in service. Employees shall immediately vacate the manhole and remain outside the manhole until the temperature of said cable return below the Emergency Operating Temperature or the cable has been de-energized and red tagged. Cable in this condition must be reported as abnormal, with an outage requested and protection requested/granted before commencement of scheduled work.
			</strong>
		</Grid>
	</>
}

export default InformationSection;