import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import get from "lodash/get";
import DateTimePicker from "../../atoms/DateTimePicker";

const DateTimePickerFromToFilter = ({
  columnDef,
  locale,
  onFilterChanged,
}) => {
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={locale}
    >
      <DateTimePicker
        value={get(columnDef, ["tableData", "filterValue", "dateFrom"]) || null}
        onChange={(dateFrom) => {
          let value = { ...columnDef.tableData.filterValue };
          value.dateFrom = dateFrom;
          onFilterChanged(columnDef.tableData.id, value);
        }}
      />

      <DateTimePicker
        value={get(columnDef, ["tableData", "filterValue", "dateTo"]) || null}
        onChange={(dateTo) => {
          let value = { ...columnDef.tableData.filterValue };
          value.dateTo = dateTo;
          onFilterChanged(columnDef.tableData.id, value);
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateTimePickerFromToFilter;
