import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextFieldComponent from '../../../Shared/atoms/TextInput';
import { RadioGroup } from "../../../Shared/RadioGroup";
import {
	GRADE_OPTIONS_LABELS,
	MANHOLE_GRADES_RADIO_OPTIONS,
	SECONDARY_CABLES_NETWORK_SYSTEMS,
	SECONDARY_CABLES_RADIAL,
	SECONDARY_CABLES_TYPES,
	yesNoRadios
} from '../../../../Constants';

const GradedSection = ({ inspection, onChangeInput, showDetailInput, editing }) => {
	return <Grid item xs={12}>
		{GRADE_OPTIONS_LABELS.map(gradedOption => <Grid key={gradedOption.label} container spacing={1}>
			<Grid item xs={12}>
				<RadioGroup
					title={gradedOption.label}
					value={inspection[gradedOption.valueName]}
					radios={MANHOLE_GRADES_RADIO_OPTIONS}
					onChange={(e) =>
						onChangeInput(e.target.value, gradedOption.valueName)
					}
					required={true}
					disabled={!editing}
				/>
			</Grid>
			{showDetailInput(inspection[gradedOption.valueName]) && <>
				<Grid item xs={12}>
					<TextFieldComponent
						value={inspection[gradedOption.detailQuantity]}
						label="Work Completed Quantity"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={(e) =>
						onChangeInput(e, gradedOption.detailQuantity)
						}
						required={true}
						maxLength="100"
						disabled={!editing}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent
						value={inspection[gradedOption.detailFollowUp]}
						label="Follow-up Work"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={(e) =>
						onChangeInput(e, gradedOption.detailFollowUp)
						}
						multiline={true}
						rows="4"
						required={true}
						maxLength="500"
						disabled={!editing}
					/>
				</Grid>
			</>}
		</Grid>)}
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<RadioGroup
					title="Secondary Cables (Sheath Damage)"
					value={'boolean' === typeof inspection.isSecondaryCables 
						? inspection.isSecondaryCables ? 'true' : 'false'
						: inspection.isSecondaryCables
					}
					radios={yesNoRadios}
					onChange={(e) =>
						onChangeInput(e.target.value, 'isSecondaryCables')
					}
					id="secondaryCablesSheatDamage"
					required={true}
					disabled={!editing}
				/>
			</Grid>
			{(inspection.isSecondaryCables === 'true' || inspection.isSecondaryCables === true) && <>
				<Grid item xs={12} style={{ marginLeft: 35 }}>
					<RadioGroup
						title="Select type"
						value={inspection.secondaryCablesType}
						radios={SECONDARY_CABLES_TYPES}
						onChange={(e) =>
							onChangeInput(e.target.value, 'secondaryCablesType')
						}
						required={true}
						disabled={!editing}
					/>
				</Grid>
				{inspection.secondaryCablesType === SECONDARY_CABLES_RADIAL &&
				<div style={{ marginLeft: 39, marginBottom: 10 }}>
					<Grid item xs={12}>
						<RadioGroup
							title="Radial Status"
							value={inspection.secondaryCablesRadial}
							radios={MANHOLE_GRADES_RADIO_OPTIONS}
							onChange={(e) =>
								onChangeInput(e.target.value, 'secondaryCablesRadial')
							}
							required={true}
							disabled={!editing}
						/>
					</Grid>
					{showDetailInput(inspection.secondaryCablesRadial) && <Grid container spacing={2}>
						<Grid item xs={12}>
							<TextFieldComponent
								value={inspection.secondaryCablesRadialWorkDetailQuantity}
								label="Work Completed Quantity"
								fullWidth
								variant="outlined"
								type="text"
								placeholder=""
								onChange={(e) =>
									onChangeInput(e, 'secondaryCablesRadialWorkDetailQuantity')
								}
								required={true}
								maxLength="100"
								disabled={!editing}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextFieldComponent
								value={inspection.secondaryCablesRadialWorkDetailFollowUp}
								label="Follow-up Work"
								fullWidth
								variant="outlined"
								type="text"
								placeholder=""
								onChange={(e) =>
									onChangeInput(e, 'secondaryCablesRadialWorkDetailFollowUp')
								}
								multiline={true}
								rows="4"
								required={true}
								maxLength="500"
								disabled={!editing}
							/>
						</Grid>
					</Grid>}
				</div>}
				{inspection.secondaryCablesType === SECONDARY_CABLES_NETWORK_SYSTEMS && <Grid item xs={12} style={{ marginLeft: 35, marginBottom: 10 }}>
					<TextFieldComponent
						value={inspection.secondaryCablesNetworkSystemWorkCompletedQuantity}
						label="Work Completed Quantity"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={(e) =>
							onChangeInput(e, 'secondaryCablesNetworkSystemWorkCompletedQuantity')
						}
						required={true}
						maxLength="100"
						disabled={!editing}
					/>
				</Grid>}
			</>}		
			<Grid item xs={12}>
				<TextFieldComponent
					value={inspection.deficiencies}
					label="Deficiencies (Other)"
					fullWidth
					variant="outlined"
					type="text"
					placeholder=""
					onChange={(e) =>
					onChangeInput(e, 'deficiencies')
					}
					multiline={true}
					rows="4"
					required={false}
					disabled={!editing}
					maxLength="500"
				/>
			</Grid>
		</Grid>
	</Grid>
}

export default GradedSection;