import React from "react";
import Hidden from "@material-ui/core/Hidden";
import PageHeader from "../../Shared/PageHeader";
import InspectionForm from "../../InspectionForm";

const CreateInspection = ({ userRoles, setInspectionsOffline }) => 
    <>
      <Hidden smUp>
        <PageHeader headerText="New Inspection" />
      </Hidden>
      <InspectionForm userRoles={userRoles} setInspectionsOffline={inspectionsOffline => setInspectionsOffline(inspectionsOffline)} />
    </>

export default CreateInspection;
