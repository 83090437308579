import React, { useEffect, useState } from "react";
import Table from '../../../Shared/Table'
import AssetsService from "../../../../services/AssetsService";
import { isArrayNotEmpty, showSnackbar } from "../../../../Utils"
import { getLookups } from "../../../../Utils/table";
import { useSnackbar } from "notistack";

const tableOptions = () => ({
  filtering: true,
  pageSize: 10,
  showSelectAllCheckbox: true,
  selection: true,
  headerStyle: {
    color: '#007749',
    fontWeight: 'bold'
  },
  pageSizeOptions: [5, 10,25],  
})

const TransmissionsTable = ({ onSelect, setPageLoading, workItems = [] }) => {
  const [transmissions, setTransmissions] = useState([])
  const [transmissionColumns, setTransmissionColumns] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getTransmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[])

  const getTransmissionColumns = (data) => {
    let lookups = {};
      lookups = getLookups(data, ["state"]);

      let cols = [
        {
          title: 'State',
          field: 'state',
          lookup: lookups["state"]
        },
        {
          title: '# of Structures',
          field: 'structureCount'
        },
        {
          title: 'Line ID',
          field: 'lineId'
        }
      ]
      setTransmissionColumns(cols)
  }

  const getTransmissions = () => {    
    setPageLoading(true);
    AssetsService.getAll({ types: "line", includeDetails: "true" }).then(response => {
      const transmissions = response.data || []
      setPageLoading(false);
      
      // Editing
      if (isArrayNotEmpty(transmissions) && isArrayNotEmpty(workItems)) {
        
        const _transmissions = [...transmissions]
        workItems.forEach(workItem => {
          // Find the workitem on the array of assets
          let index = transmissions.findIndex(transmission => transmission.id === workItem.id);
          // Add "checked" attribute for the table
          const assetFound = {...transmissions[index], tableData: { checked: true, id: workItem.id } }
          // Remove the found workitem on the original assets list
          _transmissions.splice(index, 1);
          // add it first and checked
          _transmissions.unshift(assetFound)
        })
        
        setTransmissions(Object.values(_transmissions))
        getTransmissionColumns(Object.values(_transmissions))
      } else {
        setTransmissions(transmissions)
        getTransmissionColumns(transmissions)     
      }     
    }).catch(() => {
      showSnackbarMessage("An error has occurred getting the Transmissions", "error");
      setPageLoading(false);
    });
    
  }
 
  const onSelectionChange = rows => {
    onSelect(rows);
  }

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
	}

  return <Table
          style={{}}
          columns={transmissionColumns}
          data={transmissions}
          title= "Select Lines"
          options={tableOptions()}
          onSelectionChange={(rows, e) => onSelectionChange(rows, e)}
        />
};

export default TransmissionsTable
