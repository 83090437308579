import React, { forwardRef } from 'react';
import {
	makeStyles,
	createTheme,
	ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { PatchedPagination } from './Pagination';

const useStyles = makeStyles(() => ({
	tableContainer: {
		'& h6': {
			whiteSpace: 'unset !important',
		},
	},
	export: {
		color: '#007749',
		border: '1px solid rgba(0, 119, 73, 0.5)',
		fontSize: '0.875rem',
		minWidth: '64px',
		boxSizing: 'border-box',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		fontWeight: '500',
		width: '111px',
		height: '31px',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
}));

const Table = ({
	columns,
	data,
	title,
	style,
	options,
	actions,
	onSelectionChange,
	onRowClick,
	...props
}) => {
	const theme = createTheme({
		palette: {
			secondary: {
				main: '#007749',
			},
			primary: {
				main: '#007749',
			},
		},
	});
	const classes = useStyles();
	const tableIcons = {
		Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
		Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
		Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
		DetailPanel: forwardRef((props, ref) => (
			<ChevronRight {...props} ref={ref} />
		)),
		Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
		Export: () => {
			return <div className={classes.export}>Export</div>;
		},
		Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
		FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
		LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
		NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
		PreviousPage: forwardRef((props, ref) => (
			<ChevronLeft {...props} ref={ref} />
		)),
		ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
		Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
		SortArrow: forwardRef((props, ref) => (
			<ArrowDownward {...props} ref={ref} />
		)),
		ThirdStateCheck: forwardRef((props, ref) => (
			<Remove {...props} ref={ref} />
		)),
		ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
	};

	return (
		<MuiThemeProvider theme={theme}>
			<MaterialTable
				{...props}
				style={style}
				icons={tableIcons}
				columns={columns}
				data={data}
				title={title}
				options={options}
				actions={actions}
				onSelectionChange={onSelectionChange}
				onRowClick={onRowClick ? (e, rowData) => onRowClick(rowData) : null}
				components={{
					Container: (props) => (
						<div className={classes.tableContainer} {...props} />
					),
					Pagination: PatchedPagination,
				}}
			/>
		</MuiThemeProvider>
	);
};

export default Table;
