import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useFlags } from "launchdarkly-react-client-sdk";
import Container from '@material-ui/core/Container';
import ProgressBar from "../../Shared/ProgressBar";
import VideoForm from "../../VideoForm";
import { LOADING_STYLES, FF_VIDEO_FORM } from "../../../Constants";

const CreateVideo = ({ userRoles }) =>  {    
  const [loading, setLoading] = React.useState(false);
  const ff_video_form = useFlags()[FF_VIDEO_FORM];
  const history = useHistory();

  useEffect(() => {
    if (!ff_video_form)
      history.push(`/`);    
  }, [])

  return (
    <Container component="div" maxWidth="sm">
      {loading && <ProgressBar styles={LOADING_STYLES} />}
      <VideoForm setLoading={setLoading} userRoles={userRoles} />
    </Container>
  );
}

export default CreateVideo;
