import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import DateFnsUtils from "@date-io/date-fns";
import Select from "../../Shared/atoms/Select"
import TextFieldComponent from "../../Shared/atoms/TextInput";
import { onlyNumericValuesOnInutTypeNumber, checkDateValid, showSnackbar } from "../../../Utils"
import Slider from "../../Shared/atoms/Slider";
import OutageService from "../../../services/OutageService"
import { useSnackbar } from "notistack";

const TreeFormFields = ({
  editableFields,
  resetForm,
  inspection,
  setInspection,
  initialValue,
  onChangeHandler
}) => {
  const useStyles = makeStyles((theme) => ({
    label: {
      fontSize: 16,
      color: "#000"
    },
    radioMinorDefects: {
      '&$checked': {
        color: '#DDD63E'
      }
    },
    radioMajorDefects: {
      '&$checked': {
        color: 'red'
      }
    },
    radioDead: {
      '&$checked': {
        color: 'red'
      }
    },
    checked: {},
    required: {
      color: theme.palette.primary.required
    },
    dateTimePicker: {
      "& .MuiInputLabel-root": {
        flexDirection: "row-reverse",
        display: "flex",
        "& .MuiInputLabel-asterisk": {
          color: theme.palette.primary.required,
          marginRight: 3
        }
      }
    }
  }))

  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const fieldsSelectOptions = {
    issueTypes: ["Tree", "Branch", "Vines", "Not Tree Related"],
    failureMode: ["Broken", "Uprooted", "Growth"],
    treeSpecies: ["Ash", "Beech", "Birch", "Cedar", "Cherry", "Cottonwood", "Elm", "Fir", "Hemlock", "Hickory", "Locust", "Maple", "Oak", "Pine", "Poplar", "Sassafras", "Spruce", "Sycamore", "Walnut", "Unknown", "Other"],
    construction: ["Primary", "Primary Configuration-Open 3-Phase", "Primary Multi-Phase","Primary Single-Phase", "Primary Spacer", "Secondary", "Service"],
    treeSize: ['Less than 12"', '12" - 24"', '24" - 36"', 'Greater than 36"']
  }
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(()=> {
    if (resetForm) {
      setInspection(initialValue);
    }
  },[resetForm])


  const validYesNoOption = (detailOption) => {
    return detailOption ? "Yes" : "No";
  }

  const getItemSelected = (formValue) => {
    return formValue ? formValue : "";
  }

  const requiredConditional = inspection?.issueType === "Tree" || inspection?.issueType === "Branch";

  const getCircuitByEventId = e => {   
    var eventId = e.target.value;
    if (eventId.length > 0) {
      setLoading(true)
      OutageService.getCircuitById(eventId).then(response => {
        if (response && response.data && response.data.circuitId) {
          const _inspection = {...inspection, circuitId: response.data.circuitId};
          setInspection(_inspection);
        }
      }).catch(() => {
        showSnackbarMessage("An error ocurred getting the circuit","warning");
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
  }

  return inspection ? <>
    <Grid item xs={12}>
      <TextFieldComponent
        value={inspection.eventId}
        label="Event ID"
        fullWidth
        id="eventIdInput"
        variant="outlined"
        type="number"
        placeholder=""
        disabled={!editableFields}
        onChange={(e)=> onChangeHandler("eventId",e)}
        onKeyDown = {evt =>  // Avoid letter "e", "+", space and "-"
          onlyNumericValuesOnInutTypeNumber(evt)
        }
        onBlur={getCircuitByEventId} 
        required={true}
      />
    </Grid>
    <Grid item xs={12}>
      <TextFieldComponent
        value={inspection.circuitId}
        label="Circuit ID"
        fullWidth
        id="circuitIdInput"
        variant="outlined"
        type="text"
        placeholder=""
        disabled={!editableFields || loading}
        onChange={(e)=> onChangeHandler("circuitId", e)}
        required={false}
      />
    </Grid>
    <Grid item xs={12}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          className={classes.dateTimePicker}
          ampm={true}
          inputVariant="outlined"
          label="Time Call Received from Dispatch/Supervisor"
          format="MM/dd/yyyy hh:mm a"
          value={inspection.timeCallReceived }
          onChange={(e)=> onChangeHandler("timeCallReceived",e)}
          clearable={true}
          disableFuture
          fullWidth
          required
          error={editableFields && (!inspection.timeCallReceived || !checkDateValid(new Date(inspection.timeCallReceived)))}
          helperText={editableFields && (!inspection.timeCallReceived ? "" : checkDateValid(inspection.timeCallReceived) ? "" : "Invalid Date")}
          disabled={!editableFields}
        />
      </MuiPickersUtilsProvider>
    </Grid>
    <Grid item xs={12}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          className={classes.dateTimePicker}
          ampm={true}
          inputVariant="outlined"
          label="Date Arrived"
          format="MM/dd/yyyy hh:mm a"
          value={inspection.dateArrived}
          onChange={(e)=> onChangeHandler("dateArrived",e)}
          clearable={true}
          disableFuture
          fullWidth
          required
          error={editableFields && (!inspection.dateArrived || !checkDateValid(new Date(inspection.dateArrived)))}
          helperText={editableFields && (!inspection.dateArrived ? "" : checkDateValid(inspection.dateArrived) ? "" : "Invalid Date")}
          disabled={!editableFields}
        />
      </MuiPickersUtilsProvider>
    </Grid>
    <Grid item xs={12} md={6}>
      <p className={classes.label}>On Private Property?</p>
      <RadioGroup row aria-label="Private Property" name="privateProperty" value={validYesNoOption(inspection.onPrivateProperty)} defaultValue={validYesNoOption(inspection.onPrivateProperty)}>
        <FormControlLabel
          value="Yes"
          control={<Radio color="primary" onChange={()=> onChangeHandler("onPrivateProperty",true)} />}
          label="Yes"
          disabled={!editableFields}
        />
        <FormControlLabel
          value="No"
          control={<Radio color="primary" onChange={()=> onChangeHandler("onPrivateProperty",false)} />}
          label="No"
          disabled={!editableFields}
        />
      </RadioGroup>
    </Grid>
      <Grid item xs={12} md={6}>
      <p className={classes.label}>Arborist Follow Up Needed?</p>
      <RadioGroup row aria-label="Arborist Follow Up Needed?" name="arboristFollowUp" value={validYesNoOption(inspection.isArboristNeeded)} defaultValue={validYesNoOption(inspection.isArboristNeeded) }>
        <FormControlLabel
          value="Yes"
          control={<Radio color="primary" onChange={()=> onChangeHandler("isArboristNeeded", true)} />}
          label="Yes"
          disabled={!editableFields}
        />
        <FormControlLabel
          value="No"
          control={<Radio color="primary" onChange={()=> onChangeHandler("isArboristNeeded", false)} />}
          label="No"
          disabled={!editableFields}
        />
      </RadioGroup>
    </Grid>
    <Grid item xs={12} md={6}>
      <p className={classes.label}>Grounding Needed?</p>
      <RadioGroup row aria-label="Arborist Follow Up Needed?" name="groundingNeeded" value={validYesNoOption(inspection.isGroundingNeeded)} defaultValue={validYesNoOption(inspection.isGroundingNeeded) }>
        <FormControlLabel
          value="Yes"
          control={<Radio color="primary" onChange={()=> onChangeHandler("isGroundingNeeded", true)} />}
          label="Yes"
          disabled={!editableFields}
        />
        <FormControlLabel
          value="No"
          control={<Radio color="primary" onChange={()=> onChangeHandler("isGroundingNeeded", false)} />}
          label="No"
          disabled={!editableFields}
        />
      </RadioGroup>
    </Grid>
    <Grid item xs={12}>
      <Select
        label="Issue Type"
        selectOptions={fieldsSelectOptions.issueTypes}
        selectItem={(e)=> onChangeHandler("issueType", e)}
        itemSelected={[inspection.issueType]}
        required={true}
        multiple={false}
        disabled={!editableFields}
      />
    </Grid>
    <Grid item xs={12}>
      <Select
        label="Failure Mode"
        selectOptions={fieldsSelectOptions.failureMode}
        selectItem={(e)=> onChangeHandler("failureMode", e)}
        itemSelected={inspection.failureMode !== "" ? [inspection.failureMode]: []}
        required={requiredConditional}
        multiple={false}
        disabled={!editableFields}
      />
    </Grid>
    <Grid item xs={12}>
      <p className={classes.label}>{requiredConditional && <span className={classes.required}>*</span>} Tree Condition</p>
      <RadioGroup row aria-label="Tree Condition" name="TreeCondition" value={getItemSelected(inspection.treeCondition)} defaultValue={getItemSelected(inspection.treeCondition)}>
        <FormControlLabel
          value="Healthy"
          control={<Radio color="primary"
          onChange={()=> onChangeHandler("treeCondition", "Healthy")} />}
          label="Healthy"
          disabled={!editableFields}
        />
        <FormControlLabel
          value="Minor Defects"
          control={<Radio
            classes={{root: classes.radioMinorDefects, checked: classes.checked}}
            onChange={()=> onChangeHandler("treeCondition", "Minor Defects")} />}
          label="Minor Defects"
          disabled={!editableFields}
        />
        <FormControlLabel
          value="Major Defects"
          control={<Radio classes={{root: classes.radioMajorDefects, checked: classes.checked}}
            onChange={()=> onChangeHandler("treeCondition", "Major Defects")} />}
          label="Major Defects"
          disabled={!editableFields}
        />
        <FormControlLabel
          value="Dead"
          control={<Radio classes={{root: classes.radioDead, checked: classes.checked}}
          onChange={()=> onChangeHandler("treeCondition", "Dead")} />}
          label="Dead"
          disabled={!editableFields}
        />
      </RadioGroup>
      <FormHelperText className={classes.required}>
        {requiredConditional?
          editableFields? !inspection.treeCondition ? "Required field" : "" : ""
          : ""
        }
      </FormHelperText>
    </Grid>
    <Grid item xs={12}>
      <Select
        label="Tree Species"
        selectOptions={fieldsSelectOptions.treeSpecies}
        selectItem={(e)=> onChangeHandler("treeSpecies", e)}
        itemSelected={inspection.treeSpecies !== "" ? [inspection.treeSpecies]: []}
        required={requiredConditional}
        multiple={false}
        disabled={!editableFields}
      />
    </Grid>
    <Grid item xs={12}>
      <Select
        label="Construction"
        selectOptions={fieldsSelectOptions.construction}
        selectItem={(e)=> onChangeHandler("construction", e)}
        itemSelected={inspection.construction !== "" ? [inspection.construction]: []}
        required={false}
        multiple={false}
        disabled={!editableFields}
      />
    </Grid>
    <Grid item xs={12}>
      <Slider 
        treeSize= {inspection.preFailureDistance} 
        editableFields={editableFields} 
        onChangeHandler={e => onChangeHandler("preFailureDistance", e)}
        title="Pre Failure Distance in Feet" />
    </Grid>
    <Grid item xs={12} md={6}>
      <p className={classes.label}>{requiredConditional && <span className={classes.required}>*</span>} Trim Zone Tree Position Pre-Failure</p>
      <RadioGroup row aria-label="Trim Zone Tree Position Pre-Failure" name="TrimZoneTreePosition"  value={getItemSelected(inspection.trimZoneTreePosition)} defaultValue={getItemSelected(inspection.trimZoneTreePosition)}>
        <FormControlLabel
          value="Inside"
          control={<Radio color="primary" onChange={()=> onChangeHandler("trimZoneTreePosition", "Inside")} />}
          label="Inside"
          disabled={!editableFields}
        />
        <FormControlLabel
          value="Outside"
          control={<Radio color="primary" onChange={()=> onChangeHandler("trimZoneTreePosition", "Outside") } />}
          label="Outside"
          disabled={!editableFields}
        />
      </RadioGroup>
    </Grid>
    <Grid item xs={12}>
      <Slider 
        treeSize= {inspection.treeSize } 
        editableFields={editableFields} 
        onChangeHandler={e => onChangeHandler("treeSize", e)}
        title="Tree Size Diameter in Inches"
        format='"' />
    </Grid>
  </> : ''
}

export default TreeFormFields;