import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PublishTwoToneIcon from '@material-ui/icons/PublishTwoTone';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import UploadFile from '../Shared/UploadFile';
import LinearProgressBar from '../Shared/atoms/LinearProgress';
import Button from '../Shared/atoms/Button';
import { showSnackbar } from '../../Utils';
import VideoService from '../../services/VideoService';

const useStyles = makeStyles((theme) => ({
	formContainer: {
		marginTop: theme.spacing(3),
		'& .MuiGrid-item': {
			width: '100%',
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex'
		}
	},
	nameContainer: {
		justifyContent: 'space-between !important'
	}
}));

const BATCH_SIZE = 5;
const BATCH_DELAY_MS = 1000;
const CHUNK_SIZE = 1024 * 1024; // 1MB

const VideoForm = () => {
	const classes = useStyles();
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState('');
	const [progress, setProgress] = useState(0);
	const [uploadedChunks, setUploadedChunks] = useState(0);
	const [totalChunks, setTotalChunks] = useState(0);
	const [uploadingVideo, setUploadingVideo] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (file) {
			const percentage = (uploadedChunks / totalChunks) * 100;
			setProgress(Math.round(percentage * 100) / 100);
		}
	}, [uploadedChunks, totalChunks, file]);

	const onChangeFile = (files) => {
		if (files?.[0]) {
			const videoFile = files[0];
			setFile(videoFile);
			setProgress(0);
			setUploadedChunks(0);
			const _fileName = uuidv4() + '.' + videoFile.name.split('.').pop();
			setFileName(_fileName);
			setTotalChunks(Math.ceil(videoFile.size / CHUNK_SIZE));
		}
	};

	const startUpload = async () => {
		setUploadingVideo(true);
		setUploadedChunks(0);
		await uploadFile();
	};

	const uploadFile = async () => {
		let uploadFailed = false;

		for (let i = 0; i < totalChunks; i += BATCH_SIZE) {
			const batchPromises = [];
			for (let j = i; j < i + BATCH_SIZE && j < totalChunks; j++) {
				const start = j * CHUNK_SIZE;
				const end = Math.min((j + 1) * CHUNK_SIZE, file.size);
				const chunkData = new Blob([file.slice(start, end)], { type: file.type });
				batchPromises.push(uploadChunk(chunkData, j));
			}

			try {
				await Promise.all(batchPromises);
				setUploadedChunks(chunks => chunks + batchPromises.length);
			} catch (error) {
				console.error('Error uploading batch:', error);
				fileUploadError();
				uploadFailed = true;
				break;
			}

			if (i + BATCH_SIZE < totalChunks) {
				await new Promise(resolve => setTimeout(resolve, BATCH_DELAY_MS));
			}
		}

		if (!uploadFailed) {
			uploadCompleted();
		}
	};

	const uploadChunk = async (chunkData, index) => {
		const response = await VideoService.uploadChunk(fileName, chunkData, index, index === totalChunks - 1);
		if (![200, 204].includes(response?.status)) {
			throw new Error('Upload failed');
		}
		return response;
	};

	const fileUploadError = () => {
		showSnackbarMessage('Video upload failed, please try again.', 'error');
		setUploadingVideo(false);
	};

	const uploadCompleted = () => {
		setProgress(100);
		showSnackbarMessage('Upload completed', 'success');
		resetForm();
	};

	const resetForm = () => {
		setFile(null);
		setProgress(0);
		setFileName('');
		setUploadingVideo(false);
		setUploadedChunks(0);
		setTotalChunks(0);
	};

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	return (
		<Grid container alignItems="center" alignContent="center" justifyContent="center" columnSpacing={2} className={classes.formContainer} data-testid="videoFormContainer">
			<Grid item xs={12}>
				<UploadFile
					isImage={false}
					onOpenGalleryFromIcon={false}
					onChangeFile={onChangeFile}
				/>
			</Grid>
			<Grid item>
				{!!progress && progress > 0 && progress < 100 && <LinearProgressBar value={progress} />}
			</Grid>
			<Grid item xs={12} className={classes.nameContainer}>
				{file ? (
					<>
						{file.name}
						<Button
							disabled={uploadingVideo}
							type="button"
							color="primary"
							icon={<PublishTwoToneIcon />}
							onClick={startUpload}
							label="Submit"
							id="uploadButton"
							data-testid="uploadButton"
						/>
					</>
				) : (
					''
				)}
			</Grid>
		</Grid>
	);
};

export default VideoForm;
