import { FormHelperText, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { damageSeverityRadios, yesNoRadios, STATUS_EVERSOURCE_QA_REVIEW, STATUS_QA_REVIEW, STATUS_OFFICE_INSPECTION } from "../../../../../Constants";
import { onlyNumericValuesOnInutTypeNumber } from "../../../../../Utils";
import { CheckBox } from "../../../../Shared/atoms/Checkbox";
import TextFieldComponent from "../../../../Shared/atoms/TextInput";
import { RadioGroup } from "../../../../Shared/RadioGroup";
import ImagesControl from "../../../../Shared/ImagesControl";
import Select from "../../../../Shared/atoms/Select";

const useStyles = makeStyles((theme) => ({
  indentation: {
    marginLeft: "35px",
  },
  required: {
    color: theme.palette.primary.required,
  },
}));

const SpecificsInformationSecondPart = ({
  form,
  defaultValues,
  onChange,
  images,
  setImages,
  inspectionType,
  showUploadImagesComponent,
  setShowUploadImagesComponent,
  isReadOnly,
  editing,
  originalStatus = false
}) => {
  const classes = useStyles();
  const onChangeInput = (e, key, inputsToReset = []) => {
    let values;
    values = { ...form, [key]: e };
    inputsToReset.forEach(
      (input) => (values = { ...values, [input]: defaultValues[input] })
    );

    if (
      key === "hasHazardousTrees" ||
      key === "hasHighBrushOrTrees" ||
      key === "hasGroundClearanceConcern"
    ) {
      if (
        !values["hasHazardousTrees"] &&
        !values["hasHighBrushOrTrees"] &&
        !values["hasGroundClearanceConcern"]
      ) {
        values = { ...values, groundClearanceHighOrHazardousTreeDetails: "" };
      }
    }
    onChange(values, "specifics2");
  };

  return (
    <>
      <ImagesControl
        images={images}
        onImagesChange={(e) => setImages(e)}
        inspectionType={inspectionType}
        showUploadImagesComponent={showUploadImagesComponent}
        setShowUploadImagesComponent={setShowUploadImagesComponent}
        disabled={isReadOnly && !editing}
        editing={editing}
      /> 
      <Grid container spacing={2}>
        {isReadOnly
          ? <Grid item xs={12}>
              {!editing || (editing && (originalStatus !== STATUS_QA_REVIEW && originalStatus !== STATUS_EVERSOURCE_QA_REVIEW))
                ? <TextFieldComponent
                    label="Status"
                    variant="outlined"
                    fullWidth
                    onChange={() => {}}
                    id="TransmissionStatus"
                    value={form.status}
                    disabled={true}
                  />
                : editing && (originalStatus === STATUS_QA_REVIEW || originalStatus === STATUS_EVERSOURCE_QA_REVIEW) &&
                  <Select
                    label="Status"
                    selectOptions={[STATUS_OFFICE_INSPECTION, STATUS_QA_REVIEW, originalStatus === STATUS_EVERSOURCE_QA_REVIEW && STATUS_EVERSOURCE_QA_REVIEW]}
                    selectItem={(e) => onChangeInput(e, "status")}
                    itemSelected={[form.status]}
                    required={true}
                    multiple={false}
                    id="TransmissionStatusSelect"
                  />
              }
            </Grid>
          : null
        }
        <Grid item xs={12}>
          <CheckBox
            title="Climbing Steps Present"
            checked={form.isClimbingSteps}
            id="isClimbingStepsId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "isClimbingSteps", [
                "areStepsHigherThan12Feet",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.isClimbingSteps && (
          <Grid className={classes.indentation} item xs={12}>
            <RadioGroup
              title="Are Steps Higher than 12 Feet"
              radios={yesNoRadios}
              value={form.areStepsHigherThan12Feet}
              id="areStepsHigherThan12FeetId"
              required={true}
              onChange={(e) =>
                onChangeInput(e.target.value, "areStepsHigherThan12Feet")
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="Culvert Damage or Open"
            checked={form.isCulvertDamageOrOpen}
            id="isCulvertDamageOrOpenId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "isCulvertDamageOrOpen", [
                "culvertDamageSeverity",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.isCulvertDamageOrOpen && (
          <Grid className={classes.indentation} item xs={12}>
            <RadioGroup
              title="Culvert Damage (Severity)"
              radios={damageSeverityRadios}
              value={form.culvertDamageSeverity}
              required={true}
              onChange={(e) =>
                onChangeInput(e.target.value, "culvertDamageSeverity", e.target.value === "No Action" ? ["culvertDamageDetails"] : [])
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        {form.culvertDamageSeverity &&
          form.culvertDamageSeverity !== "No Action" && (
            <Grid className={classes.indentation} item xs={12}>
              <TextFieldComponent
                value={form.culvertDamageDetails}
                label="Culvert Damage Details"
                fullWidth
                id="culvertDamageDetailsId"
                variant="outlined"
                type="text"
                placeholder=""
                onChange={(e) => onChangeInput(e, "culvertDamageDetails")}
                required={true}
                disabled={isReadOnly && !editing}
              />
            </Grid>
          )}
        <Grid item xs={12}>
          <CheckBox
            title="FAA Lighting"
            checked={form.isFAALighting}
            id="isFAALightingId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "isFAALighting", [
                "isFAALightingDamage",
                "faaLightingDamageDetails",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.isFAALighting && (
          <Grid className={classes.indentation} item xs={12}>
            <RadioGroup
              title="FAA Lighting Damage"
              radios={yesNoRadios}
              value={form.isFAALightingDamage}
              required={true}
              onChange={(e) =>
                onChangeInput(e.target.value, "isFAALightingDamage", [
                  "faaLightingDamageDetails",
                ])
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        {form.isFAALightingDamage === "true" && (
          <Grid className={classes.indentation} item xs={12}>
            <TextFieldComponent
              value={form.faaLightingDamageDetails}
              label="FAA Lighting Damage Details"
              fullWidth
              id="faaLightingDamageDetailsId"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "faaLightingDamageDetails")}
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="FAA Marker Balls"
            checked={form.isFAAMarkerBalls}
            id="isFAAMarkerBallsId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "isFAAMarkerBalls", [
                "isFAAMarkerBallsDamage",
                "faaMarkerBallsDamageDetails",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.isFAAMarkerBalls && (
          <Grid className={classes.indentation} item xs={12}>
            <RadioGroup
              title="FAA Marker Balls Damage"
              radios={yesNoRadios}
              value={form.isFAAMarkerBallsDamage}
              required={true}
              onChange={(e) =>
                onChangeInput(e.target.value, "isFAAMarkerBallsDamage", [
                  "faaMarkerBallsDamageDetails",
                ])
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        {form.isFAAMarkerBallsDamage === "true" && (
          <Grid className={classes.indentation} item xs={12}>
            <TextFieldComponent
              value={form.faaMarkerBallsDamageDetails}
              label="FAA Lighting Damage Details"
              fullWidth
              id="faaMarkerBallsDamageDetailsId"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "faaMarkerBallsDamageDetails")}
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="PCS (Cell) Antennas on Structure"
            checked={form.hasPCSAntennasOnStructure}
            id="hasPCSAntennasOnStructureId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasPCSAntennasOnStructure")
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckBox
            title="Birds Nest"
            checked={form.hasBirdsNest}
            id="hasBirdsNestId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasBirdsNest", [
                "birdsNestDetails",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.hasBirdsNest && (
          <Grid item xs={12} className={classes.indentation}>
            <TextFieldComponent
              value={form.birdsNestDetails}
              label="Birds Nest Details"
              fullWidth
              id="birdsNestDetailsId"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "birdsNestDetails")}
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="Erosion"
            checked={form.hasErosion}
            id="hasErosionId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasErosion", [
                "erosionSeverity",
                "erosionDetails",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.hasErosion && (
          <Grid item xs={12} className={classes.indentation}>
            <RadioGroup
              title="Erosion (Severity)"
              radios={damageSeverityRadios}
              value={form.erosionSeverity}
              required={true}
              onChange={(e) =>
                onChangeInput(
                  e.target.value,
                  "erosionSeverity",
                  e.target.value === "No Action" ? ["erosionDetails"] : []
                )
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        {form.erosionSeverity && form.erosionSeverity !== "No Action" && (
          <Grid item xs={12} className={classes.indentation}>
            <TextFieldComponent
              value={form.erosionDetails}
              label="Erosion Details"
              fullWidth
              id="erosionDetailsId"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "erosionDetails")}
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="Counterpoise Exposed"
            checked={form.isCounterpoiseExposed}
            id="isCounterpoiseExposedId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "isCounterpoiseExposed", [
                "counterpoiseDetails",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.isCounterpoiseExposed && (
          <Grid item xs={12} className={classes.indentation}>
            <TextFieldComponent
              value={form.counterpoiseDetails}
              label="Counterpoise Details"
              fullWidth
              id="counterpoiseDetailsId"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "counterpoiseDetails")}
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="Ground Clearance Concern"
            checked={form.hasGroundClearanceConcern}
            id="hasGroundClearanceConcernId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasGroundClearanceConcern")
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckBox
            title="High Brush/Trees"
            checked={form.hasHighBrushOrTrees}
            id="hasHighBrushOrTreesId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasHighBrushOrTrees")
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckBox
            title="Hazardous Trees"
            checked={form.hasHazardousTrees}
            id="hasHazardousTreesId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasHazardousTrees")
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {(form.hasHazardousTrees ||
          form.hasHighBrushOrTrees ||
          form.hasGroundClearanceConcern) && (
          <Grid item xs={12} className={classes.indentation}>
            <TextFieldComponent
              value={form.groundClearanceHighOrHazardousTreeDetails}
              label="Ground Clearance or High / Hazardous Tree Details"
              fullWidth
              id="groundClearanceHighOrHazardousTreeDetailsId"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) =>
                onChangeInput(e, "groundClearanceHighOrHazardousTreeDetails")
              }
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="Encroachments"
            checked={form.hasEncroachments}
            id="hasEncroachmentsId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasEncroachments", [
                "encroachmentDetails",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.hasEncroachments && (
          <Grid item xs={12} className={classes.indentation}>
            <TextFieldComponent
              value={form.encroachmentDetails}
              label="Encroachment Details"
              fullWidth
              id="encroachmentDetailsId"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "encroachmentDetails")}
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="Miscellaneous Problems to Report"
            checked={form.hasMiscellaneousProblemsToReport}
            id="hasMiscellaneousProblemsToReportId"
            onChange={(event) =>
              onChangeInput(
                event.target.checked,
                "hasMiscellaneousProblemsToReport",
                ["miscellaneousProblemsSeverity", "miscellaneousProblemsDetails"]
              )
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.hasMiscellaneousProblemsToReport && (
          <Grid item xs={12} className={classes.indentation}>
            <RadioGroup
              title="Miscellaneous Details"
              radios={damageSeverityRadios}
              value={form.miscellaneousProblemsSeverity}
              required={true}
              onChange={(e) =>
                onChangeInput(
                  e.target.value,
                  "miscellaneousProblemsSeverity",
                  e.target.value === "No Action"
                    ? ["miscellaneousProblemsDetails"]
                    : []
                )
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        {form.miscellaneousProblemsSeverity &&
          form.miscellaneousProblemsSeverity !== "No Action" && (
            <Grid item xs={12} className={classes.indentation}>
              <TextFieldComponent
                value={form.miscellaneousProblemsDetails}
                label="Miscellaneous Problems Details"
                fullWidth
                id="miscellaneousProblemsDetailsId"
                variant="outlined"
                type="text"
                placeholder=""
                onChange={(e) =>
                  onChangeInput(e, "miscellaneousProblemsDetails")
                }
                required={true}
                disabled={isReadOnly && !editing}
              />
            </Grid>
          )}
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.structureHeight}
            label="Structure Height (From Pole Stamp or Nameplate, Feet)"
            fullWidth
            id="structureHeightId"
            variant="outlined"
            type="number"
            placeholder=""
            onChange={(e) =>
              onChangeInput(e, "structureHeight")
            }
            required={false}
            onKeyDown={(evt) => onlyNumericValuesOnInutTypeNumber(evt)}
            disabled={isReadOnly && !editing}
          />
          {form.structureHeight > 999 && (
            <FormHelperText style={{ marginLeft: "14px" }} id="helperSelect" className={classes.required}>
              Structure Height must lower than 999
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.officeInspector}
            label="Office Inspector"
            fullWidth
            id="officeInspectorId"
            variant="outlined"
            type="text"
            placeholder=""
            onChange={(e) => onChangeInput(e, "officeInspector")}
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SpecificsInformationSecondPart;
