import React, { useState, useEffect } from 'react';
import AssetsReportTable from '../../AssetsReport';
import VideoService from '../../../services/VideoService';
import ProgressBar from '../../Shared/ProgressBar';

const AssetsReport = () => {
	const [videosAssets, setAssetsVideo] = useState([]);
	const [assetsLoading, setAssetsLoading] = useState(true);

	useEffect(() => {
		async function fetchData() {
			try {
				setAssetsLoading(true);
				const response = await VideoService.getVideos();
				if (response?.data) {
					setAssetsVideo(response.data);
				}
			} catch (error) {
				console.error('Failed to fetch videos:', error);
			}
			setAssetsLoading(false);
		}

		fetchData();
	}, []);

	return (
		<>
			{assetsLoading && <ProgressBar />}
			<AssetsReportTable videosAssets={videosAssets} />
		</>
	);
};

export default AssetsReport;
