import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { validRoles } from '../../../Utils';

export const RouteGuard = ({
	component: Component,
	userRoles,
	roles,
	account = '',
	...props
}) => {
	const isAcknowledgementAccepted = localStorage.getItem(
		'acknowledgementAccepted'
	);

	return (
		<>
			{validRoles(roles, userRoles).length > 0 ? (
				isAcknowledgementAccepted === 'yes' ? (
					<Route
						{...props}
						render={(routeProps) => (
							<Component
								{...props}
								{...routeProps}
								userRoles={userRoles}
								account={account}
							/>
						)}
					/>
				) : (
					<Redirect to="/" />
				)
			) : (
				<Redirect to="/" />
			)}
		</>
	);
};
