import { callApi } from '../callApi';
import axios from 'axios';
import { RESOURCE_INSPECTIONS } from '../Constants';

let cancelTokenSource = null; // Defines a cancel token globally
class OutageService  {

	getAllEvents(viewport = false) {

		// cancel  previous request if exists
		if (cancelTokenSource) {
			cancelTokenSource.cancel(); 
		}

		// creates a new token for upcomming ajax (overwrite the previous one)
		cancelTokenSource = axios.CancelToken.source();

		return callApi({
			method: 'get',
			url: viewport ?  `${RESOURCE_INSPECTIONS}outageevents?viewport=${viewport}&status=new` : 'outageevents',
			cancelTokenSource
		});

	}

	getCircuitById(eventId) {
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}Outageevents/${eventId}/Circuit`,
		});
	}
	
}
export default new OutageService ();