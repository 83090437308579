import { red } from '@material-ui/core/colors';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme  } from '@material-ui/core/styles';

const breakpoints = createBreakpoints({});
// A custom theme for this app
const theme = createTheme ({
	palette: {
		type: 'light',
		primary: {
			main: '#007749',
			light: '#00B140',
			dark: '#21a1c4',
			required: 'red'
		},
		secondary: {
			main: '#fff',
			light: '#61dafb',
			dark: '#21a1c4',
		},
		warning: {
			main: '#ffa500',
			light: '#61dafb',
			dark: '#21a1c4',
		},
		error: {
			main: red.A400,
		},
		background: {
			default: '#d5d5d5',
		},
	},
	overrides: {
		MuiPaper: {
			root: {
				padding: '20px 10px',
				margin: '10px',
				backgroundColor: '#fff', // 5d737e
			},
		},
		MuiButton: {
			root: {
				margin: 0,
				minWidth: 125,
			},
			containedPrimary: {
				backgroundColor: '#007749',
				color: '#fff',
				'&:hover':{
					backgroundColor: '#00B140',
				}
			},
			outlinedSecondary: {
				color: '#dc004e',
				border: '1px solid #dc004e',
				'&:hover':{
					border: '1px solid #dc004e',
					backgroundColor: '#dc004e0a'
				}
			}   
		},
		MuiPickersModal: {
			dialogRoot:{
				padding: 0,
				MuiPickerDTToolbar: {
					separator: {
						margin: '0 4px'
					}
				},
				'& .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded, & .MuiPickersToolbarButton-toolbarBtn': {
					padding: 0,
					margin: 0
				},
				'& .MuiPickersToolbarButton-toolbarBtn h3.MuiPickersToolbarText-toolbarTxt': {
					lineHeight: '100%',
				},
				'& .MuiPickersBasePicker-pickerView': {
					maxWidth: 'none'
				},
				'& .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary': {
					[breakpoints.down('sm')]: {
						minWidth: 100
					}
				}
			}
		},
		MuiInputBase: {
			input: {
				fontSize: 16,
				color: '#000',
			},
		},
		MuiOutlinedInput: {
			notchedOutline: {
				borderColor: '#000',
			},
			root: {
				'&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
					borderColor: '#007749',
				}
			}  
		},
		MuiFormControl: {
			root: {
				'&:hover label:not(.Mui-disabled)': {
					color: '#007749',
				},
				'& .MuiFormLabel-root:not(.Mui-disabled)': {
					color: '#000',
				}
			}
		},
	},
});
export default theme;