import React, { useEffect, useState } from "react";
import { Grid, makeStyles, TextField, FormHelperText } from "@material-ui/core";
import Select from "../../../Shared/atoms/Select";
import { STATUS_OPTIONS, WORK_QUEUES_TYPES, WORK_QUEUES_TYPES_WITHOUT_TRANSMISSIONS, WORK_QUEUE_TYPE_CIRCUIT_SWEEP, WORK_QUEUE_TYPE_MANHOLE, WORK_QUEUE_TYPE_TRANSMISSION, ASSET_TYPE_MANHOLE, STATE_NAMES } from "../../../../Constants";
import WorkQueueService from "../../../../services/WorkQueueService";
import VendorService from "../../../../services/VendorService";
import StateService from '../../../../services/StateService';
import { useSnackbar } from "notistack";
import { showSnackbar } from "../../../../Utils";

const useStyles = makeStyles((theme) => ({
  required: {
    color: theme.palette.primary.required
  }
}));

const originalState = {
  name: "",
  workQueueType: WORK_QUEUE_TYPE_CIRCUIT_SWEEP,
  status: "",
  validName: false,
  nameErrorMessage: false,
  errorMessage: "",
  vendorName: "",
  state: STATE_NAMES.EMA
}

const FormInputs = ({
  workQueueToEdit,
  updateValues,
  setPageLoading,
  ff_transmission_inspection_form,
  onChangePropName,
  validNameFromSave,
  onChangeValidName,
}) => {
  const classes = useStyles();
  const [name, setName] = useState(originalState.name);
  const [type, setType] = useState(originalState.workQueueType);
  const [status, setStatus] = useState(originalState.status);
  const [states, setStates] = useState([STATE_NAMES.EMA]);
  const [state, setState] = useState(STATE_NAMES.EMA); // "East Massachusetts" as default
  const [validName, setValidName] = useState(originalState.validName);
  const [nameErrorMessage, setNameErrorMessage] = useState(originalState.nameErrorMessage);
  const [errorMessage, setErrorMessage] = useState(originalState.errorMessage);
  const [vendorName, setVendorName] = useState(originalState.vendorName);
  const [vendors, setVendors] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  var nameInput = null;

  useEffect(() => {
    const fetchStates = async () => {
      const { status, data } = await StateService.getAssetsStates(ASSET_TYPE_MANHOLE) ?? {};
      if (status === 200) {
        setStates(data);
        setState(data[0]?.name || STATE_NAMES.EMA)
      }
    }
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    editWorkQueueValues(workQueueToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workQueueToEdit]);

  useEffect(() => {
    if (validNameFromSave) {
      isValidName(type, name, validNameFromSave);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validNameFromSave]);

  const editWorkQueueValues = (workQueueToEdit) => {
    if (workQueueToEdit) {
      setName(workQueueToEdit.name);
      setType(workQueueToEdit.workQueueType);
      setStatus(workQueueToEdit.status);
      setVendorName(workQueueToEdit.vendorName);
      setState(workQueueToEdit.state || STATE_NAMES.EMA)
      setValidName(true);
      workQueueToEdit.workQueueType === WORK_QUEUE_TYPE_TRANSMISSION &&
        getVendors(WORK_QUEUE_TYPE_TRANSMISSION);
      updateValues({
        type: workQueueToEdit.workQueueType,
        name: workQueueToEdit.name,
        status: workQueueToEdit.status,
        validName: true,
        vendorName: workQueueToEdit.vendorName,
        state: workQueueToEdit.state || STATE_NAMES.EMA
      });
    } else {
      setName(originalState.name);
      setType(originalState.workQueueType);
      setStatus(originalState.status);
      setValidName(originalState.validName);
      setNameErrorMessage(originalState.nameErrorMessage);
      setErrorMessage(originalState.errorMessage);
      setState(originalState.state || STATE_NAMES.EMA)
      updateValues({
        type: originalState.workQueueType,
        name: originalState.name,
        status: originalState.status,
        validName: originalState.validName,
        vendorName,
        state: originalState.state || STATE_NAMES.EMA
      });
    }
  };

  const onChangeName = (name) => {
    if (name.length <= 100 || name.length === 0) {
      setName(name);
      setValidName(false);
      setNameErrorMessage(false);
      onChangePropName(name);
    }
  };

  const isValidName = (
    typeSelected = null,
    name,
    validNameFromSave = false
  ) => {
    setName(name);
    if (name && name.length > 0) {
      setPageLoading(true);
      if (workQueueToEdit && name === workQueueToEdit.name) {
        setValidName(true);
        setPageLoading(false);
        updateValues({
          type,
          name,
          status,
          validName: validForm(true, type, vendorName),
          vendorName,
          state
        });
        return;
      }
      WorkQueueService.getByName(name, typeSelected || type)
        .then((workQueue) => {
          let _validName = false;
          setPageLoading(false);
          if (workQueue.length === 0) {
            _validName = true;
            setErrorMessage("");
            validNameFromSave && onChangeValidName(true);
          } else {
            setErrorMessage("Name already exists");
            setNameErrorMessage(true);
            _validName = false;
            name = "";            
            validNameFromSave && onChangeValidName(false);
          }
          setValidName(_validName);
          updateValues({
            type: typeSelected || type,
            name,
            status,
            validName: validForm(_validName, typeSelected || type, vendorName),
            vendorName,
            state
          });
        })
        .catch(() => {
          showSnackbarMessage(
            "An error has occurred getting the Work Queue",
            "error"
          );
        });
    } else {
      updateValues({
        type,
        name,
        status,
        validName: validForm(false, type, vendorName),
        vendorName,
        state
      });
    }
  };

  const onChangeType = (value) => {
    setType(value);
    updateValues({
      type: value,
      name,
      status,
      validName: validForm(validName, value, vendorName),
      vendorName,
      state
    });
    if (nameInput.value !== "") {
      isValidName(value, nameInput.value);
    }
    // Transmission type
    vendors.length === 0 && getVendors();
  };

  const onChangeVendor = (value) => {
    setVendorName(value);
    updateValues({
      type,
      name,
      status,
      validName: validForm(validName, type, value),
      vendorName: value,
      state
    });
  };

  const changeState = stateSelected => {    
    updateValues({
      type,
      name,
      status,
      validName: validForm(validName, type, vendorName),
      vendorName,
      state: stateSelected
    });
    setState(stateSelected)
  }

  const getVendors = () => {
    VendorService.get()
      .then((vendors) => {
        if (vendors.data && vendors.data.length > 0) {
          let _vendors = [];
          vendors.data.forEach(({ name }) => _vendors.push(name));
          setVendors(_vendors);
        }
      })
      .catch(() => {
        showSnackbarMessage(
          "An error has occurred getting the Vendors",
          "error"
        );
      });
  };

  const validForm = (validName, type = "", vendor) => {
    let isValid = validName;
    if (type === WORK_QUEUE_TYPE_TRANSMISSION) {
      isValid = isValid && vendor.length > 0;
    }

    return isValid;
  };

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
  }

  return (
    <>
      <Grid item xs={12} md={4}>
        <Select
          id="workQueueType"
          label="Type"
          selectOptions={
            ff_transmission_inspection_form
              ? WORK_QUEUES_TYPES
              : WORK_QUEUES_TYPES_WITHOUT_TRANSMISSIONS
          }
          selectItem={onChangeType}
          itemSelected={[type]}
          required={true}
          helperText={false}
          multiple={false}
          {...(workQueueToEdit && { disabled: true })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          inputRef={(input) => (nameInput = input)}
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => onChangeName(e.target.value)}
          id="workQueueName"
          inputProps={{ "data-testid": "workQueueName" }}
          onBlur={(e) => isValidName(null, e.target.value)}
        />
        <FormHelperText
          id="helperName"
          className={nameErrorMessage ? classes.required : ""}
        >
          {nameErrorMessage ? errorMessage : ""}
        </FormHelperText>
      </Grid>

      {type === WORK_QUEUE_TYPE_MANHOLE && <Grid item xs={12} md={4}>
        <Select
          label="State"
          selectOptions={states}
          selectItem={e => changeState(e)}
          itemSelected={[state]}
          required
          multiple={false}
        />
      </Grid>}

      {type === WORK_QUEUE_TYPE_TRANSMISSION && (
        <Grid item xs={12} md={4}>
          {!workQueueToEdit || workQueueToEdit.status === "New" ? (
            <Select
              id="vendor"
              inputProps={{ "data-testid": "vendor" }}
              label="Vendor"
              selectOptions={vendors}
              selectItem={onChangeVendor}
              itemSelected={[vendorName]}
              multiple={false}
              required={true}
            />
          ) : (
            <TextField
              id="vendor"
              inputProps={{ "data-testid": "vendor" }}
              label="Vendor"
              variant="outlined"
              fullWidth
              {...(workQueueToEdit && vendorName && { value: vendorName })}
              disabled
            />
          )}
        </Grid>
      )}

      {workQueueToEdit && (
        <Grid item xs={12} md={4}>
          <Select
            label="Status"
            selectOptions={STATUS_OPTIONS}
            itemSelected={[status]}
            required={true}
            multiple={false}
            disabled={true}
          />
        </Grid>
      )}
    </>
  );
};

export default FormInputs
