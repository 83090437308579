import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import StorageService from '../../../services/StorageService';
import ImageCarousel from '../../Shared/ImageCarousel';
import ImageContext from '../../../context/Image/ImageContext';
import { useHistory } from 'react-router-dom';
import AssignmentTwoToneIcon from '@material-ui/icons/AssignmentTwoTone';
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import NatureTwoToneIcon from '@material-ui/icons/NatureTwoTone';
import OutletTwoToneIcon from '@mui/icons-material/OutletTwoTone';
import { formatDate, showSnackbar } from '../../../Utils';
import { IMAGE_STATUS_PENDING_TO_SYNC, INSPECTION_TYPE_TREE, RESOURCE_IMAGES } from '../../../Constants';
import { useSnackbar } from 'notistack';
import StructureIcon from '../../Shared/atoms/StructureIcon';

const useStyles = makeStyles((theme) => ({
	root: {   
		padding: 0,
		height: 'calc(100% - 20px)',
		'&.MuiPaper-elevation1': {
			boxShadow: '0 0 5px 2px rgb(0 0 0 / 15%)'
		},
	},
	textContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '.5rem',
		'& .MuiSvgIcon-root': {
			margin: '0 1rem 0 2rem'
		},
		'& .MuiSvgIcon-root:first-child': {
			marginLeft: '0'
		},
		'& .MuiSvgIcon-colorPrimary': {
			color: theme.palette.primary.main
		}
	},
	chip: {
		margin: 2,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.main,
	},
	chips: {
		display: 'flex',
	},
	damageTypeNames: {
		flexWrap: 'wrap'
	},
	iconContainer: {
		display: 'flex',
		marginLeft: 'auto',
		alignItems: 'center'
	}
}));

const InspectionCard = ({ inspection, inspectionsToShow, ...props }) => {
	const classes = useStyles();
	const [imageList, setImageList] = useState(null);	    
	const { getBlobListByInspection, state } = useContext(ImageContext);
	const [loadingImages, setLoadingImages] = useState(false);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		setImageList(null);
		getImage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionsToShow]);

	const getImage = async () => {
		if (inspection.images) {
			const listobj = getBlobListByInspection() || [];
			const indexBlob = listobj.findIndex((blob) => blob.id === inspection.id);
      
			if (indexBlob !== -1) {  
				setImageList(listobj[indexBlob].blobs);    
			} else {
				setLoadingImages(true);
				let promiseList = [];
				for (const image of inspection.images) {
					if (image.status === IMAGE_STATUS_PENDING_TO_SYNC) {
						// Search in state the name of this pending image
						const pendingImageFoundOnState = state.pendingImages.find(imageState => 
							imageState.name === image.name
						);
						// If image pending to sync is in state, use that url to create the thumbnail
						if (pendingImageFoundOnState?.url) {
							let blob = await fetch(pendingImageFoundOnState.url).then(r => r.blob());
							promiseList.push(blob);
						} else {
							promiseList.push(new Promise(resolve => resolve(IMAGE_STATUS_PENDING_TO_SYNC)));
						}
					} else if (image.name !== '') {
						promiseList.push(StorageService.getBlob(RESOURCE_IMAGES, 'thumbnails', image.name));              
					}
				}

				await Promise.all(promiseList).then(resp=>{
					if (resp) {
						const blobListResult = [];             
						resp.forEach(image => {
							if (image && typeof image === 'string') {
								if (image === IMAGE_STATUS_PENDING_TO_SYNC) {
									blobListResult.push(IMAGE_STATUS_PENDING_TO_SYNC);
								} else {
									blobListResult.push(image);
								}
							} else if (image){
								blobListResult.push(URL.createObjectURL(image));
							}
						});
						let imageObj = {blobs: blobListResult, id: inspection.id};
						setLoadingImages(false);
						setImageList(blobListResult);
						props.onChangePersistenceImage(imageObj);
					}
				}).catch(() => {
					showSnackbarMessage('An error occurred getting the images', 'error');
				});
			}      
		}
	};
 
	const goToDetailPage = () => {
		history.push(`/Inspection/${inspection.id}/${inspection.inspectionType}/${inspection.partitionKey}`);
	};

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	return (
		<Card className={classes.root} data-testid="inspectionCardComponent">
			<CardActionArea>
				<ImageCarousel
					loading={loadingImages}
					images={imageList}
					status={inspection.images.map(image => image.status)}
					height="300px"
					inspectionId={inspection.id}
					inspectionType={inspection.inspectionType}
					partitionKey={inspection.partitionKey}
					carouselKey={inspection.id}
				/>
				<CardContent onClick={goToDetailPage}>
					{inspection.status && inspection.inspectionType && (
						<div className={classes.textContainer}>
							{inspection.inspectionType === INSPECTION_TYPE_TREE
								? <NatureTwoToneIcon color="primary" />
								: <DescriptionTwoToneIcon color="primary" />}
							<Typography variant="body2" color="textSecondary" component="p">
								{inspection.inspectionType}
							</Typography>
							<div className={classes.iconContainer}>
								<AssignmentTwoToneIcon color="primary" />
								<Typography variant="body2" color="textSecondary" component="p">
									{inspection.status}
								</Typography>
							</div>
						</div>
					)}

					{inspection.manholeNumber && <div className={classes.textContainer}>
						<OutletTwoToneIcon color="primary" />   
						<Chip label={inspection.manholeNumber} className={classes.chip} />
					</div>} 

					{inspection.damageTypes && inspection.damageTypes.length > 0 && (
						<div className={classes.textContainer}>
							<WarningTwoToneIcon color="primary" />              
							<div className={classes.damageTypeNames}>
								{inspection.damageTypes.map((type, index) => (
									<Chip
										key={index}
										label={type.name}
										className={classes.chip}
									/>
								))}
							</div>
						</div>
					)}        

					{inspection.issueType && (
						<div className={classes.textContainer}>
							<WarningTwoToneIcon color="primary" />  
							<Chip label={inspection.issueType} className={classes.chip} />
						</div>
					)}

					{inspection.structureId && (
						<div className={classes.textContainer}>
							<StructureIcon />   
							<Chip label={inspection.structureId} className={classes.chip} />
						</div>
					)}  

					{inspection.createdBy && (
						<div className={classes.textContainer}>
							<AssignmentIndTwoToneIcon color="primary" />
							<Typography variant="body2" color="textSecondary" component="p">
								{inspection.createdBy}
							</Typography>
						</div>
					)}

					{inspection.dateCreated && (
						<div className={classes.textContainer}>
							<EventTwoToneIcon color="primary" />
							<Typography variant="body2" color="textSecondary" component="p">
								{formatDate(inspection.dateCreated)}
							</Typography>
						</div>
					)}
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default InspectionCard;
