import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';

const useStyle = makeStyles(() => ({
	container: {
		background: '#007749',
		borderRadius: '5px',
		width: '100px',
		color: '#FFFFFF',
		fontSize: '14px',
	},
	iconSize: {
		fontSize: '14px',
		marginRight: '2px',
	},
	title: {
		fontSize: '14px',
	},
}));

const DaysSinceUpdated = ({ days }) => {
	const classes = useStyle();

	return (
		<Grid
			container
			className={classes.container}
			alignItems="center"
			justifyContent="center"
		>
			<Grid item>
				<Grid
					container
					className={classes.checkAround}
					alignItems="center"
					justifyContent="center"
				>
					<AccessTime className={classes.iconSize} />
				</Grid>
			</Grid>
			<Grid className={classes.title}>
				<label>{days} days ago</label>
			</Grid>
		</Grid>
	);
};

export default DaysSinceUpdated;
