import React from 'react';
import { Link } from "react-router-dom";
import clsx from 'clsx';
import { useFlags } from "launchdarkly-react-client-sdk";
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Home from '@material-ui/icons/Home';
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import TvIcon from '@material-ui/icons/TvTwoTone';
import Divider from '@material-ui/core/Divider';
import AssignmentTwoToneIcon from '@material-ui/icons/AssignmentTwoTone';
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import BlurOnTwoToneIcon from '@material-ui/icons/BlurOnTwoTone';
import VideoCallTwoToneIcon from '@material-ui/icons/VideoCallTwoTone';
import { ROLES, FF_VIDEO_FORM } from '../../../Constants'
import { validRoles } from '../../../Utils';

const useStyles = makeStyles((theme) => ({
  menuList: {
    "& div,  & div svg": { 
      color: theme.palette.primary.light + " !important",
    },
    "& div:hover, & div:hover svg": {      
      color: theme.palette.primary.main + " !important",
    }
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  menuHeader: {
    color: "#888",
    margin: "16px 0 0 16px",
    display: "block",
    height: 20,
    opacity: 1,
    transition: "all .5s",
    fontSize: 16
  },
  menuHeaderOpen: {
    [theme.breakpoints.up('sm')]: {      
      margin: 0,
      height: 0,
      opacity: 0
    }
  },
  menuOpen: {
    "& .MuiListItemText-primary ": {
      whiteSpace: "initial"
    }
  }  
}))

const MainMenu = ({ roles, closeMenu, open }) => {
  const ff_video_form = useFlags()[FF_VIDEO_FORM];
  const classes = useStyles();
  const menuItems = [
    {
      text: "Home",
      to: "/",
      icon: <Home />,
      roles: Object.values(ROLES)
    },
    {
      text: "Inspections",
      to: "/Inspections",
      icon: <SearchIcon />,
      roles: Object.values(ROLES)
    },
    {
      text: "Create Inspection",
      to: "/CreateInspection",
      icon: <AddBoxTwoToneIcon />,
      roles: Object.values(ROLES)
    },
    {
      text: "Work Queue",
      to: "/WorkQueue",
      icon: <AssignmentTwoToneIcon />,
      roles: [ROLES.admin, ROLES.supervisor, ROLES.transmissionInspectionContributor, ROLES.manholeInspectionContributor]
    },
    {
      text: "Work Items",
      to: "/WorkItems",
      icon: <AssignmentTurnedInTwoToneIcon />,
      roles: [ROLES.admin, ROLES.supervisor, ROLES.transmissionInspectionContributor, ROLES.manholeInspectionContributor]
    },
    {
      text: "AI Inspect",
      to: "/ai",
      icon: <BlurOnTwoToneIcon />,
      roles: [ROLES.admin, ROLES.treeInspectionContributor, ROLES.supervisor, ROLES.transmissionInspectionContributor]
    },
    ff_video_form && {
      text: "Asset Video Upload",
      to: "/CreateVideo",
      icon: <VideoCallTwoToneIcon />,
      roles: [ROLES.admin, ROLES.treeInspectionContributor, ROLES.supervisor, ROLES.transmissionInspectionContributor]
    },
    ff_video_form && {
      text: "Asset Video",
      to: "/AssetVideo",
      icon: <TvIcon />,
      roles: [ROLES.admin, ROLES.supervisor]
    },
  ]
  const menuReport = [
    {
      text: "Inspections",
      to: "/Reports/Inspections",
      icon: <AssessmentTwoToneIcon />,
      roles: [ROLES.admin, ROLES.supervisor]
    },
    {
      text: "Circuit Work Items",
      to: "/Reports/WorkQueue",
      icon: <AssignmentTwoToneIcon />,
      roles: [ROLES.admin, ROLES.supervisor]
    },
    {
      text: "Transmission Work Items",
      to: "/Reports/Transmissions",
      icon: <AssignmentTwoToneIcon />,
      roles: [ROLES.admin, ROLES.supervisor]
    },
  ]
  
  return (
  <div data-testid="mainMenu" className={clsx({
    [classes.menuOpen]: open,
  })}>
    <Divider />
    <List className={classes.menuList}>
      {menuItems.map((menuItem, index) => 
         <Link to={menuItem?.to} className={classes.link} onClick={closeMenu} key={index}>
            {validRoles(menuItem?.roles, roles).length > 0 && <ListItem button key={menuItem?.text}>
            <ListItemIcon>
              {menuItem?.icon}
            </ListItemIcon>
            <ListItemText primary={menuItem?.text} />
          </ListItem>}
        </Link>
      )}
    </List>
    
    {/* Only show divider and title roles */ }
    {validRoles([ROLES.admin, ROLES.supervisor], roles).length > 0 && <>
      <Divider />
      <span
        className={clsx(classes.menuHeader, {
          [classes.menuHeaderOpen]: !open,
        })}
      >
          Reports
      </span>
    </>}

    <List className={classes.menuList} data-testid="reportMenuId">
      {menuReport.map((menuItem, index) => 
        <Link to={menuItem.to} className={classes.link} onClick={closeMenu} key={index}>
          {validRoles(menuItem.roles, roles).length > 0 && <ListItem button key={menuItem.text}>
            <ListItemIcon>
              {menuItem.icon}
            </ListItemIcon>
            <ListItemText primary={menuItem.text} />
          </ListItem>}
        </Link>
      )}
    </List>
    
  </div>)
}

export default MainMenu;