import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Hidden from '@material-ui/core/Hidden';
import {
	MuiPickersUtilsProvider,
	KeyboardDateTimePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import MyLocationTwoToneIcon from '@mui/icons-material/MyLocationTwoTone';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AddLocationAltTwoToneIcon from '@mui/icons-material/AddLocationAltTwoTone';
import Select from '../../Shared/atoms/Select';
import { Grid } from '@material-ui/core';
import AssetsService from '../../../services/AssetsService';
import { LOCALSTORAGE_CURRENT_WORK_ITEM, ROLES, currentUserAlias } from '../../../Constants';
import { saveObjInLocalStorage, setDefaultInspectionType, validRoles } from '../../../Utils';
import TextFieldComponent from '../../Shared/atoms/TextInput';
import { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from "@material-ui/core/Paper";
import AddressAutocomplete from "../../Shared/AddressAutocomplete";
import { reverseGeocode } from "../../../Utils/geocode"
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
	filterContainer: {
		display: 'flex',
		padding: theme.spacing(1),
		marginBottom: 0,
		paddingBottom: 0,
		'& .MuiFormControl-root, & .MuiAutocomplete-root': {
			width: '100%',
		},
		'& .MuiChip-root': {
			height: 20,
		},
		'& .MuiGrid-item': {
			display: 'flex',
			alignItems: 'center',
		},
	},
	damageTypesContainer: {
		width: '100%',
	},
	moreFilters: {
		height: '100%',
		borderColor: '#000',
		color: '#000',
		fontWeight: 'normal',
		fontSize: 16,
		textTransform: 'capitalize',
		[theme.breakpoints.down('sm')]: {      
			minWidth: 100
		},
	},
	width: {
		width: '100%'
	},
	filterMobile: {
		[theme.breakpoints.up('sm')]: {      
			display: 'none'
		},
		padding: theme.spacing(1),
	},
	popoverFiltersSort: {
		'& .MuiPaper-root': {
			[theme.breakpoints.down('md')]: {      
				maxWidth: '50%' 
			},
		}
	},
	datePickers: {
		width: '100%'
	},
	assetOptions: {
		display: 'flex',
		alignContent: 'center',
		padding: 0,
		'& svg': {
			margin: 0,
			padding: 0,
			marginRight: 5,
			width: 20
		},
		'& span': {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap'
		}
	},
	assetOptionsContainer: {
		width: 450, 
		[theme.breakpoints.down('md')]: {      
			width: '100%' 
		},
	}
}));

const currentUser = [{ title: currentUserAlias }];

const ListFilters = ({
	activeFilters,
	setActiveFiltersProp,
	onClearFilters,
	onChangeFilterWithOutAction,
	inspectionContext,
	onChangeAssets,
	ff_transmission_inspection_form,
	roles,
	account,
	setLoading,
	setShowMap
}) => {
	const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [lineId, setLineId] = useState(activeFilters.lineIds)
  const [circuitId, setCircuitId] = useState(activeFilters.circuitId)
  const [address, setAddress] = useState(activeFilters.address)
  const idPopoverFilter = showFilters ? 'filter-popover' : undefined;  
	const [types, setTypes] = useState([]);
	const [autoValue, setAutoValue] = useState(checkIfCurrentUserIsSelected(activeFilters.createdBy));
	const [assetValue, setAssetValue] = useState(activeFilters.asset ? `${activeFilters?.asset?.structureNumber} ${activeFilters?.asset?.town ? ` - ${activeFilters.asset.town}` : ''} ${activeFilters?.asset?.circuitId ? ` - ${activeFilters.asset.circuitId}` : ''}` : '');
	const [poleAssets, setPoleAssets] = useState([]);
	
	const timerId = useRef(null);
	useEffect(() => {
		let _filters = {};
		if (
			validRoles([ROLES.transmissionInspectionContributor], roles).length > 0
		) {
			const fromDate = new Date();
			fromDate.setFullYear(fromDate.getFullYear() - 1);      
			_filters = {'submittedFrom': fromDate};
		}
		const currentWorkItems = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENT_WORK_ITEM)) || {workItemType: ''};
		const defaultInspectionType = setDefaultInspectionType(roles, ff_transmission_inspection_form, currentWorkItems);   
		_filters = {..._filters, type: defaultInspectionType.defaultType};
		setActiveFiltersProp({...activeFilters, ..._filters});
		setTypes(defaultInspectionType.types);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[ff_transmission_inspection_form]);

	function clearFilters() {
		onClearFilters();
    setLineId("")
    setCircuitId("")
    setAddress("")
    if(activeFilters.circuitId !== "" || activeFilters.lineIds !== "" || activeFilters.address !== ""){
			saveObjInLocalStorage(LOCALSTORAGE_CURRENT_WORK_ITEM, {

        circuitId: "",
        workItemId: "",
        workQueueId: "",
        lineIds: "",
        address: ""
      })
		}
	}

	function setFilters(type, value, changeFilterWithOutAction) {        
		const updatedValue = {};
		updatedValue[type] = value;
		const mergedValue = {
			...activeFilters,
			...updatedValue,
		};
		if(changeFilterWithOutAction){
			onChangeFilterWithOutAction(mergedValue);
		} else {
			setActiveFiltersProp(mergedValue);
		}
	}

	function checkIfCurrentUserIsSelected (createdBy) {
		let userName= '';
		if (createdBy) {      
			if (createdBy === account) {
				userName = currentUserAlias;
			} else {
				userName= createdBy;
			}
		} 
		return userName;
	}

  const onPlaceChange = (place) => {
    setAddress(place.formatted_address);
    setFilters("address", removeCommas(place.formatted_address));
  };

  const onChangeHandler = (place) => {
    if (isValidLatLong(place)) {
      setAddress(place);
      const { latitude, longitude } = splitLatLong(place);
      reverseGeocode(latitude, longitude)
        .then((resolvedAddress) => {
          setAddress(place);
          setFilters("address", removeCommas(resolvedAddress));
        })
        .catch(() => {});
    } else {
      setAddress(place);
    }
  }; 

  const onBlurAddressHelper = (e) => {
    if (isValidLatLong(e.target.value)) {
      const { latitude, longitude } = splitLatLong(e.target.value);
      reverseGeocode(latitude, longitude)
        .then((address) => {
          saveObjInLocalStorage(LOCALSTORAGE_CURRENT_WORK_ITEM, {
            address: removeCommas(address),
            circuitId: "",
            workQueueId: "",
            workItemId: "",
          });
        })
        .catch(() => {});
    } else {
      setFilters("address", removeCommas(e.target.value));
      saveObjInLocalStorage(LOCALSTORAGE_CURRENT_WORK_ITEM, {
        address: e.target.value,
        circuitId: "",
        workQueueId: "",
        workItemId: "",
      });
    }
  };

  function removeCommas(address) {

		if (!address)
			return

    if (isValidLatLong(address)) {
      return address;
    } else {
      return address.replace(/,/g, "");
    }
  }

  function isValidLatLong(input) {
    const latLongRegex = /^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)),\s*([-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?))$/;
    if (latLongRegex.test(input)) {
        const parts = input.split(',');
        const latitude = parseFloat(parts[0].trim());
        const longitude = parseFloat(parts[1].trim());

        if (latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180) {
            return true;
        }
    }
    return false;
  }

  function splitLatLong(latLongString) {
    const parts = latLongString.split(',');
    if (parts.length === 2) {
        const latitude = parts[0].trim();
        const longitude = parts[1].trim();
        return { latitude, longitude };
    } else {
        throw new Error('Invalid format for latitude/longitude');
    }
  }

  const clearAddress = () => {
    setAddress('');
    setFilters("address", "");
	saveObjInLocalStorage(LOCALSTORAGE_CURRENT_WORK_ITEM, {
        address: "",
      });
  };


	const clearFiltersButton = <Tooltip title="Clear Filters">
		<Button onClick={() => clearFilters()} style={{ minWidth: 'auto' }}>
			<ClearIcon color="primary" />
		</Button>
	</Tooltip>;

	const damageTypeFilter = <Select
		selectOptions={inspectionContext?.damageTypes}
		selectItem={value => setFilters('damageTypes', value)}
		itemSelected={activeFilters?.damageTypes}
		required={false}
		filter={true}
		label="Damage Type"
	/>;

	const submittedByFilter = <Autocomplete
		freeSolo={true}
		id="submitted-by-text-filter"
		onChange={(evt) => {
			setAutoValue(evt.target.value);
		}}
		options={currentUser.map((option) => option.title)}
		value={autoValue || ''}
		renderInput={(params) => <TextField {...params} label="Created By" variant="outlined" onBlur={(evt) => createBySearch(evt.target.value)} />}
	/>;

	const submittedFrom = <KeyboardDateTimePicker
		className={classes.datePickers}
		ampm={true}
		inputVariant="outlined"
		label="From"
		format="MM/dd/yyyy hh:mm a"
		value={inspectionContext?.filters?.submittedFrom}
		onChange={(value) => {
			setFilters('submittedFrom', value);
		}}
		clearable={true}
		id="fromId"
		disableFuture                
	/>;

	const submittedTo = <KeyboardDateTimePicker
		className={classes.datePickers}
		ampm={true}
		inputVariant="outlined"
		label="To"
		format="MM/dd/yyyy hh:mm a"
		value={activeFilters.submittedTo}
		onChange={(value) => setFilters('submittedTo', value)}
		clearable={true}
		disableFuture
	/>;

  const addressFilter = navigator.onLine && window.google && (
    <AddressAutocomplete onPlaceChange={onPlaceChange}>
      <TextFieldComponent
        variant="outlined"
        className={classes.width}
        label="Address"
        value={address}
        onBlur={(e) => {
          onBlurAddressHelper(e);
        }}
        onChange={(e) => {
					onChangeHandler(e)}
				}
				
        InputProps={{
          endAdornment: address ? (
            <InputAdornment position="end">
              <IconButton onClick={clearAddress} size="small">
                <ClearIcon/>
              </IconButton>
            </InputAdornment>
          ): null,
					onKeyDown: e => {
						if (e.key === 'Enter') {
							e.stopPropagation();
							return null
						}
					}
					
        }}
      />
    </AddressAutocomplete>
  );

	const circuitIdFilter = <TextFieldComponent
		variant="outlined"
		className={classes.width}
		label="Circuit Id"
		value={circuitId}
		onBlur={e => { 
			setFilters('circuitId', e.target.value);
			saveObjInLocalStorage(LOCALSTORAGE_CURRENT_WORK_ITEM, {
				circuitId: e.target.value,
				workQueueId: '',
				workItemId: ''
			});
		}}
		onChange={e => setCircuitId(e)}
	/>;

	const lineIdFilter = (
		<TextFieldComponent
			variant="outlined"
			className={classes.width}
			label="Line Id"
			value={lineId}
			id="lineId"
			type="text"
			fullWidth
			placeholder=""
			onBlur={(e) => {
				setFilters('lineIds', e.target.value);
				onChangeAssets(e.target.value);
				saveObjInLocalStorage(LOCALSTORAGE_CURRENT_WORK_ITEM, {
					circuitId: activeFilters.circuitId,
					lineIds: e.target.value,

          workQueueId: "",
          workItemId: "",
          address: ""
        });
			}}
			onChange={(e) => {
				setLineId(e);
			}}
		/>
	);

	const createBySearch = (value) => {       
		if (value) {
			let user = value;
			if (value === currentUserAlias) {
				user = account;
			}
			setAutoValue(value);
			setFilters('createdBy', user);
		}  else {
			setAutoValue('');
			setFilters('createdBy', '');
		}  
	};

	const assetSearch = value => {
		setAssetValue(value);
		clearTimeout(timerId.current);
		if (value?.length >= 3) {
			timerId.current = setTimeout(
				function () {
					setLoading(true);
					assetSearchCallApi(value);
				}, 1000);
		}		
	};

	const assetSearchCallApi = async value => {		
		const assetToSearch = value || assetValue;
		const response = assetToSearch ? await AssetsService.getAssetByPoleNumber(assetToSearch) : [];

		if (response.data) {
			const filtered = response.data.filter(function( asset ) {
				return asset.isActive === true;
			});

			const poleAssetSorted = sortByExactMatchFirst(filtered, value);
			setPoleAssets(poleAssetSorted);
			setLoading(false);
		}
		
	};

	const sortByExactMatchFirst = (data, character) => data
		.filter((item) => item.structureNumber.toLowerCase().includes(character.toLowerCase()))
		.sort((a, b) => {
			const key = character.toLowerCase();
			const isGoodMatchA = a.structureNumber.toLowerCase().startsWith(key);
			const isGoodMatchB = b.structureNumber.toLowerCase().startsWith(key);

			if (isGoodMatchA ^ isGoodMatchB) { // XOR
				return isGoodMatchA ? -1 : 1;
			}

			return a.structureNumber.localeCompare(b.structureNumber);
		});

	return (
		<>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Grid className={classes.filterContainer} container spacing={2}>
					<Grid item xs={6} sm={3} md={3} lg={2}>
						<Select
							selectOptions={types}
							selectItem={(value) => {
								setFilters("type", value);
							}}
							itemSelected={inspectionContext?.filters?.type}
							required={false}
							filter={true}
							multiple={true}
							label="Type"
							id="typeId"
						/>
					</Grid>
					<Grid item xs={12} sm={4} md={3} lg={2}>
						<Select
							selectOptions={inspectionContext?.status}
							selectItem={value => setFilters('status', value)}
							itemSelected={activeFilters?.status}
							required={false}
							filter={true}
							label="Status"
						/>
					</Grid>
					<Grid item xs={6} sm={4} md={3} lg={3}>
						{addressFilter}
					</Grid>
					<Grid item xs={12} sm={4} md={3} lg={3}>
						<Autocomplete
							id="assets-text-filter"
							onChange={(event, value) => {
								setFilters('asset', value);
								setAssetValue(value);
								// On clear or delete value
								if (value === '' || value === null) {
									setPoleAssets([])
								}
								setShowMap && setShowMap(true);
							}}
							options={poleAssets || []}
							value={assetValue}
							PaperComponent={(props) => <Paper {...props} className={classes.assetOptionsContainer} />}
							renderInput={(params) => 
								<TextField
									{...params}
									label="Asset"
									variant="outlined" 
									onChange={(e) => assetSearch(e.target.value)}									
								/>}
							getOptionSelected={(option, value) => {
								if (typeof value === 'object') {
									return value.structureNumber === option.structureNumber;
								} else {
									return option?.structureNumber?.indexOf(value) > -1;
								}
							}}
							getOptionLabel={(pole) => {
								if (pole) {
									if (typeof pole === 'object') {
										return `${pole.structureNumber} ${pole.town ? ` - ${pole.town}` : ''} ${pole.circuitId ? ` - ${pole.circuitId}` : ''}` || '';
									} else {
										return pole || '';
									}
								}
								return '';
							}}
							renderOption={option => <Grid container spacing={0}>								
								<Grid item lg={4}>
									{option.structureNumber && <div className={classes.assetOptions}>
										<AddLocationAltTwoToneIcon color="success" />
										<span title={option.structureNumber}>{option.structureNumber}</span>
									</div>}
								</Grid>
								<Grid item lg={5}>
									{option.town && <div className={classes.assetOptions}>
										<MyLocationTwoToneIcon color="success" />
										<span title={option.town}>{option.town}</span>
									</div>}
								</Grid>
								<Grid item lg={3}>
									{option.circuitId && <div className={classes.assetOptions}>
										<ElectricBoltIcon color="success" />
										<span title={option.circuitId}>{option.circuitId}</span>
									</div>}
								</Grid>
							</Grid>}
						/>
          </Grid>

					<Hidden xsDown>

						<Grid item xs={6} sm={3} md={3} lg={2}>
							<Button
								aria-describedby={idPopoverFilter}
								variant="outlined"
								color="primary"
								onClick={event => {
									setAnchorEl(event.currentTarget);
									setShowFilters(!showFilters);
								}}

								className={classes.moreFilters}
							>
								More
							</Button>
							{clearFiltersButton}
						</Grid>
						<Popover
							id={idPopoverFilter}
							open={showFilters}
							anchorEl={anchorEl}
							onClose={() => setShowFilters(false)}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left',

							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',

							}}
							PaperProps={{
								style: { margin: 0, maxWidth: 800 },
							}}
							className={classes.popoverFiltersSort}
						>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {submittedFrom}
                </Grid>
								<Grid item xs={12}>

                  {submittedTo}
								</Grid>
								<Grid item xs={12}>

                  {submittedByFilter}
								</Grid>
								<Grid item xs={12}>

                  {damageTypeFilter}
                </Grid>
                <Grid item xs={12}>
                  {circuitIdFilter}
								</Grid>
								<Grid item xs={12}>
									{lineIdFilter}

                </Grid>
</Grid>

              <Hidden lgUp>
                <Grid container spacing={2}>
								<Grid item xs={12}>
									{submittedFrom}
								</Grid>
								<Grid item xs={12}>
									{submittedTo}
								</Grid>
							</Grid>
					</Hidden>
						</Popover>
					</Hidden>

					<Grid container spacing={2} className={classes.filterMobile}>
						<Grid item xs={6}>
							{damageTypeFilter}
						</Grid>
						<Grid item xs={6}>
							{submittedByFilter} 

						</Grid>
						<Grid item xs={12}>
							{submittedFrom}
						</Grid>
						<Grid item xs={12}>
							{submittedTo}
						</Grid>
						<Grid item xs={6}>
							{circuitIdFilter}
						</Grid> 

						<Grid item xs={6}>
							{lineIdFilter}
						</Grid> 

						<Grid item xs={6}>
							{clearFiltersButton}
						</Grid>
					</Grid>
				</Grid> 

			</MuiPickersUtilsProvider>
			
		</>
	);
};

export default ListFilters;
