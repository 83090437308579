import React, { useEffect, useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player/file';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SmartModal } from '../atoms/Modal/smartModal';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ZoomInIcon from '@material-ui/icons/ZoomInOutlined';
import AddIcon from '@material-ui/icons/AddBoxTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ImageContext from '../../../context/Image/ImageContext';
import VideoService from '../../../services/VideoService';

const useStyles = makeStyles(() => ({
	buttonContainer: {
		textTransform: 'none',
		color: '#2a9134'
	},
	framesListContainer: {
		marginTop: '20px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	imageWidth: {
		width: '150px'
	},
	selectedImageWidth: {
		width: '100%'
	}
}));

export const AssetVideoPlayer = ({ assetData, onClose, isAssetsMap }) => {
	const [useOverlayVideoSource, setUseOverlayVideoSource] = useState(false);
	const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
	const [currentTime, setCurrentTime] = useState(0);
	const playerRef = useRef(null);
	const [frames, setFrames] = useState([]);
	const [selectedImage, setSelectedImage] = useState(null);
	const [openImageDialog, setOpenImageDialog] = useState(false);
	const classes = useStyles();
	const { setContextImage, removeImageContextImage, resetContextImage } = useContext(ImageContext);
	const history = useHistory();
	const _assetData = assetData;
	const assetIdTitle = _assetData.assetId ? `Asset Id: ${_assetData.assetId}` : ' ';
	const assetTypeTitle = _assetData.assetType ? `Asset Type: ${_assetData.assetType}` : '';
	const circuitTitle = _assetData.circuitId ? `Circuit Id: ${_assetData.circuitId}` : '';
	const title = `${assetIdTitle}  ${assetTypeTitle} ${circuitTitle}`;
	useEffect(() => {
		resetContextImage();
	}, []);

	useEffect(() => {
		if (playerRef.current) {
			playerRef.current.seekTo(currentTime);
		}
	}, [useOverlayVideoSource]);

	useEffect(() => {
		if (assetData?.blobName) {
			fetchVideoSasUri(assetData?.blobName);
		}
	}, [assetData.blobName]);

	const fetchVideoSasUri = async (blobName) => {
		const { data, status } = await VideoService.getVideoSasUri(blobName);
		if (status === 200) {
			setCurrentVideoUrl(data.sasUri);
		}
	};

	const captureImage = () => {
		const video = playerRef.current.getInternalPlayer();
		const canvas = document.createElement('canvas');
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;
		const ctx = canvas.getContext('2d');
		ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
		const imgData = canvas.toDataURL();
		setFrames(prevFrames => [...prevFrames, imgData]);
		var img = canvas.toDataURL();
		const blob = dataURItoBlob(img);
		setContextImage([new File([blob], 'filename.png')], null, true);
	};

	function dataURItoBlob(dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);

		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ia], { type: mimeString });
	}

	const deleteImage = (index) => {
		const updatedFrames = frames.filter((_, i) => i !== index);
		setFrames(updatedFrames);
		removeImageContextImage(index);
	};

	const handleViewImage = (image) => {
		setSelectedImage(image);
		setOpenImageDialog(true);
	};

	const handleCloseImageDialog = () => {
		setOpenImageDialog(false);
	};

	const handleVideoError = async (err) => {
		console.log('Error playing video', err);
		assetData?.blobName && await fetchVideoSasUri(assetData.blobName);
	};

	const handleCreateInspection = async () => {
		let data = {};
		if (isAssetsMap) {
			data = { ...assetData };
		} else {
			data = {
				assetFromVideo: {
					circuitId: assetData.circuitId,
					poleNumber: assetData.poleNumber,
					devices: assetData.devices,
					location: assetData.location
				}
			};
		}
		history.push({
			pathname: '/CreateInspection/',
			...data
		});
	};

	const imageContainerStyle = {
		position: 'relative',
		width: '150px',
		margin: '10px'
	};

	const overlayStyle = {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		height: '100%',
		width: '100%',
		opacity: 0,
		transition: '.5 ease',
		backgroundColor: 'rgba(0,0,0,0.6)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		willChange: 'transform',
	};

	const buttonStyle = {
		margin: '10px',
		padding: '5px 10px',
		cursor: 'pointer',
		color: 'white',
		backgroundColor: '#333',
		borderRadius: '5px',
		border: 'none'
	};

	return (
		<React.Fragment>
			{_assetData.videoId && (
				<SmartModal
					title={title}
					onClose={onClose}
				>
					{assetData.videoId && (
						<FormControlLabel
							control={
								<Switch
									color="primary"
									checked={useOverlayVideoSource}
									onChange={() => {
										const time = playerRef.current.getCurrentTime();
										setCurrentTime(time);
										setUseOverlayVideoSource((prevState) => !prevState);
									}}
									name="useOverlayVideoSource"
								/>
							}
							label="AI Video"
						/>
					)}
					<Button
						onClick={captureImage}
						startIcon={<CameraAltIcon />}
						className={classes.buttonContainer}
					>
						Capture Image
					</Button>
					<ReactPlayer
						ref={playerRef}
						url={currentVideoUrl}
						onError={handleVideoError}
						playing
						controls
						muted
						width="100%"
						height="100%"
						config={{
							file: {
								attributes: {
									crossOrigin: 'anonymous',
								},
							},
						}}
					/>
					<div
						className={classes.framesListContainer}
					>
						{frames.map((frame, index) => (
							<div
								key={index}
								style={imageContainerStyle}
								onMouseOver={(e) =>
									(e.currentTarget.lastChild.style.opacity = 1)
								}
								onMouseLeave={(e) =>
									(e.currentTarget.lastChild.style.opacity = 0)
								}
							>
								<img
									src={frame}
									crossOrigin="anonymous"
									alt={'Captured Frame ${index}'}
									className={classes.imageWidth}
								/>
								<div style={overlayStyle}>
									<IconButton
										style={{
											...buttonStyle,
											backgroundColor: 'transparent',
											boxShadow: 'none',
										}}
										onClick={() => handleViewImage(frame)}
									>
										<ZoomInIcon
											style={{ color: 'white' }}
											onMouseOver={(e) =>
												(e.currentTarget.lastChild.style.color = 'green')
											}
											onMouseLeave={(e) =>
												(e.currentTarget.lastChild.style.color = 'white')
											}
										/>
									</IconButton>
									<IconButton
										style={{
											...buttonStyle,
											backgroundColor: 'transparent',
											boxShadow: 'none',
										}}
										onClick={() => deleteImage(index)}
									>
										<DeleteForeverIcon
											style={{ color: 'white' }}
											onMouseOver={(e) =>
												(e.currentTarget.lastChild.style.color = 'red')
											}
											onMouseLeave={(e) =>
												(e.currentTarget.lastChild.style.color = 'white')
											}
										/>
									</IconButton>
								</div>
							</div>
						))}
					</div>
					{frames.length > 0 && (
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								onClick={handleCreateInspection}
								startIcon={<AddIcon />}
								className={classes.buttonContainer}
							>
								Create Inspection
							</Button>
						</div>
					)}
					<Dialog
						open={openImageDialog}
						onClose={handleCloseImageDialog}
						maxWidth="md"
						fullWidth
					>
						<DialogContent>
							<img
								src={selectedImage}
								alt="selected for viewing"
								className={classes.selectedImageWidth}
							/>
						</DialogContent>
					</Dialog>
				</SmartModal>
			)}

		</React.Fragment>
	);
};
