import React from "react";
import Container from '@material-ui/core/Container';
import Ai from "../../AI";

const AI = () => {  
  return <Container component="div" maxWidth="sm">
      <Ai />
    </Container>
  
}

export default AI;