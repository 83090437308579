import { IconButton } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import React, { useState } from 'react';
import { formatDate, isDateInRange } from '../../Utils';
import { AssetVideoPlayer } from '../Shared/AssetVideoPlayer/assetVideoPlayer';
import Table from '../Shared/Table';
import DateTimePickerFromToFilter from '../Shared/Table/DateTimePickerFromToFilter';

const AssetsReportTable = ({ videosAssets }) => {
	const [showVideo, setShowVideo] = useState(false);
	const [selectedAssetData, setSelectedAssetData] = useState({});

	const handleClick = (videoData) => {
		setSelectedAssetData(videoData);
		setShowVideo(true);
	};

	const handleClose = () => {
		setShowVideo(false);
	};

	const assetsDataWithVideos = videosAssets?.filter(
		(row) =>
			row.blobName &&
			Object.getOwnPropertyDescriptor(row, 'blobName')
	) || [];

	const columns = [
		{
			title: 'Circuit Id',
			field: 'circuitId',
			headerStyle: { textAlign: 'left' },
			cellStyle: { textAlign: 'left' },
		},
		{
			title: 'Asset Type',
			field: 'assetType',
			headerStyle: { textAlign: 'left' },
			cellStyle: { textAlign: 'left' },
		},
		{
			title: 'Asset Id',
			field: 'assetId',
			headerStyle: { textAlign: 'left' },
			cellStyle: { textAlign: 'left' },
		},
		{
			title: 'Video Recorded On',
			field: 'dateCreated',
			render: (rowData) => formatDate(rowData.dateCreated),
			type: 'datetime',
			customFilterAndSearch: (filter, rowData) =>
				isDateInRange(filter, rowData.dateCreated),
			filterComponent: (props) => {
				const { columnDef, onFilterChanged } = props;
				return (
					<DateTimePickerFromToFilter
						onFilterChanged={(e, a) => onFilterChanged(e, a)}
						columnDef={columnDef}
						locale={props.dateTimePickerLocalization}
					/>
				);
			},
		},
		{
			title: 'Actions',
			render: (rowData) => {
				return (
					<div>
						<IconButton aria-label="play" onClick={() => handleClick(rowData)}>
							<PlayCircleOutlineIcon />
						</IconButton>
					</div>
				);
			},
		},
	];

	return (
		<>
			<Table
				style={{ width: '95%' }}
				columns={columns}
				data={assetsDataWithVideos}
				title=""
				options={{
					search: true,
					filtering: true,
					pageSize: 10,
					showSelectAllCheckbox: false,
					selection: false,
					headerStyle: {
						color: '#007749',
						fontWeight: 'bold',
					},
					pageSizeOptions: [5, 10, 25],
				}}
			/>
			{showVideo && (
				<AssetVideoPlayer assetData={selectedAssetData} onClose={handleClose} isAssetsMap={false} />
			)}
		</>
	);
};

export default AssetsReportTable;
