import React from 'react';
import { ZOOM_LEVEL_LINE_ID_LABEL } from '../../../../Constants';
import GooglePolyline from '../Polyline';
import StructureMarker from '../../../../assets/MapMarkers/outageMap/StructureMarker.svg';
import { GoogleMarker } from '../Marker';

export const Lines = ({ lines, map }) => {
	return (
		<>
			{map &&
				lines.map((line, index) => (
					<GooglePolyline line={line.location.coordinates} key={index} />
				))}
			{map &&
				map.zoom >= ZOOM_LEVEL_LINE_ID_LABEL &&
				lines.map((line, index) => (
					<GoogleMarker
						position={line.location.coordinates[0]}
						icon={StructureMarker}
						title={line.lineId}
						key={index}
					/>
				))}
		</>
	);
};
