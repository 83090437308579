import React, { useState, useRef } from 'react';
import { Stage, Layer, Text } from 'react-konva';
import Rectangle from './Rectangle';
import { isNumber } from '../../../../Utils';

const DrawContainer = ({ imagesIds, dimensionAndPosition, currentIndex, selectShape, annotations, setAnnotations, selectedId, setNewAnnotation, newAnnotation, editable = true }) => {  
	const [isDragging, setIsDragging] = useState(false);
	const divRef = useRef(null); 

	// Calculate the size and position of the annotation for the original image size 
	const getOriginalAnnotationPositionAndSize = (sx, sy, x, y) => {
		const imageOriginalWidth = dimensionAndPosition.naturalWidth;
		const imageOriginalHeight = dimensionAndPosition.naturalHeight;
		const imageCurrentWidth = dimensionAndPosition.width;
		const imageCurrentHeight = dimensionAndPosition.height;
		const originalXPosition = (sx * imageOriginalWidth)/imageCurrentWidth;
		const originalYPosition = (sy * imageOriginalHeight)/imageCurrentHeight;		
		const currentAnnotationWidth = x - sx;
		const currentAnnotationHeight = y - sy;		
		const originalAnnotationWidth = (currentAnnotationWidth * imageOriginalWidth)/imageCurrentWidth;
		const originalAnnotationHeight = (currentAnnotationHeight * imageOriginalHeight)/imageCurrentHeight;

		return {
			originalXPosition,
			originalYPosition,
			originalAnnotationWidth,
			originalAnnotationHeight
		}
	}

	const handleMouseDown = e => {
		if (editable) {
			// deselect when clicked on empty area
			const clickedOnEmpty = e.target === e.target.getStage();
			if (clickedOnEmpty) {
				selectShape(null);
				if (newAnnotation.length === 0 && !isDragging) {
					const { x, y } = e.target.getStage().getPointerPosition();
					setNewAnnotation([{ x, y, width: 0, height: 0, key: '0', stroke: '#0000FF' }]);
				}
			}
		}
	};

	const handleMouseUp = event => {
		if (editable && newAnnotation.length === 1) {
			const sx = newAnnotation[0].x;
			const sy = newAnnotation[0].y;
			const { x, y } = event.target.getStage().getPointerPosition();
			const originalAnnotationPositionAndSize = getOriginalAnnotationPositionAndSize(sx, sy, x, y);
			const annotationToAdd = {
				imageId: imagesIds[currentIndex]?.name,
				x: sx,
				y: sy,
				width: x - sx,
				height: y - sy,
				key: annotations.length + 1,
				stroke: '#0000FF',
				issueType: '',
				issueTypeName: '',
				name: '',
				visible: true,
				index: currentIndex,
				naturalHeight: dimensionAndPosition.naturalHeight,
				naturalWidth: dimensionAndPosition.naturalWidth,
				originalXPosition: originalAnnotationPositionAndSize.originalXPosition,
				originalYPosition: originalAnnotationPositionAndSize.originalYPosition,
				originalAnnotationWidth: originalAnnotationPositionAndSize.originalAnnotationWidth,
				originalAnnotationHeight: originalAnnotationPositionAndSize.originalAnnotationHeight,
				operation: 'create'
			};
			if (x - sx >= 5) {
				annotations.push(annotationToAdd);
				setNewAnnotation([]);
				setAnnotations(annotations);
			} else {
				setNewAnnotation([]);
			}
		}
	};

	const handleMouseMove = event => {
		if (editable && newAnnotation.length === 1) {
			const sx = newAnnotation[0].x;
			const sy = newAnnotation[0].y;
			const { x, y } = event.target.getStage().getPointerPosition();
			setNewAnnotation([
				{
					imageId: imagesIds[currentIndex]?.name,
					x: sx,
					y: sy,
					width: x - sx,
					height: y - sy,
					key: '0',
					stroke: '#0000FF',
					issueType: '',
					issueTypeName: '',
					name: '',
					visible: true,
					index: currentIndex,
					naturalHeight: dimensionAndPosition.naturalHeight,
					naturalWidth: dimensionAndPosition.naturalWidth
				}
			]);
		}
	};

	const isValidAnnotationToDraw = annotation => annotation.visible && annotation.index === currentIndex && isNumber(annotation.width) && isNumber(annotation.height) && isNumber(annotation.x) && isNumber(annotation.y) && !annotation.isDeleted;
  const annotationsToDraw = editable ? [...annotations, ...newAnnotation] : [...annotations];
	return dimensionAndPosition ? (<div ref={divRef} style={{ width: dimensionAndPosition.width, height: dimensionAndPosition.height, position: 'absolute', left: dimensionAndPosition.left, top: dimensionAndPosition.top, zIndex: 3 }}>
		<Stage
			width={dimensionAndPosition.width}
			height={dimensionAndPosition.height}
			onTouchStart={handleMouseDown}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
		>
			<Layer>
				{annotationsToDraw.map((annotation, i) => isValidAnnotationToDraw(annotation) && <>
					<Text fontSize={13} ellipsis={false} text={`${annotation.name === '' ? '' : annotation.name} ${annotation.ai ? `(${annotation.confidence})` : ''}`} wrap="char" x={annotation.x} y={annotation.y <= 10 ? annotation.y + 10 : annotation.y - 20} stroke="white" fontFamily="Helvetica" strokeWidth={1} width={dimensionAndPosition.width - annotation.x} />
					<Rectangle
						key={i}
						shapeProps={annotation}
						isSelected={annotation.key === selectedId}
						onSelect={() => {
							editable && selectShape(annotation.key);
						}}
						onChange={(newAttrs) => {
							if (editable) {
								const _newAttrs = newAttrs;
								_newAttrs.operation = _newAttrs.operation && _newAttrs.operation === 'create' ? 'create' : 'update';
								const rects = annotations.slice();
								rects[i] = _newAttrs;
								setAnnotations([...rects]);
							}
						}}
						onDragStart={() => editable && setIsDragging(true)}
						onDragEnd={() => editable && setIsDragging(false)}
						// onDelete={() => deleteShape(selectedId)}
					/>
				</>)}
			</Layer>
		</Stage>
	</div>
	) : '';
};

export default DrawContainer;