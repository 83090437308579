import { FormControlLabel, FormGroup } from "@material-ui/core";
import  {default as MaterialSwitch} from "@material-ui/core/Switch";
import React from "react";

export const Switch = ({checked, label, onChange}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <MaterialSwitch
            color="primary"
            checked={checked}
            onChange={onChange}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
