import { FormLabel, Grid } from "@material-ui/core";
import * as React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  accessibleVIARadios,
  dangerWarningSignsAffixedRadios,
  downLeadGroundWireDamageRadios,
  insulatorConfigurationOtherOpts,
  jumperLoopOpts,
  lightingArrestorsPresentRadios,
  lineAngleRadio,
  yesNoRadios,
  REGION_OPTIONS,
  structureTypeCircuitRadios,
  structureTypeMaterialOpts,
  structureTypePoleOpts,
  STATUS_QA_REVIEW,
  STATUS_EVERSOURCE_QA_REVIEW,
  STATUS_OFFICE_INSPECTION
} from "../../../../../Constants";
import AssetsService from "../../../../../services/AssetsService";
import { onlyNumericValuesOnInutTypeNumber } from "../../../../../Utils";
import { CheckBox } from "../../../../Shared/atoms/Checkbox";
import Select from "../../../../Shared/atoms/Select";
import TextFieldComponent from "../../../../Shared/atoms/TextInput";
import { CheckboxGroup } from "../../../../Shared/CheckboxGroup";
import { RadioGroup } from "../../../../Shared/RadioGroup";
import ImagesControl from "../../../../Shared/ImagesControl";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#007749",
    marginBottom: "18px",
  },
  required: {
    fontSize: "0.75rem",
    marginLeft: "14px",
    color: theme.palette.primary.required
  },
  indentation: {
    marginLeft: "35px",
  },
}));

const assetsFilter = { isActive: true, includeDetails: true };
export const StructureInformation = ({
  onChange,
  form,
  defaultValues,
  onChangeType,
  transmissionFromWorkItem,
  inspectionType,
  images,
  setImages,
  showUploadImagesComponent,
  setShowUploadImagesComponent,
  workQueue,
  isReadOnly,
  editing,
  originalStatus = false,
  types
}) => {
  const [lines, setLines] = React.useState([]);
  const [structures, setStructures] = React.useState([]);
  const [regionErrorMessage, setRegionErrorMessage] = React.useState(false);
  const [lineErrorMessage, setLineErrorMessage] = React.useState(false);
  const [assetsLoading, setAssetsLoading] = React.useState(false)
  const classes = useStyles();  

  const onChangeInput = (e, key, inputsToReset = []) => {
    let values;
    values = { ...form, [key]: e };
    inputsToReset.forEach(
      (input) => (values = { ...values, [input]: defaultValues[input] })
    );
    
    if (key === "lineId" || key === "region") {
      getAssets(key, e);
    } else if (key === "structureId") { // Check for custom structureID
      values = { ...values, isCustomStructure: !structures.includes(e) };
    }
    onChange(values, "structure", true)    
  };

  const getAssets = (key, e) => {
    setAssetsLoading(true)
    let filters = { ...assetsFilter };
    let field = "lineId";
    if (key === "region") {
      setRegionErrorMessage(false)
      setLineErrorMessage(false)
      filters = { ...filters, types: "line", state: e };
    } else {
      setLineErrorMessage(false)
      field = "structureId";
      filters = {
        ...filters,
        types: "structure",
        state: form.state,
        lineIds: e,
      };
    }

    AssetsService.getAll(filters).then((lines) => {
      setAssetsLoading(false)
      let _assets = [];
      if (lines.data.length > 0) {
        lines.data.forEach((line) => _assets.push(line[field]));
        key === "region" ? setLines([..._assets]) : setStructures([..._assets]);
      }
    });
  };

  const lineIdOnOpen = () => {
    setRegionErrorMessage(form.region === "")
  }

  const materialTypeEditable = (isReadOnly && !editing) 
    || (isReadOnly && editing && (form.status === STATUS_OFFICE_INSPECTION 
      || form.status === STATUS_QA_REVIEW 
      || form.status === STATUS_EVERSOURCE_QA_REVIEW))
  return (
    <>
      <ImagesControl
        images={images}
        onImagesChange={(e) => setImages(e)}
        inspectionType={inspectionType}
        showUploadImagesComponent={showUploadImagesComponent}
        setShowUploadImagesComponent={setShowUploadImagesComponent}
        disabled={isReadOnly && !editing}
        editing={editing}
      /> 
      <Grid container spacing={2}>      
        <Grid item xs={12}>
          <Select
            selectItem={onChangeType}
            selectOptions={types}
            itemSelected={[inspectionType]}
            label="Type"
            required={true}            
            multiple={false}
            id="inspectionTypeInput"
            disabled={transmissionFromWorkItem || isReadOnly || false}
          />
        </Grid>
        {isReadOnly
          ? <Grid item xs={12}>
              {!editing || (editing && (originalStatus !== STATUS_QA_REVIEW && originalStatus !== STATUS_EVERSOURCE_QA_REVIEW))
                ? <TextFieldComponent
                    label="Status"
                    variant="outlined"
                    fullWidth
                    onChange={() => {}}
                    id="TransmissionStatus"
                    value={form.status}
                    disabled={true}
                  />
                : editing && (originalStatus === STATUS_QA_REVIEW || originalStatus === STATUS_EVERSOURCE_QA_REVIEW) &&
                  <Select
                    label="Status"
                    selectOptions={[STATUS_OFFICE_INSPECTION, STATUS_QA_REVIEW, originalStatus === STATUS_EVERSOURCE_QA_REVIEW && STATUS_EVERSOURCE_QA_REVIEW]}
                    selectItem={(e) => onChangeInput(e, "status")}
                    itemSelected={[form.status]}
                    required={true}
                    multiple={false}
                    id="TransmissionStatusSelect"
                  />
              }
            </Grid>
          : null
        }
        {((workQueue && workQueue.name !== "") || form.workQueueName) && <Grid item xs={12}>
          <TextFieldComponent
            label="Work Queue"
            variant="outlined"
            fullWidth
            onChange={() => {}}
            id="workQueue"
            value={workQueue.name || form.workQueueName}
            disabled={true}
          />
        </Grid>}
        <Grid item xs={12}>
          <Select
            label="Region"
            selectOptions={REGION_OPTIONS}
            selectItem={(e) =>
              onChangeInput(e, "region", ["lineId", "structureId"])
            }
            itemSelected={[form.region]}
            required={true}
            helperText= {true}
            multiple={false}
            disabled={form.fromWorkItem || isReadOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Line ID"
            selectOptions={lines}
            selectItem={(e) => onChangeInput(e, "lineId", ["structureId"])}
            itemSelected={form.lineId ? [form.lineId] : false}
            required={true}
            multiple={false}
            disabled={form.fromWorkItem || isReadOnly}
            onOpen={lineIdOnOpen}
            error={true}
            defaultText={regionErrorMessage ? "Please select a Region" : ""}
            loading={assetsLoading}
          />
          {regionErrorMessage && <FormLabel>
            <span className={classes.required}>Please select a Region</span>
          </FormLabel>}
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            freeSolo={true}
            options={structures.length > 0
              ? structures
              : form.region === "" 
                ? ["Please select a Region"]
                : form.lineId === "" 
                  ? ["Please select a Line ID"]
                  : []}
            getOptionDisabled={(option) => 
              option === "Please select a Line ID" || option === "Please select a Region"
            }
            onChange={(e, value) => onChangeInput(value, "structureId")}
            value={form.structureId}
            variant="outlined"
            disabled={form.fromWorkItem || isReadOnly}
            required={true}
            renderInput={(params) =>          
              <TextFieldComponent
                {...params} 
                variant="outlined" 
                value={form.structureId}
                label="Structure ID"
                placeholder=""
                required={true}
                onChange={(value) => onChangeInput(value, "structureId")}
                disabled={form.fromWorkItem || isReadOnly}
              />
            }
          />
          {lineErrorMessage && <FormLabel>
            <span className={classes.required}>Please select a Line ID</span>
          </FormLabel>}
        </Grid>
        <Grid item xs={12}>
          <CheckBox
            title="Pole Unable to be Inspected"
            checked={form.isPoleUnableToBeInspected}
            id="isPoleUnableToBeInspectedId"
            onChange={(event) =>
              onChangeInput(event.target.checked, "isPoleUnableToBeInspected", [
                "poleUnableToBeInspectedDetails",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.isPoleUnableToBeInspected && (
          <Grid item xs={12} className={classes.indentation}>
            <TextFieldComponent
              value={form.poleUnableToBeInspectedDetails}
              label="Reason"
              fullWidth
              id="poleUnableToBeInspectedDetailsId"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) =>
                onChangeInput(e, "poleUnableToBeInspectedDetails")
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <RadioGroup
            title="Structure Type (Circuit Designation)"
            radios={structureTypeCircuitRadios}
            value={form.circuitDesignation}
            required={true}
            onChange={(e) =>
              onChangeInput(e.target.value, "circuitDesignation")
            }
            disabled={isReadOnly && !editing}
            id="structureTypeInput"
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Structure Type (Material)"
            selectOptions={structureTypeMaterialOpts}
            selectItem={(e) => onChangeInput(e, "materialType")}
            itemSelected={[form.materialType]}
            required={true}
            multiple={false}
            disabled={materialTypeEditable}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Structure Type (Pole)"
            selectOptions={structureTypePoleOpts}
            selectItem={(e) => onChangeInput(e, "poleType")}
            itemSelected={[form.poleType]}
            required={true}
            multiple={false}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Insulator Configuration (Other)"
            selectOptions={insulatorConfigurationOtherOpts}
            selectItem={(e) =>
              onChangeInput(e, "insulatorConfiguration", [
                "hasJumperLoop",
                "insulatorConfigurationOther",
              ])
            }
            itemSelected={[form.insulatorConfiguration]}
            required={true}
            multiple={false}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.insulatorConfiguration === "Strain" && (
          <>
            <Grid item xs={12} className={classes.indentation}>
              <CheckBox
                title="Insulator in Jumper Loop"
                checked={form.hasJumperLoop}
                onChange={(event) =>
                  onChangeInput(event.target.checked, "hasJumperLoop")
                }
                disabled={isReadOnly && !editing}
              />
            </Grid>
            {form.hasJumperLoop && (
              <Grid item xs={12} className={classes.indentation}>
                <RadioGroup
                  radios={jumperLoopOpts}
                  value={form.jumperLoop}
                  onChange={(e) => onChangeInput(e.target.value, "jumperLoop")}
                  disabled={isReadOnly && !editing}
                />
              </Grid>
            )}
          </>
        )}
        {form.insulatorConfiguration === "Other" && (
          <Grid item xs={12} className={classes.indentation}>
            <TextFieldComponent
              value={form.insulatorConfigurationOther}
              label="(Other)"
              fullWidth
              id="insulatorConfigurationOtherInput"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "insulatorConfigurationOther")}
              required={false}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <RadioGroup
            title="Line Angle"
            radios={lineAngleRadio}
            value={form.lineAngle}
            required={true}
            onChange={(e) => onChangeInput(e.target.value, "lineAngle")}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckBox
            title="Guy Anchors"
            checked={form.hasGuyAnchors}
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasGuyAnchors", [
                "guyAnchors",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.hasGuyAnchors && (
          <Grid item xs={12} className={classes.indentation}>
            <CheckboxGroup
              title="Guy Anchor or Guy Marker Problems"
              requiredFieldMessage='At least 1 "Guy Anchor or Guy Marker Problems" must be selected'
              required={true}
              checkBoxes={form.guyAnchors}
              onGroupChange={(e) => onChangeInput(e, "guyAnchors")}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <RadioGroup
            title="Danger / Warning Signs Affixed"
            radios={dangerWarningSignsAffixedRadios}
            required={true}
            value={form.dangerWarningSign}
            onChange={(e) =>
              onChangeInput(e.target.value, "dangerWarningSign", [
                "dangerWarningSignDetails",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.dangerWarningSign === "Missing Details" && (
          <Grid item xs={12} className={classes.indentation}>
            <TextFieldComponent
              value={form.dangerWarningSignDetails}
              label="Danger/Warning Signs Missing (Details)"
              fullWidth
              id="dangerWarningSignDetailsInput"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "dangerWarningSignDetails")}
              required={false}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="Down Lead/Ground Wire Damage"
            checked={form.hasGroundWireDamage}
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasGroundWireDamage", [
                "groundWireDamage",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.hasGroundWireDamage && (
          <Grid item xs={12} className={classes.indentation}>
            <RadioGroup
              title=""
              requiredFieldMessage="At least 1 conditional option must be selected"
              value={form.groundWireDamage}
              radios={downLeadGroundWireDamageRadios}
              required={true}
              onChange={(e) =>
                onChangeInput(e.target.value, "groundWireDamage")
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="OPGW Splice Can"
            checked={form.hasOpticalGroundWireSpliceCan}
            onChange={(event) => {
              onChangeInput(
                event.target.checked,
                "hasOpticalGroundWireSpliceCan",
                ["isOpticalGroundWireSpliceCanInstalledCorrectly"]
              );
            }}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.hasOpticalGroundWireSpliceCan && (
          <Grid item xs={12} className={classes.indentation}>
            <RadioGroup
              title="OPGW Splice Can, Grounding, and Col Installed Correctly"
              radios={yesNoRadios}
              value={form.isOpticalGroundWireSpliceCanInstalledCorrectly}
              onChange={(e) =>
                onChangeInput(
                  e.target.value,
                  "isOpticalGroundWireSpliceCanInstalledCorrectly"
                )
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckBox
            title="Lightning Arrestor"
            checked={form.hasLightningArrestor}
            onChange={(event) =>
              onChangeInput(event.target.checked, "hasLightningArrestor", [
                "lightningArrestorsCount",
                "lightningArrestorCondition",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.hasLightningArrestor && (
          <>
            <Grid item xs={12} className={classes.indentation}>
              <TextFieldComponent
                value={form.lightningArrestorsCount}
                label="# of Lightning Arrestors Present"
                fullWidth
                id="lightningArrestorsCountInput"
                variant="outlined"
                type="number"
                placeholder=""
                onChange={(e) => onChangeInput(e, "lightningArrestorsCount")}
                required={true}
                onKeyDown={(evt) => onlyNumericValuesOnInutTypeNumber(evt)}
                disabled={isReadOnly && !editing}
              />
            </Grid>
            <Grid item xs={12} className={classes.indentation}>
              <RadioGroup
                title="Lighting Arrestors Present"
                value={form.lightningArrestorCondition}
                radios={lightingArrestorsPresentRadios}
                onChange={(e) =>
                  onChangeInput(e.target.value, "lightningArrestorCondition")
                }
                disabled={isReadOnly && !editing}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <RadioGroup
            title="Accessible VIA"
            value={form.accessibleBy}
            radios={accessibleVIARadios}
            required={true}
            onChange={(e) =>
              onChangeInput(e.target.value, "accessibleBy", [
                "accessibleByDetails",
              ])
            }
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {form.accessibleBy === "Not Accessible" && (
          <Grid item xs={12} className={classes.indentation}>
            <TextFieldComponent
              value={form.accessibleByDetails}
              label="Reason"
              fullWidth
              id="accessibleByDetailsInput"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "accessibleByDetails")}
              required={false}
              disabled={isReadOnly && !editing}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.notes}
            label="Notes"
            fullWidth
            id="notesInput"
            variant="outlined"
            type="text"
            placeholder=""
            onChange={(e) => onChangeInput(e, "notes")}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.fieldInspector}
            label="Field Inspector"
            fullWidth
            id="fieldInspectorInput"
            variant="outlined"
            type="text"
            placeholder=""
            onChange={(e) => onChangeInput(e, "fieldInspector")}
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.pilot}
            label="Pilot"
            fullWidth
            id="pilotInput"
            variant="outlined"
            type="text"
            placeholder=""
            onChange={(e) => onChangeInput(e, "pilot")}
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
      </Grid>
    </>
  );
};
