import React from 'react';
import { TablePagination } from '@material-ui/core';

export function PatchedPagination(props) {
	const {
		ActionsComponent,
		onChangePage,
		onChangeRowsPerPage,
		...tablePaginationProps
	} = props;

	return (
		<TablePagination
			{...tablePaginationProps}
			// @ts-expect-error onChangePage was renamed to onPageChange
			onPageChange={onChangePage}
			onRowsPerPageChange={onChangeRowsPerPage}
			ActionsComponent={(subProps) => {
				const { onPageChange, ...actionsComponentProps } = subProps;
				return (
					// @ts-expect-error ActionsComponent is provided by material-table
					<ActionsComponent
						{...actionsComponentProps}
						onChangePage={onPageChange}
					/>
				);
			}}
		/>
	);
}
