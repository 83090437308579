import React, { useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const MLSwitch = ({...props}) => {
	const [checked, setChecked] = useState(false);

	const handleChange = (e) => {
		const checked = e.target.checked;
		props.setAi(checked);     
		setChecked(checked);
	};

	return <FormGroup>
		<FormControlLabel
			control={
				<Switch 
					color="primary"
					checked={checked}
					onChange={handleChange}
				/>} 
			label="AI Images" />
	</FormGroup>;
};

export default MLSwitch;
