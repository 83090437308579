import React, { useEffect, useState } from 'react';
import Table from '../../../Shared/Table'
import CircuitService from '../../../../services/Circuits';
import { isArrayNotEmpty, showSnackbar } from '../../../../Utils'
import { getLookups } from '../../../../Utils/table';
import { useSnackbar } from 'notistack';
import { WORK_QUEUE_TYPE_MANHOLE } from '../../../../Constants';

const tableOptions = () => ({
  filtering: true,
  pageSize: 10,
  showSelectAllCheckbox: true,
  selection: true,
  selectionProps: rowData => ({
    disabled: rowData.status && rowData.status !== '0%'
  }),
  headerStyle: {
    color: '#007749',
    fontWeight: 'bold'
  },
  pageSizeOptions: [5, 10,25],  
})

const CircuitsTable = ({ onSelect, setPageLoading, workItems = [], type }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([])
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
   getCircuits();
   // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[type]);

  const getColumns = data => {
    const lookupKeys = ['state', 'division', 'areaWorkCenter'];
    let lookups = {};
    lookups = getLookups(data, lookupKeys); 
    
    let cols = [
      {
        title: 'State',
        field: 'state',
        lookup: lookups['state']
      },
      { 
          title: 'Division',
          field: 'division',
          lookup: lookups['division']
      },
      {
        title: 'AWC',
        field: 'areaWorkCenter',
        lookup: lookups['areaWorkCenter']
      },
      { title: 'Town', field: 'town' },       
      {...type === WORK_QUEUE_TYPE_MANHOLE ?
        { title: 'Manhole ID', field: 'manholeNumber' } : { title: 'Circuit ID', field: 'circuitId' }
      }
    ];
    // Add status if editing
    if (isArrayNotEmpty(workItems)) {
      cols.splice(cols.length - 1, 0, { title: 'Status', field: 'status' })
    }
    setColumns(cols);
  };

  const getCircuits = () => {
    setPageLoading(true);
    CircuitService.get().then(response => {
      setTableData(response);
    }).catch(() => {
      showSnackbarMessage('An error has occurred getting the Circuits', 'error');
      setPageLoading(false);
    });
  };

  const setTableData = response => {
    const tableData = response.data || [];
    setPageLoading(false);
    if (isArrayNotEmpty(tableData) && isArrayNotEmpty(workItems)) {
      // join all results and work items, first work items
      const arrayMerged = workItems.concat([...tableData]);
      // remove results that are already tied with workqueue
      const result = arrayMerged.reduce((unique, o) => {
        if (!unique.some(obj => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      setData(result)
      getColumns(result)
    } else {
      setData(tableData)
      getColumns(tableData)
    }
  };

  const onSelectionChange = rows => {
    onSelect(rows);
  };

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
	}
  return <Table
            style={{}}
            columns={columns}
            data={data}
            title="Select Circuits"
            options={tableOptions()}
            onSelectionChange={(rows, e) => onSelectionChange(rows, e) }
          />
};

export default CircuitsTable
