import React, { useState } from "react";
import { Grid, Hidden, Container } from "@material-ui/core";
import WorkItemsTable from "./Table";
import WorkItemsMobileList from "./MobileList";
import MainFilters from "./Filters";
import WorkItemsService from "../../services/WorkItemsService";
import ProgressBar from "../Shared/ProgressBar"
import { FF_TRANSMISSION_INSPECTION_FORM, INSPECTION_TYPE_MANHOLE, ROLES } from "../../Constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSnackbar } from "notistack";
import { showSnackbar, validRoles } from "../../Utils";
const WorkItems = ({ userRoles }) => {

	const [data, setData] = useState([]);
	const [dataFiltered, setDataFiltered] = useState([]);
	const [type, setType] = useState("Circuit Sweep");
	const [name, setName] = useState();
	const [workQueueId, setWorkQueueId] = useState();
	const [loading, setLoading] = useState(true);
	const [vendorName, setVendorName] = useState(null)
	const  ff_transmission_inspection_form = useFlags()[FF_TRANSMISSION_INSPECTION_FORM];
	const { enqueueSnackbar } = useSnackbar();
	const changeName = (workQueueId, e) => {           
		setLoading(true);
		setWorkQueueId(workQueueId)
		setName(e)
		getWorkItemsByWorkQueueId(workQueueId);
	}

	const changeType = (e) => {
		setType(e);
	}

	const getWorkItemsByWorkQueueId = id => {
		WorkItemsService.getByWorkQueueId(id).then((response) => {
			setLoading(false);
			if (response.data) {
				const workItems = response.data.workItems;
				setData(workItems)
				setDataFiltered(workItems);
				setVendorName(response.data.vendorName || null)
			}
		}).catch(() => {
			showSnackbarMessage("An error occurred getting the Work Items", "error");
		});
	}

	const onSearchChange = (circuits) => {
		setDataFiltered(circuits);
	}

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	const disableAdminActions = validRoles([ROLES.manholeInspectionContributor], userRoles).length > 0 && type !== INSPECTION_TYPE_MANHOLE;

	return (
		<>
			<div style={{ height: 20, width: "100%" }}>{loading && <ProgressBar/>}&nbsp;</div>
			<Container component="div" maxWidth={false} data-testid="workItems"> 
				<Grid container direction="column">         
					<Grid item>
						<Grid
							container
							spacing={2}
						>
							{/* Work Queue */}
							<Grid item xs={12} md={2}>
								<Grid container><Grid item><h2>Work Queue</h2></Grid></Grid>
							</Grid>
							<Grid item xs={12} md={vendorName ? 6 : 4}>
								<MainFilters 
									userRoles={userRoles}
									circuits={data}
									changeType={changeType} 
									changeName={(id, name, type) => changeName(id, name, type)} 
									onSearchChange={onSearchChange}
									vendorName={vendorName}
									ff_transmission_inspection_form={ff_transmission_inspection_form}
								/>
							</Grid>
						</Grid>

						{/* Web table */}
						<Hidden xsDown>
							<WorkItemsTable type={type} name={name} items={dataFiltered} workQueueId={workQueueId} disableAdminActions= {disableAdminActions}/>
						</Hidden>

						{/* Mobile Table */}
						<Hidden smUp>
							<WorkItemsMobileList type={type} items={dataFiltered} name={name} workQueueId={workQueueId} disableAdminActions= {disableAdminActions} />
						</Hidden>
					</Grid>
				</Grid>
			</Container>
		</>
    
	);
};

export default WorkItems;