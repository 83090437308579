import { Grid, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useLightbox } from 'simple-react-lightbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import {
	INSPECTION_TYPE_MANHOLE,
	INSPECTION_TYPE_TRANSMISSION,
	offlineImagesDbTag,
} from '../../../Constants';
import imageNotFound from '../../Shared/ImageCarousel/image-not-found.jpg';
import ImageContext from '../../../context/Image/ImageContext';
import ImageService from '../../../services/ImageService';
import { insertDataInBrowserDatabase } from '../../../Utils';
import Button from '../atoms/Button';
import ImageCarousel from '../ImageCarousel';
import UploadFile from '../UploadFile';

const useStyles = makeStyles(() => ({
	containerImage: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '15px',
		marginBottom: '15px',
	},
	title: {
		color: '#007749',
		margin: '20px 0px 10px 10px',
	},
	switchToggle: {
		marginBottom: 10,
	}
}));

const ImagesControl = ({
	images,
	onImagesChange,
	inspectionType,
	showUploadImagesComponent = null,
	setShowUploadImagesComponent = null,
	disabled = false,
	editing = false,
	selectedCardinalPoints,
	setSelectedCardinalPoints
}) => {
	const { getContextImages, setContextImage, state, editContextImages, setContextImagesToDelete } =
		useContext(ImageContext);
	const [uploadingImages, setUploadingImages] = useState(false);
	const [progressBar, setProgressBar] = useState(0);
	const classes = useStyles();
	const { openLightbox } = useLightbox();
	const [deletingPhoto, setDeletingPhoto] = useState(false);
	
	useEffect(() => {
		const images = getContextImages();
		const { uploadingImages, progressBar } = state;
		setUploadingImages(uploadingImages);
		onImagesChange(images);
		setProgressBar(progressBar);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getContextImages, state]);

	const deletePhoto = async (index) => {
		setDeletingPhoto(true);
		const names = images.names;
		const urls = images.urls;
		const nameToDelete = names.splice(index, 1)[0];
		if (nameToDelete && nameToDelete !== '' && nameToDelete.name !== '') {
			if (editing) {
				const contextImagesToDelete = getContextImages();
				contextImagesToDelete.namesToDelete.push({name: nameToDelete.name});
				setContextImagesToDelete(contextImagesToDelete.namesToDelete);
				setDeletingPhoto(false);
			} else {
				if (navigator.onLine) {
					await ImageService.deleteImage(nameToDelete.name);
				} else {
					setDeletingPhoto(false);
					const contextImages = getContextImages();
					insertDataInBrowserDatabase(
						{ name: nameToDelete.name, delete: true },
						offlineImagesDbTag,
						contextImages.iDBInstance
					);
				}
			}
		}
		
		if (inspectionType === INSPECTION_TYPE_MANHOLE) {			
			const _selectedCardinalPoints = [...selectedCardinalPoints];
			const cardinalPointsIndex = _selectedCardinalPoints.indexOf(_selectedCardinalPoints[index]);
			if (cardinalPointsIndex > -1) { // if exists, remove that index
				_selectedCardinalPoints.splice(index, 1); 
			}
			setSelectedCardinalPoints(_selectedCardinalPoints);
		}

		urls.splice(index, 1);
		editContextImages(names, urls);
		setTimeout(() => {
			setDeletingPhoto(false);
		}, 1000);
	};

	const onChangeFile = files => {
		setContextImage(files, null, editing);
	};

	return (
		<>
			{(inspectionType !== INSPECTION_TYPE_TRANSMISSION || (inspectionType === INSPECTION_TYPE_TRANSMISSION && showUploadImagesComponent)) && <>
				<Grid item xs={12} className={classes.containerImage}>
					{images?.urls?.length > 0 ? 
						<ImageCarousel
							images={images.urls}
							status={images.names.map(image => image.status)}
							height="300px"
							deleteIcon={!disabled}
							deletePhotoHandle={deletePhoto}
							loading={deletingPhoto}
							showThumbs={true}
							showIndicators={true}
							detail={true}
							uploadingImages={uploadingImages}
							progressBar={progressBar}
							inspectionType={inspectionType}
							selectedCardinalPoints={selectedCardinalPoints}
							setSelectedCardinalPoints={setSelectedCardinalPoints}
							editing={editing}
						/>
						: !disabled
							? <UploadFile onChangeFile={onChangeFile} isImage={true} inspectionType={inspectionType} />
							: <img                  
								style={{ maxWidth: '100%'}}
								src={imageNotFound}
								alt="Not found"
							/>                
					}
				</Grid>
				<Grid item xs={12} className={classes.containerImage}>
					{images?.urls?.length > 0 ? 
						<Grid container item xs={12} justifyContent="flex-start">
							<Button
								id="fullview"
								variant="outlined"
								onClick={() => openLightbox()}
								icon={<ViewCarouselIcon />}
								label="Full view"
								color="primary"
								styles={{ marginRight: '1rem' }}
							/>
							{!disabled
								&& <UploadFile
									onOpenGalleryFromIcon={true}
									onChangeFile={onChangeFile}
									isImage={true}
									inspectionType={inspectionType}
								/>
							}
						</Grid>
						: null}
				</Grid>
			</>}
			{inspectionType === INSPECTION_TYPE_TRANSMISSION && 
			<FormGroup className={classes.switchToggle}>
				<FormControlLabel label="Display Images" control={
					<Switch 
						color="primary"
						checked={showUploadImagesComponent}
						onChange={() =>
							setShowUploadImagesComponent(!showUploadImagesComponent)}
					/>} 
				/>
			</FormGroup>
			}
		</>
	);
};

export default ImagesControl;
