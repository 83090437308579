import React from "react";
import { useParams } from "react-router";
import { Container } from "@material-ui/core";
import WorkQueueForm from "../../WorkQueue/Form"

const WorkQueueFormRouteEdit = () => {
  let { name } = useParams();
  const containerStyles = {
    height: "100%",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    position: "relative",
    padding: 0,
    alignItems: "center"
  }
  return (
    <Container component="div" maxWidth={false} style={containerStyles}>
      <WorkQueueForm edit="1" name={name} />
    </Container>
  );
};

export default WorkQueueFormRouteEdit;
