import { callApi } from '../callApi';
import { RESOURCE_INSPECTIONS } from '../Constants';

class WorkItemsService {

	getByWorkQueueId(workQueueId) {
		return callApi({ method: 'get', url:  `${RESOURCE_INSPECTIONS}workqueues/${workQueueId}/workitems` });
	}

	update(workItem) {
		return callApi({ method: 'patch', data: workItem, url:  `${RESOURCE_INSPECTIONS}workitems/` });
	}

	getLines(workQueueId) {
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}workqueues/${workQueueId}/assets`
		});
	}
}
export default new WorkItemsService ();