import { callApi } from '../callApi';
import { RESOURCE_INSPECTIONS } from '../Constants';

class WorkQueueService {
	create(workqueue) {
		return callApi({ method: 'post', data: workqueue, url:  `${RESOURCE_INSPECTIONS}workqueues` });
	}

	getByName(name, type) {
		return callApi({ method: 'get', url:  `${RESOURCE_INSPECTIONS}workqueues/${name}?type=${type}` });
	}

	getAll() {
		return callApi({ method: 'get', url:  `${RESOURCE_INSPECTIONS}workqueues/` });
	}

	getReport(inspectionType) {
		return callApi({ method: 'get', url:  `${RESOURCE_INSPECTIONS}WorkQueuesInspections?InspectionType=${inspectionType}` });
	}

	edit(workqueue) {
		return callApi({ method: 'patch', data: workqueue, url: `${RESOURCE_INSPECTIONS}workqueues` });
	}

	delete(id) {
		return callApi({ method: 'delete', url: `${RESOURCE_INSPECTIONS}workqueues/${id}` });
	}
}
export default new WorkQueueService ();