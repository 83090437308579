import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextFieldComponent from '../../../Shared/atoms/TextInput';
import { RadioGroup } from "../../../Shared/RadioGroup";
import { yesNoRadios, MANHOLE_FORM_YES_NO_SECTION } from '../../../../Constants';

const YesNoSection = ({ inspection, onChangeInput, editing }) => <Grid item xs={12}>
  {MANHOLE_FORM_YES_NO_SECTION.map(yesNo => <Grid container key={yesNo.label} spacing={1}>
    <Grid item xs={12}>
      <RadioGroup
        title={yesNo.label}
        value={yesNo.noYesNo // In case of detail or edit with values that are not "YES / NO"
            ? inspection[yesNo.valueName]
            : 'boolean' === typeof inspection[yesNo.valueName] 
              ? inspection[yesNo.valueName] ? 'true' : 'false'
              : inspection[yesNo.valueName]
        }
        radios={yesNo.noYesNo ? yesNo.radioButtons : yesNoRadios}
        onChange={(e) =>
          onChangeInput(e.target.value, yesNo.valueName)
        }
        required={true}
        disabled={!editing}
      />
    </Grid>
    {(inspection[yesNo.valueName] === "true" || inspection[yesNo.valueName] === true) && // If yes selected
    <>
      {yesNo.radioButtonsOnYes && yesNo.radioButtonsOnYes.map(radioOption => 
      <Grid item xs={12} style={{ marginLeft: 35 }} key={radioOption.valueName}>
        <RadioGroup
          key={radioOption.valueName}
          title={radioOption.label}
          value={'boolean' === typeof inspection[radioOption.valueName] 
            ? inspection[radioOption.valueName] ? 'true' : 'false'
            : inspection[radioOption.valueName]
          }
          radios={yesNoRadios}
          onChange={(e) =>
            onChangeInput(e.target.value, radioOption.valueName)
          }
          required={true}
          disabled={!editing}
        />
      </Grid>
      )}
      <Grid item xs={12}>
        <TextFieldComponent
          style={yesNo.notes === 'followUpWorkNeededNotes' ? {marginLeft: 35} : {}}
          value={inspection[yesNo.notes]}
          label={yesNo.customNotesLabel || `${yesNo.label} Notes`}
          fullWidth
          variant="outlined"
          type="text"
          placeholder=""
          onChange={(e) =>
            onChangeInput(e, yesNo.notes)
          }
          multiline={true}
          rows="4"
          required={true}
          maxLength="500"
          disabled={!editing}
        />
      </Grid>
    </>}
  </Grid>)}
</Grid>

export default YesNoSection;