import { makeStyles } from "@material-ui/core";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import React from "react";

const useStyles = makeStyles((theme) => ({
  datepickers: {
    [theme.breakpoints.up("md")]: {
      margin: "0 1rem",
    },
  },
}));

const DateTimePicker = (props) => {
  const classes = useStyles();

  const defaultProps = ({
    clearable = true,
    disableFuture = true,
    ampm = true,
    value
  }) => {
    return {
      clearable,
      disableFuture,
      ampm,
      className: classes.datepickers,
      value,
      format: "MM/dd/yyyy hh:mm a",
      onChange:(value) => props.onChange(value)
    };
  };

  return <KeyboardDateTimePicker {...defaultProps(props)} />;
};

export default DateTimePicker;
