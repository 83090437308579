import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Grid, Hidden, makeStyles } from '@material-ui/core';
import Stepper from '../../Shared/Stepper';
import { StepperControl } from './StepperControl';
import { TransmissionForms } from './Forms';
import {
	WORK_QUEUE_TYPE_TRANSMISSION,
	INITIAL_VALUES_TRANSMISSION_INSPECTION,
	ASSET_TYPE_TRANSMISSION,
	getChecksSelected,
	getImagesTransmission,
	STATUS_EVERSOURCE_QA_REVIEW,
	STATUS_QA_REVIEW,
	STATUS_DRAFT,
	STATUS_OFFICE_INSPECTION,
} from '../../../Constants';
import ImageContext from '../../../context/Image/ImageContext';
import InspectionsService from '../../../services/InspectionsService';
import ImageService from '../../../services/ImageService';
import { useSnackbar } from 'notistack';
import { showSnackbar } from '../../../Utils';

const useStyles = makeStyles(() => ({
	FormContainer: {
		marginTop: 10,
		marginBottom: 36,
		width: '100%',
	},
}));

const statuses = [STATUS_DRAFT, STATUS_OFFICE_INSPECTION, STATUS_QA_REVIEW];
const steps = [
	'STRUCTURE INFORMATION',
	'STRUCTURE SPECIFICS',
	'STRUCTURE SPECIFICS PART 2',
];

const TransmissionContainer = ({
	images,
	transmissionFromWorkItem,
	inspectionType,
	onChangeType,
	ff_transmission_inspection_form,
	setImages,
	submitting,
	setSubmitting,
	workQueue,
	inspection = false,
	editing,
	transmissionEdit = null,
	types,
}) => {
	const [activeStep, setActiveStep] = React.useState(0);
	const classes = useStyles();
	const [forms, setForms] = React.useState(
		inspection
			? inspection
			: INITIAL_VALUES_TRANSMISSION_INSPECTION(transmissionFromWorkItem)
	);
	const { setContextImagesToDelete, getContextImages } =
		React.useContext(ImageContext);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {
		if (
			inspection &&
			inspection.structure &&
			inspection.specifics2 &&
			inspection.specifics
		) {
			setForms(inspection);
		}
	}, [inspection]);

	// On click edit, check status and last step completed for the inspection
	React.useEffect(() => {
		if (editing) {
			if (forms.structure.status === statuses[0]) {
				setActiveStep(0);
			} else if (
				forms.structure.status === statuses[1] ||
				forms.structure.status === statuses[2]
			) {
				forms.transmissionFormStep < activeStep &&
					setActiveStep(forms.transmissionFormStep);
			}
		}
	}, [editing]);

	function onChangeForm(newForm, type) {
		const updatedForm = { ...forms, [type]: newForm };
		setForms(updatedForm);
		transmissionEdit &&
			transmissionEdit({ ...updatedForm, ...{ activeStep } }, null, false);
	}

	function onActiveStepChange(step) {
		inspection &&
			transmissionEdit({ ...forms, ...{ activeStep: step } }, null, false);
		setActiveStep(step);
	}

	function showFormErrorMessage(e, action) {
		const errorMessage =
			e.response?.data?.title || `An error occurred ${action} the inspection`;
		showSnackbarMessage(errorMessage, 'warning');
	}

	const afterStepServiceCall = (inspection, submit, _forms, nextStep, next) => {
		setSubmitting(false);
		if (inspection.data) {
			if (!submit) {
				!nextStep &&
					showSnackbarMessage('Inspection saved successfully.', 'success');
				const formSaved = { ..._forms };
				formSaved.id = inspection.data.id;
				formSaved.partitionKey = inspection.data.partitionKey;
				formSaved.structure.status = inspection.data.status;
				formSaved.specifics.status = inspection.data.status;
				formSaved.specifics2.status = inspection.data.status;
				formSaved.transmissionFormStep = inspection.data.transmissionFormStep;
				setForms(formSaved);
				setActiveStep(next ? activeStep + 1 : activeStep - 1);
				transmissionEdit &&
					transmissionEdit(
						formSaved,
						{ ...inspection.data, ...{ activeStep: activeStep + 1 } },
						true
					);
			} else {
				showSnackbarMessage('Inspection submitted successfully.', 'success');
				setForms(INITIAL_VALUES_TRANSMISSION_INSPECTION());

				// After submit if user came from workItems table
				if (transmissionFromWorkItem && !transmissionFromWorkItem.fromMap) {
					// Redirect to workItems page with filter set on lineId
					history.push({
						pathname: '/WorkItems/',
						lineId: transmissionFromWorkItem.lineId,
					});
				} else {
					history.push(`/Inspections/_/${ASSET_TYPE_TRANSMISSION}`);
				}
			}
		} else if (inspection.error && inspection.error.errors) {
			showSnackbarMessage(inspection.error.title, 'warning');
		}
	};

	const onFinishStepper = async (submit, stayInForm = false, next) => {
		setSubmitting(true);
		const _forms = forms;
		if (transmissionFromWorkItem) {
			_forms.structure.workitemid = transmissionFromWorkItem.id;
			if (transmissionFromWorkItem.workQueue) {
				_forms.structure.workQueue = {
					id: transmissionFromWorkItem.workQueue.id,
					name: transmissionFromWorkItem.workQueue.name,
				};
			}
			delete _forms.structure.fromWorkItem;
		}

		if (workQueue) {
			_forms.structure.workQueue = {
				id: workQueue.id,
				name: workQueue.name,
			};
		}

		const currentStep =
			editing && inspection.transmissionFormStep > activeStep
				? inspection.transmissionFormStep
				: activeStep;

		const isSubmit = (currentStatus) =>
			currentStatus === STATUS_QA_REVIEW && submit && !stayInForm;

		// if orginal QA Review, and Submit was pressed, get the status selected from the step you are in
		const status =
			editing &&
			(inspection.originalStatus === STATUS_QA_REVIEW ||
				inspection.originalStatus === STATUS_EVERSOURCE_QA_REVIEW)
				? activeStep === 0
					? isSubmit(_forms.structure.status)
						? STATUS_EVERSOURCE_QA_REVIEW
						: _forms.structure.status
					: activeStep === 1
					? isSubmit(_forms.specifics.status)
						? STATUS_EVERSOURCE_QA_REVIEW
						: _forms.specifics.status
					: isSubmit(_forms.specifics2.status)
					? STATUS_EVERSOURCE_QA_REVIEW
					: _forms.specifics2.status
				: stayInForm // If save
				? statuses[1]
				: statuses[activeStep];

		const transmissionForm = {
			...(currentStep === 0 && _forms.structure),
			...(currentStep === 1 && { ..._forms.structure, ..._forms.specifics }),
			...(currentStep === 2 && {
				..._forms.structure,
				..._forms.specifics,
				..._forms.specifics2,
			}),
			...(_forms.id && { id: _forms.id }),
			...(_forms.partitionKey && { partitionKey: _forms.partitionKey }),
			inspectionType: WORK_QUEUE_TYPE_TRANSMISSION,
			transmissionFormStep: currentStep,
			status,
			location: null,
			images: getImagesTransmission(images),
			guyAnchors: getChecksSelected(forms.structure.guyAnchors),
			conductorDamageLocation: getChecksSelected(
				forms.specifics.conductorDamageLocation
			),
		};

		// Save
		if (_forms.id) {
			InspectionsService.edit(transmissionForm, true)
				.then(async (response) => {
					afterStepServiceCall(response, submit, _forms, stayInForm, next);
					if (editing && response.data) {
						const imagesContext = getContextImages();
						if (imagesContext.namesToDelete.length > 0) {
							await ImageService.deleteInspectionImages(
								response.data.id,
								imagesContext.namesToDelete,
								response.data.partitionKey
							);
							setContextImagesToDelete([]);
						}
					}
				})
				.catch((e) => {
					setSubmitting(false);
					showFormErrorMessage(e, 'updating');
				});

			// Initial Save or Create
		} else {
			InspectionsService.create(transmissionForm, true)
				.then((inspection) => {
					afterStepServiceCall(inspection, submit, _forms, stayInForm, next);
				})
				.catch((e) => {
					setSubmitting(false);
					showFormErrorMessage(e, 'creating');
				});
		}
	};

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	const renderForm = () => (
		<TransmissionForms
			steps={steps}
			onChange={(form, type) => onChangeForm(form, type)}
			forms={forms}
			activeStep={activeStep}
			defaultValues={INITIAL_VALUES_TRANSMISSION_INSPECTION()}
			transmissionFromWorkItem={transmissionFromWorkItem}
			inspectionType={inspectionType}
			onChangeType={onChangeType}
			ff_transmission_inspection_form={ff_transmission_inspection_form}
			setImages={setImages}
			images={images}
			workQueue={workQueue}
			isReadOnly={!!inspection}
			editing={editing}
			originalStatus={inspection.originalStatus}
			types={types}
		/>
	);

	return (
		<Grid container display="column" spacing={2}>
			<Grid item lg={3} md={3}>
				<Stepper
					activeStep={activeStep}
					steps={steps}
					setActiveStep={onActiveStepChange}
					editing={editing}
					transmissionFormStep={!!inspection && forms.transmissionFormStep}
				>
					<Hidden mdUp>
						<Box sx={{ mb: 2 }}>{renderForm()}</Box>
					</Hidden>
				</Stepper>
			</Grid>

			<Grid item lg={9} md={9} className={classes.FormContainer}>
				<Grid display="row">
					<Hidden smDown>
						<Grid item>{renderForm()}</Grid>
					</Hidden>
					<Grid item xs={12}>
						<StepperControl
							activeStep={activeStep}
							onActiveStepChange={(step) => onActiveStepChange(step)}
							stepsCount={steps.length}
							onFinishStepper={onFinishStepper}
							submitting={submitting}
							setSubmitting={setSubmitting}
							detailTransmission={inspection}
							editing={editing}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TransmissionContainer;
