import React, { useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import InspectionsService from '../../services/InspectionsService';
import TextFieldComponent from '../Shared/atoms/TextInput';
import ImageCarousel from '../Shared/ImageCarousel';
import ImageService from '../../services/ImageService';
import UserInformation from './UserInformation';
import Button from '../Shared/atoms/Button';
import Select from '../Shared/atoms/Select';
import InspectionsDataContext from '../../context/InspectionsData/InspectionsDataContext';
import ImageContext from '../../context/Image/ImageContext';
import UploadFile from '../Shared/UploadFile';
import AlertDialogSlide from '../Shared/Dialog';
import ProgressBar from '../Shared/ProgressBar';
import { calculateRequestProgress, isArrayNotEmpty, showSnackbar, validRoles } from '../../Utils';
import TreeFormFields from '../InspectionForm/TreeFormFields';
import ManholeContainer from '../InspectionForm/Manhole';
import {
	INSPECTION_TYPE_DAMAGE,
	INSPECTION_TYPE_TRANSMISSION,
	INSPECTION_TYPE_TREE,
	INITIAL_VALUES_TRANSMISSION_INSPECTION,
	FF_TRANSMISSION_INSPECTION_FORM,
	getChecksSelected,
	getImagesTransmission,
	LOADING_STYLES,
	STATUS_QA_REVIEW,
	STATUS_DRAFT,
	STATUS_OFFICE_INSPECTION,
	STATUS_EVERSOURCE_QA_REVIEW,
	INSPECTION_TYPE_MANHOLE,
	ROLES
} from '../../Constants';
import MLSwitch from '../Shared/UploadImageCarousel/MLSwitch';
import AssetsService from '../../services/AssetsService';
import TransmissionContainer from '../InspectionForm/Transmission';
import AddressAutocomplete from '../Shared/AddressAutocomplete';
import StatusPerIssue from '../InspectionForm/StatusPerIssueType';
import LightboxAI from './LightboxAI';

const useStyles = makeStyles((theme) => ({
	autocomplete: {
		'& .MuiChip-deletable': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.secondary.main,
			'& .MuiChip-deleteIcon': {
				color: theme.palette.secondary.main
			},
			'&.Mui-disabled': {
				opacity: 1
			}
		},
	},
	container: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(3),
	}
}));

const InspectionDetail = ({ inpectionId, inspectionType, partitionKey, userRoles }) => {
	const [loading, setLoading] = useState(true);
	const [images, setImages] = useState([]);
	const [imagesDetail, setImagesDetail] = useState({urls:[], names: []});
	const [defaultImages, setDefaultImages] = useState([]);
	const [imageNames, setImageNames] = useState([]);
	const [imagesToDelete, setImagesToDelete] = useState([]);
	const [loadingImages, setLoadingImages] = useState(false);
	const [uploadingImages, setUploadingImages] = useState(false);
	const [editable, setEditable] = useState(false);
	const [editButton, setEditButton] = useState(true);
	const [actionButtons, setActionButtons] = useState(false);  
	const [damageTypesOptions, setDamageTypesOptions] = React.useState([]);
	const [statusOptions, setStatusOptions] = React.useState([]);
	const [damageTypesEdit, setDamageTypesEdit] = useState([]);
	const [statusEdit, setStatusEdit] = useState([]);
	const [issueTypesEdit, setIssueTypesEdit] = useState([]);  
	const [notes, setNotes] = useState('');
	const [address, setAddress] = useState('');
	const [comments, setComments] = useState('');
	const [poleNumber, setPoleNumber] = useState('');
	const [circuitID, setCircuitID] = useState('');
	const [issueTypesOptions, setIssueTypesOptions] = useState([]);
	const [assets, setAssets] = useState([]);
	const [assetsEdit, setAssetsEdit] = useState([]);
	const [workQueueName, setWorkQueueName] = useState('');
	const [inspection, setInspection] = useState({
		notes: '',
		images: [],
		damageTypes: [],
		createdBy: '',
		dateCreated: '',
		dateUpdated: '',
		address:''
	});
	const [editedInspection, setEditedInspection] = useState({});
	const [openDialog, setOpenDialog] = useState(false);
	const [progressBar, setProgressBar] = useState(0);
	const [showTreeInspections, setShowTreeInspections] = useState(false);
	const [isTreeFormValid, setIsTreeFormValid] = useState(true);
	const [actionCancel, setActionCancel] = useState(false);
	const [ai, setAi] = useState(false);
	const [transmission, setTransmission] = useState(false);
	const [transmissionEdited, setTransmissionEdited] = useState(false);
	const setUserLocation = useState({});
	const [showLightBox, setShowLightBox] = useState(false);
	const [aiResponse, setAiResponse] = useState([]);
	let isTreeInspectionDetail = false;
	const ff_transmission_inspection_form = useFlags()[FF_TRANSMISSION_INSPECTION_FORM];
	const { setContextImageDetail, setContextImagesToDelete, getContextImages, resetContextImage, setGpsLocation } = useContext(ImageContext);
	const { getContextState, inspectionsDataState } = useContext(InspectionsDataContext);  
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const classes = useStyles();
	const isDamageInspectionType = inspectionType === INSPECTION_TYPE_DAMAGE;
	const isTreeInspectionType = inspectionType === INSPECTION_TYPE_TREE;
	const isTransmissionInspectionType = inspectionType === INSPECTION_TYPE_TRANSMISSION;
	const isManholeInspectionType = inspectionType === INSPECTION_TYPE_MANHOLE;
	const [selectedCardinalPoints, setSelectedCardinalPoints] = useState([]);
	useEffect(() => {
		setLoadingImages(true);
		InspectionsService.getById(inpectionId, inspectionType, partitionKey).then((result) => {
			if (result?.data) {
				initialInspectionValue(result.data);
			} else {
				showSnackbarMessage('An error occurred loading the inspection.', 'warning');
			}
			setLoading(false);
		}).catch(() => {
			showSnackbarMessage('An error occurred loading the inspection.', 'warning');
			setLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inpectionId]);
	useEffect(() => {
		const inspectionsData = getContextState();
		setDamageTypesOptions(inspectionsData.damageTypes || []);
		setStatusOptions(inspectionsData.status || []);
		if (issueTypesOptions.length === 0){
			let _issueTypes = [];
			if(inspectionsData.issueTypes && inspectionsData.issueTypes.length > 0){
				_issueTypes= inspectionsData.issueTypes.map(issueType => ({...issueType, status: 'New'}) );
			}
			setIssueTypesOptions(_issueTypes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionsDataState, getContextState]);

	const initialInspectionValue = async (inspection, cancel=false) => {
		if (cancel) {
			setImages(defaultImages);
		} else if (inspection) {
			getImages(inspection.images);    
		}

		if (isTreeInspectionType) {
			setInspection(inspection);
			setEditedInspection(inspection);
			setStatusEdit([inspection.status]);
			setShowTreeInspections(true);
			setNotes(inspection.notes);
			setAddress(inspection.address);
			setComments(inspection.comments);
			setImageNames(inspection.images);
		} else if (isTransmissionInspectionType) {
			setTransmission(INITIAL_VALUES_TRANSMISSION_INSPECTION(false, inspection));
			setInspection({...inspection, ...{
				images: inspection.images,
				createdBy: inspection.createdBy,
				dateCreated: inspection.dateCreated,
				dateUpdated: inspection.dateUpdated,
				id: inspection.id,
				partitionKey: inspection.partitionKey
			}});
			setImagesDetail(inspection.images);
		} else {
			getDefaultFields(inspection);
			setShowTreeInspections(false);
		}
		setLoading(false);
	};

	const getDefaultFields = async (inspection) => {
		if (inspection) {
			if (isDamageInspectionType) {
				const damageTypes = inspection.damageTypes.map(
					(types) => types.name
				);
				setDamageTypesEdit(damageTypes);
				setStatusEdit([inspection.status]);
				setInspection(inspection);    
				setPoleNumber(inspection.poleNumber);
				setNotes(inspection.notes);
				setAddress(inspection.address);
				setComments(inspection.comments);
				setImageNames(inspection.images);
				setIssueTypesEdit([...inspection.issueTypes]);
				if (inspection.circuitId && inspection.circuitId !== '') {
					setCircuitID(inspection.circuitId || '');
					const assetsOfInspections = inspection.assets.map(asset => `${asset.type} - ${asset.id}`);
					const _assets = inspection.poleId ? await getAssets(inspection.poleId, inspection.townName, 'Pole') : [];
					setAssets(_assets);
					setAssetsEdit(assetsOfInspections);
					setWorkQueueName(inspection.workQueueName || '');
				}
			} else if (isTreeInspectionType) {
				setInspection(inspection);
				setStatusEdit([inspection.status]);
				setShowTreeInspections(true);
				setNotes(inspection.notes);
				setAddress(inspection.address);
				setComments(inspection.comments);
				setImageNames(inspection.images);
			} else if (isManholeInspectionType) {
				setAddress(inspection.address);
				setStatusEdit([inspection.status]);
				setNotes(inspection.notes);
				setComments(inspection.comments);
				setStatusEdit([inspection.status]);
				setImageNames(inspection.images);
				setInspection({...inspection, ...{
					createdBy: inspection.createdBy,
					dateCreated: inspection.dateCreated,
					dateUpdated: inspection.dateUpdated
				}});
				setSelectedCardinalPoints(inspection.images?.map(image => image.cardinalDirection))
			}
		}
	};

	function showFormErrorMessage(e, action) {
		const errorMessage = e.response?.data?.title || `An error occurred ${action} the inspection`;
		showSnackbarMessage(errorMessage, 'warning');
	}

	const getAssets = async (id, partitionKey, type) => {
		const assets = await AssetsService.getAssets(id, partitionKey, type) || [];
		if (assets.data.devices.length > 0)
			return assets.data.devices.map(asset => asset && `${asset.type} - ${asset.id}`) || [];
		else
			return [`${assets.data.type} - ${assets.data.id}`];
	};

	const getImages = (images) => {
		if (images.length > 0) {
			ImageService.getInspectionImages(images, 'thumbnails').then((resp) => {
				if (resp) {
					const getBlobList = ImageService.getBlobList(resp); 
					setDefaultImages(getBlobList);
					if (isTransmissionInspectionType) {
						const imagesObj = {names: images, urls: getBlobList};
						setImagesDetail(imagesObj);
						setContextImageDetail(imagesObj);
					} else {
						setImages(getBlobList);
					}
					setLoadingImages(false);
				}
			}).catch(() => {
				showSnackbarMessage('An error occurred.', 'error');
			});
		} else {
			setLoadingImages(false);
		}
	};

	const editInspection = async (event) => {
		setLoading(true);
		event.preventDefault();
		
		if (isTransmissionInspectionType) {
			const statuses = [STATUS_DRAFT, STATUS_OFFICE_INSPECTION, STATUS_OFFICE_INSPECTION];
			transmission.activeStep = transmission.activeStep || 0;
			let currentStep = inspection.transmissionFormStep > transmission.activeStep 
				? inspection.transmissionFormStep
				: transmission.activeStep;
				
			let transmissionForm = {
				...currentStep === 0 &&  transmission.structure,
				...currentStep === 1 && {...transmission.structure, ...transmission.specifics },
				...currentStep === 2 && {...transmission.structure, ...transmission.specifics, ...transmission.specifics2 },
				id: transmission.id,
				inspectionType: INSPECTION_TYPE_TRANSMISSION,
				partitionKey: transmission.partitionKey,
				transmissionFormStep: currentStep,
				// if original QA Review or Eversource QA Review, get the status selected from the step you are in 
				status: transmission.originalStatus === STATUS_QA_REVIEW || transmission.originalStatus === STATUS_EVERSOURCE_QA_REVIEW
					? transmission.activeStep === 0
						? transmission.structure.status
						: transmission.activeStep === 1
							? transmission.specifics.status
							: transmission.specifics2.status
					: statuses[currentStep],
				location: null,
				images: getImagesTransmission(imagesDetail),
				guyAnchors: getChecksSelected(transmission.structure.guyAnchors || []),
				conductorDamageLocation: getChecksSelected(transmission.specifics.conductorDamageLocation || [])
			};

			InspectionsService.edit(transmissionForm, true).then(response => {
				setLoading(false);
				if (response.data) {          
					showSnackbarMessage('Inspection saved successfully.', 'success');
					const _transmissionEdited = {...response.data, ...{activeStep: transmission.activeStep}};
					const imagesContext = getContextImages();
					if (imagesContext.namesToDelete.length > 0) {
						ImageService.deleteInspectionImages(response.data.id, imagesContext.namesToDelete, response.data.partitionKey);
						setContextImagesToDelete([]);
					}
					setInspection(_transmissionEdited);
					setTransmissionEdited(_transmissionEdited);
				} else if (response.error && response.error.errors) {  
					showSnackbarMessage(response.error.title, 'warning');
				}        
			}).catch(e => {
				setLoading(false);
				showFormErrorMessage(e, 'updating');
			});
		} else {
			const newInspection = isTreeInspectionType ? {...editedInspection} : { ...inspection };    
			newInspection.status = statusEdit[0];
			let checkImages = [...images];
			let checkNames = [...imageNames];
			if (imageNames.length > 0) {
				imageNames.forEach((image, index) => {
					if(image.name === '') {
						checkImages.splice(index,1);
						checkNames.splice(index,1);
						setImages(checkImages);
					} else if(isManholeInspectionType && image.name !== ''){
						image.cardinalDirection = selectedCardinalPoints[index] || 'None';
						checkNames[index] = image;
					}         
				});
			}
			newInspection.images = checkNames;
			delete newInspection.dateUpdated;
			newInspection.comments = comments;
			newInspection.notes = notes;
			newInspection.address = address;
			newInspection.inspectionType = inspectionType;
			if (isDamageInspectionType) {
				newInspection.damageTypes = damageTypesEdit.map((damageType) => ({
					name: damageType,
				})); 
				newInspection.poleNumber = poleNumber;
				newInspection.issueTypes = issueTypesEdit;
				if (assetsEdit.length > 0) {
					newInspection.assets = assetsEdit.map(deviceSelected => {
						const device = deviceSelected.split(' - ');
						return isArrayNotEmpty(device) && { id: device[1], type: device[0] };
					});
				} else {
					newInspection.assets = [];
				}
			}
			if (imagesToDelete.length > 0)
				await ImageService.deleteInspectionImages(newInspection.id, imagesToDelete, newInspection.partitionKey);
			InspectionsService.edit(newInspection).then((resp) => {
				setLoading(false);
				if (resp.data) {
					getDefaultFields(resp.data);
					setDefaultImages(checkImages);
					setEditable(false);
					setActionButtons(false);
					setEditButton(true);
					showSnackbarMessage('Inspection updated successfully.', 'success');
				}
			}).catch((e) => {
				setLoading(false);
				showFormErrorMessage(e, 'updating');
			});
		}    
	};

	const onUploadProgress = event => {
		setProgressBar(calculateRequestProgress(event));
	};

	const setNewImages = (files) => {
		setLoadingImages(true);
		setUploadingImages(true);

		const blobList = ImageService.getBlobList(files);
		const _files = [];
		files.map(file => {
			const format = file.name.split('.').pop();
			const fileName = `${uuidv4()}.${format}`;
			
			// Rename file object with the new name
			const blob = file.slice(0, file.size, `image/${format}`);
			const newFile = new File([blob], fileName, {type: `image/${format}`});
			_files.push(newFile);
		});

		setImages([...images, ...blobList]);
		ImageService.createInspectionImages(_files, null, onUploadProgress).then(
			(result) => {
				setLoadingImages(false);
				setUploadingImages(false);
				setProgressBar(0);
				setImageNames([...imageNames, ...result]);          
				if( result.some((image) => image.name === ''))
					showSnackbarMessage('Photo(s) could not be uploaded', 'error');
			},
			e => {
				showFormErrorMessage(e, 'updating');
			}
		).catch(e => {
			showFormErrorMessage(e, 'updating');
		});
	};

	const onClickEdit = () => {
		setEditable(true);
		setEditButton(false);
		setActionButtons(true);  
		setActionCancel(false);
	};

	const onCancel = () => {
		// If inspection had images or not, deleted one or more, then cancel without save
		function checkForDeletedImagesCancel() {
			const imagesContext = getContextImages();
			if (imagesContext.namesToDelete.length > 0) { // deleted images, merge without dup with existing ones in case of add new, deleted old, then cancel
				const names = new Set(imagesContext.namesToDelete.map(image => image.name));
				const mergedNamesNoDuplicates = [...imagesContext.namesToDelete, ...inspection.images.filter(image => !names.has(image.name))];
				getImages(mergedNamesNoDuplicates);
			} else if (imagesContext.namesToDelete.length === 0 && inspection.images.length === 0) { // No previous images, added image, deleted it, then cancel
				resetContextImage();
			} else if (imagesContext.namesToDelete.length === 0 && inspection.images.length > 0) { // Nothing to delete, keep previous
				getImages(inspection.images);
			}
			setContextImagesToDelete([]);
		}

		if (isTransmissionInspectionType && !transmissionEdited) {
			checkForDeletedImagesCancel();  
			initialInspectionValue(inspection, true);
		} else if (isTransmissionInspectionType && transmissionEdited) {
			checkForDeletedImagesCancel();
			initialInspectionValue(transmissionEdited);
		} else {
			initialInspectionValue(inspection,true);
		}
		setEditable(false);
		setActionButtons(false);
		setEditButton(true);
		setActionCancel(true);
	};

	const deletePhoto = async (index) => {
		setLoadingImages(true);    
		const blobs = [...images];
		const names = [...imageNames];
		blobs.splice(index, 1);
		const nametodelete = names.splice(index, 1)[0];
		setImages(blobs);
		setImageNames(names);     
		setImagesToDelete([...imagesToDelete, {...nametodelete}]);
		setTimeout(() => {
			setLoadingImages(false);      
		}, 1000);
	};

	const deleteInspection = () => {
		setOpenDialog(false);
		InspectionsService.delete(inspection.id, inspectionType, inspection.partitionKey).then(()=>{
			showSnackbarMessage('Inspection deleted successfully', 'success');
			if (history.length === 1) { 
				history.push('/Inspections');
			} else {
				history.goBack();
			}
		}).catch(() => {
			showSnackbarMessage('An error occurred deleting the inspection.', 'warning');
		});
	};

	const treeInspectionOnChange = (treeInspection) =>{
		setEditedInspection(treeInspection);
	};

	const validateForm = isDamageInspectionType
		? damageTypesEdit.length === 0 || statusEdit.length === 0 || issueTypesEdit.length === 0
		: !isTreeFormValid;

	const onChangeHandler = (name, value) =>{
		setAddress(value);
	};

	const onPlaceChange = (place) =>{
		const {formatted_address, geometry} = place;
		onChangeHandler('address', formatted_address);
		if(!isTreeInspectionDetail){
			const location = {
				latitude: geometry.location.lat(),
				longitude: geometry.location.lng()
			};
			updateLocation(location);
		}
	};

	const updateLocation = (location) => {
		setGpsLocation(location);
		setUserLocation({...location, updateLocation: true});
	};

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	const disableActionsByRole = () => !(validRoles([ROLES.manholeInspectionContributor] ,userRoles).length > 0 && inspectionType !== INSPECTION_TYPE_MANHOLE);

	return (
		<>	
			{showLightBox && <LightboxAI images={images} imagesIds={inspection.images} setShowLightBox={setShowLightBox} aiResponse={aiResponse} setAiResponse={setAiResponse} />}
			{loading && <ProgressBar styles={LOADING_STYLES} />}
			<Container component="div" maxWidth="md" className={classes.container}>
				{inspectionType !== INSPECTION_TYPE_TRANSMISSION
					? <>
						<Grid container spacing={2} justifyContent="center" style={{ marginBottom: 15 }}>
							{images.length === 0 && editable
								? <UploadFile onChangeFile={setNewImages} inspectionType={inspectionType}  isImage={true} />
								: <ImageCarousel
									showThumbs={false}
									showIndicators={true}
									images={images}
									height="400px"
									carouselKey={0}
									loading={loadingImages}
									detail={true}
									deleteIcon={editable}
									deletePhotoHandle={deletePhoto}
									uploadingImages={uploadingImages}
									progressBar={progressBar}
									ai={ai}
									setLoading={setLoading}
									aiResponse={aiResponse}
									setAiResponse={setAiResponse}
									inspectionType={inspectionType}
									imagesIds={inspection.images}
									editing={editable}
									selectedCardinalPoints={selectedCardinalPoints}
									setSelectedCardinalPoints={setSelectedCardinalPoints}
								/>}
						</Grid>
						<Grid container spacing={2}>
							<Grid container item xs={12} justifyContent="flex-start">          
								<Button
									id="fullview"
									variant="outlined"
									onClick={() => setShowLightBox(true)}
									icon={<ViewCarouselIcon />}
									label="Full view"
									color="primary"
									styles={{ marginRight: '1rem' }}
								/>
								{editable && images.length > 0 && <UploadFile onOpenGalleryFromIcon={true} onChangeFile={setNewImages} isImage={true} inspectionType={inspectionType}/>}
								{!editable && images.length > 0 && <MLSwitch inspectionsAILoading={e=> setLoadingImages(e)} images={imageNames} setAi={setAi} /> }								
							</Grid>
							<Grid item xs={12}>
								<TextField
									inputProps={{ 'data-testid': 'inspectionTypeInput' }}
									label="Type"
									variant="outlined"
									value={inspectionType}
									fullWidth
									disabled={true}
									id="inspectionTypeInput"
								/>
							</Grid>
							{isDamageInspectionType && <Grid item xs={12}>
								<Select            
									selectItem={setDamageTypesEdit} // function to get the selected items to store on this state
									selectOptions={damageTypesOptions} // the options for the select
									itemSelected={damageTypesEdit} // get the selected items from this state and send to coponent to show
									label="Damage Type"
									required="true"
									disabled={!editable}
									helperText={false}
									id="damageSelect"
								/>
							</Grid>}
							{issueTypesOptions.length > 0 && isDamageInspectionType &&
							<Grid item xs={12}>
								<Autocomplete
									className={classes.autocomplete}
									multiple
									options={issueTypesOptions}
									getOptionLabel={(option) => option && `${option.name}: ${option.description}`}
									onChange={(event, value) => {
										setIssueTypesEdit(value);
									}}
									getOptionSelected={(option, value) => option.id === value.id}
									value={issueTypesEdit.map(issueType => issueType)}
									variant="outlined"
									disabled={!editable}
									required={false}
									renderInput={(params) =>          
										<TextField
											inputProps={{ 'data-testid': 'issueType' }}
											{...params} 
											variant="outlined" 
											value={issueTypesEdit ? issueTypesEdit.toString() : ''}
											label="Issue Type"
											placeholder=""
											required={true}
										/>
									}
								/>
							</Grid>}

							<StatusPerIssue 
								issueTypes={issueTypesEdit} 
								disabled= {!editable}
								setIssueTypes={(items) => setIssueTypesEdit([...items])} 
							/>
							{workQueueName && workQueueName !== '' &&
							<Grid item xs={12}>
								<TextField
									label="Work Queue Name"
									variant="outlined"
									value={workQueueName}
									fullWidth
									disabled={true}
									id="workQueueNameInput"
									inputProps={{ 'data-testid': 'workQueueNameInput' }}
								/>
							</Grid>}
							{isDamageInspectionType && circuitID && circuitID !== '' &&
								<Grid item xs={12}>
									<TextFieldComponent
										label="Circuit ID"
										variant="outlined"
										value={circuitID}
										fullWidth
										disabled={true}
										id="circuitIDInput"
									/>
								</Grid>}
							{(assetsEdit.length > 0 && !editable) || (assets.length > 0 && editable) ?
								<Grid item xs={12}>
									<Select            
										selectItem={setAssetsEdit} // function to get the selected items to store on this state
										selectOptions={assets} // the options for the select
										itemSelected={assetsEdit} // get the selected items from this state and send to coponent to show
										label="Assets"
										required={false}
										multiple={true}
										disabled={!editable}
										helperText={false}
										id="assets"
									/>
								</Grid> : null}
							<Grid item xs={12}>
								<Select            
									selectItem={(e) => setStatusEdit([e])} // function to get the selected items to store on this state
									selectOptions={statusOptions} // the options for the select
									itemSelected={statusEdit} // get the selected items from this state and send to component to show
									label="Inspection Status"
									required="true"
									multiple={false}
									disabled={!editable}
									helperText={false}
									id="statusSelect"
								/>
							</Grid>

							{showTreeInspections && <TreeFormFields 
								editTreeInspection={treeInspectionOnChange} 
								isValidForm={(validForm)=>setIsTreeFormValid(validForm)} 
								editableFields={editable} 
								isTreeInspectionDetail={true} 
								inspection={inspection}
								cancel={actionCancel}
								setInspection={setInspection}
							/>}

							{isDamageInspectionType && <Grid item xs={12}>
								<TextField
									label="Pole Number"
									variant="outlined"
									value={poleNumber}
									fullWidth
									disabled={!editable}
									onChange={event => setPoleNumber(event.target.value)}
									id="poleNumberInput"
									inputProps={{ 'data-testid': 'poleNumberInput' }}
								/>
							</Grid>}

							{!isManholeInspectionType && <Grid item xs={12}>
								{navigator.onLine && window.google ? (
									<AddressAutocomplete onPlaceChange={onPlaceChange}>
										<TextFieldComponent
											value={address}
											label="Address"
											placeholder=""
											disabled={!editable}
											onChange={(e) => onChangeHandler('address', e)}
											required={
												inspectionType === INSPECTION_TYPE_TREE ? true : false
											}
										/>
									</AddressAutocomplete>
								) : (
									<TextFieldComponent
										value={inspection.address}
										label="Address"
										placeholder=""
										disabled={!editable}
										onChange={(e) => onChangeHandler('address', e)}
										required={
											inspectionType === INSPECTION_TYPE_TREE ? true : false
										}
									/>
								)}
							</Grid>}

							{isDamageInspectionType && <Grid item xs={12}>
								<TextFieldComponent
									value={inspection.townName}
									label="Town"
									disabled={true}
									placeholder=""
									id="townID"
								/>
							</Grid>}

							{isManholeInspectionType && <ManholeContainer
								inspection={inspection}
								onPlaceChange={onPlaceChange}
								editable={editable} 
								setInspection={setInspection}
								detail={true}				
							/>}

							<Grid item xs={12}>
								<TextFieldComponent
									disabled={!editable}
									label="Additional Inspection details"
									placeholder="Physical Hazards, Notes about the area surrounding"
									value={notes}
									multiline={true}
									rows="4"
									onChange={(note) => setNotes(note)}
									id="additionalDetails"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextFieldComponent
									disabled={!editable}
									label="Comments"
									placeholder="Damage Assessment Notes and Comments"
									value={comments}
									onChange={(comment) => setComments(comment)}
									id="comments"
								/>
							</Grid>
						</Grid>
					</>
					: <TransmissionContainer
						images={imagesDetail}
						transmissionFromWorkItem={false}
						inspectionType={INSPECTION_TYPE_TRANSMISSION}
						onChangeType={() => {}}
						ff_transmission_inspection_form={ff_transmission_inspection_form}
						setUploadingImages={setUploadingImages}
						setImages={setImagesDetail}
						submitting={loading}
						setSubmitting={setLoading}
						workQueue={false}
						inspection={transmission}
						editing={editable}
						transmissionEdit={(transmission, transmissionSaved, lastEdited) => {
							setTransmission(transmission);
							lastEdited && setTransmissionEdited(transmissionSaved);
						}}
						openDeleteTransmissionDialog={setOpenDialog}
					/>}
				<Grid justifyContent="space-between" container spacing={2}>
					<Grid item>
						<UserInformation
							title="Created"
							user={inspection.createdBy}
							date={inspection.dateCreated}
						/>
					</Grid>
					<Grid item>
						<UserInformation
							title="Updated"
							user={inspection.updatedBy}
							date={inspection.dateUpdated}
						/>
					</Grid>
				</Grid>        
				{disableActionsByRole() && <Grid container justifyContent="flex-end">
					<Button
						icon={<DeleteIcon />}
						onClick={()=> setOpenDialog(true)}
						label="Delete"
						variant="outlined"
						color="secondary"
						styles={{ marginTop: 20, marginLeft: '1rem' }}
						disabled={loading}
						id="deleteButton"
					/>					
					{editButton && (            
						<Button
							icon={<EditIcon />}
							onClick={onClickEdit}
							label="Edit"
							color="primary"
							styles={{ marginTop: 20, marginLeft: '1rem' }}
							id="editButton"
							disabled={loading}
						/>  
					)}
					{actionButtons && (
						<>
							<Button
								icon={<CancelIcon />}
								onClick={onCancel}
								label="Cancel"
								color="primary"
								variant="outlined"
								styles={{ marginTop: 20, marginLeft: '1rem'}}
								id="cancelButton"
								disabled={loading}
							/>
							<Button
								icon={<SaveIcon />}
								label="Save"
								color="primary"
								onClick={editInspection}
								disabled={validateForm || loading}
								styles={{ marginTop: 20, marginLeft: '1rem' }}
								id="saveButton"
							/>
						</>
					)}
					
				</Grid>}
				<AlertDialogSlide
					openDialog={openDialog}
					handleConfirm={deleteInspection}
					title="Delete Inspection"
					content="Are you sure you want to delete this Inspection?"      
					handleClickClose={() => {
						setOpenDialog(false);
					}}
				/>
			</Container>
		</>
	);
};

export default InspectionDetail;
