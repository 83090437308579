import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { GoogleMap } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/core/styles';
import InspectionsDataContext from '../../../context/InspectionsData/InspectionsDataContext';
import {
	zoomIndividualLevel,
	ZOOM_LEVEL_DISTRIBUTIONS,
	inspectionsMapOptions,
	inspectionsMapDefaultOptions,
	ASSET_TYPE_TRANSMISSION,
	ZOOM_LEVEL_TRANSMISSIONS,
} from '../../../Constants';
import MapMarkers from './Markers';
import MapToggles from './MapToggles';
import OutageService from '../../../services/OutageService';
import AssetsService from '../../../services/AssetsService';
import { showSnackbar } from '../../../Utils';
import { useSnackbar } from 'notistack';
import { Lines } from './Lines';
import { geocodeAddress } from '../../../Utils/geocode';

const useStyles = makeStyles((theme) => ({
	map: {
		with: '100%',
		height: '100%',
	},
	mapContainer: {
		position: 'relative',
		height: '100%',
	},
	emptyControl: {
		minWidth: 41,
		margin: '10px 10px 0 10px',
		height: 40,
		width: 41,
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}));

// eslint-disable-next-line react/display-name
const Map = React.memo(
	({
		inspectionList,
		townsSummary,
		highlighted,
		selectMarker,
		changeBoundaries,
		statuses,
		inspectionTypes,
		circuitCenter,
		setFullscreen,
		fullscreen,
		activeFilters,
		asset,
		setLoading,
		onChangeAssets,
		lineId,
		videosByAsset
	}) => {
		const { getContextState, setContextMap } = React.useContext(
			InspectionsDataContext
		);
		const contextState = getContextState();
		const classes = useStyles();
		const [map, setMap] = useState(null);
		const [showInspections, setShowInspections] = useState(true);
		const [onLoadApi, setOnLoadApi] = useState(false);
		const [userPosition, setUserPosition] = useState(null);
		const [mapClick, setMapClick] = useState(0);
		const [zoom, setZoom] = useState(null);
		const [assets, setAssets] = useState([]);
		const [defaultCenter, setDefaultCenter] = useState(null);
		const [showTransmissionAssets, setShowTransmissionAssets] = useState(false);
		const [showDistributionAssets, setShowDistributionAssets] = useState(false);
		const [showEvents, setShowEvents] = useState(false);
		const [outageEvents, setOutageEvents] = useState([]);
		const [showLines, setShowLines] = useState(false);
		const [lines, setLines] = useState([]);
		const [linesFiltered, setLinesFiltered] = useState([]);
		const [assetHighlighted, setAssetHighlighted] = useState(null);
		const { enqueueSnackbar } = useSnackbar();
		const ZOOM_LEVEL_ADDRESS = 18;

		useEffect(() => {
			setZoom(contextState.map.zoom);
			setDefaultCenter(contextState.map.defaultCenter);
			lines.length === 0 &&
				AssetsService.getLines().then((response) => {
					if (response && response.data) {
						const _lines = AssetsService.getLinesCoordinates(response.data);
						setLines(_lines);
						setLinesFiltered(_lines);
					}
				});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			if (contextState.map) {
				setOnLoadApi(contextState.map.apiMapLoad);
			}
		}, [contextState]);

		useEffect(() => {
			if (map && circuitCenter) {
				map.panTo({
					lat: circuitCenter.lat,
					lng: circuitCenter.lng,
				});
				map.setZoom(ZOOM_LEVEL_DISTRIBUTIONS);
			}
		}, [circuitCenter, map]);

		// After getting asset from routing parameters
		useEffect(() => {
			if (asset && asset === ASSET_TYPE_TRANSMISSION) {
				setShowTransmissionAssets(true);
				setShowLines(true);
				setShowDistributionAssets(false);
				setZoom(ZOOM_LEVEL_TRANSMISSIONS);
			} else if (asset && asset !== ASSET_TYPE_TRANSMISSION) {
				setShowTransmissionAssets(false);
				setShowLines(false);
				setShowDistributionAssets(true);
				setZoom(ZOOM_LEVEL_DISTRIBUTIONS);
			}
		}, [asset]);

		useEffect(() => {
			if (map) {
				getAssets(activeFilters, false, true, map.zoom);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [onChangeAssets]);

		// If user filtered by asset, set center and zoom, activate assets view on map
		useEffect(async () => {
			if (map && activeFilters?.asset?.location?.coordinates) {
				await setShowDistributionAssets(true);
				setTimeout(() => {
					map.panTo({
						lat: activeFilters.asset.location?.coordinates[1],
						lng: activeFilters.asset.location?.coordinates[0]
					});
					map.setZoom(ZOOM_LEVEL_DISTRIBUTIONS + 3);
					setAssetHighlighted(activeFilters.asset);
				}, 500);
			}
		}, [activeFilters.asset, map]);

		useEffect(() => {
			changeLines();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [lines, lineId]);

		const emptyControl = <div className={classes.emptyControl} />;

		// eslint-disable-next-line react-hooks/exhaustive-deps
		const onLoad = React.useCallback(function onLoad(mapInstance) {
			const controlButtonDiv = document.createElement('div');
			// eslint-disable-next-line react/no-deprecated
			ReactDOM.render(emptyControl, controlButtonDiv);
			mapInstance.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
				controlButtonDiv
			);
			setMap(mapInstance);
		});

		function assetToastMessage(transmission, distribution, zoom) {
			let toastMessage = 'Please zoom in to view Assets';
			let showZoomInMessage = false;
			let search = true;

			if (zoom < ZOOM_LEVEL_TRANSMISSIONS) {
				showZoomInMessage = true;
				search = false;
			} else if (
				transmission &&
				distribution &&
				zoom >= ZOOM_LEVEL_TRANSMISSIONS &&
				zoom < ZOOM_LEVEL_DISTRIBUTIONS
			) {
				showZoomInMessage = true;
				toastMessage = 'Please zoom in to View Distribution Assets';
			} else if (zoom < ZOOM_LEVEL_DISTRIBUTIONS && distribution) {
				showZoomInMessage = true;
				search = false;
			}

			if (showZoomInMessage) {
				showSnackbarMessage(toastMessage, 'warning');
			}
			return search;
		}

		// On map location or zoom changed save the lat / lng and zoom level
		const onMapChanged = async () => {
			if (map) {
				await setContextMap({
					zoom: map.zoom,
					defaultCenter: { lat: map.center.lat(), lng: map.center.lng() },
					apiMapLoad: true,
				});

				let northEast = map?.getBounds()?.getNorthEast();
				let southWest = map?.getBounds()?.getSouthWest();
				const viewPort = northEast
					? `${northEast.lat()}:${southWest.lat()}:${northEast.lng()}:${southWest.lng()}`
					: '';

				// Assets
				if (
					(showTransmissionAssets || showDistributionAssets) &&
					viewPort !== ''
				) {
					let search = assetToastMessage(
						showTransmissionAssets,
						showDistributionAssets,
						map.zoom
					);
					search &&
						getAssets(
							{ ...activeFilters, ...{ viewPort } },
							null,
							null,
							map.zoom
						);
				}

				// Events
				if (showEvents) {
					getEvents(viewPort);
				}

				if (northEast && southWest)
					changeBoundaries(northEast, southWest, map.zoom);
			}
		};

		const getAssets = (
			filters,
			distribution = null,
			transmission = null,
			zoom
		) => {
			setLoading(true);
			const _activeFilters = filters;
			const assetTypes = [];

			if (!distribution && !transmission) {
				showDistributionAssets &&
					zoom >= ZOOM_LEVEL_DISTRIBUTIONS &&
					assetTypes.push('Distribution');
				showTransmissionAssets &&
					zoom >= ZOOM_LEVEL_TRANSMISSIONS &&
					assetTypes.push('Transmission');
			} else {
				distribution &&
					zoom >= ZOOM_LEVEL_DISTRIBUTIONS &&
					assetTypes.push('Distribution');
				transmission &&
					zoom >= ZOOM_LEVEL_TRANSMISSIONS &&
					assetTypes.push('Transmission');
			}

			_activeFilters.types = assetTypes.join(',');
			AssetsService.getAll(_activeFilters)
				.then((result) => {
					if (result && result.data) {
						setLoading(false);
						if (result.data && result.data.canceled) {
							return [];
						}
						if (result.data.length === 0)
							showSnackbarMessage('Assets not found', 'warning');
						setAssets(result.data);
					}
				})
				.catch(() => {
					showSnackbarMessage('An error occurred getting the assets', 'error');
				});
		};

		const getEvents = (viewPort) => {
			setLoading(true);
			OutageService.getAllEvents(viewPort)
				.then((result) => {
					if (result && result.data) {
						setLoading(false);
						if (result.data && result.data.canceled) {
							return [];
						}
						setOutageEvents(result.data);
					} else {
						setOutageEvents([]);
					}
				})
				.catch(() => {
					setOutageEvents([]);
					showSnackbarMessage(
						'An error occurred getting the outage events',
						'error'
					);
				});
		};

		const getAssetTypesSelected = (distribution, transmission, zoom) => {
			setShowTransmissionAssets(transmission);
			setShowDistributionAssets(distribution);
			setZoom(zoom);
			const _activeFilters = activeFilters;
			const assetTypes = [];

			if (transmission || distribution) {
				assetToastMessage(transmission, distribution, zoom);
				distribution &&
					zoom >= ZOOM_LEVEL_DISTRIBUTIONS &&
					assetTypes.push('Distribution');
				transmission &&
					zoom >= ZOOM_LEVEL_TRANSMISSIONS &&
					assetTypes.push('Transmission');
			}
			_activeFilters.types = assetTypes.join(',');
			((distribution && zoom >= ZOOM_LEVEL_DISTRIBUTIONS) ||
				(transmission && zoom >= ZOOM_LEVEL_TRANSMISSIONS)) &&
				getAssets(_activeFilters, distribution, transmission, zoom);
		};

		function showSnackbarMessage(message, type) {
			showSnackbar(enqueueSnackbar, message, type);
		}		

		useEffect(() => {
			if (activeFilters.address) {
			  geocodeAddress(activeFilters.address).then(coordinates => {
				setDefaultCenter(coordinates);
				setZoom(ZOOM_LEVEL_ADDRESS)
			}).catch(error => {
				console.error("Geocoding error:", error);
			  });
			}
		  }, [activeFilters]);
		
		const changeLines = () => {
			setLinesFiltered([]);
			const linesFiltered =
				lineId !== '' ? lines.filter((line) => line.lineId === lineId) : lines;
			setLinesFiltered([...linesFiltered]);
		};
		  
		// loads the google map
		const renderMap = () => (
			<GoogleMap
				mapContainerClassName={classes.map}
				center={defaultCenter}
				defaultCenter={defaultCenter}
				zoom={zoom}
				zoomControl={true}
				fullScreen={false}
				yesIWantToUseGoogleMapApiInternals
				onLoad={onLoad}
				onZoomChanged={onMapChanged}
				onDragEnd={onMapChanged}
				options={inspectionsMapOptions}
				defaultOptions={inspectionsMapDefaultOptions}
				onClick={() => {
					let mapClickCopy = mapClick + 1;
					setMapClick(mapClickCopy);
				}}
			>
				<MapMarkers
					mapClick={mapClick}
					statuses={statuses}
					map={map}
					inspectionList={inspectionList}
					townsSummary={townsSummary}
					highlighted={highlighted}
					selectMarker={selectMarker}
					outageEvents={outageEvents}
					showEvents={showEvents}
					showInspections={showInspections}
					showTransmissionAssets={showTransmissionAssets}
					showDistributionAssets={showDistributionAssets}
					inspectionTypes={inspectionTypes}
					userPosition={userPosition}
					assets={assets}
					assetHighlighted={assetHighlighted}
					videosByAsset={videosByAsset}
				/>
				{showLines && map && (
					<Lines lineId={lineId} map={map} lines={linesFiltered} />
				)}
			</GoogleMap>
		);
		return (
			<>
				{onLoadApi ? (
					<div className={classes.mapContainer}>
						<MapToggles
							map={map}
							circuitCenter={circuitCenter}
							setFullscreen={setFullscreen}
							fullscreen={fullscreen}
							showInspections={showInspections}
							setShowInspections={setShowInspections}
							showTransmissionAssets={showTransmissionAssets}
							showDistributionAssets={showDistributionAssets}
							setShowTransmissionAssets={(
								showTransmissionAssets,
								showDistributionAssets,
								zoom
							) => {
								getAssetTypesSelected(
									showDistributionAssets,
									showTransmissionAssets,
									zoom
								);
							}}
							setShowDistributionAssets={(
								showTransmissionAssets,
								showDistributionAssets,
								zoom
							) => {
								getAssetTypesSelected(
									showDistributionAssets,
									showTransmissionAssets,
									zoom
								);
							}}
							showEvents={showEvents}
							setShowEvents={(show, zoom) => {
								setShowEvents(show);
								setZoom(zoom);
								zoom > zoomIndividualLevel &&
									show &&
									getEvents(activeFilters['viewPort']);
							}}
							setUserPosition={setUserPosition}
							showLines={showLines}
							onChangeShowLines={(e) => {
								setShowLines(e);
							}}
						/>
						{renderMap()}
					</div>
				) : null}
			</>
		);
	}
);

export default Map;
