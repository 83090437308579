import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {Select as MUISelect} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compact } from 'lodash';
import { isArrayNotEmpty } from '../../../../Utils';

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%',
    "& [class^='MuiInputLabel-root'], & [class*=' MuiInputLabel-root']": {
      backgroundColor: "#fff"
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    maxWidth: "100%",
    textOverflow: "ellipsis"
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  required: {
    color: theme.palette.primary.required
  },
  optionSelector: {
    "&[class^='Mui-selected'], &[class*=' Mui-selected']": {
      backgroundColor: '#00B140',
      color: "#fff",
      "&:hover": {
        backgroundColor: '#00B140',
        color: "#fff",
      }
    },
    "&:hover": {
      backgroundColor: '#00B140',
      color: "#fff",
    }
  },
  basicSelector: {
    height: "60px"
  }
}));
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      margin: 0
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const Select = ({
  selectOptions,
  label,
  selectItem,
  itemSelected,
  required,
  helperText = false,
  requiredText = false,
  filter = false,
  disabled = false,
  multiple = true,
  chip= true,
  variant="outlined",
  id,
  checkbox= false,
  onBlur = null,
  autoFocus = false,
  onOpen= null,
  loading = true,
  defaultText = "",
}) => {
  const classes = useStyles();
  const [hasError, setHasError] = React.useState(false);
    
  const handleChange = (event) => {
    setHasError(required && event.target.value.length === 0)
    selectItem(event.target.value, event)
  };

  const handleBlur = () => {
    if (Array.isArray(itemSelected)) {
      if (isArrayNotEmpty(itemSelected)) {
        setHasError((required && (itemSelected[0] === "" || itemSelected[0] === undefined)))
      } else {
        setHasError((required && itemSelected.length === 0))
      }
    } else {
      setHasError(required && itemSelected === "")
    }
    onBlur && onBlur()
  }

  return (
    <div className={classes.selectContainer} data-testid="selectContainer">
      <FormControl className={classes.formControl} variant={variant} disabled={disabled}>
        <InputLabel id="select-mutiple-chip-label">{required && <span className={classes.required}>*</span>} {label}</InputLabel>
        <MUISelect
          inputProps={{ id, 'data-testid': id }}
          autoFocus={autoFocus}
          name={id}
          className = {!chip ? classes.basicSelector : ""}
          labelId="select-mutiple-chip-label"
          aria-describedby="helperSelect"
          id={id}
          multiple={multiple}
          value={multiple ? itemSelected : compact(itemSelected).length > 0 ? itemSelected : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          onOpen={e => onOpen && onOpen(e)}
          renderValue={(selected) => (
            filter
              ? multiple ? selected.join(', ') : selected
              : <div className={classes.chips}>
                  {multiple
                    ? selected.map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))
                    : <Chip key={selected} label={selected} className={classes.chip} />
                  }
                </div>
          )}
          MenuProps={MenuProps}
          error={hasError}
          disabled={disabled}
        >
          {selectOptions && selectOptions.length > 0
            ? selectOptions.map((selectItemMap, index) => {
                if (!selectItemMap) {
                  return null                  
                }
                const option = typeof selectItemMap === 'object' ? selectItemMap.name : selectItemMap
                
                return <MenuItem	
                  key={index}
                  value={option}
                  className={classes.optionSelector}
                >
                  {filter || checkbox
                    ? <>
                        <Checkbox checked={itemSelected && itemSelected.indexOf(option) > -1} />
                        <ListItemText primary={option} />
                      </>
                    : option}
                </MenuItem>
              })
            : loading 
                ? <CircularProgress />
                : itemSelected 
                  ? <MenuItem	value={itemSelected} className={classes.optionSelector}>{itemSelected}</MenuItem>
                  : <MenuItem	value="" className={classes.optionSelector}>{defaultText}</MenuItem>
          }         
        </MUISelect>
        {(!hasError && helperText) && <FormHelperText id="helperSelect">{helperText}</FormHelperText>}
        {hasError && <FormHelperText id="errorMessageSelect" className={classes.required}>{requiredText || "You must select at least one"}</FormHelperText>}
      </FormControl>
    </div>
  );
}

Select.propTypes = {
  selectItem: PropTypes.func,
  itemSelected: PropTypes.array
};

export default Select;
