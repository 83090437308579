import { callApi } from '../callApi';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { RESOURCE_INSPECTIONS, EMPTY_PROMISE } from '../Constants';

function validateFilter(field) {
	let value = '';
	if (typeof field.map == 'function') {
		value = field.toString();
	} else if (typeof field.getMonth == 'function') {
		value = moment.utc(field).format();
	} else if (field.structureNumber) { // Asset
		value = field.structureNumber;
	} else {
		value = field;
	}

	return value;
}

let cancelTokenSource = null; // Defines a cancel token globally

class InspectionsService {
	getInspectionByFilters(filters, continuationToken, useContinuationToken) {
		const filterValues = {
			type: 'inspectionType',
			createdBy: 'submittedby',
			submittedFrom: 'start',
			submittedTo: 'end',
			damageTypes: 'damageType',
			status: 'status',
			sortby: 'sortby',
			sortorder: 'sortorder',
			viewPort: 'viewport',
			withoutPagination: 'withoutPagination',
			circuitId: 'circuitId',
			lineIds: 'lineId',
			asset: 'asset',
			address: 'address'
		};

		let header = {};
		if (continuationToken !== '' && useContinuationToken)
			header = { 'Continuation-Token': continuationToken };
		const finalFilter = _.pickBy(filters, (value) => {
			return !(value === null || value === '' || value.length === 0);
		});
		let filterStr = '';
		for (const key in finalFilter) {
			filterStr += `${filterValues[key]}=${validateFilter(finalFilter[key])}&`;
		}
		filterStr =
			filterStr !== '' ? filterStr.substring(filterStr.length - 1, '') : '';

		// cancel  previous request if exists
		if (cancelTokenSource) {
			cancelTokenSource.cancel();
		}

		// creates a new token for upcomming ajax (overwrite the previous one)
		cancelTokenSource = axios.CancelToken.source();

		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}${
				filterStr !== '' ? `Inspections?${filterStr}` : 'Inspections'
			}`,
			header,
			cancelTokenSource,
		});
	}

	create(data, getErrorResponse) {
		return data?.inspectionType
			? callApi({
				method: 'post',
				url: `${RESOURCE_INSPECTIONS}Inspections/${data.inspectionType.toLowerCase()}`,
				data,
				getErrorResponse,
			})
			: EMPTY_PROMISE;
	}

	getById(id, inspectionType, partitionKey) {
		return inspectionType
			? callApi({
				method: 'get',
				url: `${RESOURCE_INSPECTIONS}Inspections/${inspectionType.toLowerCase()}/${id}?partitionKey=${partitionKey}`,
			})
			: EMPTY_PROMISE;
	}

	edit(inspection, getErrorResponse = false) {
		return inspection?.inspectionType
			? callApi({
				method: 'put',
				data: inspection,
				url: `${RESOURCE_INSPECTIONS}Inspections/${inspection.inspectionType.toLowerCase()}`,
				getErrorResponse,
			})
			: EMPTY_PROMISE;
	}

	delete(id, inspectionType, partitionKey) {
		return inspectionType
			? callApi({
				method: 'delete',
				url: `${RESOURCE_INSPECTIONS}Inspections/${inspectionType.toLowerCase()}/${id}?partitionKey=${partitionKey}`,
				getErrorResponse: true,
			})
			: EMPTY_PROMISE;
	}

	async getAllInspections(filters, continuationToken, useContinuationToken) {
		const inspections = await this.getInspectionByFilters(
			filters,
			continuationToken,
			useContinuationToken
		);

		if (inspections && inspections.canceled) {
			return { inspections: { canceled: true } };
		}

		return {
			inspections: inspections ? inspections.data : [],
			newContinuationToken: inspections?.headers
				? inspections.headers['continuation-token']
				: '',
			totalItems: inspections?.headers
				? inspections.headers['total-items']
				: '',
		};
	}

	async getAllInspectionsSummary() {
		const inspectionsSummary = await callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}towns/summary`,
		});
		return inspectionsSummary ? inspectionsSummary.data : [];
	}

	save(inspection) {
		return inspection?.inspectionType
			? callApi({
				method: inspection.id ? 'put' : 'post',
				data: inspection,
				url: `${RESOURCE_INSPECTIONS}Inspections/${inspection.inspectionType.toLowerCase()}`,
			})
			: EMPTY_PROMISE;
	}
}
export default new InspectionsService();
