import { Grid } from "@material-ui/core";
import * as React from "react";
import { conductorDamageLocationOpts, STATUS_EVERSOURCE_QA_REVIEW, STATUS_OFFICE_INSPECTION, STATUS_QA_REVIEW } from "../../../../../Constants";
import { SpecificsSteel } from "./Steel";
import { SpecificsAll } from "./All";
import { SpecificsWood } from "./Wood";
import { SpecificsConcrete } from "./Concrete";
import ImagesControl from "../../../../Shared/ImagesControl";
import TextFieldComponent from "../../../../Shared/atoms/TextInput";
import Select from "../../../../Shared/atoms/Select";

export const SpecificsInformation = ({
  onChange,
  form,
  materialType,
  poleType,
  images,
  setImages,
  inspectionType,
  showUploadImagesComponent,
  setShowUploadImagesComponent,
  isReadOnly,
  editing,
  originalStatus = false
}) => {
  const onChangeInput = (value, key) => {    
    let values
    if (key === "isVisualDamage" && !value) {
      values = { ...form, isVisualDamage: value, visualDamageDetails: null }
    } else if (key === "isInsectDamage") {
      values = { ...form, isInsectDamage: value, insectDamageDetails: null }
    } else if (key === "isWoodpeckerDamage") {
      values = { ...form, isWoodpeckerDamage: value, woodpeckerHolesSeverity: null, woodpeckerHolesLocation: null }
    } else if (key === "woodpeckerHolesSeverity" && value === "No Action") {
      values = { ...form, woodpeckerHolesSeverity: value, woodpeckerHolesDetails: null }
    } else if (key === "isHardwareDamage") {
      values = { ...form, isHardwareDamage: value, hardwareDamageSeverity: null, hardwareDamageDetails: null }
    } else if (key === "hardwareDamageSeverity" && value === "No Action") {
      values = { ...form, hardwareDamageSeverity: value, hardwareDamageDetails: null }
    } else if (key === "isCrossarmDamage") {
      values = { ...form, isCrossarmDamage: value, crossarmDamageSeverity: null, crossarmSplitOrBreakSeverity: null, crossarmDamageDetails: null }
    } else if (key === "isInsulatorDamage") {
      values = { ...form, isInsulatorDamage: value, insulatorDamageSeverity: null, insulatorChippedOrBrokenBellsNumberPerString: null, insulatorDamageDetails: null }
    } else if (key === "insulatorDamageSeverity" && value === "No Action") {
      values = { ...form, insulatorDamageSeverity: value, insulatorDamageDetails: null }
    } else if (key === "isConductorDamage") {
      values = { ...form, isConductorDamage: value, conductorDamageLocation: conductorDamageLocationOpts, conductorStrandDamageSeverity: null, conductorSpliceDamageSeverity: null, conductorAttachmentPointDamageSeverity: '', conductorBurnedDamageSeverity: '', conductorDamageDetails: '' }
    } else if (key === "insulatorChippedOrBrokenBellsNumberPerString") {
      if (value === "" || (value <= 20 && value > 0) ) {
        values = { ...form, [key]: value }
      } else {
        values = {...form}
      }
    } else if (key === "leaningOrBentSeverity" || key === "corrosionSeverity" || key === "rustSeverity") {
      values = { ...form, [key]: value }
      if (!showDetailInput(["leaningOrBentSeverity", "corrosionSeverity", "rustSeverity"], values)) {
        values = { ...values, structureIssueDetails: null }
      }
    } else if ((materialType === "Laminate Wood" || materialType === "Natural Wood") && (key === "shellRotSeverity" || key === "decaySeverity" || key === "splitOrBreakSeverity" || key === "fractureSeverity" || key === "structuralIntegrity")) {
      values = { ...form, [key]: value }
      if (!showDetailInput(["shellRotSeverity", "decaySeverity", "splitOrBreakSeverity", "fractureSeverity", "structuralIntegrity"], values)) {
        values = { ...values, structureIssueDetails: null }
      }
    } else if ((materialType === "Concrete" || materialType === "Composite") && (key === "splitOrBreakSeverity" || key === "fractureSeverity" || key === "structuralIntegrity")) {
      values = { ...form, [key]: value }
      if (!showDetailInput(["splitOrBreakSeverity", "fractureSeverity", "structuralIntegrity"], values)) {
        values = { ...values, structureIssueDetails: null }
      }
    } else if (key === "crossarmDamageSeverity" || key === "crossarmSplitOrBreakSeverity") {
      values = { ...form, [key]: value }
      if (!showDetailInput(["crossarmDamageSeverity", "crossarmSplitOrBreakSeverity"], values)) {
        values = { ...values, crossarmDamageDetails: null }
      }
    } else if (key === "conductorStrandDamageSeverity" || key === "conductorSpliceDamageSeverity" || key === "conductorAttachmentPointDamageSeverity" || key === "conductorBurnedDamageSeverity") {
      values = { ...form, [key]: value }
      if (!showDetailInput([
        "conductorStrandDamageSeverity",
        "conductorSpliceDamageSeverity",
        "conductorAttachmentPointDamageSeverity",
        "conductorBurnedDamageSeverity"], values)) {
          values = { ...values, conductorDamageDetails: null }
        }
    } else {
      values = { ...form, [key]: value }
    }
    onChange(values, "specifics", true);
  };
  
  const showDetailInput = (structureOptions, formValues = null) => {
    if (!formValues) {
      formValues = form
    }
    let found = false
    structureOptions.forEach(structureOption => {
      if (formValues[structureOption] === "Severe" || formValues[structureOption] === "Minimal" || formValues[structureOption] === "Moderate") {
        found = true
      }
    });
    return found;
  }
  return (
    <>
      <ImagesControl
        images={images}
        onImagesChange={(e) => {setImages(e)}}
        inspectionType={inspectionType}
        showUploadImagesComponent={showUploadImagesComponent}
        setShowUploadImagesComponent={setShowUploadImagesComponent}
        disabled={isReadOnly && !editing}
        editing={editing}
      /> 
      <Grid container spacing={2}>
        {isReadOnly
          ? <Grid item xs={12}>
              {!editing || (editing && (originalStatus !== STATUS_QA_REVIEW && originalStatus !== STATUS_EVERSOURCE_QA_REVIEW))
                ? <TextFieldComponent
                    label="Status"
                    variant="outlined"
                    fullWidth
                    onChange={() => {}}
                    id="TransmissionStatus"
                    value={form.status}
                    disabled={true}
                  />
                : editing && (originalStatus === STATUS_QA_REVIEW || originalStatus === STATUS_EVERSOURCE_QA_REVIEW) &&
                  <Select
                    label="Status"
                    selectOptions={[STATUS_OFFICE_INSPECTION, STATUS_QA_REVIEW, originalStatus === STATUS_EVERSOURCE_QA_REVIEW && STATUS_EVERSOURCE_QA_REVIEW]}
                    selectItem={(e) => onChangeInput(e, "status")}
                    itemSelected={[form.status]}
                    required={true}
                    multiple={false}
                    id="TransmissionStatusSelect"
                  />
              }
            </Grid>
          : null
        }
        {(materialType === "Steel" || materialType === "Custom Steel" || materialType === "Galvanized" 
        || materialType === "Painted Steel" || materialType === "Weathering") &&
        <SpecificsSteel
          onChangeInput={onChangeInput}
          showDetailInput={showDetailInput}
          form={form}
          poleType={poleType}
          isReadOnly={isReadOnly}
          editing={editing}
        />}
        
        {(materialType === "Laminate Wood" || materialType === "Natural Wood") &&
        <SpecificsWood
          onChangeInput={onChangeInput}
          showDetailInput={showDetailInput}
          form={form}
          poleType={poleType}
          isReadOnly={isReadOnly}
          editing={editing}
        />}

        {(materialType === "Concrete" || materialType === "Composite") &&
        <SpecificsConcrete
          onChangeInput={onChangeInput}
          showDetailInput={showDetailInput}
          form={form}
          poleType={poleType}
          isReadOnly={isReadOnly}
          editing={editing}
        />} 
        <SpecificsAll
          onChangeInput={onChangeInput}
          showDetailInput={showDetailInput}
          form={form}
          isReadOnly={isReadOnly}
          editing={editing}
        />
      </Grid>
    </>
  );
};
