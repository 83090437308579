import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AlertDialogSlide from '../Shared/Dialog';
import Table from '../Shared/Table';
import Select from '../Shared/atoms/Select';
import WorkQueueService from '../../services/WorkQueueService';
import {
	formatDate,
	saveObjInLocalStorage,
	tableAlphanumericalSort,
	getToday,
	validRoles,
	showSnackbar,
} from '../../Utils';
import {
	WORK_ITEMS_GLOBAL_SEARCH,
	LOCALSTORAGE_CURRENT_WORK_ITEM,
	STATUS_OPTIONS,
	STATUS_NEW,
	STATUS_IN_PROGRESS,
	ROLES,
} from '../../Constants';
import { msalInstance } from '../App/App';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	fabIconCreate: {
		position: 'fixed',
		bottom: 60,
		right: 60,
		[theme.breakpoints.down('sm')]: {
			bottom: 70,
			right: 20,
		},
		[theme.breakpoints.down('xs')]: {
			bottom: 120,
			right: 40,
		},
	},
	statusEditContainer: {
		cursor: 'pointer',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
	},
	statusEdit: {
		borderBottom: '1px dashed #333',
	},
}));

const resetCurrentWorkItem = {
	circuitId: '',
	workQueueId: '',
	workItemId: '',
};

const resetWorkItemSearch = {
	search: '',
	type: '',
	name: '',
};

const WorkQueueContent = ({ testData = null, setLoading, ...props }) => {
	const classes = useStyles();
	const history = useHistory();
	const [openDialog, setOpenDialog] = useState(false);
	const [workQueueToDelete, setWorkQueueToDelete] = useState('');
	const [editStatus, setEditStatus] = useState(null);
	const [editStatusSelected, setEditStatusSelected] = useState(null);
	const [workQueuesList, setWorkQueuesList] = useState([]);
	const [account, setAccount] = useState('');
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		WorkQueueService.getAll().then(
			(response) => {
				if (response && response.data) {
					setWorkQueuesList(response.data);
					setLoading(false);
				}
			},
			() => {
				showSnackbarMessage(
					'An error has occurred getting the Work Queues',
					'error'
				);
			}
		);

		const accounts = msalInstance.getAllAccounts();
		if (accounts.length > 0) {
			setAccount(accounts[0] || {});
		}
		return () => {
			setWorkQueuesList([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const editStatusValue = (id, name, event, status) => {
		const date = getToday();
		setEditStatusSelected([status]);
		setLoading(true);
		event.stopPropagation();
		WorkQueueService.edit({
			id,
			name,
			status,
		})
			.then((response) => {
				if (response.status === 204) {
					// Get the index of the element just updated
					const index = workQueuesList.findIndex((item) => item.id === id);
					const workQueueListCopy = [...workQueuesList];
					workQueueListCopy[index].status = status;
					workQueueListCopy[index].dateUpdated = date;
					workQueueListCopy[index].updatedBy = (account && account.name) || '';
					setWorkQueuesList(workQueueListCopy);
					showSnackbarMessage('Work Queue edited successfully', 'success');
				} else {
					showSnackbarMessage(
						'An error has occurred updating the Work Queue',
						'error'
					);
				}
				setEditStatus(null);
				setEditStatusSelected(null);
				setLoading(false);
			})
			.catch(() => {
				showSnackbarMessage(
					'An error has occurred updating the Work Queue',
					'error'
				);
			});
	};

	const columns = [
		{ title: 'Type', field: 'workQueueType' },
		{
			title: 'Name',
			field: 'name',
			customSort: (a, b) => tableAlphanumericalSort('name', a, b),
		},
		{
			title: 'Status',
			field: 'status',
			render: (rowData) =>
				rowData.status &&
				(editStatus === rowData.id ? (
					<Select
						label="Status"
						selectOptions={STATUS_OPTIONS}
						selectItem={(value, event) =>
							editStatusValue(rowData.id, rowData.name, event, value)
						}
						itemSelected={editStatusSelected || [rowData.status]}
						required={true}
						multiple={false}
						helperText={false}
						disabled={!!editStatusSelected}
					/>
				) : (
					<div
						role="button"
						onClick={(e) => {
							e.stopPropagation();
							showAdminActions && setEditStatus(rowData.id);
						}}
						className={classes.statusEditContainer}
					>
						<span className={classes.statusEdit}>{rowData.status}</span>
					</div>
				)),
		},
		{ title: '% Complete', field: 'percentageCompleted' },
		{ title: 'Work Items', field: 'workItemsCount' },
		{ title: 'Created By', field: 'createdBy' },
		{
			title: 'Created On',
			field: 'dateCreated',
			render: (rowData) =>
				rowData.dateCreated && formatDate(rowData.dateCreated),
			type: 'datetime',
		},
		{ title: 'Updated By', field: 'updatedBy' },
		{
			title: 'Updated On',
			field: 'dateUpdated',
			render: (rowData) =>
				rowData.dateUpdated && formatDate(rowData.dateUpdated),
			type: 'datetime',
		},
	];

	const onRowClick = (row) => {
		saveObjInLocalStorage(WORK_ITEMS_GLOBAL_SEARCH, {
			search: '',
			type: [row.workQueueType],
			name: row.name,
		});
		history.push(`/WorkItems/${encodeURIComponent(row.name)}/`);
	};

	const deleteWorkQueue = () => {
		setLoading(true);
		setOpenDialog(false);
		WorkQueueService.delete(workQueueToDelete)
			.then((result) => {
				if (result) {
					deleteWorkQueueStorage(workQueueToDelete, WORK_ITEMS_GLOBAL_SEARCH);
					deleteWorkQueueStorage(
						workQueueToDelete,
						LOCALSTORAGE_CURRENT_WORK_ITEM
					);
					// Get the index of the element just deleted
					const index = workQueuesList.findIndex(
						(item) => item.id === workQueueToDelete
					);
					const workQueueListCopy = [...workQueuesList];
					workQueueListCopy.splice(index, 1); // remove from array
					setWorkQueuesList(workQueueListCopy);
					setLoading(false);
					showSnackbarMessage('Work Queue deleted successfully.', 'success');
				}
			})
			.catch(() => {
				showSnackbarMessage(
					'An error has occurred deleting the Work Queue',
					'error'
				);
			});
	};

	const deleteWorkQueueStorage = (workQueueToDelete, storageKey) => {
		const workQueueStorage = localStorage.getItem(storageKey);

		if (workQueueStorage && workQueueStorage !== '') {
			const _workQueue = JSON.parse(workQueueStorage);
			if (storageKey === WORK_ITEMS_GLOBAL_SEARCH) {
				if (workQueueToDelete === _workQueue.name) {
					saveObjInLocalStorage(WORK_ITEMS_GLOBAL_SEARCH, resetWorkItemSearch);
				}
			} else {
				if (workQueueToDelete === _workQueue.workQueueId) {
					saveObjInLocalStorage(
						LOCALSTORAGE_CURRENT_WORK_ITEM,
						resetCurrentWorkItem
					);
				}
			}
		}
	};

	const onOpenModal = (e) => {
		setOpenDialog(true);
		setWorkQueueToDelete(e);
	};

	const onCloseModal = () => {
		setOpenDialog(false);
		setWorkQueueToDelete('');
	};

	const rowAbleToDelete = (status) => status === STATUS_NEW;
	const rowAbleToEdit = (status) =>
		status === STATUS_NEW || status === STATUS_IN_PROGRESS;

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	const showAdminActions =
		validRoles([ROLES.admin, ROLES.supervisor], props.roles).length > 0;

	return (
		<>
			{(testData || workQueuesList) && (
				<Table
					style={{ width: '95%' }}
					columns={columns}
					data={testData || workQueuesList}
					title=""
					options={{
						filtering: false,
						pageSize: 10,
						showSelectAllCheckbox: false,
						selection: false,
						headerStyle: {
							color: '#007749',
							fontWeight: 'bold',
						},
						pageSizeOptions: [5, 10, 25],
						actionsColumnIndex: -1,
					}}
					onRowClick={onRowClick}
					actions={
						showAdminActions && [
							(rowData) => ({
								icon: () => (
									<DeleteIcon
										data-testid="deleteIcon"
										color={
											rowAbleToDelete(rowData.status) ? 'error' : 'disabled'
										}
									/>
								),
								tooltip: rowAbleToDelete(rowData.status)
									? 'Delete Work Queue'
									: null,
								onClick: (e, row) => onOpenModal(row.id),
								disabled: !rowAbleToDelete(rowData.status),
							}),
							(rowData) => ({
								icon: () => (
									<EditIcon
										data-testid="editIcon"
										color={
											rowAbleToEdit(rowData.status) ? 'primary' : 'disabled'
										}
									/>
								),
								tooltip: rowAbleToEdit(rowData.status)
									? 'Edit Work Queue'
									: null,
								onClick: (e, row) => {
									history.push({
										pathname: `/WorkQueue/edit/${row.id}`,
										workQueue: row,
									});
								},
								disabled: !rowAbleToEdit(rowData.status),
							}),
						]
					}
				/>
			)}
			{showAdminActions && (
				<Fab
					color="primary"
					aria-label="add"
					onClick={() => history.push('/WorkQueue/create')}
					className={classes.fabIconCreate}
					data-testid="addWorkQueueButton"
				>
					<AddIcon />
				</Fab>
			)}
			<AlertDialogSlide
				openDialog={openDialog}
				handleConfirm={deleteWorkQueue}
				title="Delete Work Queue"
				content="Are you sure you want to delete this Work Queue?"
				handleClickClose={() => {
					onCloseModal();
				}}
			></AlertDialogSlide>
		</>
	);
};

export default WorkQueueContent;
