import { Grid, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import { makeStyles } from '@material-ui/core/styles';
import RemoveTwoToneIcon from '@material-ui/icons/RemoveTwoTone';
import NatureTwoToneIcon from '@material-ui/icons/NatureTwoTone';
import { useSnackbar } from "notistack";
import DashboardService from "../../services/DashboardService";
import InspectionsService from "../../services/InspectionsService";
import Cards from "./Cards";
import DoughnutCard  from "./Doughnut";
import InspectionsTable from "./InspectionsTable";
import { getRandomColor, validRoles, showSnackbar } from "../../Utils";
import { INSPECTION_TYPE_TREE, ROLES } from "../../Constants";

const useStyles = makeStyles(() => ({ 
  statesChartIcon : {
    fontSize: 50
  },
  dashboardGrid: {
    "& .MuiPaper-root": {
      margin: 0
    }
  }
}))

function Dashboard({ userRoles }) {
  const theme = useTheme();
  const classes = useStyles();
  const [summary, setSummary] = useState({});
  const [inspections, setInspections] = useState([]);
  const [inspectionsDoughnuts, setInspectionsDoughnuts] = useState(null);
  const [cardsVisibility, setCardsVisibility] = useState({ activeWorkItems: true, transmissionView: false });
  const { enqueueSnackbar } = useSnackbar();
  
  const isTreeInspectionContributor = validRoles([ROLES.treeInspectionContributor], userRoles).length > 0
  const isTransmissionInspectionContributor = validRoles([ROLES.transmissionInspectionContributor], userRoles).length > 0
  const isManholeInspectionContributor = validRoles([ROLES.manholeInspectionContributor], userRoles).length > 0
  const isAdminOrSupervisor = validRoles([ROLES.admin, ROLES.supervisor], userRoles).length > 0

  useEffect(() => {
    DashboardService.getSummary().then(result => {
      if (result.data && result.data.inspectionsSummary) {
        setSummary(result.data)
        setDoughnutsData(result.data.inspectionsSummary, result.data.overallConditionRatingsCount)
      }      
    }).catch(() => {
      showSnackbarMessage("There was an error fetching the data", "warning");
    })
    InspectionsService.getAllInspections().then(result => {
      if (result && result.inspections) {
        setInspections(result.inspections)
      }
    }).catch(() => {
      showSnackbarMessage("There was an error fetching the data", "warning");
    })
    
    isTreeInspectionContributor && setCardsVisibility({...cardsVisibility, ...{ activeWorkItems: false }})
    isTransmissionInspectionContributor && setCardsVisibility({...cardsVisibility, ...{ transmissionView: true }})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setDoughnutsData = (inspectionsSummary, overallConditions) => {
    function getColor(key) {
      switch (key) {
        case "Connecticut":
          return theme.palette.primary.main
        case "New Hampshire":
          return theme.palette.primary.light
        case "Massachusetts":
          return theme.palette.primary.dark
        default:
          return getRandomColor()
      }
    }

    function baseDataChart(data = [], backgroundColor = [], labels = []) {
      return {
        datasets: [{
          data,
          backgroundColor,
          borderWidth: 2,
          borderColor: theme.palette.secondary.main,
          hoverBorderColor: theme.palette.secondary.main
        }],
        labels
      }
    }

    const states = []
    const types = {
      ...!isTransmissionInspectionContributor && {Tree: 0},
      Damage: 0,
      Transmission: 0
    }
        
    // Arrange an array for each state with count by type and total
    inspectionsSummary.forEach(inspectionSummary => {
      const { stateName, inspectionType, count} = inspectionSummary
      if (stateName === "Connecticut" || stateName === "New Hampshire" || stateName === "Massachusetts") {
        if (states[stateName] && states[stateName][inspectionType] ) { // if state and type exist, add new value
          states[stateName][inspectionType] = states[stateName][inspectionType] + count
          states[stateName].totalCount = states[stateName].totalCount + count 
        } else if (!states[stateName]) { // If nothing exist create object with state and type with initial count
          states[stateName] = {}
          states[stateName][inspectionType] = count
          states[stateName].totalCount = count
        } else if (states[stateName] && !states[stateName][inspectionType]) { // if state exist but type not, create with initial count
          states[stateName][inspectionType] = count  
          states[stateName].totalCount = states[stateName].totalCount + count            
        }
      }
      if (!isTransmissionInspectionContributor || (isTransmissionInspectionContributor && inspectionType !== INSPECTION_TYPE_TREE))
        types[inspectionType] = types[inspectionType] ? types[inspectionType] + count : count
    })

    // Base obj for chart
    const statesDataChart = baseDataChart()    
    // Each of the chart section
    for (var key in states) {           
      if (key !== "null") {
        var stateInfo = states[key];
        const color = getColor(key)
        statesDataChart.datasets[0].data.push(stateInfo.totalCount)
        statesDataChart.datasets[0].backgroundColor.push(color)          
        statesDataChart.labels.push(key)
      }
    }
    // Information beneath graphic
    const statesChartInfo = [
      {
        title: <><RemoveTwoToneIcon className={classes.statesChartIcon} style={{ color: getColor("Connecticut") }} /> <div>CT</div></>,
        color: getColor("Connecticut"),
        icon: "",
      },
      {
        title: <><RemoveTwoToneIcon className={classes.statesChartIcon} style={{ color: getColor("Massachusetts")  }} /> <div>MA</div></>,
        color: getColor("Massachusetts"),
        icon: "",
      },
      {
        title: <><RemoveTwoToneIcon className={classes.statesChartIcon} style={{ color: getColor("New Hampshire")  }} /> <div>NH</div></>,
        color: getColor("New Hampshire"),
        icon: "",
      },
    ]

    // BY TYPES, chart data
    const typesDataChart = baseDataChart(
      [
        types.Damage,
        ...(!isTransmissionInspectionContributor ? [types.Tree] : []),
        types.Transmission
      ],
      [
        theme.palette.primary.dark,
        ...(!isTransmissionInspectionContributor ? [theme.palette.primary.light] : []),
        theme.palette.primary.main
      ],
      ["Damage", ...(!isTransmissionInspectionContributor ? ["Tree"] : []), "Transmission"]
    )

    // Information beneath graphic for types
    const typesChartInfo = [
      ...(!isTransmissionInspectionContributor ? [{
      title: "Tree",
      color: theme.palette.primary.light,
      icon: <NatureTwoToneIcon color="primary" style={{ margin: 5 }} />
    }] : []),
    {
      title: "Damage",
      color: theme.palette.primary.dark,
      icon:  <DescriptionTwoToneIcon style={{ color: theme.palette.primary.dark, margin: 5  }} />
    },
    {
      title: "Transmission",
      color: theme.palette.primary.main,
      icon:  <DescriptionTwoToneIcon style={{ color: theme.palette.primary.main, margin: 5  }} />
    }]

    // Overall conditions
    let conditionsChartInfo
    let conditionsDataChart
    if (overallConditions) {
      const conditions = {}
      overallConditions.map(overallCondition => {
        conditions[overallCondition.conditionRating] = overallCondition.count
      })

      // Overall conditions, chart data
      conditionsDataChart = baseDataChart(
        [conditions.A, conditions.B, conditions.C, conditions.D],
        [theme.palette.primary.dark, theme.palette.primary.light, theme.palette.primary.main, "#FFB020"],
        ["A", "B", "C", "D"]
      )
      // Information beneath graphic for overall conditions
      conditionsChartInfo = [{
        title: <><RemoveTwoToneIcon className={classes.statesChartIcon} style={{ color: theme.palette.primary.dark }} /> <div>A</div></>,
        color: theme.palette.primary.dark,
        icon: ""        
      },
      {
        title: <><RemoveTwoToneIcon className={classes.statesChartIcon} style={{ color: theme.palette.primary.light }} /> <div>B</div></>,
        color: theme.palette.primary.light,
        icon:  ""
      },
      {
        title: <><RemoveTwoToneIcon className={classes.statesChartIcon} style={{ color: theme.palette.primary.main }} /> <div>C</div></>,
        color: theme.palette.primary.main,
        icon:  ""
      },
      {
        title: <><RemoveTwoToneIcon className={classes.statesChartIcon} style={{ color: "#FFB020" }} /> <div>D</div></>,
        color: "#FFB020",
        icon:  ""
      }]
    }
    setInspectionsDoughnuts({
      states: {
        chartInfo: statesChartInfo,
        dataChart: statesDataChart
      },
      types: {
        chartInfo: typesChartInfo,
        dataChart: typesDataChart
      },
      ...overallConditions && {overallConditions: {
        chartInfo: conditionsChartInfo,
        dataChart: conditionsDataChart
      }}
    })
  }

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
	}

  return (         
    <Grid container spacing={2} className={classes.dashboardGrid}>
      <Grid item xs={12}>
        <Cards summary={summary} cardsVisibility={cardsVisibility} />
      </Grid>      
      <Grid item xs={12} md={6}>
        <Grid container alignContent="center" spacing={2}>
          {isAdminOrSupervisor || isTreeInspectionContributor || isManholeInspectionContributor 
          ? <Grid item xs={12} md={6}>
              {inspectionsDoughnuts && inspectionsDoughnuts.states && <DoughnutCard 
                title="Inspections by State"
                data={inspectionsDoughnuts.states.dataChart}
                chartInfo={inspectionsDoughnuts.states.chartInfo}
              />}
            </Grid>
          : isTransmissionInspectionContributor && <Grid item xs={12} md={6}>
              {inspectionsDoughnuts && inspectionsDoughnuts.overallConditions && <DoughnutCard 
                title="Transmission Inspections Conditions"
                data={inspectionsDoughnuts.overallConditions.dataChart}
                chartInfo={inspectionsDoughnuts.overallConditions.chartInfo}
              />}
            </Grid>
          }          
          <Grid item xs={12} md={6}>
            {inspectionsDoughnuts && inspectionsDoughnuts.types && <DoughnutCard 
              title="Inspections by Type"
              data={inspectionsDoughnuts.types.dataChart}
              chartInfo={inspectionsDoughnuts.types.chartInfo}
            />}
          </Grid>         
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <InspectionsTable inspections={inspections} />
      </Grid>
    </Grid>
  );
      
}

export default Dashboard;
