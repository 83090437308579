import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	useTheme,
} from '@material-ui/core';
Chart.register([ArcElement, Tooltip]);

const DoughnutCard = ({ data, chartInfo, title }) => {
	const theme = useTheme();
	const options = {
		animation: true,
		cutoutPercentage: 80,
		layout: { padding: 0 },
		legend: {
			display: true,
		},
		maintainAspectRatio: false,
		responsive: true,
		tooltips: {
			backgroundColor: theme.palette.background.paper,
			bodyFontColor: theme.palette.text.secondary,
			borderColor: theme.palette.divider,
			borderWidth: 1,
			enabled: true,
			footerFontColor: theme.palette.text.secondary,
			intersect: false,
			mode: 'index',
			titleFontColor: theme.palette.text.primary,
		},
	};

	return (
		<Card style={{ height: '100%' }}>
			<CardHeader title={title} />
			<Divider />
			<CardContent>
				<Box
					sx={{
						height: 300,
						width: '100%',
						position: 'relative',
					}}
				>
					<Doughnut data={data} options={options} />
				</Box>
				{chartInfo && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							pt: 2,
						}}
					>
						{chartInfo.map((chartData, index) => (
							<Box
								key={`${chartData.title} ${index}`}
								sx={{
									textAlign: 'center',
								}}
								display="flex"
								alignItems="center"
								flexWrap="wrap"
								justifyContent="center"
							>
								{chartData.icon}
								{chartData.title}
							</Box>
						))}
					</Box>
				)}
			</CardContent>
		</Card>
	);
};

export default DoughnutCard;
