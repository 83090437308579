import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { StructureInformation } from './Information';
import SpecificsInformationSecondPart from './Specific2';
import { SpecificsInformation } from './Specifics';

const useStyles = makeStyles(() => ({
	title: {
		color: '#007749',
		margin: '0 0 20px 0',
	},
}));

export const TransmissionForms = ({
	activeStep,
	steps,
	onChange,
	forms,
	defaultValues,
	transmissionFromWorkItem,
	inspectionType,
	onChangeType,
	images,
	setImages,
	workQueue,
	ff_transmission_inspection_form,
	isReadOnly,
	editing,
	originalStatus = false,
	types,
}) => {
	const classes = useStyles();
	const [showUploadImagesComponent, setShowUploadImagesComponent] =
		React.useState(true);

	const stepTitle = () => {
		if (steps[activeStep] === 'STRUCTURE SPECIFICS') {
			const { materialType } = forms.structure;

			if (
				materialType === 'Custom Steel' ||
				materialType === 'Steel' ||
				materialType === 'Galvanized' ||
				materialType === 'Painted Steel' ||
				materialType === 'Weathering'
			) {
				return 'Structure Specifics (Steel)';
			} else if (
				materialType === 'Laminate Wood' ||
				materialType === 'Natural Wood'
			) {
				return 'Structure Specifics (Wood)';
			} else if (materialType === 'Concrete' || materialType === 'Composite') {
				return 'Structure Specifics (Concrete & Composite)';
			}
		} else {
			return steps[activeStep];
		}
	};

	const formComponents = [
		<StructureInformation
			defaultValues={defaultValues.structure}
			onChange={onChangeForm}
			form={forms.structure}
			onChangeType={onChangeType}
			inspectionType={inspectionType}
			ff_transmission_inspection_form={ff_transmission_inspection_form}
			images={images}
			setImages={setImages}
			transmissionFromWorkItem={transmissionFromWorkItem}
			showUploadImagesComponent={showUploadImagesComponent}
			setShowUploadImagesComponent={setShowUploadImagesComponent}
			workQueue={workQueue}
			isReadOnly={isReadOnly}
			editing={editing}
			originalStatus={originalStatus}
			types={types}
			key={'StructureInformation'}
		/>,
		<SpecificsInformation
			onChange={onChangeForm}
			form={forms.specifics}
			materialType={forms.structure.materialType}
			poleType={forms.structure.poleType}
			images={images}
			setImages={setImages}
			inspectionType={inspectionType}
			showUploadImagesComponent={showUploadImagesComponent}
			setShowUploadImagesComponent={setShowUploadImagesComponent}
			isReadOnly={isReadOnly}
			editing={editing}
			originalStatus={originalStatus}
			key={'SpecificsInformation'}
		/>,
		<SpecificsInformationSecondPart
			defaultValues={defaultValues.specifics2}
			onChange={onChangeForm}
			form={forms.specifics2}
			images={images}
			setImages={setImages}
			inspectionType={inspectionType}
			showUploadImagesComponent={showUploadImagesComponent}
			setShowUploadImagesComponent={setShowUploadImagesComponent}
			isReadOnly={isReadOnly}
			editing={editing}
			originalStatus={originalStatus}
			key={'SpecificsInformationSecondPart'}
		/>,
	];

	function onChangeForm(form, type) {
		onChange(form, type);
	}

	return (
		<>
			<div className={classes.title}>
				<Typography sx={{ mt: 2, mb: 1 }}>{stepTitle()}</Typography>
			</div>
			<div key={`component${activeStep}`}>{formComponents[activeStep]}</div>
		</>
	);
};
