import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InspectionsDataContext from '../../../../context/InspectionsData/InspectionsDataContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import Paper from '@material-ui/core/Paper';
import TextFieldComponent from '../../../Shared/atoms/TextInput';
import AlertDialogSlide from '../../../Shared/Dialog';
import Button from '../../../Shared/atoms/Button';
import AIService from '../../../../services/AIService';

const useStyles = makeStyles((theme) => ({	
	annotationListContainer: {
		position: 'fixed',
		top: 0,
		right: 0,
		height: '100%',
		width: 300,
		backgroundColor: '#141B28',
		color: 'white',
		zIndex: 2
	},	
	annotationsListWrapper: {
		position: 'relative',
		height: '100%'
	},
	annotationsList: {		
		width: '100%',
		height: '100%',
		overflow: 'auto',
		padding: 10
	},
	annotation: {
		transition: 'all 0.2s ease-in-out',
		borderBottom: '1px solid #999',
		borderRadius: 3,
		paddingTop: 5
	},
	annotationIcons: {
		float: 'right',
		marginTop: 16
	},
	icons: {
		cursor: 'pointer',
		marginLeft: 5
	},
	autocomplete: {
		border: '#fff 2px solid',
		color: '#fff',
		'& .MuiChip-deletable': {
			backgroundColor: theme.palette.primary.main,
			color: '#fff',
			'& .MuiChip-deleteIcon': {
				color: '#fff'
			}
		},
		'& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined': {
			color: '#fff',
			backgroundColor: 'rgb(20, 27, 40)'
		},
		'& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty, & .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"].MuiAutocomplete-input:first-child, & .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator, & .MuiInputBase-input': {
			color: '#fff'
		}
	},
	autocompleteOptions: {
		'&.Mui-selected': {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.secondary.main,
			'&:hover': {
				backgroundColor: theme.palette.primary.light,
				color: theme.palette.secondary.main,
			}
		},
		'&:hover': {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.secondary.main,
		}
	},
	inputText: {
		'& .MuiInputBase-input': {
			border: '1px solid #fff',
			color: '#fff'
		},
		'& .MuiFormControl-root .MuiFormLabel-root:not(.Mui-disabled)': {
			color: '#fff',
			backgroundColor: 'rgb(20, 27, 40)'
		}
	}
}));

const annotationSelectedStyles = {borderLeft: '1px solid #999', borderTop: '1px solid #999', borderRight: '1px solid #999', transform: 'scaleX(1.05)', paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5, backgroundColor: '#141B28'}

export const AnnotationsList = ({ annotations, setAnnotations, currentIndex, selectShape, selectedId, newAnnotation, dimensionAndPositions, loading, setLoading, showSnackbarMessage }) => {
	const classes = useStyles();
	const { getContextState, inspectionsDataState } = useContext(InspectionsDataContext);
	const [issueTypesOptions, setIssueTypesOptions] = useState([]);
	const [listOfAnnotationsByGroup, setListOfAnnotationsByGroup] = useState({});
	const [annotationToDelete, setAnnotationToDelete] = useState(false);

	useEffect(() => {
		const inspectionsData = getContextState();		
		if (issueTypesOptions.length === 0)
			setIssueTypesOptions(inspectionsData.issueTypes || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionsDataState]);

	useEffect(() => {
		const annotationsToDraw = [...annotations, ...newAnnotation];
		setListOfAnnotationsByGroup(groupByIssueType([...annotationsToDraw]));
	}, [currentIndex, annotations, newAnnotation]);

	const CustomPaper = (props) => {
		return <Paper className={classes.autocompleteOptions} {...props} />;
	};

	const findAnnotationIndex = id => {
		const _annotations = annotations.slice();
		return _annotations.findIndex(rect => rect.key === id);
	};

	const editShapeDetails = (newDetails, id) => {
		const _newDetails = {...newDetails};
		const index = findAnnotationIndex(id);
		const _annotations = annotations.slice();
		_annotations[index] = {..._annotations[index], ..._newDetails};
		// If its a new shape, keep "create" as operation, if not, its an update of a previously created shape, If its IA and it was deleted, keep delete
		if (_annotations[index].operation !== 'create' && _annotations[index].operation !== 'delete') {
			_annotations[index].operation ='update';
		}
		setAnnotations(_annotations);
	};

	// Group the annotations by AI or by issue type
	const groupByIssueType = list => {
		const groupedValues = {};
		list.map(annotation => {
			if (annotation.index === currentIndex) {
				if (groupedValues[annotation.issueTypeName] === undefined)
					groupedValues[annotation.issueTypeName] = [];				
				groupedValues[annotation.issueTypeName].push(annotation);
			}
		});
		return groupedValues;
	};

	const deleteAnnotation = id => {
		const index = findAnnotationIndex(id);
		if (!annotations[index]) {
			return;
		}
		// If annotation was created before
		if (annotations[index]?.boundingBox) {
			editShapeDetails({ isDeleted: true, operation: 'delete' }, id);			
		} else { // annotation created in this session and deleted without being saved, remove from array
			const _annotations = annotations.filter(annotation => annotation.key !== id);
			setAnnotations(_annotations);
		}
		setAnnotationToDelete(false);
	};
	
	const saveAnnotations = async () => {

		const getBoundingBox = (annotation, index) => {
			const x2 = annotation.x + annotation.width;
			const y2 = annotation.y + annotation.height;
			return adaptPositionsToOriginalImageSize(annotation.x, annotation.y, x2, y2, annotation.naturalHeight, annotation.naturalWidth, dimensionAndPositions[index].width, dimensionAndPositions[index].height);
		}

		const adaptPositionsToOriginalImageSize = (x1, y1, x2, y2, originalHeight, originalWidth, currentWidth, currentHeight) => {
			const originalSizeX1Position = (x1 * originalWidth)/currentWidth;
			const originalSizeY1Position = (y1 * originalHeight)/currentHeight;		
			const originalSizeX2Position = (x2 * originalWidth)/currentWidth;
			const originalSizeY2Position = (y2 * originalHeight)/currentHeight;
			return [originalSizeX1Position, originalSizeY1Position, originalSizeX2Position, originalSizeY2Position];
		};

		const allAnnotations = getImagesAnnotations();
		const listOfUpdatedAnnotations = [];
		Object.keys(allAnnotations).map((imageId, index) => {
			const imageWithAnnotations = allAnnotations[imageId];
			const updatedAnnotation = {
			  imageId,
			  operations: []
			};
			imageWithAnnotations.map(annotation => {
				if (annotation.operation) {					
					const newBoundingBox = getBoundingBox(annotation, index);
					updatedAnnotation.operations.push({
						operationType: annotation.operation,
						issueType: annotation.issueType,
						issueTypeName: annotation.issueTypeName,
						label: annotation.name,
						currentBoundingBox: annotation.boundingBox || [],
						newBoundingBox,
						color: annotation.stroke,
						...annotation.ai && {isAi: annotation.ai},
						...annotation.ai && {confidence: annotation.confidence},
						id: annotation.key
					});
				}
			});
			if (updatedAnnotation.operations.length)
				listOfUpdatedAnnotations.push(updatedAnnotation);
		});
		
		if (listOfUpdatedAnnotations.length) {
			setLoading(true);
			const response = await AIService.saveImageMetadata(listOfUpdatedAnnotations);
			setLoading(false);
			if (!response?.data?.results) {
				showSnackbarMessage('An error occurred updating the annotations.', 'warning');
			} else {
				let atLeastOneSuccess = false;
				let atLeastOneError = false;
				const failedIds = [];
				const okIds = [];
				Object.keys(response.data.results).map(imageId => {
					response.data.results[imageId].map((resultAnnotation, indexAnnotation) => {
						const imageIndex = listOfUpdatedAnnotations.findIndex(annotationSent => annotationSent.imageId === imageId);
						const annotationId = listOfUpdatedAnnotations[imageIndex].operations[indexAnnotation].id;
						if (resultAnnotation.success) {
							atLeastOneSuccess = true;
							okIds.push(annotationId);							
						} else {
							atLeastOneError = true;
							failedIds.push(annotationId);
						}
					});
				});

				if (atLeastOneError) {
					if (atLeastOneSuccess) {
						showSnackbarMessage('Some annotations were not saved, please try again.', 'warning');
					} else {
						showSnackbarMessage('An error occurred updating the annotations.', 'warning');
					}
				} else if (atLeastOneSuccess) {
					showSnackbarMessage('Annotations saved successfully.', 'success');
				}

				// Avoid re-sending objects to update, show errors in case there is one				
				if (failedIds.length || okIds.length) {
					const allAnnotations = [...annotations, ...newAnnotation];
					failedIds.map(failedId => {
						const failedAnnotationIndex = allAnnotations.findIndex(annotation => annotation.key === failedId);
						allAnnotations[failedAnnotationIndex] = {...allAnnotations[failedAnnotationIndex], error: true};						
					});
					okIds.map(okId => {
						const okAnnotationIndex = allAnnotations.findIndex(annotation => annotation.key === okId);
						if (!allAnnotations[okAnnotationIndex].boundingBox) { // If creation, add the newBoundingBox, just for deleting on this same session
							const newAnnotation = allAnnotations[okAnnotationIndex];
							const imageIndex = dimensionAndPositions.findIndex(dimensionAndPosition => dimensionAndPosition.imageId === newAnnotation.imageId);
							const boundingBox = getBoundingBox(newAnnotation, imageIndex);
							allAnnotations[okAnnotationIndex] = {...allAnnotations[okAnnotationIndex], boundingBox};
						}
						allAnnotations[okAnnotationIndex] = {...allAnnotations[okAnnotationIndex], operation: false };
					});
					setAnnotations([...allAnnotations]);
				}
			}
		}
	};

	const getImagesAnnotations = () => {
		const groupedValues = {};
		const allAnnotations = [...annotations, ...newAnnotation];
		allAnnotations.map(annotation => {				
			if (groupedValues[annotation.imageId] === undefined)
				groupedValues[annotation.imageId] = [];				
			groupedValues[annotation.imageId].push(annotation);				
		});		
		return groupedValues;
	}

	// If at least there is one item to show on the current group
	const showAnnotationsGroup = issueTypeName => listOfAnnotationsByGroup[issueTypeName].some(item => !item.isDeleted);

	return <div className={classes.annotationListContainer} onClick={() => {
		selectShape(null)
	}}>
		<div className={classes.annotationsListWrapper}>
			<div className={classes.annotationsList}>
				{listOfAnnotationsByGroup && Object.keys(listOfAnnotationsByGroup).map(issueTypeName => showAnnotationsGroup(issueTypeName) && 
				<div key={issueTypeName}>
					<h2>{issueTypeName || issueTypeName !== '' ? issueTypeName : 'Unnamed'}</h2>
					{listOfAnnotationsByGroup[issueTypeName].map(annotation => Object.keys(annotation).length && !annotation.isDeleted && 
					<div
						key={annotation.key}
						onClick={(e) => {
							e.stopPropagation();
							!annotation.ai && selectShape(annotation.key);
						}}
						className={classes.annotation}
						style={{ ...annotationToDelete === annotation.key || annotation.error ? {color: 'red'} : {color: '#fff'}, ...annotation.key === selectedId && annotationSelectedStyles }}
					>
						<div className={classes.annotationIcons}>
							<DeleteIcon
								onClick={e => {
									e.stopPropagation();
									selectShape(null);
									setAnnotationToDelete(annotation.key)
								}} 
								className={classes.icons}
							/>				
							{annotation.visible 
								? <VisibilityIcon
									onClick={e => {
										editShapeDetails({visible: false}, annotation.key);
										e.stopPropagation();
									}}
									className={classes.icons}
								/>
								: <VisibilityOffIcon
									onClick={e => {
										e.stopPropagation();
										editShapeDetails({visible: true}, annotation.key);
									}}
									className={classes.icons}
								/>}
						</div>              
						{annotation.key === selectedId 
							? <div className={classes.inputText}>
								<TextFieldComponent
									value={annotation.name}
									label="Name"
									placeholder=""
									onChange={(e) => editShapeDetails({name: e}, annotation.key)}
									style={{ border: '2px solid #fff', color: '#fff', marginBottom: 10 }}
								/>
							</div>
							: <h3 style={{ borderLeft: `10px solid ${annotation.stroke}` }}><span style={{ marginLeft: 5 }}>{annotation.name === '' ? 'Unnamed' : annotation.name}</span></h3>}
						<div style={{ marginBottom: 10 }}>
							{annotation.ai 
							? `Confidence: ${annotation.confidence}`
							: annotation.key === selectedId 
							? <Autocomplete
								className={classes.autocomplete}
								options={issueTypesOptions.map(issueType => issueType && `${issueType.name}: ${issueType.description}`)}
								onChange={(e, value) => {
									const valueSplit = value.split(':');
									editShapeDetails({issueTypeName: valueSplit[0], issueType: valueSplit[1]}, annotation.key);
								}}
								value={annotation.issueTypeName && `${annotation.issueTypeName}: ${annotation.issueType}`}
								variant="outlined"
								PaperComponent={CustomPaper}
								required={false}
								getOptionSelected={(option, value) => option.indexOf(value) > -1}
								renderInput={params =>
									<TextFieldComponent          
										{...params}  
										value={annotation.issueType || ''}
										label="Issue Type"
										placeholder=""
										onChange={() => {}}
										style={{ color: '#fff' }}
									/>
								}
							/>
							: annotation.issueType ? <p style={{ display: 'flex', alignItems: 'center' }}><WarningTwoToneIcon style={{ marginRight: 5 }} />{annotation.issueType }</p> : ''}
						</div>
						{annotation.key === selectedId 
							&& <input
								type="color"
								value={annotation.stroke}
								onChange={e => editShapeDetails({stroke: e.target.value}, annotation.key)}
							/>
						}
						<AlertDialogSlide
							openDialog={!!annotationToDelete}
							handleConfirm={() => deleteAnnotation(annotationToDelete)}
							title="Delete annotation"
							content="Are you sure you want to delete this Annotation?"      
							handleClickClose={() => {
								setAnnotationToDelete(false);
								selectShape(null);
							}}
						/>
					</div>
					)}					
				</div>
				)}
				<Button
					id="saveAnnotations"
					variant="outlined"
					onClick={() => saveAnnotations()}         
					label="Save"
					color="primary"
					disabled={loading}
					styles={{
						position: 'absolute',
						bottom: 10,
						left: 10,
						width: 'calc(100% - 20px)',
						backgroundColor: '#007749',
						padding: 10,
						color: '#fff',
						cursor: 'pointer'
					}}
				/>
			</div>
		</div>
	</div>;
};