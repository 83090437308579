import { msalInstance } from './Components/App/App';
import { azureConfig } from './Constants';

class TokenService {
	async getToken() {
		const account = msalInstance.getActiveAccount() ?? msalInstance.getAllAccounts()?.[0];

		if (!account || !Object.keys(account).length) {
			msalInstance.logoutRedirect();
		} else {
			msalInstance.setActiveAccount(account);
			
			const silentRequest = {
				scopes: azureConfig.StorageScopes,
				account: account,
			};

			const response = await msalInstance
				.acquireTokenSilent(silentRequest)
				.catch(async (error) => {
					if (error.name === 'InteractionRequiredAuthError') {
						return await msalInstance
							.acquireTokenRedirect(silentRequest)
							.catch((error) => {
								console.log(error);
								msalInstance.logoutRedirect();
							});
					}
				});

			return this.tokenCredential(response.accessToken);
		}
	}

	tokenCredential = accessToken => ({
		getToken: () => ({
			token: accessToken,
			expiresOnTimestamp: Date.now() + 60 * 60 * 1000,
		}),
	});

}

export default new TokenService();
