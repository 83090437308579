import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { INSPECTION_TYPE_DAMAGE } from "../../Constants/";
import { formatDate, getToday, isArrayNotEmpty, isDateInRange, showSnackbar } from "../../Utils";
import Table from "../Shared/Table";
import { MaterialUITable } from "../Shared/MaterialUITable";
import { drop, first } from "lodash";
import { getLookups, isTextInclude, redirectToDetailPage } from "../../Utils/table";
import DateTimePickerFromToFilter from "../Shared/Table/DateTimePickerFromToFilter";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";

const issueTypesColumns = [
  { field: "name", title: "Issue Type" },
  { field: "description", title: "Issue Description" },
];

const lookupKeys = ["inspectionStatus", "stateName"];
let lookups = {
  inspectionStatus: {},
  stateName:{},
  issueType:{},
  issueDescription:{}
};
let issueLookups = {
  name:{},
  description:{}
};

const WorkQueueReport = ({ workQueues }) => {
  lookups = getLookups(workQueues, lookupKeys); 
  const { enqueueSnackbar } = useSnackbar();

  const getIssueTypesLookups = () => {
    let lookupName = {};
    let lookupDescription = {};
    workQueues.forEach((workQueue) => {
      if (workQueue.issueTypes) {
        const newLookups = getLookups(workQueue.issueTypes, ["name", "description"]);
        lookupName = { ...lookupName, ...newLookups.name };
        lookupDescription = { ...lookupDescription, ...newLookups.description };
      }
    });
    issueLookups.name = lookupName;
    issueLookups.description = lookupDescription;
  };

  getIssueTypesLookups();

  const issueTypeFilter = (filter, rowData, key) => {    
    if (typeof filter === "string") {
      return rowData.issueTypes && isTextInclude(filter, first(rowData.issueTypes)[key]);
    } else {
      if (isArrayNotEmpty(filter)) {
        let flag = false;
        filter.forEach((value) => {
          if (isArrayNotEmpty(rowData.issueTypes)) {
            rowData.issueTypes.forEach((issue) => {
              if (value === issue[key]) flag = true;
            });
          } else {
            flag = false
          }
        });
        return flag;
      } else {
        return true;
      }
    }
  };

  const issueTypeDescriptionRender = (rowData, type) => {
    if (rowData && rowData.issueTypes) {
      const moreThanOne = rowData?.issueTypes?.length > 1
      const issueToShow = first(rowData.issueTypes)[type]
      if (moreThanOne) {
        const listIssueTypes = rowData.issueTypes.map( issueType => <div key={rowData.inspectionId + issueType[type]}>{issueType[type]}</div>)
        return <Tooltip
          title={<>{listIssueTypes}</>}
        >
          <span data-testid={`issuetype_${type}_${rowData.inspectionId}`}>{`${issueToShow}, ...`}</span>
        </Tooltip>
      } else {
        return <span data-testid={`issuetype_${type}_${rowData.inspectionId}`}>{issueToShow}</span>
      }
    }
  }
  
  const columns = [
    { title: "Name", field: "workQueueName" },
    { title: "Status", field: "inspectionStatus", lookup: lookups.inspectionStatus },
    {
      title: "Work Item Id",
      field: "workItemId",
      headerStyle: { textAlign: "left" },
      cellStyle: { textAlign: "left" },
    },
    {
      title: "Issue Type",
      field: "issueTypesName",
      lookup: issueLookups.name,
      render: rowData => issueTypeDescriptionRender(rowData, "name"),
      customFilterAndSearch: (filter, rowData) => issueTypeFilter(filter, rowData, "name"),
      customSort: (a, b) => {
        return first(a.issueTypes).name > first(b.issueTypes).name ? 1 :-1
      }
    },
    {
      title: "Issue Description",
      field: "issueTypesDescription",
      lookup: issueLookups.description,
      render: rowData => issueTypeDescriptionRender(rowData, "description"),
      customFilterAndSearch: (filter, rowData) => issueTypeFilter(filter, rowData, "description"),
      customSort: (a, b) => {
        return first(a.issueTypes).description > first(b.issueTypes).description ? 1 :-1
      }
    },
    { title: "Pole Number", field: "poleNumber", lookup: lookups.poleNumber },
    { title: "State", field: "stateName", lookup: lookups.stateName },
    { title: "Town", field: "townName" },
    { title: "Created By", field: "createdBy" },
    {
      title: "Created On",
      field: "dateCreated",
      render: (rowData) => formatDate(rowData.dateCreated),
      type: "datetime",
      customFilterAndSearch: (filter, rowData) => isDateInRange(filter, rowData.dateCreated),
      filterComponent: (props) => {
        const { columnDef, onFilterChanged } = props 
        return <DateTimePickerFromToFilter
          key={columnDef.tableData.id}
          onFilterChanged={(e,a) => onFilterChanged(e,a)} 
          columnDef={columnDef} 
          locale={props.dateTimePickerLocalization}
        />
      }
    },
  ];

  const onRowClick = ({ inspectionId, inspectionType, partitionKey }) => {
    redirectToDetailPage(inspectionId, inspectionType, partitionKey);
  };
  
  const exportCsv = (allColumns, allData) => {
    try {
      if (allData.length > 0) {
        const csvRows = []
        // get headers
        const keys = ["workQueueName", "workItemId", "areaWorkCenter", "stateName", "townName", "address", "circuitId", "inspectionStatus", "issueTypes", "issueDescription", "assetType","assetId", "poleNumber","notes", "comments","createdBy", "dateCreated", "updatedBy","dateUpdated"];
        const headers = ["Work Queue Name", "Work Item ID", "Area Work Center", "State", "Town", "Address", "Circuit", "Inspection Status", "Issue Type", "Issue Description", "Asset Type", "Asset ID", "Pole Number", "Additional Inspection details", "Comments", "Created By", "Created Date", "Updated By", "Updated Date"]
        csvRows.push(headers.join(','))
        for (const row of allData) {
          const values = keys.map(key => {
            let value 
            if (key === "dateCreated" || key === "dateUpdated") {
              value = formatDate(row[key])
            } else if (key === "assetType" && row.inspectionType === INSPECTION_TYPE_DAMAGE) {
              value = row.assets.map(asset => asset.type).join(", ")              
            } else if (key === "assetId" && row.inspectionType === INSPECTION_TYPE_DAMAGE) {
              value = row.assets.map(asset => asset.id).join(", ")              
            } else if (key === "issueTypes" && row.inspectionType === INSPECTION_TYPE_DAMAGE) {
              value = row.issueTypes.map(issueType => issueType.name).join(", ")              
            } else if (key === "issueDescription" && row.inspectionType === INSPECTION_TYPE_DAMAGE) {
              value = row.issueTypes.map(issueType => issueType.description).join(", ")              
            } else {
              value = row[key]
            }
            let escaped = ('' + value).replace(/"/g, "'").replace(/'/g, "'")
            return escaped === "undefined" ? `""` :`"${escaped}"`
          })
          
          csvRows.push(values.join(','))
        }
      
        const csvRowsString = csvRows.join('\n')
        const blob = new Blob([csvRowsString], {type: "text/csv"}) 
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.setAttribute("href", url)
        a.setAttribute("download", 'Work Queues ' + getToday('YYYY-MM-DDTHHmmss') + '.csv')
        document.body.appendChild(a)
        a.click()
      } else {
        showSnackbarMessage("The page contains no data to export", "warning")
      }
    } catch (err) {
      showSnackbarMessage("An error occurred exporting the data", "error")
    }
  }

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
	}
  
  return (
    <Table
      style={{ width: "95%" }}
      columns={columns}
      data={workQueues}
      title=""
      options={{
        search: true,
        filtering: true,
        pageSize: 10,
        showSelectAllCheckbox: false,
        selection: false,
        headerStyle: {
          color: "#007749",
          fontWeight: "bold",
        },
        exportButton: true,
        exportCsv,
        exportAllData: true,
        pageSizeOptions: [5, 10, 25],
      }}
      onRowClick= {onRowClick}
      detailPanel={[
        {
          tooltip: "Open",
          render: (rowData) => {           
            return (
              rowData?.issueTypes?.length > 1 && <Grid container alignContent="center">
                <Grid item xs={3}>
                  <MaterialUITable
                    data={drop(rowData.issueTypes)}
                    columns={issueTypesColumns}
                    isSubTable={true}
                  />
                </Grid>                
              </Grid>
            );
          },
        },
      ]}
    />
  );
};

export default WorkQueueReport;
