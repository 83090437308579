import React from "react";
import  {Polyline} from "@react-google-maps/api";

const options = {
  strokeColor: "#FF0000",
  strokeWeight: 2,
  strokeOpacity: 0.8,
  fillColor: "#FF0000",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};

const GooglePolyline = ({ line=[]}) => {
  return <Polyline path={line} options={options}/>;
};

export default GooglePolyline;