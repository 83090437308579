import React from "react";
import { Container } from "@material-ui/core";
import WorkQueueForm from "../../WorkQueue/Form"

const WorkQueueFormRoute = () => {
  const containerStyles = {
    height: "100%",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    position: "relative",
    padding: 0,
    alignItems: "center"
  }

  return (
    <Container component="div" maxWidth={false} style={containerStyles}>
      <WorkQueueForm edit="0" />
    </Container>
  );
};

export default WorkQueueFormRoute;
