import React from "react";
import InspectionDetailForm from "../../InspectionDetail";
import { useParams } from "react-router";

const InspectionDetail = ({ userRoles }) => {
  let { id, partitionKey, inspectionType } = useParams();
  return <InspectionDetailForm inpectionId={id} partitionKey={partitionKey} inspectionType={inspectionType} userRoles= {userRoles} />
};

export default InspectionDetail;
