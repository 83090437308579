export const GET_IMAGE = 'GET_IMAGE';
export const SET_IMAGE = 'SET_IMAGE';
export const RESET_IMAGE = 'RESET_IMAGE';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const SET_BLOB_LIST = 'SET_BLOB_LIST';
export const SET_DAMAGE_TYPES = 'SET_DAMAGE_TYPES';
export const SET_ISSUE_TYPES = 'SET_ISSUE_TYPES';
export const SET_STATUS = 'SET_STATUS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SORTING = 'SET_SORTING';
export const SET_MAP = 'SET_MAP';
export const SET_INSPECTIONS_PER_PAGE = 'SET_INSPECTIONS_PER_PAGE';
export const SET_PROGRESS_BAR = 'SET_PROGRESS_BAR';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_BACKGROUND_SYNC = 'SET_BACKGROUND_SYNC';
export const SET_IDB_INSPECTION_INSTANCE = 'SET_IDB_INSPECTION_INSTANCE';
export const SET_IDB_IMAGE_INSTANCE = 'SET_IDB_IMAGE_INSTANCE';
export const SET_USERS_ACCEPTED_ACKNOWLEDGEMENT = 'SET_USERS_ACCEPTED_ACKNOWLEDGEMENT';
export const SET_IMAGE_TO_DELETE = 'SET_IMAGE_TO_DELETE';
export const SET_PENDING_IMAGES = 'SET_PENDING_IMAGES';
