import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const ProgressBar = ({ styles = {} }) => {
  const useStyles = makeStyles((theme) => ({ 
    loading: {
      minHeight: 10,
      width: "100%",
      borderTopLeftRadius: 5,
      backgroundColor: theme.palette.primary.dark,
      ...styles
    },
  }))  

  const classes = useStyles();
  return <LinearProgress className={classes.loading} />
}

export default ProgressBar;
