import React, { useContext, useEffect, useState, useRef } from 'react';
// import { useHistory } from "react-router";
import moment from 'moment';
import { Badge } from '@mui/material';
import SyncTwoToneIcon from '@mui/icons-material/SyncTwoTone';
import ArticleIcon from '@mui/icons-material/Article';
import ImageIcon from '@mui/icons-material/Image';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import {
	IMAGE_STATUS_PENDING_TO_SYNC,
	IMAGE_STATUS_SYNCED,
	IMAGE_STATUS_SYNCING,
	dateHHMMAFormat,
	pendingUploadsItemsStatuses,
	// ID_OFFLINE,
	offlineImagesDbTag,
	offlineInspectionsDbTag,
} from '../../../../Constants';
import Button from '../../../Shared/atoms/Button';
import BasicPopover from '../../../Shared/atoms/Popover';
import { MaterialUITable } from '../../../Shared/MaterialUITable';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import ImageContext from '../../../../context/Image/ImageContext';
import InspectionsDataContext from '../../../../context/InspectionsData/InspectionsDataContext';
import ImageService from '../../../../services/ImageService';
import InspectionsService from '../../../../services/InspectionsService';
import { getStoreObjctOfIDB, showSnackbar, getToday } from '../../../../Utils';
import { isArray } from 'lodash';

let columns = [
	{ title: '', field: 'icon' },
	{ title: 'Date', field: 'dateCreated' },
	{ title: 'Status', field: 'status' },
	{ title: '', field: 'retry' },
];

const PendingUploads = ({ inspectionsOffline, ...props }) => {
	// const [inspectionWithIndexes, setInspectionWithIndexes] = useState([])
	const history = useHistory();
	const { setContextIDInstance } = useContext(InspectionsDataContext);
	const { setImageContextIDInstance, setPendingImages, getContextImages } =
		useContext(ImageContext);
	const [countInspectionsOnIndexDB, setCountInspectionsOnIndexDB] =
		useState(undefined);
	const [requests, setRequests] = useState({});
	const { enqueueSnackbar } = useSnackbar();
	const [popOverAnchorEL, setPopOverAnchorEL] = useState(null);
	const [pendingUploads, setPendingUploads] = useState([]);
	const [retryDisabled, setRetryDisabled] = useState(false);
	const containerRef = useRef();

	useEffect(() => {
		syncPendingUploads(inspectionsOffline);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionsOffline]);

	useEffect(() => {
		// When a retry button is clicked, update list state so the table updates and show the button disabled or enabled
		if (retryDisabled) {
			syncOfflineData(offlineInspectionsDbTag, false);
			columns = [...columns];
		}
	}, [retryDisabled]);

	useEffect(() => {
		openDBInstance(offlineInspectionsDbTag);
		openDBInstance(offlineImagesDbTag);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		history.listen(() => {
			syncOfflineData(offlineInspectionsDbTag);
			syncOfflineData(offlineImagesDbTag);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			countInspectionsOnIndexDB &&
			countInspectionsOnIndexDB == Object.keys(requests).length
		) {
			const countInspectionsCreated = Object.keys(requests).filter((key) => {
				return requests[key] === true;
			});
			if (countInspectionsCreated.length > 0) {
				if (countInspectionsCreated.length > 1) {
					showSnackbarMessage(
						`(${countInspectionsCreated.length}) Inspections created offline have been synced`,
						'success'
					);
				} else {
					showSnackbarMessage(
						'Inspection created offline has been synced',
						'success'
					);
				}

				// All pending inspections are created, empty list for popover
				if (countInspectionsCreated.length === countInspectionsOnIndexDB) {
					syncPendingUploads([]);
					setCountInspectionsOnIndexDB(undefined);
				} else {
					setCountInspectionsOnIndexDB(
						countInspectionsOnIndexDB - countInspectionsCreated.length
					);
				}
				setRequests({});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requests]);

	const retryUpload = async (itemToRetry, image = false) => {
		// Update image or inspection to "syncing" status, do not remove from list
		await updatedIndexDBList(itemToRetry.value, 2, false, image);
		if (!image) {
			// Inspection
			if (itemToRetry.value.id) {
				await updatePendingImagesForInspection(itemToRetry);
			} else {
				// Images and inspection are not uploaded yet
				// Save only the inspection
				await saveInspection(
					itemToRetry.value,
					itemToRetry.key,
					false,
					'Inspection created successfully'
				);
			}
		} else {
			// Image
			sendImagesToServer([image])
				.then((newImage) => {
					const inspectionToUpdate = itemToRetry.value;
					let allImagesSynced = true;
					const updatedImages = [];
					// Replace on the inspection images the new value
					if (newImage && newImage[0] && newImage[0].name) {
						inspectionToUpdate.images.forEach((outdatedImage) => {
							let inspectionImage = outdatedImage;
							if (outdatedImage.name === newImage[0].name) {
								inspectionImage = newImage[0];
							} else if (
								outdatedImage.status !== 'Synced' &&
								outdatedImage.status !== 4
							) {
								// Check if the rest of images are already uploaded
								allImagesSynced = false;
							}
							updatedImages.push(inspectionImage);
						});
						inspectionToUpdate.images = updatedImages;
					}
					if (inspectionToUpdate.id) {
						inspectionToUpdate.dateUpdated = getToday();
					}
					if (allImagesSynced) {
						// if all images are synced, update inspection with the updated values and remove from list
						delete inspectionToUpdate.pendingImages;
						saveInspection(
							inspectionToUpdate,
							itemToRetry.key,
							true,
							'Inspection created successfully'
						);
					} else {
						saveInspection(inspectionToUpdate, itemToRetry.key, false);
						// Update indexDB with updated image status
						updatedIndexDBList(inspectionToUpdate, 4, false, image);
					}
				})
				.catch(() => {
					updatedIndexDBList(itemToRetry.value, 3, false, image);
					showSnackbarMessage(
						'An error has occurred creating the image',
						'error'
					);
				});
		}
	};

	const syncOfflineData = (tag, sync = true) => {
		try {
			openDBInstance(tag, (iDBInstance) => {
				const storeObj = getStoreObjctOfIDB(iDBInstance, tag);
				if (storeObj) {
					var cursorRequest = storeObj.openCursor();
					const _inspectionsOffLine = [];
					cursorRequest.onsuccess = async function (evt) {
						var cursor = evt.target.result;
						if (cursor) {
							if (countInspectionsOnIndexDB === undefined) {
								var getAllInspections = storeObj.getAll();
								getAllInspections.onsuccess = function (evt) {
									const inspections = evt.target.result;
									setCountInspectionsOnIndexDB(inspections.length);
								};
							}
							if (cursor.value) {
								if (navigator.onLine) {
									if (tag === offlineImagesDbTag) {
										if (cursor.value.delete && sync) {
											deleteImages(cursor.value, cursor.key);
										}
									} else {
										const inspectionDB = cursor.value;
										inspectionDB.status = 2; // Syncing
										_inspectionsOffLine.push({
											value: inspectionDB,
											key: cursor.key,
										});
										syncPendingUploads([..._inspectionsOffLine]);
										/* 
                      Inspection created successfully but one or more images are 
                      pending to sync and already uploading or uploaded
                    */
										if (sync) {
											if (cursor.value.id) {
												updatePendingImagesForInspection(cursor);
											} else {
												// Images and inspection are not uploaded yet
												await createInspection(cursor.value, cursor.key);
											}
										}
									}
								} else if (tag === offlineInspectionsDbTag) {
									// if offline and inspection DB
									_inspectionsOffLine.push({
										value: cursor.value,
										key: cursor.key,
									});
									syncPendingUploads([..._inspectionsOffLine]);
								}
								cursor.continue();
							}
						}
					};
				}
			});
			// eslint-disable-next-line no-empty
		} catch (error) {}
	};

	const getImagesSent = (images) =>
		images ? images.filter((image) => image?.name !== '' && image?.file) : [];

	const updatePendingImagesForInspection = (cursor) => {
		const inspectionToUpdate = cursor.value;
		let stillPendingImagesForThisInspection = false;
		let imagesSyncing = false;
		const imagesNotUploaded = [];
		const imagesUploaded = [];

		// check if all images are already synced
		inspectionToUpdate.images.forEach((inspectionImage) => {
			if (inspectionImage.status === pendingUploadsItemsStatuses[2]) {
				imagesSyncing = true;
			} else {
				// Not completed
				if (
					inspectionImage.status !== IMAGE_STATUS_SYNCED &&
					inspectionImage.status !== 4
				) {
					stillPendingImagesForThisInspection = true;
					imagesNotUploaded.push(inspectionImage);
				} else {
					imagesUploaded.push(inspectionImage);
				}
			}
		});

		inspectionToUpdate.dateUpdated = getToday();
		if (!imagesSyncing) {
			if (stillPendingImagesForThisInspection) {
				// upload the images
				sendImagesToServer(imagesNotUploaded).then((newImages) => {
					const combinedImages = [...inspectionToUpdate.images, ...newImages];
					// Remove from array the previous image object now updated on newImages
					inspectionToUpdate.images = combinedImages.filter(
						(image) => !image?.coordinates
					);
					// Delete from the context list the pending images now synced
					const imageState = getContextImages();
					// now are updated and exclude them form the array
					const updatedPendingImages = imageState.pendingImages.filter(
						({ name }) =>
							!inspectionToUpdate.images.some(
								(imageUploaded) => imageUploaded.name === name
							)
					);
					setPendingImages(updatedPendingImages);
					saveInspection(
						inspectionToUpdate,
						cursor.key,
						true,
						'Inspection created successfully'
					);
				});
			} else {
				saveInspection(
					inspectionToUpdate,
					cursor.key,
					true,
					'Inspection created successfully'
				);
			}
		}
	};

	const createPromiseImages = (images) => {
		let promises = [];
		for (const image of images) {
			promises.push(
				ImageService.createInspectionImage(image.file, image.coordinates)
			);
		}
		return promises;
	};

	const sendImagesToServer = (images) => {
		let names = [];
		let _images = images.filter((image) => image.name !== '');
		return new Promise((resolve, reject) => {
			if (_images.length > 0) {
				const promises = createPromiseImages(_images);
				Promise.all(promises)
					.then(async (resp) => {
						if (resp) {
							// No images uploaded
							if (isArray(resp[0]) && !resp[0].length) {
								reject();
							}

							resp.forEach((result, index) => {
								if (result && result.data[0] && result.data[0].name) {
									names.push({
										name: result.data[0].name,
										status: result.data[0].status,
									});
								} else {
									// If failed result is empty array, push the corresponding image with status failed
									names.push(..._images[index], ...{ status: 3 });
								}
							});
							resolve(names);
						} else {
							resolve(names);
						}
					})
					.catch(() => {
						resolve(names);
					});
			} else {
				resolve(names);
			}
		});
	};

	const createInspection = (inspection, key) => {
		let originalImagesNotBlank = getImagesSent(inspection.images);
		sendImagesToServer(originalImagesNotBlank, inspection)
			.then((newImages) => {
				const combinedImages = [...inspection.images, ...newImages];

				// Remove from array the previous image object now updated on newImages
				inspection.images = combinedImages.filter(
					(image) => !image?.coordinates
				);
				if (inspection.images && inspection.images[0] === undefined)
					inspection.images = (inspection.images && inspection.images[0]) || [
						{ name: '' },
					];
				saveInspection(inspection, key, true);
			})
			.catch(() => {
				// Since inspection and image are not created, update the status of each image to error too
				const updatedImagesError = inspection.images.map((image) => ({
					...image,
					...{ status: 3 },
				}));
				inspection.images = updatedImagesError;
				updatedIndexDBList(inspection, 3, false);
				showSnackbarMessage(
					'An error has occurred creating the inspection',
					'error'
				);
			});
	};

	const saveInspection = (
		inspection,
		key,
		deleteAfterSuccess,
		showSuccessMessage = false
	) => {
		let _deleteAfterSuccess = deleteAfterSuccess;
		InspectionsService.save(inspection)
			.then(async (response) => {
				if (response.data) {
					setRequests((requests) => {
						requests[response.data.dateCreated] = true;
						return { ...requests };
					});

					(await _deleteAfterSuccess) &&
						deleteDataFromBrowserDB(key, offlineInspectionsDbTag);

					let inspectionUpdated = response.data;
					// if not delete after finish, it means its a retry for inspection alone or an image retry, use the previous failed or not images
					if (!_deleteAfterSuccess) {
						inspectionUpdated.images = inspection.images;
						if (
							inspectionUpdated.images.length === 1 &&
							inspectionUpdated.images[0] &&
							inspectionUpdated.images[0].name === ''
						) {
							// No images on inspection, delete after update on indexeddb
							_deleteAfterSuccess = true;
						}
					}
					updatedIndexDBList(inspectionUpdated, 4, _deleteAfterSuccess);
					if (showSuccessMessage) {
						showSnackbarMessage(showSuccessMessage, 'success');
					}
				} else {
					// Update indexDB and pending upload list with updated error status (3), don't remove from list
					updatedIndexDBList(inspection, 3, false);
					showSnackbarMessage(
						'An error has occurred creating the inspection',
						'error'
					);
					setRequests((requests) => {
						requests[inspection.dateCreated] = false;
						return { ...requests };
					});
				}
			})
			.catch(() => {
				showSnackbarMessage(
					'An error has occurred creating the inspection',
					'error'
				);
			});
	};

	const deleteImages = (image, index) => {
		if (image) {
			ImageService.deleteImage(image.name)
				.then((response) => {
					if (response) {
						deleteDataFromBrowserDB(index, offlineImagesDbTag);
					}
				})
				.catch(() => {
					showSnackbarMessage(
						'An error has occurred deleting the images',
						'error'
					);
				});
		}
	};

	const updatedIndexDBList = (
		inspection,
		updateStatus = false,
		removeInspectionFromList = false,
		imageToUpdate = false
	) => {
		try {
			openDBInstance(offlineInspectionsDbTag, (iDBInstance) => {
				const storeObj = getStoreObjctOfIDB(
					iDBInstance,
					offlineInspectionsDbTag
				);
				if (storeObj) {
					var cursorRequest = storeObj.openCursor();
					const inspectionsOffline = [];
					cursorRequest.onsuccess = async function (evt) {
						var cursor = evt.target.result;
						if (cursor) {
							if (cursor?.value) {
								let inspectionOffline = cursor.value;
								const inspectionFound =
									inspectionOffline.dateCreated === inspection.dateCreated;
								if (inspectionFound && updateStatus) {
									// If image sent, update status of the image and not of the inspection
									if (imageToUpdate) {
										// updated status on image
										const imagesStatusUpdated = inspectionOffline.images.map(
											(image) => {
												if (image.name === imageToUpdate.name) {
													return {
														...imageToUpdate,
														...{ status: updateStatus },
													};
												}
												return image;
											}
										);
										inspectionOffline.images = imagesStatusUpdated;
									} else {
										// In case inspection is not created and images failed, bring updated status for images
										inspectionOffline = inspection;
										inspectionOffline.status = updateStatus;
									}
									// Update current record on indexDB
									cursor.update(inspectionOffline);
								}

								// If removeInspectionFromList = false, do not insert into array the inspection updated from param
								if (
									!removeInspectionFromList ||
									(removeInspectionFromList && !inspectionFound)
								) {
									inspectionsOffline.push({
										value: inspectionOffline,
										key: cursor.key,
									});
								}
							}
							cursor.continue();
						} else {
							// No more values on indexDB, push list with updated status to pendingUploads
							syncPendingUploads(inspectionsOffline);
						}
					};
				}
			});
			// eslint-disable-next-line no-empty
		} catch (error) {}
	};

	// delete data from indexedb, that sent to server
	const deleteDataFromBrowserDB = (index, tag) => {
		openDBInstance(tag, (iDBInstance) => {
			const storeObj = getStoreObjctOfIDB(iDBInstance, tag);
			storeObj.delete(index);
		});
	};

	// open database
	const openDBInstance = (tag, onsuccess = null) => {
		var indexedDBOpenRequest = window.indexedDB
			? window.indexedDB.open(tag, 1)
			: null;
		if (indexedDBOpenRequest) {
			indexedDBOpenRequest.onupgradeneeded = function () {
				this.result.createObjectStore(`${tag}_requests`, {
					autoIncrement: true,
				});
			};
			indexedDBOpenRequest.onsuccess = function () {
				if (typeof onsuccess == 'function') onsuccess(indexedDBOpenRequest);
			};
		}
		setInstanceToState(tag, indexedDBOpenRequest);
	};

	const setInstanceToState = (tag, iDBInstance) => {
		switch (tag) {
			case offlineInspectionsDbTag:
				setContextIDInstance(iDBInstance);
				break;
			case offlineImagesDbTag:
				setImageContextIDInstance(iDBInstance);
				break;

			default:
				break;
		}
	};

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	const syncPendingUploads = (_pendingUploads) => {
		let items = [];
		// const _inspectionIndexes = []
		// let currentIndex = 0
		_pendingUploads.map((inspectionAndKey) => {
			items.push(
				pendingUploadItem(
					true,
					inspectionAndKey.value?.id ? 4 : inspectionAndKey.value?.status,
					inspectionAndKey,
					null
				)
			);
			// Store with the same index as the list in the modal
			// _inspectionIndexes[currentIndex] = inspection
			inspectionAndKey.value?.images?.map((image) => {
				image = image ?? { name: '' };
				if (image?.name && image?.name !== '') {
					// currentIndex++
					if (image.file) {
						items.push(
							pendingUploadItem(
								false,
								image.status || 1,
								inspectionAndKey,
								image
							)
						);
					} else {
						// image syncing or pending to sync
						let status;
						if (!isNaN(image.status)) {
							status = image.status;
						} else if (image.status === IMAGE_STATUS_PENDING_TO_SYNC) {
							status = 1;
						} else if (image.status === IMAGE_STATUS_SYNCED) {
							status = 4;
						} else if (image.status === IMAGE_STATUS_SYNCING) {
							status = 2;
						}
						if (status !== 4)
							items.push(
								pendingUploadItem(false, status, inspectionAndKey, image)
							);
					}
				}
			});
			// currentIndex++
		});
		// setInspectionWithIndexes(_inspectionIndexes)
		setPendingUploads([...items]);
	};

	const pendingUploadItem = (
		isInspection,
		status,
		inspectionAndKey,
		image = null
	) => ({
		dateCreated: moment(new Date(inspectionAndKey.value?.dateCreated)).format(
			dateHHMMAFormat
		),
		icon: isInspection ? <ArticleIcon /> : <ImageIcon />,
		status: pendingUploadsItemsStatuses[status],
		retry:
			status === 3 || status === 2 ? (
				<Button
					disabled={retryDisabled || status === 2}
					variant="outlined"
					color="primary"
					icon={<SyncTwoToneIcon />}
					onClick={async () => {
						if (navigator.onLine) {
							setRetryDisabled(true);
							retryUpload(inspectionAndKey, !isInspection ? image : false);
							setTimeout(() => setRetryDisabled(false), 4000);
						}
					}}
					label="Retry"
					styles={{ margin: '0 1rem 0 0' }}
				/>
			) : (
				''
			),
	});

	// Onclick functionality to see detail of non created inspections
	/*const onClickRow = index => {
    const offlineInspection = inspectionWithIndexes[index]
    history.push({
      pathname: `/Inspection/${ID_OFFLINE}/${offlineInspection.inspectionType}/_`,
      offlineInspection
    });
  }
  */
	return (
		<div ref={containerRef}>
			{pendingUploads.length > 0 ? (
				<>
					<Badge
						badgeContent={
							pendingUploads.filter(
								(upload) => upload.status !== pendingUploadsItemsStatuses[4]
							).length
						}
						color="error"
					>
						<CloudSyncIcon
							onClick={() => setPopOverAnchorEL(containerRef.current)}
						/>
					</Badge>
					<BasicPopover
						anchorEl={popOverAnchorEL}
						title={props.title}
						handleClose={() => setPopOverAnchorEL(null)}
					>
						<MaterialUITable
							columns={columns}
							data={pendingUploads} /*onClickRow={index => onClickRow(index)}*/
						/>
					</BasicPopover>
				</>
			) : (
				<CloudDoneIcon />
			)}
		</div>
	);
};

export default PendingUploads;
