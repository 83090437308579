import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import AssignmentTwoToneIcon from "@material-ui/icons/AssignmentTwoTone";
import PageHeader from "../../Shared/PageHeader";
import ProgressBar from "../../Shared/ProgressBar";
import WorkQueueService from "../../../services/WorkQueueService";
import WorkQueueReport from "../../WorkQueueReport";
import { showSnackbar } from "../../../Utils";
import { INSPECTION_TYPE_DAMAGE } from "../../../Constants";

const WorkQueueReportPage = () => {
  const [workQueues, setWorkQueues] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    WorkQueueService.getReport(INSPECTION_TYPE_DAMAGE).then((result) => {        
      setLoading(false);
      setWorkQueues(result.data  || []);
    }).catch(() => {
      showSnackbar(enqueueSnackbar, "An error occurred getting the work queues", "error");
    });  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <ProgressBar />}
      <PageHeader
        headerText=""
        icon={<AssignmentTwoToneIcon color="secondary" />}
      />
      {!loading && workQueues.length > 0 && (
        <WorkQueueReport workQueues={workQueues} />
      )}
    </>
  );
};

export default WorkQueueReportPage;
