import { callApi } from '../callApi';
import { RESOURCE_INSPECTIONS } from '../Constants';

class AcknowledgementService {
	getAcknowledgement() {
		return callApi({ method: 'get', url: `${RESOURCE_INSPECTIONS}acknowledgements/?OnlyCurrentVersion=true` });
	}
	getUserAcknowledgement() {
		return callApi({ method: 'get', url: `${RESOURCE_INSPECTIONS}users/acknowledgement/?OnlyCurrentVersion=true` });
	}
	updateUserAcknowledgement(acknowledgementData) {
		return callApi({ method: 'patch', data: acknowledgementData, url: `${RESOURCE_INSPECTIONS}users/acknowledgement` });
	}
}
export default new AcknowledgementService ();