import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core";
import TableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: theme.palette.secondary.main,
    fontWeight: "bold"
  }
}));

export const MaterialUITable = ({ columns = [], data = [], isSubTable= false }) => {
  const classes = useStyles();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {data.length > 0 &&
            columns.map((column, index) => <TableCell key={index} className={isSubTable && classes.tableCell} >{column.title}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length > 0 &&
          data.map((row, index) => (
            <TableRow key={index}>
                {columns.map((column, index) => <TableCell key={column+index}>{row[column.field]}</TableCell>)}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
