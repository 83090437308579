import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone';
import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import { dateHHMMAFormat } from "../../../Constants";

const useStyles = makeStyles(() => ({
  userInfoContainer: {
    display: "flex",
    alignItems: "center",
  },
  typography: {
    paddingLeft: "10px"
  },
  icons: {
    marginLeft: "-3px"
  }
}));

const UserInformation = ({ user, date, title }) => {
  const classes = useStyles();
  return (
    <>
      <Typography component="h2" variant="subtitle2" data-testid="title">
        {title}
      </Typography>
      {user !== "" && user ? (
        <div className={classes.userInfoContainer}>
          <PersonOutlineIcon className={classes.icons} color="primary" data-testid="userIcon" />
          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p" data-testid="username">
            {user}
          </Typography>
        </div>
      ) : null}
      {date !== "" && date ? (
        <div className={classes.userInfoContainer} >
          <EventTwoToneIcon className={classes.icons} color="primary" data-testid="dateIcon" />
          <Typography data-testid="date" className={classes.typography} variant="body2" color="textSecondary" component="p">
            {moment(new Date(date)).format(dateHHMMAFormat)}
          </Typography>
        </div>
      ) : null}
    </>
  );
};

export default UserInformation;
