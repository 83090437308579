import { Grid } from "@material-ui/core";
import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  damageSeverityRadios,
} from "../../../../../../Constants";
import { CheckBox } from "../../../../../Shared/atoms/Checkbox";
import TextFieldComponent from "../../../../../Shared/atoms/TextInput";
import { RadioGroup } from "../../../../../Shared/RadioGroup";
import LatticeImg from "../../../../../../assets/TransmissionInspection/lattice.png"
import PolesImg from "../../../../../../assets/TransmissionInspection/poles.png"

const useStyles = makeStyles(() => ({
  detailGroup: {
    margin: '0 0 0 31px'
  },
  poleGuide: {
    maxWidth: "100%"
  }
}))

export const SpecificsSteel = ({ onChangeInput, showDetailInput, form, poleType, isReadOnly, editing }) => {
  const classes = useStyles();
  return (    
        <>
          <Grid item xs={12}>
            <RadioGroup
              title="Leaning/Bent Steel"
              value={form.leaningOrBentSeverity}
              radios={damageSeverityRadios}
              onChange={(e) =>
                onChangeInput(e.target.value, "leaningOrBentSeverity")
              }
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              value={form.corrosionSeverity}
              title="Corrosion"
              radios={damageSeverityRadios}
              onChange={(e) =>
                onChangeInput(e.target.value, "corrosionSeverity")
              }
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              title="Rust"
              radios={damageSeverityRadios}
              value={form.rustSeverity}
              onChange={(e) =>
                onChangeInput(e.target.value, "rustSeverity")
              }
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              title="Structural Integrity"
              radios={damageSeverityRadios}
              value={form.structuralIntegrity}
              onChange={(e) =>
                onChangeInput(e.target.value, "structuralIntegrity")
              }
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
          {showDetailInput(["leaningOrBentSeverity", "corrosionSeverity", "rustSeverity", "structuralIntegrity"]) && 
          <Grid item xs={12}>
            <TextFieldComponent
              value={form.structureIssueDetails}
              label="Details of Structure Issues"
              fullWidth
              id="structureIssueDetails"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) =>
                onChangeInput(e, "structureIssueDetails")
              }
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>}
          <Grid item xs={12}>
            <CheckBox
              title="Visual Damage"
              checked={form.isVisualDamage}
              id="isVisualDamage"
              onChange={(event) =>
                onChangeInput(event.target.checked, "isVisualDamage")
              }
              disabled={isReadOnly && !editing}
            />
          </Grid>
          {form.isVisualDamage && <Grid container spacing={2} className={classes.detailGroup}>
            <Grid item xs={12}>
              <TextFieldComponent
                value={form.visualDamageDetails}
                label="Visual Damage Details"
                fullWidth
                id="visualDamageDetails"
                variant="outlined"
                type="text"
                placeholder=""
                onChange={(e) =>
                  onChangeInput(e, "visualDamageDetails")
                }
                required={true}
                disabled={isReadOnly && !editing}
              />
            </Grid>
          </Grid>}
          <Grid item xs={12}>
            <TextFieldComponent
              value={form.damagedPoleReferenceNumberDetails}
              label="Damaged Pole Reference Number"
              fullWidth
              id="damagedPoleReferenceNumberDetails"
              variant="outlined"
              type="text"
              placeholder=""
              onChange={(e) => onChangeInput(e, "damagedPoleReferenceNumberDetails")}
              required={true}
              disabled={isReadOnly && !editing}
            />
          </Grid>
          {(poleType === "H-Frame" || poleType === "3-Pole" || poleType === "Lattice") &&
          <Grid item xs={12}>
            <img
              src={ poleType === "H-Frame" || poleType === "3-Pole"
                ? PolesImg
                : LatticeImg}
              alt=""
              className={classes.poleGuide}
            />          
          </Grid>}
        </>        
  );
};
