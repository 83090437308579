import { Grid, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from '@material-ui/icons/Add';
import CircuitsTable from "./CircuitsTable";
import ManholesTable from "./ManholesTable";
import TransmissionsTable from "./TransmissionsTable";
import FormInputs from "./FormInputs";
import ProgressBar from "../../Shared/ProgressBar";
import Button from "../../Shared/atoms/Button";
import WorkQueueService from "../../../services/WorkQueueService";
import WorkItemsService from "../../../services/WorkItemsService";
import { FF_TRANSMISSION_INSPECTION_FORM, WORK_QUEUE_TYPE_CIRCUIT_SWEEP, WORK_QUEUE_TYPE_MANHOLE, WORK_QUEUE_TYPE_TRANSMISSION } from "../../../Constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSnackbar } from "notistack";
import { showSnackbar } from "../../../Utils";

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: "100%",
    width: "calc(100% - (" + theme.spacing(1) + "px * 2))",
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  required: {
    color: theme.palette.primary.required
  }
}));

const loadingStyles = {
  position: "sticky",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 99
}

const WorkQueueForm = ({ edit, name }) => {
  const classes = useStyles();
  const browserRedirectLocation = useLocation();
  const history = useHistory();
  const selectedRow = React.useRef([]);
  const [currentWorkQueue, setCurrentWorkQueue] = useState({circuitsEmpty: true});
  const [originalWorkQueue, setOriginalWorkQueue] = useState(false);
  const [workItemsEdit, setWorkItemsEdit] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [currentName, setCurrentName] = useState("");
  const [validNameFromSave, setValidNameFromSave] = useState(false);
  const  ff_transmission_inspection_form = useFlags()[FF_TRANSMISSION_INSPECTION_FORM];
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // EDIT
    if (browserRedirectLocation.workQueue) {
      const _originalWorkQueue = browserRedirectLocation.workQueue;
      const _currentWorkQueue = {}
      setCurrentName(_originalWorkQueue.name)
      _currentWorkQueue.id = _originalWorkQueue.id
      _currentWorkQueue.type = _originalWorkQueue.workQueueType
      _currentWorkQueue.name = _originalWorkQueue.name
      _currentWorkQueue.status = _originalWorkQueue.status
      _currentWorkQueue.validName = true
      _currentWorkQueue.circuitsEmpty = true;
      if (_originalWorkQueue.workQueueType === WORK_QUEUE_TYPE_TRANSMISSION) {
        WorkItemsService.getLines(_originalWorkQueue.id).then(response => {
          if (response && response.data) {
            _currentWorkQueue.circuitsEmpty = false;
            currentWorkQueue.vendorName = response.data.vendorName
            _originalWorkQueue.vendorName = response.data.vendorName
            const workItemsChecked = response.data.assets
            setWorkItemsEdit(workItemsChecked || [])
            setCurrentWorkQueue({..._currentWorkQueue})
            setOriginalWorkQueue({..._originalWorkQueue})
          } else {
            showSnackbarMessage("An error has occurred while fetching the Work Queue information", "error");
          }
          setPageLoading(false)
        }).catch(() => {
          showSnackbarMessage("An error has occurred while fetching the Work Queue information", "error");
        })
      } else {
        WorkItemsService.getByWorkQueueId(_originalWorkQueue.id).then(response => {
          if (response && response.data) {
            _currentWorkQueue.circuitsEmpty = false;            
            const workItemsChecked = response.data.workItems.map( workItem => {
              return {...workItem, tableData: { checked: true, id: workItem.id } }
            })
            setWorkItemsEdit(workItemsChecked || [])
            setCurrentWorkQueue({..._currentWorkQueue})
            setOriginalWorkQueue({..._originalWorkQueue})
          } else {
            showSnackbarMessage("An error has occurred while fetching the Work Queue information", "error");
            setPageLoading(false)
          }          
        }).catch(() => {
          showSnackbarMessage("An error has occurred while fetching the Work Queue information", "error");
        })
      }
        
    } else if (edit === "1" && name && name !== '') {
      // If enter /edit on url and no object is present, but name on url
      // search by workqueue name and then setCurrentWorkQueue
      history.push("/WorkQueue")
    } else if (edit === "1" && !name) {
      // If enter /edit on url and no object is present and no name on url, redirect to list
      history.push("/WorkQueue")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])
  
  const editWorkQueue = (workQueueName) => {    
    const _workQueueEdit = {};
    if (currentWorkQueue.type === WORK_QUEUE_TYPE_TRANSMISSION) {
      _workQueueEdit.newAssets = [];
      _workQueueEdit.deletedAssets = [];
      _workQueueEdit.vendorName = currentWorkQueue.vendorName;
    } else if(currentWorkQueue.type === WORK_QUEUE_TYPE_MANHOLE) {
      _workQueueEdit.newAssets = [];
      _workQueueEdit.deletedWorkItemIds = [];
    } else {
      _workQueueEdit.newWorkItems = [];
      _workQueueEdit.deletedWorkItemIds = [];
    }
    _workQueueEdit.type = currentWorkQueue.type;
    _workQueueEdit.id = currentWorkQueue.id;
    _workQueueEdit.status = currentWorkQueue.status;
    _workQueueEdit.name = workQueueName ||  currentWorkQueue.name;       
    
    // Work Items selected
    const workItems = selectedRow.current.map(row => {
      let workItem
      if (currentWorkQueue.type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP) {
        workItem = {
          id: row.id,
          circuitId: row.circuitId,
          state: row.state,
          division: row.division,
          areaWorkCenter: row.areaWorkCenter,
          town: row.town
        }
        // WorkItem not found in original, add to newWorkItems list 
        if (!workItemsEdit.some( originalWI => row.id === originalWI.id )) {            
          _workQueueEdit.newWorkItems.push(workItem)            
        }
      } else {
        workItem = {
          lineId: row.lineId,
          id: row.id
        }
        // WorkItem not found in original, add to newAssets list 
        if (!workItemsEdit.some( originalWI => row.id === originalWI.id)) {
          _workQueueEdit.newAssets.push(workItem)
        }
      }
      return workItem
    });

    // Find the unchecked items on the original list to get the deleted items
    if (workItems.length > 0) {
      const deletedWorkItemIds = []
      
      if (_workQueueEdit.type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP || _workQueueEdit.type === WORK_QUEUE_TYPE_MANHOLE) {
        workItemsEdit.forEach(o1 => {
          if (workItems.findIndex(item => item.id === o1.id) === -1) {
            deletedWorkItemIds.push(o1.id)
          }
        })
        _workQueueEdit.deletedWorkItemIds = deletedWorkItemIds
      } else {
        workItemsEdit.forEach(o1 => {
          if (workItems.findIndex(item => item.lineId === o1.lineId) === -1) {
            deletedWorkItemIds.push({ lineId: o1.lineId, id: o1.id })
          }
        })
        _workQueueEdit.deletedAssets = deletedWorkItemIds
      }
    }
    WorkQueueService.edit(_workQueueEdit).then((response)=> {
      setPageLoading(false)
      if (response.status === 204) {
        showSnackbarMessage("Work Queue Updated successfully", "success");
        history.push("/WorkQueue")
      } else {
        showSnackbarMessage("An error occurred editing the Work Queue", "error");
      }
    }).catch(() => {
      setPageLoading(false)
      setSubmitting(false)
      showSnackbarMessage("An error occurred editing the Work Queue", "error");
    });
  }

  const submitForm = (event) => {
    event.preventDefault();
    setPageLoading(true)
    setSubmitting(true)
    
    // If editing    
    if (originalWorkQueue) {
      if (currentWorkQueue.name !== currentName) {
        setValidNameFromSave(true)        
      } else{
        editWorkQueue()
      }
    } else {
      let workItems = {};
      let workQueueData = {};
      if (currentWorkQueue.type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP) {
        workItems = selectedRow.current.map(({circuitId,state,division,areaWorkCenter, town}) => ({
          circuitId,
          state,
          division,
          areaWorkCenter,
          town
        }));
        workQueueData.workItems = workItems
      } else if (currentWorkQueue.type === WORK_QUEUE_TYPE_TRANSMISSION) {
        const assets = [];
        selectedRow.current.forEach(({ id, lineId }) => assets.push({ id, lineId }));
        workQueueData.assets = assets;
        workQueueData.vendorName = currentWorkQueue.vendorName;
      } else if (currentWorkQueue.type === WORK_QUEUE_TYPE_MANHOLE) {
        const assets = [];
        selectedRow.current.forEach(({ id, manholeId }) => assets.push({ id, manholeId }));
        workQueueData.assets = assets;
      } 
      
      workQueueData = {...workQueueData, type: currentWorkQueue.type, name: currentWorkQueue.name, status: 0 };
      WorkQueueService.create(workQueueData).then((response)=> {
        setPageLoading(false)
        setSubmitting(false)        
        if (response.data) {
          showSnackbarMessage("Work Queue created successfully", "success");
          history.goBack();
        } else {
          showSnackbarMessage("An error occurred creating the Work Queue", "error");
        }
      }).catch(() => {
        setPageLoading(false)
        setSubmitting(false)
        showSnackbarMessage("An error occurred creating the Work Queue", "error");
      });
    }
  };

  const onTableSelectionChange = (rows)=> {
    selectedRow.current = rows;
    currentWorkQueue.circuitsEmpty = rows.length === 0
    setCurrentWorkQueue({...currentWorkQueue})  
  }

  const updateInputsChange = (workQueueValues) => {
    setCurrentWorkQueue({...{...currentWorkQueue, ...workQueueValues }})
  }

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
	}

  const disabledSubmit = submitting || (!currentWorkQueue.circuitsEmpty && currentWorkQueue.validName ? false: true);
  return (
    <>
      {pageLoading && <ProgressBar styles={loadingStyles} />}
      <form
        className={classes.form}
        onSubmit={submitForm}
      >
        <Grid container direction="column" spacing={2}>
          <FormInputs
            setPageLoading={setPageLoading}
            workQueueToEdit={originalWorkQueue}
            updateValues={updateInputsChange} 
            ff_transmission_inspection_form={ff_transmission_inspection_form}
            onChangePropName={name => setCurrentName(name)}  
            validNameFromSave={validNameFromSave}  
            onChangeValidName={isValidName => {
              if (isValidName) {
                setValidNameFromSave(true)
                editWorkQueue(currentName);
              }
            }}          
          />
          <Grid item xs={12}>
            {(edit === "0" || (edit === "1" && originalWorkQueue && workItemsEdit && workItemsEdit.length > 0))
              && <>
                {currentWorkQueue.type === WORK_QUEUE_TYPE_TRANSMISSION &&
                  <TransmissionsTable
                    onSelect={items => onTableSelectionChange(items)}
                    setPageLoading={setPageLoading}
                    workItems={[...workItemsEdit]}
                  />}
                {currentWorkQueue.type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP &&
                  <CircuitsTable
                    onSelect={items => onTableSelectionChange(items)}
                    setPageLoading={setPageLoading}
                    workItems={[...workItemsEdit]}
                    type={currentWorkQueue?.type}
                  />}
                {currentWorkQueue.type === WORK_QUEUE_TYPE_MANHOLE &&
                  <ManholesTable
                    onSelect={items => onTableSelectionChange(items)}
                    setPageLoading={setPageLoading}
                    workItems={[...workItemsEdit]}
                    type={currentWorkQueue?.type}
                    state={currentWorkQueue?.state}
                  />}
              </>}
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              icon={<CancelIcon />}
              onClick={()=>{history.goBack()}}
              label="Cancel"
              color="primary"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              icon={<AddIcon />}
              type="submit"
              label={originalWorkQueue ? "Save" : "Create"}
              color="primary"
              disabled={disabledSubmit}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default WorkQueueForm;
