import React, { Component } from 'react';
import { HashRouter as Router, withRouter} from 'react-router-dom'
import TelemetryProvider from './../../TelemetryProvider'
import { getAppInsights } from './../../TelemetryService'
import { InteractionType } from "@azure/msal-browser";
import { ErrorComponent } from "../Shared/error";
import { LoadingComponent } from "../Shared/loading";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { azureConfig } from "../../Constants";
import Layout from "../Layout";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import SimpleReactLightbox from 'simple-react-lightbox'
import AcknowledgementDialog from '../Shared/Dialog/acknowledgement';

const { REACT_APP_APP_INSIGHTS_KEY } = process.env
const authRequest = azureConfig.scope

// MSAL configuration
const { REACT_APP_AUTHORITY, REACT_APP_CLIENTID } = process.env
const configuration = {
  auth: {
    clientId: REACT_APP_CLIENTID,
    authority: REACT_APP_AUTHORITY
  },
  cache: {
    cacheLocation: 'localStorage',
  }
};
export const msalInstance = new PublicClientApplication(configuration);

class App extends Component {

  componentDidMount () {    
    this.props.history.listen(() => {
      navigator.serviceWorker.getRegistration(`${new URL(process.env.PUBLIC_URL, window.location.href)}`)
      .then(swReg => {
        if(swReg ) {
          swReg.update();
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.stop();
            window.location.reload();
          });
        }
      }).catch(() => {
        
      })
    });
  }

  render(){
    return (
      
      <MsalProvider instance={msalInstance}>
        <Router>
          <TelemetryProvider instrumentationKey={REACT_APP_APP_INSIGHTS_KEY} after={() => { getAppInsights() }}>
            { /* Childs of this component won´t load unless an user is logged in */ }
            <MsalAuthenticationTemplate 
              interactionType={InteractionType.Redirect} 
              authenticationRequest={authRequest} 
              errorComponent={ErrorComponent} 
              loadingComponent={LoadingComponent}         
            >
              <AcknowledgementDialog
                title="Accept Terms"   
                buttonTitle="I Agree"
                msalInstance={msalInstance}
              />              
              <SimpleReactLightbox>
                <Layout msalInstance={msalInstance}/>
              </SimpleReactLightbox>
            </MsalAuthenticationTemplate>      
          </TelemetryProvider>
        </Router>
      </MsalProvider>
    );
  } 
}

export default withRouter(App);
