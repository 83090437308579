import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../../Shared/atoms/Button"
import ImageCarousel from "../../Shared/ImageCarousel";
import UploadFile from "../UploadFile";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import { useLightbox } from "simple-react-lightbox";
import ImageService from "../../../services/ImageService";

const UploadImageCarousel = ({ 
	fullView= true, 
	multipleImages=true, 
	changeDeletePhoto, 
	changeFile,
  detectedObject,
  loadingProp,
  showThumbs=true
}) => {

	const [uploadingImages, setUploadingImages] = useState(false);
	const [progressBar] = useState(1);
	const [loadingImages, setLoadingImages] = useState(false);
	const [images, setImages] = useState([]);
	const [names, setNames] = useState([]);
	const { openLightbox } = useLightbox();

  const onChangeFile = async (files) => {
    setLoadingImages(true);
    setUploadingImages(true)

    const blobs = ImageService.getBlobList(files); 
    const newBlobs = [...images, ...blobs];
    const newNames = [[...names, ""]];
    setImages(newBlobs);
    setNames(newNames); 
    changeFile({urls: newBlobs, names: newNames, files })
    setUploadingImages(false);
    setLoadingImages(false);
  };

	const deletePhoto = async (index) => {    
    setLoadingImages(true);    
    const blobs = [...images];
    const _names = [...names];
    blobs.splice(index, 1)
    const nametodelete = _names.splice(index, 1)[0];
    setImages(blobs);
    setNames(_names);
		changeDeletePhoto({urls: blobs, names: _names},nametodelete)
    setTimeout(() => {
      setLoadingImages(false);      
    }, 1000);
  }
  
	const imageCarouselProps = () => {
		return {
      detectedObject,
			images: images,
			height: "300px",
			deleteIcon: true,
			deletePhotoHandle: deletePhoto,
			loading: loadingImages,
			showThumbs: showThumbs,
			showIndicators: true,
			detail: true,
			uploadingImages: uploadingImages,
			progressBar: progressBar,
      loadingAI: loadingProp
		}
	}

  return (
    <Grid container alignContent="center" direction="column">
      <Grid item xs={12}>
        {images.length > 0 ? (
          <ImageCarousel {...imageCarouselProps()} />
        ) : (
          <UploadFile onChangeFile={onChangeFile} isImage={true} />
        )}
      </Grid>
			<Grid item xs={12} style={{marginTop: showThumbs ? "" : "10px"}}>         
          {images.length > 0 ? (
            <Grid container item xs={12} justifyContent="flex-start">
              {fullView && <Button
                variant="outlined"
                onClick={() => openLightbox()}
                icon={<ViewCarouselIcon />}
                label="Full view"
                color="primary"
                styles={{ marginRight: '1rem' }}
              />}
              {multipleImages && <UploadFile
                onOpenGalleryFromIcon={true}
                onChangeFile={onChangeFile}
                isImage={true} />}          
            </Grid>
          ) : null}
        </Grid>
    </Grid>
  );
};

export default UploadImageCarousel;
