import React from "react";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Image from "@material-ui/icons/Image";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Button from "../atoms/Button";
import { validExtensionsVideo, validExtensionsImage, acceptImage, acceptVideo } from "../../../Constants";
import { useSnackbar } from "notistack";

const UploadFile = ({ onOpenGalleryFromIcon, isImage, ...props }) => {
  const validExtensions = isImage ? validExtensionsImage : validExtensionsVideo
  const inputFileRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const onChangeFile = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.target.files;
    const filesValidated = Array.from(files).filter((file) => {
      const extension = file.name.split(".").pop();
        return validExtensions.includes(extension.toLowerCase()) ;
    });

    if (filesValidated.length !== files.length) {
      enqueueSnackbar(`One or more ${isImage ? "images" : "videos"} need a valid extension`, {
        variant: "warning",
      });
    }
    if (filesValidated.length > 0) {
      props.onChangeFile(filesValidated);
      evt.target.value = null; // reset fileupload
    }
  };

  const useStyles = makeStyles((theme) => ({
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.dark,
    },
    fileInput: {
      display: "none",
    },
    fileUploadContainer: {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      width: 260,
      height: 260,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
  }));

  const onOpenGallery = () => {
    inputFileRef.current.click();
  };

  const classes = useStyles();

  return (
    <>
      <input
        className="file-input"
        accept={isImage ? acceptImage : acceptVideo}
        id="icon-button-file"
        type="file"
        ref={inputFileRef}
        onChange={onChangeFile}
        {...isImage && {multiple: true}}
        data-testid="file-upload-input"
      />
      {!onOpenGalleryFromIcon ? (
        <div className={classes.fileUploadContainer} onClick={onOpenGallery}>
          <Avatar className={classes.avatar}>
            <Hidden xsDown>
              <Image color="secondary" />
            </Hidden>
            <Hidden smUp>
              <AddAPhotoIcon color="secondary" />
            </Hidden>
          </Avatar>
          <Typography component="h2" variant="h6" className={classes.header}>
            <Hidden xsDown>Upload a {isImage ? "photo" : "video"}</Hidden>
            <Hidden smUp>{isImage ? "Take a photo" : "Upload a video"}</Hidden>
          </Typography>
        </div>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={onOpenGallery}
          icon={<AddPhotoAlternateIcon />}
          label="Add Photo"
        />
      )}
    </>
  );
};

export default UploadFile;
