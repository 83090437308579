import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextFieldComponent from '../../../Shared/atoms/TextInput';
import Select from '../../../Shared/atoms/Select';
import AddressAutocomplete from '../../../Shared/AddressAutocomplete';
import StateService from '../../../../services/StateService';
import TownService from '../../../../services/TownService';
import AssetsService from '../../../../services/AssetsService';
import { ASSET_TYPE_MANHOLE } from '../../../../Constants';
import { reverseGeocode } from '../../../../Utils/geocode';

const GeographicSection = ({ inspection, setInspection, onPlaceChange, onChangeInput, manholeFromWorkItem, editing, detail }) => {

	const [states, setStates] = useState([]);
	const [towns, setTowns] = useState([]);
	const [manholeNumbers, setManholeNumbers] = useState([]);
	
	useEffect(() => {
		fetchStates();
	}, []);

	useEffect(() => {
		const updateDataWithWorkQueue = async (manholeFromWorkItem) => {
			setStates([manholeFromWorkItem.state]);
			setTowns([manholeFromWorkItem.town]);
			const _inspection = { ...inspection };			
			_inspection.town = manholeFromWorkItem.town;
			_inspection.state = manholeFromWorkItem.state;
			_inspection.manholeId = manholeFromWorkItem.id;
			_inspection.workQueue = manholeFromWorkItem.workQueue;
			const address = await reverseGeocode(manholeFromWorkItem.location.coordinates[1], manholeFromWorkItem.location.coordinates[0]);
			_inspection.address = address;
			setInspection(_inspection);
		}
		if (manholeFromWorkItem?.workQueue?.name && manholeFromWorkItem?.workQueue?.workQueueId) {
			updateDataWithWorkQueue(manholeFromWorkItem)			
		}
	}, [manholeFromWorkItem])

	useEffect(() => {
		if (manholeFromWorkItem) {
			return;
		}
		if (inspection?.state) {
			fetchTowns(inspection.state);
		} else {
			setTowns([]);
			setManholeNumbers([]);
		}
	}, [inspection?.state]);

	useEffect(() => {
		if (inspection?.town) {
			fetchManholes(inspection.town);
		}
	}, [inspection?.town]);

	const fetchStates = async () => {
		if (manholeFromWorkItem) return
		const { status, data } = await StateService.getAssetsStates(ASSET_TYPE_MANHOLE) ?? {};
		if (status === 200) {
			setStates(data);
		}
	};

	const fetchTowns = async (state) => {
		if (manholeFromWorkItem) return
		const { status, data } = await TownService.getAssetsTowns({
			state,
			assetType: ASSET_TYPE_MANHOLE
		}) ?? {};

		if (status === 200) {
			setTowns(data);
		}
	};

	const fetchManholes = async (town) => {
		const { status, data } = await AssetsService.getAll({
			isActive: true,
			types: ASSET_TYPE_MANHOLE,
			town: town
		}) ?? {};

		if (status === 200) {
			setManholeNumbers(data.map(a => {
				if (manholeFromWorkItem && manholeFromWorkItem.id === a.id) {					
					const _inspection = { ...inspection };			
					_inspection.manholeNumber = a.manholeNumber;
					setInspection(_inspection);					
				}
				return a.manholeNumber;
			}));
		}
	};

	const selectState = state => {
		const _inspection = { ...inspection };
		_inspection.state = state;
		_inspection.town = '';
		_inspection.manholeNumber = '';
		setInspection(_inspection);
	};

	const selectTown = town => {
		const _inspection = { ...inspection };
		_inspection.town = town;
		_inspection.manholeNumber = '';
		setInspection(_inspection);
	};

	return <>
		{manholeFromWorkItem?.workQueue?.name && <Grid item xs={12}>
			<TextFieldComponent
				label="Work Queue"
				variant="outlined"
				fullWidth
				onChange={() => {}}
				id="workQueue"
				value={inspection.workQueue?.name}
				disabled={true}
			/>
		</Grid>}
		<Grid item xs={12}>
			<Select
				label="State"
				selectOptions={states}
				selectItem={e => selectState(e)}
				itemSelected={[inspection.state]}
				required
				multiple={false}
				requiredText="State is required"
				disabled={!!manholeFromWorkItem?.workQueue || !editing || detail}
			/>
		</Grid>
		<Grid item xs={12}>
			<Select
				label="Town"
				selectOptions={towns}
				selectItem={e => selectTown(e)}
				requiredText={!inspection.state ? 'Please select a State' : 'Town is required'}
				itemSelected={inspection.town ? [inspection.town] : []}
				required
				multiple={false}
				disabled={!!manholeFromWorkItem?.workQueue || !editing || detail}
			/>
		</Grid>
		<Grid item xs={12}>
			<Select
				label="Manhole #"
				selectOptions={manholeNumbers}
				selectItem={e => onChangeInput(e, 'manholeNumber')}
				itemSelected={inspection.manholeNumber ? [inspection.manholeNumber] : []}
				required
				multiple={false}
				requiredText="Required Field"
				disabled={!!manholeFromWorkItem?.workQueue || !editing || detail}
			/>
		</Grid>
		<Grid item xs={12}>
			{navigator.onLine && window.google ? (
				<AddressAutocomplete onPlaceChange={onPlaceChange}>
					<TextFieldComponent
						value={inspection.address}
						label="Address"
						placeholder=""
						onChange={(e) => onChangeInput(e, 'address')}
						required
						disabled={!editing || detail}
					/>
				</AddressAutocomplete>
			) : (
				<TextFieldComponent
					value={inspection.address}
					label="Address"
					placeholder=""
					onChange={(e) => onChangeInput(e, 'address')}
					disabled={!editing || detail}
				/>
			)}
		</Grid>
		<Grid item xs={12}>
			<TextFieldComponent
				label="Work Order"
				variant="outlined"
				fullWidth
				onChange={e => onChangeInput(e, 'workOrder')}
				id="workOrder"
				value={inspection.workOrder}
				required={false}
				disabled={!editing || detail}
			/>
		</Grid>
	</>
}

export default GeographicSection;
