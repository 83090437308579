import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const BasicPopover = ({anchorEl, title, ...props}) => {

  const handleClose = () => {
    props.handleClose(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}        
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div><Typography sx={{ p: 2 }}>{title}</Typography></div>
        <>{props.children}</>
      </Popover>
    </div>
  );
}

export default BasicPopover;