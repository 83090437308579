import React, { useEffect } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import _ from 'lodash';
import InspectionsDataContext from '../../../context/InspectionsData/InspectionsDataContext';

const Paginator = ({
	data = [],
	page,
	rowsPerPage,
	setRowsPerPage,
	totalItems,
	inspectionPage = false,
	...props
}) => {
	const { setContextInspectionsPerPage } = React.useContext(
		InspectionsDataContext
	);

	function handleChangePage() {
		const page = arguments[1];
		const chunks = _.chunk(data, rowsPerPage);
		if (page === chunks.length && inspectionPage) {
			props.getMoreInspections();
		} else {
			onChangePage(page);
		}
		props.setPage(page);
	}

	const onChangePage = (currentPage, recordsPerPageParam = rowsPerPage) => {
		const chunks = _.chunk(data, recordsPerPageParam);
		props.onChangePage(chunks[currentPage]);
	};

	useEffect(() => {
		onChangePage(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, page]);

	useEffect(() => {
		onChangePage(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.setPageProps]);

	const handleChangeRowsPerPage = (event) => {
		setContextInspectionsPerPage(event.target.value);
		setRowsPerPage(event.target.value);
		props.setPage(0);
		onChangePage(0, event.target.value);
	};
	return (
		<TablePagination
			component="div"
			count={isNaN(totalItems) ? 0 : Number(totalItems)}
			page={page}
			onPageChange={handleChangePage}
			rowsPerPage={rowsPerPage}
			rowsPerPageOptions={[5, 10, 25]}
			labelRowsPerPage=""
			onRowsPerPageChange={handleChangeRowsPerPage}
		/>
	);
};

export default Paginator;
