import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialogSlide from '../../../Shared/Dialog';
import TextFieldComponent from '../../../Shared/atoms/TextInput';
import Select from '../../../Shared/atoms/Select';
import Button from '../../../Shared/atoms/Button';

const JointMeasurement = ({ inspection, changeInfraredValue, deleteInfraredIndex, editing }) => {
	const [jointMeasurements, setJointMeasurements] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [indexToDelete, setIndexToDelete] = useState(false);

	useEffect(() => {
		if (inspection.jointMeasurements) {
			setJointMeasurements(inspection?.jointMeasurements?.length - 1);
		}
	}, [inspection]);

	const jointMeasurementsList = () => {
		const rows = [];
		const cableLocations = ['East Wall', 'North Wall', 'South Wall', 'West Wall'];
		for (let i = 0; i <= jointMeasurements; i++) {
			rows.push(<Grid container key={`jointMeasurements${i}`} spacing={1}>
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<h4 style={{ ...indexToDelete === i && {color: 'red'} }}>Joint Measurement {i + 1}</h4>
					{i > 0 && editing &&
					<DeleteIcon
					 	style={{ cursor: 'pointer', color: 'red' }}
						onClick={() => {
							setOpenDialog(true);
							setIndexToDelete(i);
						}}
					/>}
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent
						value={inspection.jointMeasurements && inspection.jointMeasurements[i] && inspection.jointMeasurements[i].cableFeederID || ''}
						label="Cable Feeder ID"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={e => changeInfraredValue(e, 'jointMeasurements', 'cableFeederID', i)}
						multiline={false}
						required={true}
						disabled={!editing}
					/>
				</Grid>
				<Grid item xs={12}>
					<Select
						label="Cable Location"
						selectOptions={cableLocations}
						selectItem={e => changeInfraredValue(e, 'jointMeasurements', 'cableLocation', i)}
						itemSelected={[inspection.jointMeasurements && inspection.jointMeasurements[i] && inspection.jointMeasurements[i].cableLocation] || []}
						required={true}
						multiple={false}
						disabled={!editing}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent
						value={inspection.jointMeasurements && inspection.jointMeasurements[i] && inspection.jointMeasurements[i].cableMeasurement || ''}
						label="Cable Measurement"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={(e) => changeInfraredValue(e, 'jointMeasurements', 'cableMeasurement', i)}
						multiline={false}
						required={true}
						disabled={!editing}
					/>
				</Grid>
			</Grid>);
		}
		return rows;
	}

	return <>
		<Grid item xs={12}>
			<h3>Joint Measurement</h3>     
			{jointMeasurementsList()}			
		</Grid>
		<Grid item xs={12}>
			<Button
				variant="outlined"
				color="primary"
				onClick={() => {
					const newJointMeasurementIndex = jointMeasurements + 1;
					setJointMeasurements(newJointMeasurementIndex);
					changeInfraredValue('', 'jointMeasurements', 'cableMeasurement', newJointMeasurementIndex);
				}}
				label="+ Joint Measurement"
				styles={{ marginTop: 10}}
				disabled={!editing}
			/>
		</Grid>
		<AlertDialogSlide
			openDialog={openDialog}
			handleConfirm={() => {
				setOpenDialog(false);
				const newJointMeasurementIndex = jointMeasurements - 1;
				setJointMeasurements(newJointMeasurementIndex);
				deleteInfraredIndex(indexToDelete, 'jointMeasurements');
				setIndexToDelete(false);
			}}
			title={`Delete Joint Measurement ${indexToDelete + 1}`}
			content="Are you sure you want to delete this joint measurement?"      
			handleClickClose={() => {
				setOpenDialog(false);
				setIndexToDelete(false);
			}}
		/>
	</>
}

export default JointMeasurement;