import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import MyLocationOutlinedIcon from "@material-ui/icons/MyLocationOutlined";
import FullscreenTwoToneIcon from "@material-ui/icons/FullscreenTwoTone";
import FullscreenExitTwoToneIcon from "@material-ui/icons/FullscreenExitTwoTone";
import { makeStyles } from "@material-ui/core/styles";
import { ZOOM_LEVEL_DISTRIBUTIONS, ZOOM_LEVEL_TRANSMISSIONS } from '../../../../Constants'
import { GetUserLocation, showSnackbar } from "../../../../Utils";
import { useSnackbar } from "notistack";
import { Grid, Typography } from "@material-ui/core";
import { Switch } from "../../../Shared/atoms/Toggle";

const useStyles = makeStyles((theme) => ({
  toggleEventsInspections: {
    position: "absolute",
    top: 10,
    right: 60,
    zIndex: 2,
    borderRadius: 2,
    height: 40,
    "& .MuiButton-text": {
      height: "100%",
      boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
      backgroundColor: "#fff",
      "&:first-child": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      "&:nth-child(2)": {
        borderRadius: 0,
      },
      "&:last-child": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    [theme.breakpoints.down("md")]: {
      bottom: 24,
      left: 10,
      top: "initial",
      right: "initial",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: 103,
    },
  },
  toggleEventsInspectionsOn: {
    fontWeight: "500",
    color: "#000",
    fontSize: 18,
    textTransform: "capitalize",
  },
  toggleEventsInspectionsOff: {
    fontWeight: "400",
    color: "rgb(86, 86, 86)",
    fontSize: 18,
    textTransform: "capitalize",
  },
  currentLocationContainer: {
    position: "absolute",
    bottom: 110,
    right: 10,
    zIndex: 2,
    height: 40,
    borderRadius: 2,
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 41,
  },
  currentLocationButton: {
    padding: 0,
    minWidth: 0,
    height: "100%",
    width: "100%",
  },
  fullScreenContainer: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 2,
    height: 40,
    borderRadius: 2,
    boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 41,
    "& .MuiButton-root": {
      minWidth: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  assetsPopover: {
    zIndex: "9999 !important",
    "& .MuiPaper-root": {
      marginLeft: 0,
    },
  },
}));

const MapToggles = ({
  map,
  circuitCenter,
  setFullscreen,
  fullscreen,
  showTransmissionAssets,
  setShowTransmissionAssets,
  showDistributionAssets,
  setShowDistributionAssets,
  showEvents,
  showInspections,
  setShowInspections,
  setShowEvents,
  setUserPosition,
  showLines,
  onChangeShowLines
}) => {
  const classes = useStyles();
  const [assetsMenu, setAssetsMenu] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (map && circuitCenter) {
      map.panTo({
        lat: circuitCenter.lat,
        lng: circuitCenter.lng
      })
      map.setZoom(ZOOM_LEVEL_DISTRIBUTIONS)
    } 
  }, [circuitCenter, map])
  
  // Get my location button on map clicked 
  const getCurrentLocation = () => {
    GetUserLocation()
      .then((response) => {
        if (!response.error && response.position && response.position.coords) {
          const location = {latitude: response.position.coords.latitude, longitude: response.position.coords.longitude}
          map.setZoom(ZOOM_LEVEL_DISTRIBUTIONS)
          map.panTo({
            lat: location.latitude,
            lng: location.longitude,
          });
          setUserPosition([location.latitude, location.longitude]);
        }
      })
      .catch(() => {
        showSnackbarMessage("An error occurred getting your location", "error");
      });
  };

  function showSnackbarMessage(message, type) {
    showSnackbar(enqueueSnackbar, message, type);
  }

  const checkingZoomIntoViewAssets = (isToggleOn) => {
    if (map.zoom < ZOOM_LEVEL_TRANSMISSIONS && isToggleOn) {
      showSnackbarMessage("Please zoom in to view Assets", "warning");
    }
  };

  return (
    <>
      <div className={classes.toggleEventsInspections}>
        <Button
          aria-describedby="assetsPopover"
          onClick={(e) => setAssetsMenu(e.currentTarget)}
          className={
            assetsMenu || showTransmissionAssets || showDistributionAssets ||
            showLines
              ? classes.toggleEventsInspectionsOn
              : classes.toggleEventsInspectionsOff
          }
        >
          Assets
        </Button>
        <Popover
          id="assetsPopover"
          open={Boolean(assetsMenu)}
          anchorEl={assetsMenu}
          onClose={() => setAssetsMenu(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className={classes.assetsPopover}
        >
          <Grid container>
            <Grid item>
              <Grid container direction="column">
                <Typography component="h3" variant="h6" >
                  Transmission
                </Typography>
                <Switch
                  label="Lines"
                  checked={showLines}
                  onChange={() => onChangeShowLines(!showLines)}
                />              
                <Switch
                  label="Structures"
                  checked={showTransmissionAssets}
                  onChange={(e) => {
                    checkingZoomIntoViewAssets(e.target.checked);
                    setShowTransmissionAssets(
                      e.target.checked,
                      showDistributionAssets,
                      map.zoom
                    );
                  }}
                />
              </Grid>              
            </Grid>
            <Grid item>
            <Switch
                label="Distribution"
                checked={showDistributionAssets}
                onChange={(e) => {
                  checkingZoomIntoViewAssets(e.target.checked);
                  setShowDistributionAssets(
                    showTransmissionAssets,
                    e.target.checked,
                    map.zoom
                  );
                }}
              />              
            </Grid>
          </Grid>
        </Popover>
        <Button
          onClick={() => setShowEvents(!showEvents, map.zoom)}
          className={
            showEvents
              ? classes.toggleEventsInspectionsOn
              : classes.toggleEventsInspectionsOff
          }
        >
          Outages
        </Button>
        <Button
          onClick={() => setShowInspections(!showInspections)}
          className={
            showInspections
              ? classes.toggleEventsInspectionsOn
              : classes.toggleEventsInspectionsOff
          }
        >
          Inspections
        </Button>
      </div>
      <div className={classes.currentLocationContainer}>
        <Tooltip title="Current Location" placement="top">
          <Button
            onClick={() => getCurrentLocation()}
            className={classes.currentLocationButton}
          >
            <MyLocationOutlinedIcon />
          </Button>
        </Tooltip>
      </div>
      <div className={classes.fullScreenContainer}>
        <Button
          onClick={() => setFullscreen(!fullscreen)}
          className={
            fullscreen
              ? classes.toggleEventsInspectionsOn
              : classes.toggleEventsInspectionsOff
          }
        >
          {fullscreen ? (
            <FullscreenExitTwoToneIcon />
          ) : (
            <FullscreenTwoToneIcon />
          )}
        </Button>
      </div>
    </>
  );
};

export default MapToggles;
