import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import DirectionsTwoToneIcon from '@material-ui/icons/DirectionsTwoTone';
import { Fab } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { InfoWindow } from '@react-google-maps/api';
import { isIphone, isArrayNotEmpty } from '../../../../../Utils';
import {
	LOCALSTORAGE_CURRENT_WORK_ITEM,
	ASSET_TYPE_POLE
} from '../../../../../Constants';
import { AssetVideoPlayer } from '../../../../Shared/AssetVideoPlayer/assetVideoPlayer';

function InfoWindowWrapper({
	closeInfoWindow,
	setInfoWindowAssetsData,
	options = null,
	outage = null,
	asset = null,
	inspection = null
}) {
	const [showVideo, setShowVideo] = useState(false); 
	const [selectedAssetData, setSelectedAssetData] = useState({});
	const useStyles = makeStyles(theme => ({
		assetsInfoWindow: {
			fontSize: 12,
			minWidth: 175,
			'& strong': {
				fontWeight: 500,
				display: 'inline-block',
				marginBottom: 3
			}
		},
		fabIconAssetsInfoWindow: {
			minHeight: 30,
			height: 30,
			width: 30,
			boxShadow: 'none',
			float: 'right',
			marginLeft: 5
		},
		tableContainer: {
			marginTop: 8,
		},
		table: {
			'& th': {
				color:  theme.palette.primary.main,
			},
			'& .MuiTableCell-root': {
				fontSize: '12px !important',
				paddingLeft: 0
			}
		},
	}));

	const classes = useStyles();
	const history = useHistory();
	const videoByAsset = asset?.videosByAsset.find(videoByAsset => videoByAsset.assetId == asset.asset.id);
  
	function createInspectionFromCircuit(asset, isDistribution) {
		let inspectionObject =  {};
		if (!isDistribution) {
			const currentTransmission = {};
			currentTransmission.lineId = asset.lineId;
			currentTransmission.structureId = asset.structureId;
			currentTransmission.state = asset.state;
			currentTransmission.fromMap = true;
			currentTransmission.assetId= asset.lineId;
			currentTransmission.assetType= asset.type;
			inspectionObject = currentTransmission;
		} else {
			let currentWorkItem = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENT_WORK_ITEM));    
			currentWorkItem = currentWorkItem || {};
			currentWorkItem.id = asset.id;
			currentWorkItem.circuitId = asset.circuitId;
			currentWorkItem.pole = asset.structureNumber;
			currentWorkItem.assetId= asset.id;
			currentWorkItem.assetType= asset.type;

			if (asset.location && asset.location.coordinates) {
				currentWorkItem.location = {
					lat: asset.location.coordinates[1],
					lng: asset.location.coordinates[0],
				};
			}

			if (asset.type === ASSET_TYPE_POLE) {
				currentWorkItem.devices = (isArrayNotEmpty(asset.devices) && asset.devices) || [];
			} else {
				currentWorkItem.devices = [{type: asset.type, id: asset.id, noPole: true}];
			}
			inspectionObject = currentWorkItem;
		}
		return inspectionObject;
	}

	const onVideoIconClick = async (asset, isDistribution) => { 
		try {
			const inspectionObj = createInspectionFromCircuit(asset, isDistribution);
			setSelectedAssetData({
				...inspectionObj, 
				...videoByAsset });
			setShowVideo(true);
		} catch (error) {
			console.log(error);
		}
	};

	const onClickAddInspection = (asset, isDistribution) => { 
		const inspectionObj = createInspectionFromCircuit(asset, isDistribution);
		let paramsObject = { pathname: '/CreateInspection/' }
		if (!isDistribution) {
			paramsObject.currentTransmission = inspectionObj;
		} else {
			paramsObject.currentWorkItem = inspectionObj;
		}
		history.push(paramsObject);
	};

	function renderAssetInfo(asset, assetsNoPoles, isDistribution, zoom) {
		function linkToMap(lat, lng, zoom) {
			let urlDirections = isIphone()
				? `maps://maps.apple.com/?q=${lat},${lng}&ll=${lat},${lng}&z=${zoom}`
				: `http://www.google.com/maps/search/?api=1&query=${lat},${lng}&z=${zoom}`;

			window.open(urlDirections);
		}

		function renderTransmissionInfo(asset) {
			return <>          
				{asset.type && <div><strong>Type:</strong> {asset.type}</div>}
				{asset.structureId && <div><strong>Id:</strong> {asset.structureId }</div>}
				{asset.lineId && <div><strong>Line:</strong> {asset.lineId }</div>}
			</>;
		}

		function renderDistributionInfo(asset, assetsNoPoles) {
			return <>
				{assetsNoPoles
					? asset.type && <div><strong>Type:</strong>{asset.type}</div>
					: asset.structureType && <div><strong>Type:</strong>{asset.structureType}</div>
				}
				{asset.structureNumber && <div><strong>Id:</strong> {asset.structureNumber }</div>}
				{assetsNoPoles && asset.circuitId && <div><strong>Circuit:</strong> {asset.circuitId}</div>}
				{asset.circuits && asset.circuits.length > 0 && <div><strong>Circuit:</strong> {asset.circuits.map(circuitID => circuitID).join(', ')}</div>}
				{asset.structureType === 'Pole' && asset.devices.length > 0 &&
          			<div className={classes.tableContainer}>
						<TableContainer className={classes.table}>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Type</TableCell>
										<TableCell>Id</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{asset.devices.map(device =>
										<TableRow key={device.id}>                            
											<TableCell>{device.type}</TableCell>
											<TableCell>{device.id}</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				}
			</>;
		}
		return <InfoWindow
			onCloseClick={() => setInfoWindowAssetsData(false)}
			position={{
				lat: asset.location.coordinates[1],
				lng: asset.location.coordinates[0],
			}}
		>
			<div className={classes.assetsInfoWindow}>      
				<Fab
					color="primary"
					aria-label="add"
					onClick={() => onClickAddInspection(asset, isDistribution)}
					className={classes.fabIconAssetsInfoWindow}
				>
					<AddIcon />
				</Fab>
				{(asset.structureType === 'Pole' && videoByAsset)  && <Fab
					color="primary"
					aria-label="video"
					onClick={() => onVideoIconClick(asset, isDistribution)}
					className={classes.fabIconAssetsInfoWindow}
				>
					<VideoCallIcon />
				</Fab>}
				<Fab
					color="primary"
					aria-label="share"
					className={classes.fabIconAssetsInfoWindow}
					onClick={() => linkToMap(asset.location.coordinates[1], asset.location.coordinates[0], zoom)}
				>
					<DirectionsTwoToneIcon />
				</Fab>
				{isDistribution 
					? renderDistributionInfo(asset, assetsNoPoles)
					: renderTransmissionInfo(asset)}
			</div>
		</InfoWindow>;
	}

	function infoWindowInspection(inspection) {
		return (
			<InfoWindow
				onCloseClick={closeInfoWindow}
				position={inspection.position}
			>
				<div>{inspection.notes}</div>
			</InfoWindow>);
	}
  
	function renderOutageInfo(outage) {
		return (
			<InfoWindow
				onCloseClick={closeInfoWindow}
				position={{
					lat: outage.assetLocation.coordinates[1],
					lng: outage.assetLocation.coordinates[0],
				}}
				{...options}
			>
				<div className={classes.assetsInfoWindow}>
					<div><strong>Event Id:</strong> {outage.eventId}</div>
					<div><strong>Asset Id:</strong> {outage.assetId}</div>
				</div>
			</InfoWindow>);
	}

	return !outage && !asset && !inspection 
		? null
		: <div>
			{outage && renderOutageInfo(outage)}
			{asset && renderAssetInfo(asset.asset, asset.assetsNoPoles, asset.isDistribution, asset.zoom)}
			{inspection && infoWindowInspection(inspection)}
			{showVideo && (
				  <AssetVideoPlayer assetData={selectedAssetData} onClose={() => setShowVideo(false)} isAssetsMap={ true } />
			    )}
		</div>;
} 
export default InfoWindowWrapper;