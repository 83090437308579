import React from "react";
import PageHeader from "../../Shared/PageHeader";
import AssignmentTwoToneIcon from "@material-ui/icons/AssignmentTwoTone";
import TransmissionsReport from "../../TransmissionsReport";
import ProgressBar from "../../Shared/ProgressBar";
import { LOADING_STYLES } from "../../../Constants";

const TransmissionsReportPage = () => {
  const [loading, setLoading] = React.useState(true);
  
  return (
    <>
      {loading && <ProgressBar styles={LOADING_STYLES} />}
      <PageHeader
        headerText=""
        icon={<AssignmentTwoToneIcon color="secondary" />}
      />
      <TransmissionsReport setLoading={setLoading} />
    </>
  );
};

export default TransmissionsReportPage;
