import React from 'react';
import Select from '../../Shared/atoms/Select';
import { Grid, Hidden, InputAdornment, Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import WorkQueueService from '../../../services/WorkQueueService';
import TextFieldComponent from '../../Shared/atoms/TextInput';
import SearchIcon from '@material-ui/icons/Search';
import {
	isMobile,
	saveObjInLocalStorage,
	isArrayNotEmpty,
	showSnackbar,
	validRoles,
} from '../../../Utils';
import {
	WORK_ITEMS_GLOBAL_SEARCH,
	ASSIGNED_USER_OBJECT_KEY,
	WORK_QUEUES_TYPES,
	WORK_QUEUE_TYPE_CIRCUIT_SWEEP,
	WORK_QUEUE_TYPE_TRANSMISSION,
	ROLES
} from '../../../Constants';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
	vendorName: {
		height: '100%',
		'& .MuiInputBase-root': {
			height: '100%',
		},
	},
}));

const MainFilters = ({
	circuits,
	vendorName,
	ff_transmission_inspection_form,
	userRoles,
	...props
}) => {
	const initialType = validRoles([ROLES.admin, ROLES.supervisor], userRoles).length > 0 ? WORK_QUEUE_TYPE_CIRCUIT_SWEEP : WORK_QUEUE_TYPE_TRANSMISSION
	const [workQueues, setWorkQueues] = useState([]);
	const [nameOptions, setNameOptions] = useState([]);
	const [type, setType] = useState([initialType]);
	const [name, setName] = useState([]);
	const [search, setSearch] = useState('');
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	useEffect(() => {
		WorkQueueService.getAll()
			.then((response) => {
				const workQueuesResponse =
					response && response.data ? response.data : [];

				if (workQueuesResponse.length > 0) {
					const _names = {};
					const _workQueues = [];
					let defaultName;
					let updatedType = [initialType];
					const storageSearch = localStorage.getItem(WORK_ITEMS_GLOBAL_SEARCH);

					if (storageSearch && storageSearch !== '') {
						const { search, name, type } = JSON.parse(storageSearch);
						if (name !== '') {
							defaultName = name;
							setSearch(search);
							if (type[0] !== initialType) {
								updatedType = type;
							}
						}
					}

					workQueuesResponse.forEach((e) => {
						_workQueues.push({
							id: e.id,
							workQueueType: e.workQueueType,
							name: e.name,
						});
						if (e.workQueueType === updatedType[0]) _names[e.name] = e.id;
					});

					// If data is stored on localstorage
					if (storageSearch && storageSearch !== '') {
						// Update the type state and change it on workitems component
						setType(updatedType);
						props.changeType(updatedType[0]);
					} else {
						// Leave type here and in workitems component unchanged and get the first item from the list
						defaultName = Object.keys(_names)[0];
					}

					setWorkQueues(_workQueues);
					setNameOptions(_names);
					setName([defaultName]);
					props.changeName(_names[defaultName], defaultName, updatedType);
				}
			})
			.catch(() => {
				showSnackbarMessage(
					'An error occurred getting the Work Items',
					'error'
				);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isMobile() && search !== '') {
			onSearchChange(search);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [circuits]);

	const onChangeType = (typeSelected) => {
		const _nameOptions = {};
		workQueues.forEach((workQueue) => {
			if (workQueue.workQueueType === typeSelected) {
				_nameOptions[workQueue.name] = workQueue.id;
			}
		});
		setNameOptions(_nameOptions);
		let defaultName;
		if (isArrayNotEmpty(Object.keys(_nameOptions))) {
			defaultName = Object.keys(_nameOptions)[0];
		} else {
			defaultName = '';
		}

		setName([defaultName]);
		setType([typeSelected]);
		setSearch('');

		props.changeName(_nameOptions[defaultName], defaultName, typeSelected);
		props.changeType(typeSelected);
		workItemsSavedSearch('', [typeSelected], defaultName);
	};

	const onChangeName = (e) => {
		setName([e]);
		setSearch('');
		props.changeName(nameOptions[e], e, type[0]);
		workItemsSavedSearch('', type, e);
	};

	const filterBySearch = (circuit, search) => {
		let result = false;
		for (const key in circuit) {
			// if key is not empty and includes search in the field or if key is "assignedUser" check if property "name" included on the search
			if (circuit[key] !== null) {
				if (
					circuit[key]
						.toString()
						.toLowerCase()
						.includes(search.toLowerCase()) ||
					(key === ASSIGNED_USER_OBJECT_KEY &&
						circuit[key].name.toLowerCase().includes(search.toLowerCase()))
				) {
					result = true;
				}
			} else if (
				key === ASSIGNED_USER_OBJECT_KEY &&
				'unassigned'.includes(search.toLowerCase())
			) {
				// if key is "assignedUser" and its value is null check if search is included in the word "unnasigned"

				result = true;
			}
		}
		return result;
	};

	const onSearchChange = (search) => {
		setSearch(search);
		const circuitsFiltered = circuits.filter((circuit) => {
			return filterBySearch(circuit, search);
		});
		props.onSearchChange(circuitsFiltered);
		workItemsSavedSearch(search, [type], name);
	};

	const workItemsSavedSearch = (search, type, name) => {
		saveObjInLocalStorage(WORK_ITEMS_GLOBAL_SEARCH, { search, type, name });
	};

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	const visibleVendorGridSize = vendorName ? 4 : 6;

	const workqueueTypeOptions = ff_transmission_inspection_form
		? validRoles([ROLES.admin, ROLES.supervisor, ROLES.manholeInspectionContributor], userRoles).length > 0
			? WORK_QUEUES_TYPES
			: [WORK_QUEUES_TYPES[1]] // Only transmissions
		: [WORK_QUEUES_TYPES[0]]; // Only Circuit

	return (
		<Container component="div" maxWidth={false}>
			<Grid
				container
				alignItems="center"
				justifyContent="flex-start"
				spacing={2}
			>
				<Grid item xs={12}>
					<Grid container direction="row" spacing={1}>
						<Grid item xs={6} md={6} lg={visibleVendorGridSize}>
							<Select
								label="Type"
								selectOptions={workqueueTypeOptions}
								selectItem={onChangeType}
								itemSelected={type}
								required={false}
								multiple={false}
								chip={false}
								id="workQueueTypeFilter"
							/>
						</Grid>
						<Grid item xs={6} md={6} lg={visibleVendorGridSize}>
							<Select
								label="Name"
								selectOptions={Object.keys(nameOptions)}
								selectItem={onChangeName}
								itemSelected={name}
								required={false}
								multiple={false}
								chip={false}
								id="workQueueNameFilter"
							/>
						</Grid>
						{vendorName && (
							<Grid item xs={12} md={6} lg={4}>
								<TextField
									label="Vendor"
									variant="outlined"
									fullWidth
									onChange={() => {}}
									id="workQueueVendorName"
									data-testid="workQueueVendorName"
									value={vendorName}
									disabled={true}
									className={classes.vendorName}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Hidden smUp>
					<Grid item xs={12}>
						<TextFieldComponent
							value={search}
							label="Search"
							fullWidth
							id="searchIdInput"
							variant="outlined"
							type="text"
							placeholder=""
							onChange={onSearchChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Hidden>
			</Grid>
		</Container>
	);
};

export default MainFilters;
