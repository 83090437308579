import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useState, useEffect } from 'react';
import DaysSinceUpdated from '../daysSinceUpdated';
import CountOfInspections from '../CountOfInspections';
import { ReactComponent as MapIcon } from '../../../assets/table/map.svg';
import ImageSearchTwoToneIcon from '@material-ui/icons/ImageSearchTwoTone';
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone';
import Table from '../../Shared/Table';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory, useLocation } from 'react-router-dom';
import {
	WORK_ITEMS_GLOBAL_SEARCH,
	LOCALSTORAGE_CURRENT_WORK_ITEM,
	WORK_QUEUE_TYPE_CIRCUIT_SWEEP,
	WORK_QUEUE_TYPE_TRANSMISSION,
	INSPECTION_TYPE_DAMAGE,
	ASSET_TYPE_TRANSMISSION,
	INSPECTION_TYPE_TRANSMISSION,
	WORK_QUEUE_TYPE_MANHOLE,
	INSPECTION_TYPE_MANHOLE,
} from '../../../Constants';
import { saveObjInLocalStorage, showSnackbar, tableAlphanumericalSort } from '../../../Utils';
import UserAssignment from '../UserAssignment';
import WorkItemsStatus from '../Status';
import { useSnackbar } from 'notistack';
import AssetsService from '../../../services/AssetsService';

const lookupStatusCircuit = {
	'0%': '0%',
	'25%': '25%',
	'50%': '50%',
	'75%': '75%',
	'100%': '100%',
};

const useStyles = makeStyles(() => ({
	circuitId: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '14px',
	},
	tableIcon: {
		cursor: 'pointer',
	},
}));

const WorkItemsTable = ({ items, type, name, workQueueId, disableAdminActions }) => {
	const classes = useStyles();
	const history = useHistory();
	const browserRedirectLocation = useLocation();
	const [data, setData] = useState(items);
	const [lineIdFilter, setLineIdFilter] = useState('');
	const storageSearch = JSON.parse(
		localStorage.getItem(WORK_ITEMS_GLOBAL_SEARCH)
	);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		// EDIT
		if (browserRedirectLocation.lineId) {
			setLineIdFilter(browserRedirectLocation.lineId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setData(items);
	}, [items]);

	const setLookupColumns = (data) => {
		let lookupState = {};
		let lookupDivision = {};
		let lookupAWC = {};
		let lookupStatus = {};
		data.forEach((el) => {
			lookupState = { ...lookupState, [el.state]: el.state };
			lookupDivision = { ...lookupDivision, [el.division]: el.division };
			lookupAWC = { ...lookupAWC, [el.areaWorkCenter]: el.areaWorkCenter };
			if (el.workItemType === WORK_QUEUE_TYPE_TRANSMISSION || el.workItemType === WORK_QUEUE_TYPE_MANHOLE) {
				lookupStatus = { ...lookupStatus, [el.status]: el.status };
			}
		});
		return { lookupState, lookupDivision, lookupAWC, lookupStatus };
	};

	const { lookupState, lookupDivision, lookupAWC, lookupStatus } =
		setLookupColumns(items);

	const userUpdated = () => {
		showSnackbarMessage('Work Item updated successfully', 'success');
	};

	const redirectDetailInspection = (id, workQueueType, partitionKey) => {
		let inspectionType;
		switch(workQueueType) {
			case WORK_QUEUE_TYPE_CIRCUIT_SWEEP:
				inspectionType = INSPECTION_TYPE_DAMAGE;
				break;
			case WORK_QUEUE_TYPE_TRANSMISSION:
				inspectionType = INSPECTION_TYPE_TRANSMISSION;
				break;
			case WORK_QUEUE_TYPE_MANHOLE:
				inspectionType = INSPECTION_TYPE_MANHOLE;
				break;
			default:
				break;
			
		}
		history.push(`/Inspection/${id}/${inspectionType}/${partitionKey}`);
	}
		

	const redirectCreateInspection = (rowData) => {
		const currentTransmission = rowData;
		currentTransmission.workQueue = { name, workQueueId };
		history.push({
			pathname: '/CreateInspection',
			currentTransmission,
		});
	};

	let columns = [
		...(type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP
		? [{
				title: 'ID',
				field: 'circuitId',
				align: 'center',
				sorting: true,
				render: (rowData) => (
					<label className={classes.circuitId}>{rowData.circuitId}</label>
				),
			}]
		: type === WORK_QUEUE_TYPE_TRANSMISSION 
		? [{
				title: 'Line ID',
				field: 'lineId',
				align: 'left',
				sorting: true,
				defaultFilter: lineIdFilter,
				render: (rowData) => (
					<label className={classes.circuitId}>{rowData.lineId}</label>
				),
			},
			{
				title: 'Structure ID',
				field: 'structureId',
				align: 'center',
				sorting: true,
				render: (rowData) => <label>{rowData.structureId}</label>
			}] 
		: type === WORK_QUEUE_TYPE_MANHOLE
		? [{
				title: 'ID',
				field: 'id',
				align: 'left',
				sorting: true,
				render: (rowData) => (
					<label className={classes.circuitId}>{rowData.id}</label>
				)
			}]
		: []),
		{
			title: 'Status',
			field: 'status',
			align: 'center',
			sorting: true,
			render: (item) => {
				return (
					<Grid container justifyContent="center">
						<Grid item style={{ width: '100%' }}>
							{type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP ? (
								<WorkItemsStatus
									webView
									workitem={item}
									workQueues={data}
									updateList={(list) => setData(list)}
									workQueueId={workQueueId}
									userUpdated={userUpdated}
									key={item.id}
									disabled = {disableAdminActions}
								/>
							) : (
								item.status
							)}
						</Grid>
					</Grid>
				);
			},
			lookup:
				type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP
					? lookupStatusCircuit
					: lookupStatus,
		},
		...(type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP || type === WORK_QUEUE_TYPE_MANHOLE
		? [{
				title: 'Town',
				field: 'town',
				align: 'center',
				sorting: true,
				render: (rowData) => (
					<Grid container justifyContent="center">
						<Grid item>
							<label>{rowData.town}</label>
						</Grid>
					</Grid>
				),
			},
			{
				title: 'AWC',
				field: 'areaWorkCenter',
				align: 'center',
				sorting: true,
				lookup: lookupAWC,
				render: (rowData) => (
					<Grid container justifyContent="center">
						<Grid item>
							<label>{rowData.areaWorkCenter}</label>
						</Grid>
					</Grid>
				),
			}]
		: []),
		...(type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP ? [{
			title: 'Division',
			field: 'division',
			sorting: true,
			align: 'center',
			lookup: lookupDivision,
			render: (rowData) => (
				<Grid container justifyContent="center">
					<Grid item>
						<label>{rowData.division}</label>
					</Grid>
				</Grid>
			),
		}] : []),
		{
			title: 'State',
			field: 'state',
			align: 'center',
			sorting: true,
			lookup: lookupState,
			render: (rowData) => (
				<Grid container justifyContent="center">
					<Grid item>
						<label>{rowData.state}</label>
					</Grid>
				</Grid>
			),
		},
		{
			title: 'Map',
			field: 'map',
			align: 'center',
			sorting: false,
			filtering: false,
			render: (rowData) => (
				<MapIcon
					className={classes.tableIcon}
					onClick={() => onMapClick(rowData)}
				/>
			),
		},
		{
			title: 'Inspections',
			field: 'inspectionsCount',
			align: 'center',
			sorting: type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP,
			render: (rowData) =>
				type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP ? (
					<Grid container justifyContent="center">
						<Grid>
							<CountOfInspections
								key={rowData.id}
								items={rowData.inspectionsCount}
							/>
						</Grid>
					</Grid>
				) : // No inspection, icon to create inspection
					!rowData.inspectionId && !disableAdminActions ? (
						<Tooltip title="Create Inspection" placement="top">
							<AddBoxTwoToneIcon
								color="primary"
								className={classes.tableIcon}
								onClick={() => redirectCreateInspection(rowData)}
							/>
						</Tooltip>
					) : (
						<Tooltip title="Inspection Detail" placement="top">
							<ImageSearchTwoToneIcon
								color="primary"
								className={classes.tableIcon}
								onClick={() =>
									redirectDetailInspection(
										rowData.inspectionId,
										rowData.workItemType,
										rowData.inspectionPartitionKey
									)
								}
							/>
						</Tooltip>
					),
			filtering: false,
		},
		{
			title: 'Days since Updated',
			field: 'daysSinceUpdated',
			align: 'center',
			sorting: true,
			customSort: (a, b) => tableAlphanumericalSort('daysSinceUpdated', a, b),
			render: (rowData) => (
				<Grid container justifyContent="center">
					<Grid item>
						<DaysSinceUpdated
							key={rowData.id}
							days={rowData.daysSinceUpdated}
						/>
					</Grid>
				</Grid>
			),
			filtering: false,
			type: 'numeric',
		},
		{
			title: 'Assigned',
			field: 'assignedUser.name',
			align: 'center',
			sorting: true,
			customFilterAndSearch: (filter, rowData) =>
				rowData.assignedUser && rowData.assignedUser.name
					? rowData.assignedUser.name
						.toLowerCase()
						.includes(filter.toLowerCase())
					: 'unassigned'.includes(filter.toLowerCase()),
			render: (item) => (
				<Grid container justifyContent="center">
					<Grid item style={{ width: '100%' }}>
						<UserAssignment
							item={item}
							webView
							workQueueId={workQueueId}
							updateList={(list) => setData(list)}
							workQueueList={data}
							userUpdated={userUpdated}
							key={item.id}
							disabled= {disableAdminActions}
						/>
					</Grid>
				</Grid>
			),
		},
	];

	const onMapClick = async (row) => {
		const lineIds = row.lineId ? encodeURIComponent(row.lineId) : '';
		let assetLocation = null;
		if (row.workItemType === ASSET_TYPE_TRANSMISSION) {
			const asset = await AssetsService.getAssets(
				row.id,
				row.town,
				'Structure'
			);
			assetLocation = asset?.location;
		}
		saveObjInLocalStorage(LOCALSTORAGE_CURRENT_WORK_ITEM, {
			circuitId: row.circuitId || '',
			lineIds,
			workQueueId,
			workQueue: name,
			workItemId: row.id,
			fromWorkItem: true,
			workItemType: row.workItemType,
			assetLocation,
		});
		history.push(
			`/Inspections/${lineIds || row.circuitId}/${row.workItemType}/`
		);
	};

	const onSearchChange = (e) => {
		saveObjInLocalStorage(WORK_ITEMS_GLOBAL_SEARCH, { search: e, type, name });
	};

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}

	return (
		<Table
			style={{ width: '100%' }}
			columns={columns}
			data={data}
			title=""
			options={{
				searchText: storageSearch ? storageSearch.search : '',
				filtering: true,
				pageSize: 10,
				showSelectAllCheckbox: false,
				selection: false,
				headerStyle: {
					color: '#44444F',
					textTransform: 'uppercase',
					fontSize: '11px',
					textAlign: 'center',
				},
				rowStyle: {
					height: '79px',
				},
				cellStyle: {
					textAlign: 'center',
				},
				pageSizeOptions: [5, 10, 25],
			}}
			onSearchChange={onSearchChange}
		/>
	);
};

export default WorkItemsTable;
