import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '../../Shared/atoms/Button';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import IconButton from '@material-ui/core/IconButton';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog({
	title,
	content,
	openDialog,
	showDialogActions = true,
	...props
}) {
	const [open, setOpen] = React.useState(false);

	const handleClose = (e) => {
		e.stopPropagation();
		setOpen(false);
		props.handleClickClose();
	};

	const handleConfirm = (e) => {
		e.stopPropagation();
		setOpen(false);
		props.handleConfirm();
	};

	useEffect(() => {
		setOpen(openDialog);
	}, [openDialog]);

	return (
		<div>
			{props.children}
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
				data-testid="alertDialog"
			>
				<DialogActions>
					{!showDialogActions && (
						<IconButton onClick={e => handleClose(e)}>
							<CloseTwoToneIcon color="primary" />
						</IconButton>
					)}
				</DialogActions>
				<DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
				<DialogContent>{content}</DialogContent>
				<DialogActions>
					{showDialogActions && (
						<Button
							variant="outlined"
							color="primary"
							onClick={e => handleClose(e)}
							label="Cancel"
						/>
					)}
					{showDialogActions && (
						<Button
							type="submit"
							color="primary"
							label="Yes"
							onClick={e => handleConfirm(e)}
						/>
					)}
					{!showDialogActions && (
						<Button
							variant="outlined"
							color="primary"
							onClick={e => handleClose(e)}
							label="Close"
						/>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}
