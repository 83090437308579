import React from "react";
import { default as MaterialCheckbox } from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core";

export const CheckBox = ({ title, onChange, checked, id="", disabled = false }) => {
  const handleChange = (e) => {
    onChange(e);
  };
  return (
    <FormControlLabel
      label={title}
      control={
        <MaterialCheckbox
          onChange={handleChange}
          checked={checked}
          color="primary"     
          inputProps={{"data-testid": id}}
          disabled={disabled}
        />
      }
    />
  );
};
