import React from "react";
import { CardHeader, Divider, CardContent, useTheme } from "@material-ui/core";
import { default as MaterialCard } from "@material-ui/core/Card";
import Table from '../../Shared/Table';
import { formatDate } from "../../../Utils";
import { redirectToDetailPage } from "../../../Utils/table";

const InspectionsTable = ({ inspections }) => {
  const theme = useTheme();
  const columns = [
    { title: 'Type', field: 'inspectionType' },
    { title: 'Status', field: 'status' },
    { title: 'Created By', field: 'createdBy' },
    {
      title: 'Created On',
      field: 'dateCreated',
      render: rowData => formatDate(rowData.dateCreated),
      type: "datetime",      
    }
  ]

  const onRowClick = ({ id, inspectionType, partitionKey }) => {
    redirectToDetailPage(id, inspectionType, partitionKey);
  };

  return (
    <MaterialCard>
      <CardHeader title={"Latest Inspections"} />
      <Divider />
      <CardContent>
        <Table
          style={{ marginTop: -65}}
          columns={columns}
          data={inspections}
          title=""
          options={{
            filtering: false,
            search: false,
            pageSize: 10,
            showSelectAllCheckbox: false,
            selection: false,
            headerStyle: {
              color: theme.palette.primary.main,
              fontWeight: 'bold'
            },
            exportButton: false,
            pageSizeOptions: [5, 10, 25]
          }}
          onRowClick= {onRowClick}      
        />
      </CardContent>
    </MaterialCard>
  );      
}

export default InspectionsTable;
