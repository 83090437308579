import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
	formatDate,
	isArrayNotEmpty,
	isDateInRange,
	showSnackbar,
	getToday,
} from '../../Utils';
import Table from '../Shared/Table';
import DateTimePickerFromToFilter from '../Shared/Table/DateTimePickerFromToFilter';
import {
	getLookups,
	isTextInclude,
	redirectToDetailPage,
} from '../../Utils/table';
import WorkQueueService from '../../services/WorkQueueService';
import { INSPECTION_TYPE_TRANSMISSION } from '../../Constants';

const TransmissionsReport = ({ setLoading }) => {
	const [transmissions, setTransmissions] = useState([]);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		WorkQueueService.getReport(INSPECTION_TYPE_TRANSMISSION)
			.then((result) => {
				setLoading(false);
				setTransmissions(result.data || []);
			})
			.catch(() => {
				showSnackbar(
					enqueueSnackbar,
					'An error occurred getting the Transmissions',
					'error'
				);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const lookups = getLookups(transmissions, [
		'inspectionStatus',
		'region',
		'overallConditionRating',
		'structureConditionRating',
		'hardwareConditionRating',
	]);

	const customFilterCondition = (term, totalCondition, arrayConditions) => {
		if (isArrayNotEmpty(term)) {
			let found = false;
			for (let i = 0; i < term.length; i++) {
				if (isTextInclude(term[i], totalCondition)) {
					found = true;
					break;
				}
			}
			return found;
		} else if (Array.isArray(term) && term.length === 0) {
			return true;
		} else if (term.length) {
			let filteredArray = [];
			if (arrayConditions)
				filteredArray = arrayConditions.filter(function (el) {
					return el.rating !== 'A' && isTextInclude(term, el.name);
				});
			if (totalCondition)
				return isTextInclude(term, totalCondition) || filteredArray.length > 0;
		}
	};

	const exportCsv = (allColumns, allData) => {
		try {
			if (allData.length > 0) {
				const csvRows = [];
				const headers = [
					'Region',
					'Line #',
					'Structure #',
					'GIS #',
					'Pole not in database',
					'Pole not inspected',
					'Why not inspected',
					'Circuit Designation',
					'Material',
					'Structure Type',
					'Insulator Configuration',
					'Insulator In Jumper Loop',
					'Bells/Poly Or Rigid Post',
					'Line Angle',
					'Guy Anchors Present',
					'Guy Anchor Or Guy Marker Problems',
					'Danger / Warning Signs Affixed On Structure',
					'Danger / Warning Signs Missing (Details)',
					'Down Lead / Ground Wire Damages',
					'Down Lead / Ground Wire Issues',
					'Opgw Splice Can',
					'Opgw Splice Can; Grounding; And Coil Installed Correctly',
					'Lightning Arrestor Present',
					'# Lightning Arrestors Present',
					'Lightning Arrestor Chain',
					'Accessible Via',
					'Why Structure Not Accessible',
					'Notes',
					'Field Inspector (Visual Observer)',
					'Pilot',
					'Leaning / Bent Steel',
					'Corrosion (Steel)',
					'Rust (Steel)',
					'Structural Integrity; Visual (Steel)',
					'Details Of Structure Issues (Steel)',
					'Visual Damage To Structure (Steel)',
					'Visual Damage Details (Steel)',
					'Damaged Pole Reference Number (Steel)',
					'Shell Rot (Wood)',
					'Decay (Wood)',
					'Pole Splits / Breaks (Wood)',
					'Pole Fracture (Wood)',
					'Structural Integrity (Wood)',
					'Details Of Structure Issues (Wood)',
					'Insect Damage',
					'Insect Damage Details',
					'Woodpecker Damage',
					'Woodpecker Holes (Severity)',
					'Woodpecker Holes (Location)',
					'Woodpecker Damage Details',
					'Damaged Pole Number (Wood)',
					'Pole Splits / Breaks (Compsit)',
					'Pole Fracture (Compsit&Conc)',
					'Structural Integrity; Visual (Composite & Concrete)',
					'Details Of Structure Issues (Composite & Concrete)',
					'Damaged Pole Number (Composite & Concrete)',
					'Hardware Damages',
					'Hardware Damage',
					'Hardware Damage Details',
					'Crossarm Damages',
					'Crossarm Damage',
					'Crossarm Split/Breaks',
					'Crossarm Damage Details',
					'Insulator Damages',
					'Insulator Condition',
					'# Bells Chipped / Broken Per String',
					'Insulator Damage Details',
					'Conductor Damages',
					'Conductor Damage (Location)',
					'Conductor Strand Damage (Severity)',
					'Conductor Splice Damage (Severity)',
					'Conductor Attachment Point Damage (Severity)',
					'Conductor Burned (Severity)',
					'Conductor Damage Details',
					'Climbing Steps Present',
					'Are Steps Higher Than 12 Feet',
					'Culvert Damaged Or Open',
					'Culvert Damage (Severity)',
					'Culvert Damage Details',
					'Faa Lighting',
					'Faa Lighting Damage',
					'Faa Lighting Damage Details',
					'Faa Marker Balls',
					'Faa Marker Ball Damage',
					'Faa Marker Ball Damage Details',
					'Pcs (Cell) Antennas On Structure',
					'Bird Nest',
					'Bird Nest Details',
					'Erosion',
					'Erosion (Severity)',
					'Erosion Details',
					'Counterpoise Exposed',
					'Counterpoise Details',
					'Ground Clearance Concern',
					'High Brush / Trees',
					'Hazardous Trees',
					'Ground Clearance Or High / Hazardous Tree Details',
					'Encroachments',
					'Encroachment Details',
					'Miscellaneous Problems To Report',
					'Miscellaneous Problems',
					'Miscellaneous Problems Details',
					'Structure Height (From Pole Stamp Or Nameplate - Feet)',
					'Office Inspector',
					'Status',
					'Work Queue',
					'Comments',
					'Date Created',
					'Created By',
					'Date Updated',
					'Updated By',
					'Unique ID',
					'Inspection Type',
				];
				const keys = [
					'region',
					'lineId',
					'structureId',
					'GISNumber',
					'poleNotInDB',
					'isPoleUnableToBeInspected',
					'poleUnableToBeInspectedDetails',
					'circuitDesignation',
					'materialType',
					'poleType',
					'insulatorConfiguration',
					'hasJumperLoop',
					'jumperLoop',
					'lineAngle',
					'hasGuyAnchors',
					'guyAnchors',
					'dangerWarningSign',
					'dangerWarningSignDetails',
					'hasGroundWireDamage',
					'groundWireDamage',
					'hasOpticalGroundWireSpliceCan',
					'isOpticalGroundWireSpliceCanInstalledCorrectly',
					'hasLightningArrestor',
					'lightningArrestorsCount',
					'lightningArrestorCondition',
					'accessibleBy',
					'accessibleByDetails',
					'notes',
					'fieldInspector',
					'pilot',
					'leaningOrBentSeverity',
					'corrosionSeverity',
					'rustSeverity',
					'structuralIntegritySteel',
					'structureIssueDetailsSteel',
					'isVisualDamage',
					'visualDamageDetails',
					'damagedPoleReferenceNumberDetailsSteel',
					'shellRotSeverity',
					'decaySeverity',
					'splitOrBreakSeverityWood',
					'fractureSeverityWood',
					'structuralIntegrityWood',
					'structureIssueDetailsWood',
					'isInsectDamage',
					'insectDamageDetails',
					'isWoodpeckerDamage',
					'woodpeckerHolesSeverity',
					'woodpeckerHolesLocation',
					'woodpeckerHolesDetails',
					'damagedPoleReferenceNumberDetailsWood',
					'splitOrBreakSeverityConcrete',
					'fractureSeverityConcrete',
					'structuralIntegrityConcrete',
					'structureIssueDetailsConcrete',
					'damagedPoleReferenceNumberDetailsConcrete',
					'hardwareDamageSeverity',
					'isHardwareDamage',
					'hardwareDamageDetails',
					'isCrossarmDamage',
					'crossarmDamageSeverity',
					'crossarmSplitOrBreakSeverity',
					'crossarmDamageDetails',
					'isInsulatorDamage',
					'insulatorDamageSeverity',
					'insulatorChippedOrBrokenBellsNumberPerString',
					'insulatorDamageDetails',
					'isConductorDamage',
					'conductorDamageLocation',
					'conductorStrandDamageSeverity',
					'conductorSpliceDamageSeverity',
					'conductorAttachmentPointDamageSeverity',
					'conductorBurnedDamageSeverity',
					'conductorDamageDetails',
					'isClimbingSteps',
					'areStepsHigherThan12Feet',
					'isCulvertDamageOrOpen',
					'culvertDamageSeverity',
					'culvertDamageDetails',
					'isFAALighting',
					'isFAALightingDamage',
					'faaLightingDamageDetails',
					'isFAAMarkerBalls',
					'isFAAMarkerBallsDamage',
					'faaMarkerBallsDamageDetails',
					'hasPCSAntennasOnStructure',
					'hasBirdsNest',
					'birdsNestDetails',
					'hasErosion',
					'erosionSeverity',
					'erosionDetails',
					'isCounterpoiseExposed',
					'counterpoiseDetails',
					'hasGroundClearanceConcern',
					'hasHighBrushOrTrees',
					'hasHazardousTrees',
					'groundClearanceHighOrHazardousTreeDetails',
					'hasEncroachments',
					'encroachmentDetails',
					'hasMiscellaneousProblemsToReport',
					'miscellaneousProblemsSeverity',
					'miscellaneousProblemsDetails',
					'structureHeight',
					'officeInspector',
					'inspectionStatus',
					'workQueueName',
					'comments',
					'dateCreated',
					'createdBy',
					'dateUpdated',
					'updatedBy',
					'inspectionId',
					'inspectionType',
				];

				csvRows.push(headers.join(','));

				// eslint-disable-next-line no-inner-declarations
				function getMaterial(materialType) {
					if (
						materialType === 'Custom Steel' ||
						materialType === 'Steel' ||
						materialType === 'Galvanized' ||
						materialType === 'Painted Steel' ||
						materialType === 'Weathering'
					) {
						return 'Steel';
					} else if (
						materialType === 'Laminate Wood' ||
						materialType === 'Natural Wood'
					) {
						return 'Wood';
					} else if (
						materialType === 'Concrete' ||
						materialType === 'Composite'
					) {
						return 'Concrete';
					}
				}

				for (const row of allData) {
					const values = keys.map((key) => {
						let value;
						if (key === 'lineIdStructureId') {
							value = `${row.lineId} - ${row.structureId}`;
						} else if (
							key === 'structuralIntegritySteel' &&
							getMaterial(row.materialType) === 'Steel'
						) {
							value = row.structuralIntegrity;
						} else if (
							key === 'structuralIntegrityConcrete' &&
							getMaterial(row.materialType) === 'Concrete'
						) {
							value = row.structuralIntegrity;
						} else if (
							key === 'structuralIntegrityWood' &&
							getMaterial(row.materialType) === 'Wood'
						) {
							value = row.structuralIntegrity;
						} else if (
							key === 'structureIssueDetailsSteel' &&
							getMaterial(row.materialType) === 'Steel'
						) {
							value = row.structureIssueDetails;
						} else if (
							key === 'structureIssueDetailsConcrete' &&
							getMaterial(row.materialType) === 'Concrete'
						) {
							value = row.structureIssueDetails;
						} else if (
							key === 'structureIssueDetailsWood' &&
							getMaterial(row.materialType) === 'Wood'
						) {
							value = row.structureIssueDetails;
						} else if (
							key === 'damagedPoleReferenceNumberDetailsSteel' &&
							getMaterial(row.materialType) === 'Steel'
						) {
							value = row.damagedPoleReferenceNumberDetails;
						} else if (
							key === 'damagedPoleReferenceNumberDetailsConcrete' &&
							getMaterial(row.materialType) === 'Concrete'
						) {
							value = row.damagedPoleReferenceNumberDetails;
						} else if (
							key === 'damagedPoleReferenceNumberDetailsWood' &&
							getMaterial(row.materialType) === 'Wood'
						) {
							value = row.damagedPoleReferenceNumberDetails;
						} else if (
							key === 'splitOrBreakSeverityWood' &&
							getMaterial(row.materialType) === 'Wood'
						) {
							value = row.splitOrBreakSeverity;
						} else if (
							key === 'splitOrBreakSeverityConcrete' &&
							getMaterial(row.materialType) === 'Concrete'
						) {
							value = row.splitOrBreakSeverity;
						} else if (
							key === 'fractureSeverityWood' &&
							getMaterial(row.materialType) === 'Wood'
						) {
							value = row.fractureSeverity;
						} else if (
							key === 'fractureSeverityConcrete' &&
							getMaterial(row.materialType) === 'Concrete'
						) {
							value = row.fractureSeverity;
						} else {
							value = row[key];
						}
						let escaped = ('' + value).replace(/"/g, "'").replace(/'/g, "'");
						return escaped === 'undefined' ? '""' : `"${escaped}"`;
					});
					csvRows.push(values.join(','));
				}

				const csvRowsString = csvRows.join('\n');
				const blob = new Blob([csvRowsString], { type: 'text/csv' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.setAttribute('href', url);
				a.setAttribute(
					'download',
					'Transmissions ' + getToday('YYYY-MM-DDTHHmmss') + '.csv'
				);
				document.body.appendChild(a);
				a.click();
			} else {
				showSnackbar(
					enqueueSnackbar,
					'The page contains no data to export',
					'warning'
				);
			}
		} catch (err) {
			showSnackbar(
				enqueueSnackbar,
				'An error occurred exporting the data',
				'error'
			);
		}
	};

	const onRowClick = ({ inspectionId, inspectionType, partitionKey }) => {
		redirectToDetailPage(inspectionId, inspectionType, partitionKey);
	};

	const columns = [
		{ title: 'Name', field: 'workQueueName' },
		{
			title: 'Status',
			field: 'inspectionStatus',
			lookup: lookups.inspectionStatus,
		},
		{ title: 'Region', field: 'region', lookup: lookups.region },
		{
			title: 'Line Id',
			field: 'lineId',
			headerStyle: { textAlign: 'left' },
			cellStyle: { textAlign: 'left' },
		},
		{
			title: 'Structure Id',
			field: 'structureId',
			headerStyle: { textAlign: 'left' },
			cellStyle: { textAlign: 'left' },
		},
		{
			title: 'Overall Condition',
			field: 'overallConditionRating',
			cellStyle: { textAlign: 'center' },
			lookup: lookups.overallConditionRating,
		},
		{
			title: 'Structure Condition',
			width: '150px',
			field: 'structureConditionRating',
			cellStyle: { textAlign: 'center' },
			lookup: lookups.structureConditionRating,
			customFilterAndSearch: (term, rowData) =>
				customFilterCondition(
					term,
					rowData.structureConditionRating,
					rowData.structureConditionRatings
				),
		},
		{
			title: 'Hardware Condition',
			field: 'hardwareConditionRating',
			width: '150px',
			cellStyle: { textAlign: 'center' },
			lookup: lookups.hardwareConditionRating,
			customFilterAndSearch: (term, rowData) =>
				customFilterCondition(
					term,
					rowData.hardwareConditionRating,
					rowData.hardwareConditionRatings
				),
		},
		{
			title: 'Created On',
			field: 'dateCreated',
			render: (rowData) => formatDate(rowData.dateCreated),
			type: 'datetime',
			customFilterAndSearch: (filter, rowData) =>
				isDateInRange(filter, rowData.dateCreated),
			filterComponent: (props) => {
				const { columnDef, onFilterChanged } = props;
				return (
					<DateTimePickerFromToFilter
						key={columnDef.tableData.id}
						onFilterChanged={(e, a) => onFilterChanged(e, a)}
						columnDef={columnDef}
						locale={props.dateTimePickerLocalization}
					/>
				);
			},
		},
	];

	const detailPanel = (rowData) => (
		<table
			width="100%"
			cellSpacing="0"
			cellPadding="0"
			style={{ tableLayout: 'fixed' }}
		>
			<tbody style={{ display: 'table-row-group' }}>
				<tr>
					<td style={{ width: 50 }}></td>
					<td
						colSpan="5"
						style={{
							padding: '0 16px',
							boxSizing: 'border-box',
							display: 'table-cell',
							width: 'calc((100% - 300px) / 7)',
						}}
					></td>
					<td
						valign="top"
						style={{ padding: '0 8px', width: '150px', fontSize: '0.8em' }}
					>
						<table align="center" cellSpacing="0" cellPadding="2">
							{rowData.structureConditionRatings?.map(
								(structure, index) =>
									structure.rating !== 'A' && (
										<tr key={structure.name + index}>
											<td
												valign="top"
												align="right"
												style={{ paddingRight: 5 }}
											>{`${structure.name}: `}</td>
											<td valign="top">
												<strong>{structure.rating}</strong>
											</td>
										</tr>
									)
							)}
						</table>
					</td>
					<td
						valign="top"
						style={{ padding: '0 8px', width: '150px', fontSize: '0.8em' }}
					>
						<table align="center" cellSpacing="0" cellPadding="2">
							{rowData.hardwareConditionRatings?.map(
								(hardware) =>
									hardware.rating !== 'A' && (
										<tr key={hardware.name + rowData.id}>
											<td
												valign="top"
												align="right"
												style={{ paddingRight: 5 }}
											>{`${hardware.name}: `}</td>
											<td valign="top">
												<strong>{hardware.rating}</strong>
											</td>
										</tr>
									)
							)}
						</table>
					</td>
					<td
						style={{
							padding: '0 16px',
							boxSizing: 'border-box',
							display: 'table-cell',
							width: 'calc((100% - 300px) / 7)',
						}}
					></td>
				</tr>
			</tbody>
		</table>
	);

	return (
		<Table
			style={{ width: '95%' }}
			columns={columns}
			data={transmissions}
			title=""
			options={{
				search: true,
				filtering: true,
				pageSize: 10,
				showSelectAllCheckbox: false,
				selection: false,
				headerStyle: {
					color: '#007749',
					fontWeight: 'bold',
				},
				exportButton: true,
				exportCsv,
				exportAllData: true,
				pageSizeOptions: [5, 10, 25],
			}}
			onRowClick={onRowClick}
			detailPanel={(rowData) => detailPanel(rowData)}
		/>
	);
};

export default TransmissionsReport;
