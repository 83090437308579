import { Box } from "@material-ui/core";
import React from "react";
import Button from "../../../Shared/atoms/Button";

export const StepperControl = ({
  activeStep,
  stepsCount,
  onFinishStepper,
  onActiveStepChange,
  submitting,
  setSubmitting,
  detailTransmission,
  editing
}) => {
  const [skipped, setSkipped] = React.useState(new Set());
  
  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNavigation = next => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (!detailTransmission || (detailTransmission && editing)) {
      setSubmitting(true)
      if (next && activeStep === stepsCount - 1) {
        onFinishStepper(true, false, next);
      } else {
        onFinishStepper(false, true, next);
        setSkipped(newSkipped);
      }
    } else {
      onActiveStepChange(next ? activeStep + 1 : activeStep - 1);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        {activeStep !== 2 && !detailTransmission && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => onFinishStepper(true, false)}
            label="Save"
            id="saveButton"
            disabled={submitting}
          />
        )}
        {(activeStep === 2 || (detailTransmission && activeStep === 1)) && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleNavigation(false)}
            label="Back"
            id="backButton"
            disabled={submitting}
          />
        )}
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          color="primary"
          onClick={() => handleNavigation(true)}
          label={activeStep === stepsCount - 1 ? "Submit" : "Next"}
          id="nextButton"
          disabled={submitting || (detailTransmission && !editing && activeStep === stepsCount - 1)}
        />
      </Box>
    </>
  );
};
