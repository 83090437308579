import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Select from '../../Shared/atoms/Select';
import TextFieldComponent from '../../Shared/atoms/TextInput';
import StatusPerIssue from '../StatusPerIssueType';

const DamageFormFields = ({
	damageTypesOptions,
	setDamageTypes,
	damageTypes,
	issueTypesOptions,
	setIssueTypes,
	issueTypesSelected,
	circuitID,
	setCircuitID,
	devicesList,
	devices,
	setDevices,
	poleNumber,
	setPoleNumber,
	workQueueName
}) => {

	const useStyles = makeStyles((theme) => ({
		autocomplete: {
			'& .MuiChip-deletable': {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.secondary.main,
				'& .MuiChip-deleteIcon': {
					color: theme.palette.secondary.main
				}
			},
		},
		autocompleteOptions: {
			'&.Mui-selected': {
				backgroundColor: theme.palette.primary.light,
				color: theme.palette.secondary.main,
				'&:hover': {
					backgroundColor: theme.palette.primary.light,
					color: theme.palette.secondary.main,
				}
			},
			'&:hover': {
				backgroundColor: theme.palette.primary.light,
				color: theme.palette.secondary.main,
			}
		}
	}));
	const classes = useStyles();

	const CustomPaper = (props) => {
		return <Paper className={classes.autocompleteOptions} {...props} />;
	};

	return <>    
		<Grid item xs={12}>
			<Select
				label="Damage Type"
				selectOptions={damageTypesOptions}
				selectItem={setDamageTypes}
				itemSelected={damageTypes}
				required={true}
				helperText="Multiple selection allowed"
			/>
		</Grid>
		<Grid item xs={12}>
			<Autocomplete
				className={classes.autocomplete}
				multiple
				options={issueTypesOptions}
				getOptionLabel={(option) => option && `${option.name}: ${option.description}`}
				onChange={(_event, value) => {
					setIssueTypes(value);
				}}
				value={issueTypesSelected}
				variant="outlined"
				PaperComponent={CustomPaper}
				required={false}
				renderInput={(params) =>          
					<TextFieldComponent          
						{...params}  
						value={issueTypesSelected ? issueTypesSelected.toString() : ''}
						label="Issue Type"
						placeholder=""
						required={issueTypesSelected.length === 0}
						onChange={() => {}}
					/>
				}
			/>
		</Grid>
		<StatusPerIssue 
			issueTypes={issueTypesSelected} 
			setIssueTypes={(items) => setIssueTypes([...items])} 
		/>

		{workQueueName && workQueueName !== '' && <Grid item xs={12}>
			<TextField
				label="Work Queue"
				variant="outlined"
				fullWidth
				onChange={() => {}}
				id="workQueue"
				value={workQueueName}
				disabled={true}
			/>
		</Grid>}
		<Grid item xs={12}>
			<TextField
				label="Circuit ID"
				variant="outlined"
				fullWidth
				onChange={e => setCircuitID(e.target.value)}
				id="circuitIdInput"
				value={circuitID}
				disabled={circuitID !== '' && workQueueName !== ''}
			/>
		</Grid>
		{devicesList && devicesList.length > 0 && <Grid item xs={12}>
			<Select
				label="Devices"
				checkbox
				multiple={true}
				selectOptions={devicesList}
				selectItem={setDevices}
				itemSelected={devices}
				required={false}
				id="devicesList"
			/>
		</Grid>}
		<Grid item xs={12}>
			<TextField
				label="Pole Number"
				variant="outlined"
				fullWidth
				onChange={(e)=>setPoleNumber(e.target.value)}
				id="poleNumberInput"
				value={poleNumber}
			/>
		</Grid>
	</>;
};

export default DamageFormFields;