import { callApi } from '../callApi';
import { RESOURCE_AI, RESOURCE_INSPECTIONS } from '../Constants';

class AIService {
	objectDetection(modelName, image) {
		const formData = new FormData();
		formData.append('image', image);
		
		return callApi({
			method: 'post',
			url: `${RESOURCE_AI}ObjectDetection?ModelName=${modelName}`,
			data: formData,
			contentType: 'multipart/form-data',
		}, true); 
	}

	imageMetadata(imageId) {
		return callApi({ method: 'get', url: `${RESOURCE_INSPECTIONS}ImageMetadata/${imageId}` }); 
	}

	saveImageMetadata(data) {
		return callApi({ method: 'post', url: `${RESOURCE_INSPECTIONS}ImageMetadata`, data }); 
	}
}
export default new AIService ();