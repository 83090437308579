import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { CheckBox } from "../atoms/Checkbox";

const useStyles = makeStyles((theme) => ({
  required: {
    color: theme.palette.primary.required,
  },
}));

export const CheckboxGroup = ({
  title,
  checkBoxes = [],
  onGroupChange,
  required,
  requiredFieldMessage = "Required field",
  disabled = false
}) => {
  const onChange = (e, label) => {
    const checkBoxSelected = checkBoxes.findIndex(
      (checkBoxOpt) => checkBoxOpt.label === label
    );
    const newCheckBoxes = [...checkBoxes];
    newCheckBoxes[checkBoxSelected] = { label, checked: e.target.checked };
    onGroupChange(newCheckBoxes);
  };
  const classes = useStyles();

  const checkIfAtLeastOneIsSelected = () => {
    return  checkBoxes.find((checkbox) => checkbox.checked);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">
          {required && <span className={classes.required}>* </span>} {title}
        </FormLabel>
        <FormGroup>
          <Grid container display="column">
            {checkBoxes.length > 0 &&
              checkBoxes.map(({ label, checked }) => {
                return (
                  <Grid item key={label}>
                    <CheckBox
                      title={label}
                      checked={checked}
                      onChange={(e) => onChange(e, label)}
                      disabled={disabled}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </FormGroup>
        {required && !checkIfAtLeastOneIsSelected() && (
          <FormHelperText
            style={{ marginLeft: "14px" }}
            className={classes.required}
          >
            {requiredFieldMessage}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};
