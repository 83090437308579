import { callApi } from '../callApi';
import { RESOURCE_VIDEOS, RESOURCE_INSPECTIONS } from '../Constants';

class VideoService {
	async uploadChunk(fileName, chunk, index, isLastChunk) {
		const formData = new FormData();
		formData.append('chunk', chunk, fileName);
		return callApi({
			method: 'post',
			url: `${RESOURCE_INSPECTIONS}${RESOURCE_VIDEOS}?index=${index}&isLastChunk=${isLastChunk}`,
			data: formData,
			contentType: 'multipart/form-data',
		});
	}

	async getVideoStream(blobName) {
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}${RESOURCE_VIDEOS}${blobName}`,
			responseType: 'blob'
		});
	}

	async getVideoSasUri(blobName) {
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}${RESOURCE_VIDEOS}${blobName}/token`,
		});
	}

	async getVideos(assetId) {
		const assetUrl = assetId ? `?assetId=${assetId}` : '';
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}${RESOURCE_VIDEOS}assets${assetUrl}`,
		});
	}
}

export default new VideoService();
