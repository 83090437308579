import { Grid } from "@material-ui/core";
import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  damageSeverityRadios,
} from "../../../../../../Constants";
import { CheckBox } from "../../../../../Shared/atoms/Checkbox";
import TextFieldComponent from "../../../../../Shared/atoms/TextInput";
import { CheckboxGroup } from "../../../../../Shared/CheckboxGroup";
import { RadioGroup } from "../../../../../Shared/RadioGroup";
import { onlyNumericValuesOnInutTypeNumber } from "../../../../../../Utils";

const useStyles = makeStyles(() => ({
  detailGroup: {
    margin: '0 0 0 31px'
  }
}))

export const SpecificsAll = ({ form, onChangeInput, showDetailInput, isReadOnly, editing }) => {
  const classes = useStyles();
  
  return (
    <>  
      <Grid item xs={12}>
        <CheckBox
          title="Hardware Damage"
          checked={form.isHardwareDamage}
          id="isHardwareDamage"
          onChange={(event) =>
            onChangeInput(event.target.checked, "isHardwareDamage")
          }
          disabled={isReadOnly && !editing}
        />
      </Grid>
      {form.isHardwareDamage &&
      <Grid container spacing={2} className={classes.detailGroup}>
        <Grid item xs={12}>
          <RadioGroup
            title="Hardware Damage Severity"
            value={form.hardwareDamageSeverity}
            radios={damageSeverityRadios}
            onChange={(e) =>
              onChangeInput(e.target.value, "hardwareDamageSeverity")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {showDetailInput(["hardwareDamageSeverity"]) &&
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.hardwareDamageDetails}
            label="Hardware Damage Details"
            fullWidth
            id="hardwareDamageDetails"
            variant="outlined"
            type="text"
            placeholder=""
            onChange={(e) =>
              onChangeInput(e, "hardwareDamageDetails")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        }
      </Grid>}
      <Grid item xs={12}>
        <CheckBox
          title="Crossarm Damages"
          checked={form.isCrossarmDamage}
          id="isCrossarmDamage"
          onChange={(event) =>
            onChangeInput(event.target.checked, "isCrossarmDamage")
          }
          disabled={isReadOnly && !editing}
        />
      </Grid>
      {form.isCrossarmDamage &&
      <Grid container spacing={2} className={classes.detailGroup}>
        <Grid item xs={12}>
          <RadioGroup
            title="Crossarm Damage"
            value={form.crossarmDamageSeverity}
            radios={damageSeverityRadios}
            onChange={(e) =>
              onChangeInput(e.target.value, "crossarmDamageSeverity")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            title="Crossarm Split/Breaks"
            radios={damageSeverityRadios}
            value={form.crossarmSplitOrBreakSeverity}
            onChange={(e) =>
              onChangeInput(e.target.value, "crossarmSplitOrBreakSeverity")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {showDetailInput(["crossarmDamageSeverity", "crossarmSplitOrBreakSeverity"]) &&
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.crossarmDamageDetails}
            label="Crossarm Damage Details"
            fullWidth
            id="crossarmDamageDetails"
            variant="outlined"
            type="text"
            placeholder=""
            onChange={(e) =>
              onChangeInput(e, "crossarmDamageDetails")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        }
      </Grid>}

      <Grid item xs={12}>
        <CheckBox
          title="Insulator Damages"
          checked={form.isInsulatorDamage}
          id="isInsulatorDamage"
          onChange={(event) =>
            onChangeInput(event.target.checked, "isInsulatorDamage")
          }
          disabled={isReadOnly && !editing}
        />
      </Grid>
      {form.isInsulatorDamage &&
      <Grid container spacing={2} className={classes.detailGroup}>
        <Grid item xs={12}>
          <RadioGroup
            title="Insulator Condition"
            radios={damageSeverityRadios}
            value={form.insulatorDamageSeverity}
            onChange={(e) =>
              onChangeInput(e.target.value, "insulatorDamageSeverity")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {showDetailInput(["insulatorDamageSeverity"]) &&
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.insulatorDamageDetails}
            label="Insulator Damage Details"
            fullWidth
            id="insulatorDamageDetails"
            variant="outlined"
            type="text"
            placeholder=""
            onChange={(e) =>
              onChangeInput(e, "insulatorDamageDetails")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>}
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.insulatorChippedOrBrokenBellsNumberPerString}
            label="# Bells Chipped/ Broken per String"
            fullWidth
            errorMessage="Required Field. Value must be from 1 to 20"
            id="insulatorChippedOrBrokenBellsNumberPerString"
            variant="outlined"
            type="number"
            placeholder="1 - 20"
            onChange={(e) =>
              onChangeInput(e, "insulatorChippedOrBrokenBellsNumberPerString")
            }
            required={true}
            onKeyDown={(evt) => onlyNumericValuesOnInutTypeNumber(evt)}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        
      </Grid>}

      <Grid item xs={12}>
        <CheckBox
          title="Conductor Damages"
          checked={form.isConductorDamage}
          id="isConductorDamage"
          onChange={(event) =>
            onChangeInput(event.target.checked, "isConductorDamage")
          }
          disabled={isReadOnly && !editing}
        />
      </Grid>
      {form.isConductorDamage &&
      <Grid container spacing={2} className={classes.detailGroup}>
        <Grid item xs={12}>
          <CheckboxGroup
            title="Conductor Damage Location"
            checkBoxes={form.conductorDamageLocation}
            onGroupChange={(e) => onChangeInput(e, "conductorDamageLocation")}
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            title="Conductor Strand Damage (Severity)"
            radios={damageSeverityRadios}
            value={form.conductorStrandDamageSeverity}
            onChange={(e) =>
              onChangeInput(e.target.value, "conductorStrandDamageSeverity")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            title="Conductor Spice Damage (Severity)"
            radios={damageSeverityRadios}
            value={form.conductorSpliceDamageSeverity}
            onChange={(e) =>
              onChangeInput(e.target.value, "conductorSpliceDamageSeverity")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            title="Conductor Attachment Point Damage (Severity)"
            radios={damageSeverityRadios}
            value={form.conductorAttachmentPointDamageSeverity}
            onChange={(e) =>
              onChangeInput(e.target.value, "conductorAttachmentPointDamageSeverity")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            title="Conductor Burned (Severity)"
            radios={damageSeverityRadios}
            value={form.conductorBurnedDamageSeverity}
            onChange={(e) =>
              onChangeInput(e.target.value, "conductorBurnedDamageSeverity")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        {showDetailInput([
          "conductorStrandDamageSeverity",
          "conductorSpliceDamageSeverity",
          "conductorAttachmentPointDamageSeverity",
          "conductorBurnedDamageSeverity"]) &&
        <Grid item xs={12}>
          <TextFieldComponent
            value={form.conductorDamageDetails}
            label="Conductor Damage Details"
            fullWidth
            id="conductorDamageDetails"
            variant="outlined"
            type="text"
            placeholder=""
            onChange={(e) =>
              onChangeInput(e, "conductorDamageDetails")
            }
            required={true}
            disabled={isReadOnly && !editing}
          />
        </Grid>
        }
      </Grid>}
    </>
  );
};
