import { RESOURCE_INSPECTIONS } from '../Constants';
import { convertToQueryString } from '../Utils';
import { callApi } from '../callApi';

class TownService {
	async getTowns(state) {
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}towns?state=${state}`
		});
	}

	async getAssetsTowns(filters) {
		const queryString = convertToQueryString(filters);

		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}AssetsTowns${queryString}`
		});
	}
}

export default new TownService();