import Geocode from 'react-geocode';
const { REACT_APP_MAPS_API_KEY } = process.env;

Geocode.setApiKey(REACT_APP_MAPS_API_KEY);

Geocode.setLanguage('en');

Geocode.setRegion('us');

// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
Geocode.setLocationType('ROOFTOP');

export const reverseGeocode = (lat, lng) => {
	return new Promise((resolve, reject)=>{
		Geocode.fromLatLng(lat, lng).then(
			(response) => {
				const address = response.results[0].formatted_address;
				resolve(address);
			},
			() => {
				reject({});
			}
		);
	});
};

export const geocodeAddress = (address) => {
	return new Promise((resolve, reject) => {
		Geocode.fromAddress(address).then(
			response => {
				const { lat, lng } = response.results[0].geometry.location;
				resolve({ lat, lng });
			},
			error => {
				// console.error("Error in Geocoding:", error);
				reject(error);
			}
		);
	});
};

export const isValidLatLong = input => {
	const latLongRegex = /^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)),\s*([-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?))$/;
	if (latLongRegex.test(input)) {
		const parts = input.split(',');
		const latitude = parseFloat(parts[0].trim());
		const longitude = parseFloat(parts[1].trim());

		if (latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180) {
			return true;
		}
	}
	return false;
};