import React, {useEffect, useState} from "react"
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone'
import InspectionsService from '../../../services/InspectionsService'
import PageHeader from "../../Shared/PageHeader"
import ProgressBar from "../../Shared/ProgressBar"
import InspectionsReportsTable from "../../InspectionsReport"

const InspectionsReport = () => {
  
  const [inspections, setInspections] = useState([])
  const [inspectionsLoading, setInspectionsLoading] = useState(true)

  useEffect(() => {
    async function fetchData(){
      setInspectionsLoading(true);
      const filters = {
        withoutPagination: true
      }
      setInspections(await InspectionsService.getAllInspections(filters))
      setInspectionsLoading(false);
    }
    fetchData();
  }, [])

  return (
    <>
      {inspectionsLoading && <ProgressBar />}
      <PageHeader headerText="" icon={<AssessmentTwoToneIcon color="secondary" />} />
      {!inspectionsLoading && inspections.inspections && <InspectionsReportsTable inspections={inspections.inspections}/>}
    </>
  );
};

export default InspectionsReport;
