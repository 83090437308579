import React, { useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import EmojiObjectsTwoToneIcon from '@material-ui/icons/EmojiObjectsTwoTone';
import Button from "../../Shared/atoms/Button";
import AlertDialogSlide from "../../Shared/Dialog"

const PhotoGuidelines = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const useStyles = makeStyles((theme) => ({
    titles: {
      color: theme.palette.primary.main,
      margin: 0
    },
    modalContent: {
      color: "#000"
    },
    mixedCaseLabel: {
      textTransform: "none"
    }
  }));
  const classes = useStyles();

  return <>
    <Grid data-testid="photoguidelines" container item xs={12} justifyContent="center">
      <Button
        icon={<EmojiObjectsTwoToneIcon />}
        onClick={() => setOpenDialog(!openDialog)}
        label={<div className={classes.mixedCaseLabel}>View Photo Guidelines</div>}
        color="primary"
        variant="outlined"
      />
    </Grid>
    <AlertDialogSlide
      openDialog={openDialog}
      title={<div className={classes.titles}>Photo Guidelines</div>} 
      content={<div className={classes.modalContent}>
        <p>General Information</p>
        <ul>
          <li>Stand far enough away to ensure the top of the equipment is visible (electrical equipment is at the top of the pole, 4 feet below is secondary)</li>
          <li>Take photos from all angles (front, sides, and back) if possible</li>
        </ul>
        <p>Pole Damage</p>
        <ul>
          <li>Take photo if pole has visible damage, is leaned over, or is on the ground</li>
        </ul>
        <p>Equipment Damage</p>
        <ul>
          <li>Take photo if equipment has visible damage, shows signs of being burnt, or is knocked off the pole</li>
          <li>Stand far enough back that entire transformer is visible including the top section if possible</li>
        </ul>
        <p>Tree Damage</p>
        <ul>
          <li>Take photo if any trees are resting on power lines, equipment, or pole</li>
          <li>Take photo if trees are blocking access to equipment, or blocking the road</li>
        </ul>
        <p>Wire Damage</p>
        <ul>
          <li>Take photo if there is visible damage to the line, lines are broken, or lines are resting on the ground</li>
          <li>If lines are resting on the ground do not approach them</li>
        </ul>
        <p>Public Safety Hazard</p>
        <ul>
          <li>Take photo if lines are on or near the ground, or of any other damage that could cause a safety hazard to the public</li>
        </ul>
      </div>}
      showDialogActions = {false}        
      handleClickClose={() => {
        setOpenDialog(false);
      }}
    />
  </>}

export default PhotoGuidelines
