import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { Paper }  from '@material-ui/core';
import { useJsApiLoader } from '@react-google-maps/api';
import NavMenu from '../NavMenu';
import ImageState from '../../context/Image/ImageState';
import InspectionsDataState from '../../context/InspectionsData/InspectionsDataState';
import HomePage from '../Routes/Home';
import Inspections from '../Routes/Inspections';
import CreateInspection from '../Routes/CreateInspection';
import InspectionDetail from '../Routes/InspectionDetail';
import WorkQueue from '../Routes/WorkQueue';
import WorkQueueFormRoute from '../Routes/CreateWorkQueue';
import WorkQueueFormRouteEdit from '../Routes/EditWorkQueue';
import InspectionsReport from '../Routes/InspectionsReport';
import CreateVideo from '../Routes/CreateVideo';
import WorkItemsPage from '../Routes/WorkItems';
import AI from '../Routes/AI';
import WorkQueueReportPage from '../Routes/WorkQueueReportPage';
import TransmissionsReportPage from '../Routes/TransmissionsReportPage';
import { RouteGuard } from '../Routes/Guard';
import { ROLES } from '../../Constants';
import { getLogedUser, getUserRoles } from '../../Utils';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import AssetsReport from '../Routes/AssetsReport';

const { REACT_APP_MAPS_ID_PROJECT , REACT_APP_MAPS_API_KEY } = process.env;
const libraries = ['places'];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  header: {
    wordBreak: 'break-word'
  },
  content: {
    width: '100%',
    height: '100vh',    
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 75
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
      overflow: 'auto'
    },
  },
  paperContainer: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 20px)',
    },
  }
}));

const Layout = ({ msalInstance }) => {
  const classes = useStyles();
  const [account, setAccount] = useState('');
  const [userRoles, setUserRoles] = useState(['']);
  const [userRolesLoad, setUserRolesLoad] = useState(false);
  const [inspectionsOffline, setInspectionsOffline] = useState([]);
  
  const { isLoaded } = useJsApiLoader({
    id: REACT_APP_MAPS_ID_PROJECT,
    googleMapsApiKey: REACT_APP_MAPS_API_KEY,
    libraries
  });
  const ldClient = useLDClient();
  useEffect(() => {
    const onInit = async () => {
      const currentUser = await getLogedUser(msalInstance) ;     
      setAccount(currentUser || {});
      if (currentUser) {
        const currentUserRoles = await getUserRoles(currentUser);
        ldClient.identify({key: currentUser.homeAccountId, name: currentUser.name, email: currentUser.username });
        setUserRoles([...currentUserRoles]);
        setUserRolesLoad(true);
      }
    }
    onInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[msalInstance]);

  return (
    <div className={classes.root} data-testid="layoutComponent">
      {/* Menu */}
      {userRolesLoad && <ImageState setInspectionsOffline={(inspections) => setInspectionsOffline([...inspections])}>
        <InspectionsDataState apiMapLoad={isLoaded}>
          <NavMenu inspectionsOffline={inspectionsOffline} username={(account && account.name) || ""} roles={userRoles} />
          {/* Main content */}
          <main className={classes.content}>
            <Paper className={classes.paperContainer}>
              <Switch>
                <Route path="/" exact render={() => <HomePage userRoles={userRoles} />} />
                <RouteGuard path="/Inspections/:circuitIdParam?/:asset?" exact component={Inspections} account={(account && account.name) || ''} userRoles={userRoles} roles={[ROLES.admin, ROLES.treeInspectionContributor, ROLES.transmissionInspectionContributor, ROLES.supervisor, ROLES.manholeInspectionContributor]} />
                <RouteGuard path="/CreateInspection" exact component={CreateInspection} roles={[ROLES.admin, ROLES.treeInspectionContributor, ROLES.transmissionInspectionContributor, ROLES.supervisor, ROLES.manholeInspectionContributor]} userRoles={userRoles} setInspectionsOffline={(inspections) => setInspectionsOffline([...inspections])} />
                <RouteGuard path="/Inspection/:id/:inspectionType/:partitionKey" exact component={InspectionDetail} roles={[ROLES.admin, ROLES.treeInspectionContributor, ROLES.transmissionInspectionContributor, ROLES.supervisor, ROLES.manholeInspectionContributor]} userRoles={userRoles} />
                <RouteGuard path="/WorkQueue" exact component={WorkQueue} roles={[ROLES.admin, ROLES.supervisor, ROLES.transmissionInspectionContributor, ROLES.manholeInspectionContributor]} userRoles={userRoles} />
                <RouteGuard path="/WorkQueue/create" exact component={WorkQueueFormRoute} roles={[ROLES.admin, ROLES.supervisor]} userRoles={userRoles} />
                <RouteGuard path="/WorkQueue/edit/:name" exact component={WorkQueueFormRouteEdit} roles={[ROLES.admin, ROLES.supervisor]} userRoles={userRoles} />
                <RouteGuard path="/Reports/Inspections" exact component={InspectionsReport} roles={[ROLES.admin, ROLES.supervisor]} userRoles={userRoles} />
                <RouteGuard path="/WorkItems/:workqueueId?" exact component={WorkItemsPage} roles={[ROLES.admin, ROLES.supervisor, ROLES.transmissionInspectionContributor, ROLES.manholeInspectionContributor]} userRoles={userRoles} />
                <RouteGuard path="/ai" exact component={AI} roles={[ROLES.admin, ROLES.treeInspectionContributor, ROLES.supervisor, ROLES.transmissionInspectionContributor]} userRoles={Object.values(ROLES)}/>
                <RouteGuard path="/Reports/WorkQueue/" exact component={WorkQueueReportPage} roles={[ROLES.admin, ROLES.supervisor]} userRoles={userRoles} />
                <RouteGuard path="/Reports/Transmissions/" exact component={TransmissionsReportPage} roles={[ROLES.admin, ROLES.supervisor]} userRoles={userRoles} />
                <RouteGuard path="/CreateVideo" exact component={CreateVideo} roles={[ROLES.admin, ROLES.supervisor]} userRoles={userRoles} />
                <RouteGuard path="/AssetVideo" exact component={AssetsReport} roles={[ROLES.admin, ROLES.supervisor]} userRoles={userRoles} />
              </Switch>
            </Paper>
          </main>
          {/* Main content - END*/}
        </InspectionsDataState>
      </ImageState>}
    </div>
  );
}

Layout.propTypes = {
  account: PropTypes.object
};

Layout.defaultProps = {
  account: {name: ''}
};

export default Layout;
