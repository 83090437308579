import React from "react";
import {Marker} from "@react-google-maps/api/";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  markerLabel: {
    fontWeight: "bold",
    fontSize: "40px",
  },
}));

export const GoogleMarker = ({ position, title, icon }) => {
  const classes = useStyles();

  return (
    <Marker
      tracksViewChanges={false}
      noRedraw={true}
      position={position}
      label={{
        color: "red",
        text: title,
        className: classes.markerLabel,
      }}
      zIndex={1000}
      icon={{ 
        url: icon,
        anchor: new window.google.maps.Point(50, 20) }}
    />
  );
};
