import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import UserMenu from '../UserMenu';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontSize: "1rem" 
  },
}));

const DesktopMenu = ({ pageTitle, handleDrawerOpen, username, open, roles, inspectionsOffline }) => {
  const classes = useStyles();
  
  return (
    <div data-testid="desktopMenu" className={classes.menuContainer}>
      <IconButton
        color="secondary"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton, {
          [classes.hide]: open,
        })}
      >
        <MenuIcon />
      </IconButton>
      {/* Page title */}
      <Typography variant="h1" color="secondary" className={classes.title}>
        {pageTitle}
      </Typography>
      {/* User menu */}
      <UserMenu inspectionsOffline={inspectionsOffline} username={username} roles={roles} />
    </div>)
}


DesktopMenu.propTypes = {
  username: PropTypes.string,
  pageTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
};

DesktopMenu.defaultProps = {
  username: "",
  open: false
}

export default DesktopMenu;
