import React, { useState } from "react";
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ListFilters from "../Filters";
import Sorting from "../Sorting";
import { FF_TRANSMISSION_INSPECTION_FORM } from "../../../Constants";
import { useFlags } from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.background.default}`,
    backgroundColor: theme.palette.secondary.main,
    padding: "0.5rem",
    [theme.breakpoints.up('sm')]: {
      display: "none"
    },
    "& .MuiButton-containedPrimary": {
      minWidth: 100,
    }
  },
  popoverFiltersSort: {
    [theme.breakpoints.up('sm')]: {
      display: "none !important",
    }
  }
}))

const MobileActionContainer = ({
  showMap,
  setShowMap,
  activeFilters,
  changeFilters,
  inspectionsFiltered,
  changeSort,
  inspectionContext,
  onChangeFilterWithOutAction,
  onClearFilters,
  onChangeAssets,
  roles,
  setLoading
}) => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const idPopoverFilter = showFilters ? 'filter-popover' : undefined;
  const idPopoverSort = showSort ? 'sort-popover' : undefined;
  const ff_transmission_inspection_form = useFlags()[FF_TRANSMISSION_INSPECTION_FORM];


  return (
    <div className={classes.actionContainer} data-testid="mobileactionContainer">
      <Button variant="contained" color="primary" onClick={() => setShowMap(!showMap)}>
        {showMap ? "Show list" : "Show map"}
      </Button>
      <Button
        aria-describedby={idPopoverFilter}
        variant="contained"
        color="primary"
        onClick={event => setShowFilters(event.currentTarget)}
      >
        Filters
      </Button>
      {!showMap && <Button
        aria-describedby={idPopoverSort}
        variant="contained"
        color="primary"
        onClick={event => setShowSort(event.currentTarget)}
      >
        Sort
      </Button>}
      <Popover
        id={idPopoverFilter}
        open={showFilters}
        anchorEl={showFilters}
        onClose={() => setShowFilters(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: '100%', margin: 0 },
        }}
        className={classes.popoverFiltersSort}
      >
        <ListFilters
          activeFilters={activeFilters}
          setActiveFiltersProp={filters => changeFilters(filters)}
          inspectionContext={inspectionContext}
          ff_transmission_inspection_form={ff_transmission_inspection_form}
          onChangeFilterWithOutAction={onChangeFilterWithOutAction}
          onClearFilters={onClearFilters}
          onChangeAssets={onChangeAssets}
          roles={roles}
          setLoading={setLoading}
          setShowMap={setShowMap}
        />
      </Popover>
      <Popover
        id={idPopoverSort}
        open={showSort}
        anchorEl={showSort}
        onClose={() => setShowSort(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.popoverFiltersSort}
      >
        <Sorting data={inspectionsFiltered} onChangeSort={changeSort} />
      </Popover>
    </div>)
}

export default MobileActionContainer;