import React from 'react';
import { Rect, Transformer } from 'react-konva';

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange, onDragStart, onDragEnd }) => {
	const shapeRef = React.useRef();
	const trRef = React.useRef();
	React.useEffect(() => {
		if (isSelected) {
			trRef.current.nodes([shapeRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [isSelected]);

	return (
		<React.Fragment>
			<Rect
				onClick={!shapeProps.ai && onSelect}
				onTap={!shapeProps.ai && onSelect}
				ref={shapeRef}
				{...shapeProps}
				{...!shapeProps.ai && {draggable: true}}
				fill="transparent"
				onDragEnd={(e) => {
					onChange({
						...shapeProps,
						x: e.target.x(),
						y: e.target.y(),
					});
					onDragEnd();
				}}
				onTransformEnd={() => {
					// transformer is changing scale of the node
					// and NOT its width or height
					// but in the store we have only width and height
					// to match the data better we will reset scale on transform end
					const node = shapeRef.current;
					const scaleX = node.scaleX();
					const scaleY = node.scaleY();

					// we will reset it back
					node.scaleX(1);
					node.scaleY(1);
					onChange({
						...shapeProps,
						x: node.x(),
						y: node.y(),
						// set minimal value
						width: Math.max(5, node.width() * scaleX),
						height: Math.max(node.height() * scaleY),
					});
				}}
				onDragStart={onDragStart}
			/>
			{isSelected && <>
				<Transformer
					ref={trRef}
					rotateEnabled={false}
					flipEnabled={false}
					boundBoxFunc={(oldBox, newBox) => {
						// limit resize
						if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			</>}
		</React.Fragment>
	);
};

export default Rectangle;
