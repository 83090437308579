import React from "react";
import Container from '@material-ui/core/Container';
import InspectionsPage from "../../InspectionsPage"

const Inspections = (props) => {
  const containerStyles = {
    height: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    position: "relative"
  }  
  return (
    <>
      <Container component="div" maxWidth={false} style={containerStyles} >
        <InspectionsPage roles={props.userRoles} account={props.account} inspectionsOffline={props.inspectionsOffline} />
      </Container>
    </>
  )
}

export default Inspections;