import {
  Avatar,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { default as MaterialCard } from "@material-ui/core/Card";
import React from "react";
import Assessment from "@material-ui/icons/Assessment";

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: "#F9FAFC",
    [theme.breakpoints.only('lg')]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.only('md')]: {
      width: 30,
      height: 30,
    },
    width: 50,
    height: 50,
  },
  card: {
    height: "100%"
  },
  MuiPaperRoot: {
    MuiCardRoot: {
      height: "100%"
    }
  }
}));
const iconDefault = <Assessment />;
const colorDefault = "#5048E5";

const Card = ({
  title,
  value,
  subTitle,
  icon = { avatar: iconDefault, color: colorDefault },
}) => {
  const classes = useStyles();
  return (
    <MaterialCard className={classes.card}>
      <CardContent>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            <Typography color="textSecondary" variant="subtitle2">
              {title}
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {value}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              className={classes.avatar}
              style={{ backgroundColor: icon.color || colorDefault }}
            >
              {icon.avatar || iconDefault}
            </Avatar>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Typography color="textSecondary" variant="caption">
              {subTitle}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </MaterialCard>
  );
};

export default Card;
