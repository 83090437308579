import { callApi } from '../callApi';
import axios from 'axios';
import { RESOURCE_INSPECTIONS } from '../Constants';
import { compact } from 'lodash';
import { convertToQueryString } from '../Utils';

let cancelTokenSource = null; // Defines a cancel token globally

class AssetsService {
	getAll(filters) {
		const queryString = convertToQueryString(filters);

		// cancel  previous request if exists
		if (cancelTokenSource) {
			cancelTokenSource.cancel();
		}

		// creates a new token for upcomming ajax (overwrite the previous one)
		cancelTokenSource = axios.CancelToken.source();
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}assets${queryString}`,
			cancelTokenSource,
		});
	}

	getCenterMap(id, workQueType) {
		const assets = workQueType === 'lines' ? 'assets/' : '';
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}${assets}${workQueType}/center/${id}`,
		});
	}

	getAssets(structureId, partitionKey, type) {
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}assets/${structureId}?Partitionkey=${partitionKey}&types=${type}`,
		});
	}

	async getAssetByPoleNumber(poleNumber) {
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}assets?structureNumber=${poleNumber}&types=Pole`,
		});
	}

	getLines() {
		return callApi({
			method: 'get',
			url: `${RESOURCE_INSPECTIONS}assets?types=Line`,
		});
	}

	getLinesCoordinates = (lines) => {
		let lineIdCoordinates = [];
		lines.forEach((line) => {
			if (line.location && line.location.coordinates) {
				const coordinates = line.location.coordinates;
				const coordinatesByLine = [];
				const isSingleLine = coordinates[0].length === 2;
				coordinates.forEach((coordinate) => {
					if (isSingleLine) {
						coordinatesByLine.push(latLngStructure(coordinate));
					} else {
						const multiLine = [];
						coordinate.forEach((latLngPosition) => {
							multiLine.push(latLngStructure(latLngPosition));
						});
						lineIdCoordinates.push(
							coordinatesStructure(line.lineId, multiLine)
						);
					}
				});
				if (isSingleLine) {
					lineIdCoordinates.push(
						coordinatesStructure(line.lineId, coordinatesByLine)
					);
				}
			}
		});

		return lineIdCoordinates;
	};
}

function latLngStructure(coordinates) {
	return !isNaN(coordinates[1]) && !isNaN(coordinates[0])
		? { lat: coordinates[1], lng: coordinates[0] }
		: null;
}

function coordinatesStructure(lineId, line) {
	return { lineId, location: { coordinates: compact(line) } };
}

export default new AssetsService();
