import { Grid } from "@material-ui/core";
import React from "react";
import TocTwoToneIcon from "@material-ui/icons/TocTwoTone";
import Card from "./Card";
function Cards({ summary, cardsVisibility }) {
  const { inspectionsSummary = [], activeWorkItemsCount = 0 } = summary;
  
  const countInspectionsByStatus = (status) => inspectionsSummary
          .filter((e) => e.status === status)
          .reduce((prev, current) => prev + current.count, 0)
          
  const cardList = cardsVisibility && cardsVisibility.transmissionView
    ? [
        { title: "New" },
        { title: "Draft", icon: { color: "#FFB020"}},
        { title: "Office Inspection", icon: { color: "#14B8A6" }},
        { title: "QA Review", icon: { color: "#007749" }},
        { title: "Eversource QA Review", icon: {color: "#FFB020" }}
      ]
    : [
        { title: "Submitted"},
        { title: "DA Review", icon: { color: "#FFB020"}},
        { title: "Assessed", icon: { color: "#14B8A6" }},
        { title: "Completed", icon: { color: "#007749" }},
        {...cardsVisibility && cardsVisibility.activeWorkItems &&
          { 
            title: "Active Work Items",
            icon: { avatar: <TocTwoToneIcon />, color: "#FFB020" },
            value: activeWorkItemsCount
          }
        }
      ]
          
  return (
    <Grid container spacing={2} >
      {cardList.map(card => 
        <Grid item xs={12} sm={6} md key={card.title}>
          <Card
            title={card.title}
            value={card.value || countInspectionsByStatus(card.title)}
            {...card.icon && {icon: card.icon}}
          />
        </Grid>)}
    </Grid>
  );
}

export default Cards;
