import React from 'react';
import { Card, Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ImageSearchTwoToneIcon from '@material-ui/icons/ImageSearchTwoTone';
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone';
import Paginator from '../../InspectionsPage/Paginator';
import { ReactComponent as MapIcon } from '../../../assets/table/map.svg';
import DaysSinceUpdated from '../daysSinceUpdated';
import CountOfInspections from '../CountOfInspections';
import UserAssignment from '../UserAssignment';
import WorkItemsStatus from '../Status';
import {
	LOCALSTORAGE_CURRENT_WORK_ITEM,
	WORK_QUEUE_TYPE_CIRCUIT_SWEEP,
	WORK_QUEUE_TYPE_TRANSMISSION,
	ASSET_TYPE_TRANSMISSION,
	INSPECTION_TYPE_DAMAGE,
	INSPECTION_TYPE_TRANSMISSION,
	WORK_QUEUE_TYPE_MANHOLE
} from '../../../Constants';
import { saveObjInLocalStorage } from '../../../Utils';
import AssetsService from '../../../services/AssetsService';

const useStyle = makeStyles((theme) => ({
	paginatorContainer: {
		marginTop: '56px',
	},
	mainCard: {
		margin: '0px',
		marginTop: '30px',
	},
	circuit: {
		fontWeight: 'bold',
		marginBottom: '10px',
	},
	assignedTo: {
		color: '#007749',
		textAlign: 'right',
	},
	columnRight: {
		textAlign: 'right',
	},
	lineProgressContainer: {
		marginTop: '41px',
	},
	mapIcon: {
		cursor: 'pointer',
	},
	truncate: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '130px',
	},
	editIcon: {
		cursor: 'pointer',
		fontSize: 20,
		color: theme.palette.primary.main,
	},
	editUser: {
		cursor: 'pointer',
		borderBottom: '1px dashed #000',
		color: theme.palette.primary.main,
	},
	disableEditUser: {
		cursor: 'point',
		borderBottom: 'none',
		color: theme.palette.primary.main,
	},
	rowContainer: {
		marginBottom: '10px',
	},
}));

const WorkItemsMobileList = ({ items, name, type, workQueueId, disableAdminActions }) => {
	const classes = useStyle();
	const history = useHistory();
	const [data, setData] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [editingAssignedTo, setEditingAssignedTo] = useState(false);

	useEffect(() => {
		setData(items);
	}, [items]);

	const onMapClick = async (row) => {
		let assetLocation = null;
		const lineIds = row.lineId ? encodeURIComponent(row.lineId) : '';
		if (row.workItemType === ASSET_TYPE_TRANSMISSION) {
			const asset = await AssetsService.getAssets(
				row.id,
				row.town,
				'Structure'
			);
			assetLocation = asset?.location;
		}

		saveObjInLocalStorage(LOCALSTORAGE_CURRENT_WORK_ITEM, {
			circuitId: row.circuitId || '',
			lineIds,
			workQueueId,
			workQueue: name,
			workItemId: row.id,
			fromWorkItem: true,
			workItemType: row.workItemType,
			assetLocation,
		});
		history.push(
			`/Inspections/${lineIds || row.circuitId}/${row.workItemType}/`
		);
	};

	const redirectDetailInspection = (id, inspectionType, partitionKey) =>
		history.push(`/Inspection/${id}/${inspectionType}/${partitionKey}`);

	const redirectCreateInspection = (rowData) => {
		const currentTransmission = rowData;
		currentTransmission.workQueue = { name, workQueueId };
		history.push({
			pathname: '/CreateInspection',
			currentTransmission,
		});
	};

	const workitemCard = (item) => (
		<>
			{type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP && (
				<Grid container justifyContent="flex-start" className={classes.circuit}>
					{' '}
					<Grid item>{item.circuitId}</Grid>
				</Grid>
			)}
			{(type === WORK_QUEUE_TYPE_TRANSMISSION || type === WORK_QUEUE_TYPE_MANHOLE) && (
				<Grid
					container
					justifyContent="space-between"
					className={classes.circuit}
				>
					<Grid item>{type === WORK_QUEUE_TYPE_TRANSMISSION ? item.lineId : item.id}</Grid>
					<Grid item>
						{
							// No inspection
							!item.inspectionId && !disableAdminActions? (
								// No inspection, icon to create inspection
								<AddBoxTwoToneIcon
									color="primary"
									className={classes.tableIcon}
									onClick={() => redirectCreateInspection(item)}
								/>
							) : (
								<ImageSearchTwoToneIcon
									color="primary"
									className={classes.tableIcon}
									onClick={() =>
										redirectDetailInspection(
											item.inspectionId,
											item.workItemType === WORK_QUEUE_TYPE_CIRCUIT_SWEEP
												? INSPECTION_TYPE_DAMAGE
												: INSPECTION_TYPE_TRANSMISSION,
											item.inspectionPartitionKey
										)
									}
								/>
							)
						}
					</Grid>
				</Grid>
			)}
			<Grid
				container
				justifyContent="space-between"
				direction="row"
				className={classes.rowContainer}
			>
				<Grid item>
					<div className={classes.truncate}>
						{type === WORK_QUEUE_TYPE_TRANSMISSION
							? item.structureId
							: item.areaWorkCenter}
					</div>
				</Grid>
				<Grid item>
					<MapIcon
						className={classes.mapIcon}
						onClick={() => onMapClick(item)}
					/>
				</Grid>
			</Grid>
			{type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP &&
			<Grid
				container
				justifyContent="space-between"
				direction="row"
				className={classes.rowContainer}
			>
				<Grid item>{item.town}</Grid>
				<Grid item>
					<CountOfInspections items={item.inspectionsCount} />
				</Grid>
			</Grid>}

			{type === WORK_QUEUE_TYPE_MANHOLE &&
			<Grid
				container
				justifyContent="space-between"
				direction="row"
				className={classes.rowContainer}
			>
				<Grid item>{item.town}</Grid>
				<Grid item>
					{editingAssignedTo !== false &&
					editingAssignedTo === item.id ? null : (
							<label
								className={classes.editUser}
								onClick={() => setEditingAssignedTo(item.id)}
							>
								{item.assignedUser && item.assignedUser.name
									? item.assignedUser.name
									: 'Unassigned'}
							</label>
						)}
				</Grid>
			</Grid>}
			
			{type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP && <Grid
				container
				justifyContent="space-between"
				direction="row"
				className={classes.rowContainer}
			>
				<Grid item>{item.division}</Grid>
				<Grid item>
					<DaysSinceUpdated days={item.daysSinceUpdated} />
				</Grid>
			</Grid>}
			<Grid
				container
				justifyContent="space-between"
				direction="row"
				className={classes.rowContainer}
			>
				<Grid item>
					<div className={classes.truncate}>
						<span>{item.state}</span>
					</div>
				</Grid>
				<Grid item>
					{type === WORK_QUEUE_TYPE_MANHOLE
						?	<DaysSinceUpdated days={item.daysSinceUpdated} />
						: editingAssignedTo !== false &&
						editingAssignedTo === item.id ? null : (
								<label
									className={` ${!disableAdminActions ? classes.editUser : classes.disableEditUser}`}
									onClick={() => !disableAdminActions && setEditingAssignedTo(item.id)}
								>
									{item.assignedUser && item.assignedUser.name
										? item.assignedUser.name
										: 'Unassigned'}
								</label>
							)}
				</Grid>
			</Grid>

			{(type === WORK_QUEUE_TYPE_TRANSMISSION || type === WORK_QUEUE_TYPE_MANHOLE) && (
				<Grid
					container
					justifyContent="space-between"
					direction="row"
					className={classes.rowContainer}
				>
					<Grid item>{item.status}</Grid>
					<Grid item>
						{type === WORK_QUEUE_TYPE_TRANSMISSION && <DaysSinceUpdated days={item.daysSinceUpdated} />}
					</Grid>
				</Grid>
			)}
			<UserAssignment
				item={item}
				updateList={(list) => setData(list)}
				workQueueId={workQueueId}
				workQueueList={data}
				setEditingAssignedToParam={setEditingAssignedTo}
				editingAssignedToParam={editingAssignedTo}
				disabled={disableAdminActions}
			/>
			{type === WORK_QUEUE_TYPE_CIRCUIT_SWEEP && (
				<Grid
					className={classes.lineProgressContainer}
					container
					// eslint-disable-next-line no-constant-condition
					justifyContent={50 === 100 ? 'center' : 'flex-start'}
				>
					<Grid item xs={12}>
						<WorkItemsStatus
							workitem={item}
							workQueues={data}
							updateList={(list) => setData(list)}
							workQueueId={workQueueId}
							disabled={disableAdminActions}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);

	return (
		<>
			<Card className={classes.mainCard} variant="outlined">
				{data &&
					data.length > 0 &&
					data.map((item) => (
						<Card
							className={classes.cardContainer}
							variant="outlined"
							key={item.id}
						>
							{workitemCard(item)}
						</Card>
					))}
			</Card>
			<Grid className={classes.paginatorContainer} container>
				<Grid item>
					<Paginator
						data={items}
						onChangePage={(data) => setData(data)}
						page={page}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
						setPage={(page) => setPage(page)}
						totalItems={items.length}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default WorkItemsMobileList;
