import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialogSlide from '../../../Shared/Dialog';
import TextFieldComponent from '../../../Shared/atoms/TextInput';
import Button from '../../../Shared/atoms/Button';

const CableSection = ({ inspection, changeInfraredValue, deleteInfraredIndex, editing }) => {
	const [cableSection, setCableSection] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [indexToDelete, setIndexToDelete] = useState(false);

	useEffect(() => {
		if (inspection.cableSections) {
			setCableSection(inspection.cableSections.length - 1);
		}
	}, [inspection]);


	const cableSectionList = () => {
		const rows = [];
		for (let i = 0; i <= cableSection; i++) {
			rows.push(<Grid container spacing={1} key={`Cable${i}`}>
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<h4 style={{ ...indexToDelete === i && {color: 'red'} }}>Cable {i + 1}</h4>
					{i > 0 && editing &&
					<DeleteIcon
					 	style={{ cursor: 'pointer', color: 'red' }}
						onClick={() => {
							setOpenDialog(true);
							setIndexToDelete(i);
						}}
					/>}
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent
						value={inspection.cableSections && inspection.cableSections[i] && inspection.cableSections[i].cableEntering || ''}
						label="Cable Entering"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={e => changeInfraredValue(e, 'cableSections', 'cableEntering', i)}
						multiline={false}
						required={true}
						disabled={!editing}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent
						value={inspection.cableSections && inspection.cableSections[i] && inspection.cableSections[i].joint || ''}
						label="Joint"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={(e) => changeInfraredValue(e, 'cableSections', 'joint', i)}
						multiline={false}
						required={true}
						disabled={!editing}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent
						value={inspection.cableSections && inspection.cableSections[i] && inspection.cableSections[i].cableExiting || ''}
						label="Cable Exiting"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={e => changeInfraredValue(e, 'cableSections', 'cableExiting', i)}
						multiline={false}
						required={true}
						disabled={!editing}
					/>
				</Grid>
				<Grid item xs={12} >
					<TextFieldComponent
						value={inspection.cableSections && inspection.cableSections[i] && inspection.cableSections[i].referenceTemperature || ''}
						label="Reference Temperature"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={e => changeInfraredValue(e, 'cableSections', 'referenceTemperature', i)}
						multiline={false}
						required={true}
						disabled={!editing}
					/>
				</Grid>
			</Grid>);
		}
		return rows;
	}	

	return <>
		<Grid item xs={12}>
			<h3>Cable Section</h3> 
			{cableSectionList()}
		</Grid>
		<Grid item xs={12}>
			<Button
				variant="outlined"
				color="primary"
				onClick={() => {
					const newCableSectionSectionIndex = cableSection + 1;
					setCableSection(newCableSectionSectionIndex);
					changeInfraredValue('', 'cableSections', 'referenceTemperature', newCableSectionSectionIndex);
				}}
				label="+ Cable"
				styles={{ marginTop: 10}}
				disabled={!editing}
			/>
		</Grid>
		<Grid item xs={12}>
			<i>*** IR scans shall only be performed on energized primary cable, cable accessories and electrical equipment that are in service carrying load current ***</i>
		</Grid>
		<AlertDialogSlide
			openDialog={openDialog}
			handleConfirm={() => {
				setOpenDialog(false);
				const newCableSectionSectionIndex = cableSection - 1;
				setCableSection(newCableSectionSectionIndex);
				deleteInfraredIndex(indexToDelete, 'cableSections');
				setIndexToDelete(false);
			}}
			title={`Delete Cable ${indexToDelete + 1}`}
			content="Are you sure you want to delete this cable?"      
			handleClickClose={() => {
				setOpenDialog(false);
				setIndexToDelete(false);
			}}
		/>
	</>
}

export default CableSection;