import React from "react";
import { withMsal } from "@azure/msal-react";
import ExitToApp from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';

class LogoutButton extends React.Component {
    render() {
      const isAuthenticated = this.props.msalContext.accounts.length > 0;
      const msalInstance = this.props.msalContext.instance;
      if (isAuthenticated) {
        return (
          <IconButton
            aria-label="Logout"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => msalInstance.logoutRedirect()}
            color="primary"
            data-testid="logoutIcon"
            style={{ paddingLeft: 0 }}
          >
            <ExitToApp  />
          </IconButton>)
      }
      return null
    }
}

export default withMsal(LogoutButton);