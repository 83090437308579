import React, { useEffect } from "react";
import Container from '@material-ui/core/Container';
import Dashboard from "../../Dashboard";

const HomePage = ({ userRoles }) => {
  useEffect(() => {
    document.body.className = "dashboard" 
    return () => {
      document.body.className = "" 
    }
  }, [])
  return (  
    <Container component="div" maxWidth="xl">
      <Dashboard userRoles={userRoles} />
    </Container>
  );
};

export default HomePage;
