import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { updateAnnotationAccordingToSize } from '../index';

const useStyles = makeStyles(() => ({
	imageCard:  {
		width: '30vw',
		height: '30vw',
		objectFit: 'cover',
	},
	lightboxImg: {
		height: '80vh',
		maxWidth: '90vw',
		minWidth: 10,
		border: '3px solid #444',
		boxShadow: '2px 2px 5px #777'
	},
	lightbox: {
		zIndex: 2,
		position: 'fixed',
		top: 0,
		left: 0,
		width: 'calc(100% - 300px)',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	arrows: {
		cursor: 'pointer',
		fontSize: '70px !important'
	}
}));

const LightBox = ({ images, dimensionAndPositions, setDimensionAndPositions, currentIndex, setCurrentIndex, annotations, setAnnotations, loading }) => {
	const [imageToShow, setImageToShow] = useState('');
 	const classes = useStyles();
	const timerId = useRef(null);
	const annotationsRef = useRef([]);
	const dimensionAndPositionsRef = useRef([]);
	const currentIndexRef = useRef(0);
	useEffect(() => {
		if (images?.length)	
			setImageToShow(images[0]);
	}, [images]);

	useEffect(() => {
		dimensionAndPositionsRef.current = dimensionAndPositions;
	}, [dimensionAndPositions]);
	
	useEffect(() => {
		annotationsRef.current = annotations;
	}, [annotations]);

	useEffect(() => {
		currentIndexRef.current = currentIndex;
	}, [currentIndex]);

	useEffect(() => {
		window.addEventListener('resize', () => {
			// cancel and reset timeout if the user still resizing and 500 ms didn't pass
			clearTimeout(timerId.current);
			// After half a second of the last movement start the canvas and annotations resize	
			timerId.current = setTimeout(
				function () {
					updateCanvasAndAnnotationsSize(true);
				}, 500);
		});
	}, []);

	// show next image in lightbox
	const showNext = async (e) => {
		e.stopPropagation();
		if (loading) {
			return;
		}
		let nextImage;
		if (currentIndex >= images.length - 1) {
			nextImage = images[0];
			setImageToShow(nextImage);
			setCurrentIndex(0);
		} else {
			nextImage = images[currentIndex + 1];
			setImageToShow(nextImage);
			setCurrentIndex(currentIndex + 1);
		}
	};

	// show previous image in lightbox
	const showPrev = (e) => {
		e.stopPropagation();
		if (loading) {
			return;
		}
		if (currentIndex <= 0) {
			let nextImage = images[images.length - 1];
			setImageToShow(nextImage);
			setCurrentIndex(images.length - 1);
		} else {
			let nextImage = images[currentIndex - 1];
			setCurrentIndex(currentIndex - 1);
			setImageToShow(nextImage);
		}
	};
	
	const updateCanvasAndAnnotationsSize = () => {
		const element = document.querySelector('#lightbox_detail');
		const imgs = element && element.getElementsByTagName('img') || [];
		if (imgs && imgs.length) {
			const image = imgs[0];
			const dimensionAndPosition = dimensionAndPositionsRef.current[currentIndexRef.current];
			dimensionAndPosition.width =  image.offsetWidth;
			dimensionAndPosition.height =  image.offsetHeight;
			dimensionAndPosition.left =  image.offsetLeft;
			dimensionAndPosition.top =  image.offsetTop;
			if (annotationsRef.current && annotationsRef.current.length) {
				const updatedAnnotations = [];
				annotationsRef.current.map(annotation => {
					const _annotation = annotation;
					_annotation.resized = false;
					// If this annotation is for the current image
					if (_annotation.index === currentIndexRef.current) {
						const {currentX,
							currentY,
							currentAnnotationWidth,
							currentAnnotationHeight
						} = updateAnnotationAccordingToSize(
							_annotation.originalAnnotationWidth, // original Width
							_annotation.originalAnnotationHeight, // original Height
							_annotation.originalXPosition, // Original X;
							_annotation.originalYPosition, // Original Y
							dimensionAndPosition);
						_annotation.x =  currentX;
						_annotation.y = currentY;
						_annotation.width = currentAnnotationWidth;
						_annotation.height = currentAnnotationHeight;						
					}
					updatedAnnotations.push(_annotation);
				});
				annotationsRef.current = updatedAnnotations;				
				setAnnotations(updatedAnnotations);		
			}
			setDimensionAndPositions(dimensionAndPosition);			
		}
	}

	return <div className={classes.lightbox} id="lightbox_detail">
		<ChevronLeftIcon onClick={showPrev} className={classes.arrows} />
		<img
			className={classes.lightboxImg}
			src={imageToShow}
			onLoad={e => {
				const img = e.target;
				const dimensionAndPosition = { 
					width: img.offsetWidth,
					height: img.offsetHeight,
					left: img.offsetLeft,
					top: img.offsetTop,
					naturalHeight: img.naturalHeight,
					naturalWidth: img.naturalWidth
				};
				setDimensionAndPositions(dimensionAndPosition);
				// If there is already an annotation for this image update it according to size
				if (annotations && annotations.length) {
					const updatedAnnotations = [];
					annotations.map(annotation => {
						const _annotation = annotation;						
						// If this annotation is for the current image
						if (_annotation.index === currentIndex && !_annotation.resized) {
							const {currentX,
								currentY,
								currentAnnotationWidth,
								currentAnnotationHeight
							} = updateAnnotationAccordingToSize(
								_annotation.originalAnnotationWidth, // original Width
								_annotation.originalAnnotationHeight, // original Height
								_annotation.originalXPosition, // Original X;
								_annotation.originalYPosition, // Original Y
								dimensionAndPosition);
							_annotation.x =  currentX;
							_annotation.y = currentY;
							_annotation.width = currentAnnotationWidth;
							_annotation.height = currentAnnotationHeight;
							_annotation.resized = true;
						}
						updatedAnnotations.push(_annotation);
					});
					setAnnotations(updatedAnnotations);
				}
				
			}}
		/>
		<ChevronRightIcon onClick={showNext} className={classes.arrows} />
	</div>;
};
export default LightBox;