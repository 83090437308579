import { Box, Modal, Typography } from '@material-ui/core';
import React from 'react';

export const SmartModal = ({ title, onClose, children }) => {
	return (
		<React.Fragment>
			<Modal
				open={true}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 800,
						bgcolor: '#FFFFFF',
						border: '10px solid #cccccc',
						boxShadow: 24,
						justifyContent: 'center',
						p: 2,
					}}
				>
					<Typography id="modal-modal-title" variant="h6">
						{title}
					</Typography>
					<div id="modal-modal-description">{children}</div>
				</Box>
			</Modal>
		</React.Fragment>
	);
};
