import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import LightBox from './LightBox';
import DrawCanvas from './DrawCanvas';
import {AnnotationsList} from './AnnotationList';
import AIService from '../../../services/AIService';
import { showSnackbar } from '../../../Utils';
import ProgressBar from '../../Shared/ProgressBar';
import { useSnackbar } from 'notistack';

export const updateAnnotationAccordingToSize = (originalAnnotationWidth, originalAnnotationHeight, originalXPosition, originalYPosition, dimensionAndPosition) => {
	const imageOriginalWidth = dimensionAndPosition.naturalWidth;
	const imageOriginalHeight = dimensionAndPosition.naturalHeight;
	const imageCurrentWidth = dimensionAndPosition.width;
	const imageCurrentHeight = dimensionAndPosition.height;
	const currentAnnotationWidth = (imageCurrentWidth*originalAnnotationWidth)/imageOriginalWidth;
	const currentAnnotationHeight =(imageCurrentHeight*originalAnnotationHeight)/imageOriginalHeight;
	const currentX = (originalXPosition*imageCurrentWidth)/imageOriginalWidth;
	const currentY = (originalYPosition*imageCurrentHeight)/imageOriginalHeight;

	return {
		currentX,
		currentY,
		currentAnnotationWidth,
		currentAnnotationHeight
	};
};

export const inspect = async (annotations, dimensionAndPosition, currentIndex, imageId) => {	
	const detectedObjectResult = await AIService.imageMetadata(imageId);
	if (detectedObjectResult && detectedObjectResult.data && detectedObjectResult.data.boundingBoxes) {
		const _annotations = createAnnotation(annotations, dimensionAndPosition, detectedObjectResult.data.boundingBoxes, currentIndex, imageId);
		if (detectedObjectResult.data.boundingBoxes.length === 0)
			return {error: false};
		return {error: false, annotations: [..._annotations], metadata: detectedObjectResult.data.boundingBoxes };
	} else {
		return {error: true}
	}
};

export const createAnnotation = (annotations, dimensionAndPosition, metadata, currentIndex, imageId) => {
	const _annotations = annotations || [];
	const typesCount = [];
	metadata.map(annotation => {
		if (annotation.isAi) {
			if (typesCount[annotation?.label?.name]) {
				typesCount[annotation.label.name] = typesCount[annotation.label.name] + 1;
			} else {
				typesCount[annotation.label.name] = 1;
			}
		}
		const originalAnnotationWidth = -(annotation.boundingBox[0] - annotation.boundingBox[2]);
		const originalAnnotationHeight = -(annotation.boundingBox[1] - annotation.boundingBox[3]);
		const originalXPosition = annotation.boundingBox[0];
		const originalYPosition = annotation.boundingBox[1];
		const {currentX,
			currentY,
			currentAnnotationWidth,
			currentAnnotationHeight
		} = updateAnnotationAccordingToSize(
			originalAnnotationWidth, // original Width
			originalAnnotationHeight, // original Height
			originalXPosition, // Original X;
			originalYPosition, // Original Y
			dimensionAndPosition);

		const newAnnotation = {
			x: currentX,
			y: currentY,
			width: currentAnnotationWidth,
			height: currentAnnotationHeight,
			key: _annotations.length + 1,
			stroke: annotation.label.color,
			issueType: !annotation.isAi && annotation.issueType,
			issueTypeName:  annotation.isAi ? 'AI Findings' : annotation.issueTypeName,
			name: annotation.label.name === '' ? 'Unnamed' : `${annotation.label.name} ${typesCount[annotation.label.name] || ''}`,
			visible: true,
			index: currentIndex,
			naturalHeight: dimensionAndPosition.naturalHeight,
			naturalWidth: dimensionAndPosition.naturalWidth,
			ai: annotation.isAi || false,
			confidence: annotation.isAi && parseFloat(annotation.confidence).toFixed(2),
			originalXPosition,
			originalYPosition,
			originalAnnotationWidth,
			originalAnnotationHeight,
			imageId,
			boundingBox: annotation.boundingBox
		};
		_annotations.push(newAnnotation);
	});
	return _annotations;
}

const useStyles = makeStyles(() => ({
	containter: {
		zIndex: 1201
	},
	closeIcon: {
		position: 'absolute',
		top: 10,
		right: 310,
		zIndex: 9999,
		cursor: 'pointer',
		fontSize: '40px !important'  
	},
	downloadIcon: {
		position: 'absolute',
		top: 10,
		right: 360,
		zIndex: 9999,
		cursor: 'pointer',
		color: 'rgba(0, 0, 0, 0.87)',
		lineHeight: '10px',
		'& svg': {
			fontSize: 40
		},
		'&:visited': {
			color: 'rgba(0, 0, 0, 0.87)'
		}
	}
}));

const LightboxAI = ({ images, imagesIds, setShowLightBox, aiResponse, setAiResponse }) => {
	const [loading, setLoading] = useState(false);
	const [dimensionAndPositions, setDimensionAndPositions] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);	
	const [annotations, setAnnotations] = useState([]);	
	const [selectedId, selectShape] = useState(null);
	const [newAnnotation, setNewAnnotation] = useState([]);
	const [imageIndexInspected, setImageIndexInspected] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const dimensionAndPositionsRef = useRef([]);
	const currentIndexRef = useRef(0);
	const classes = useStyles();

	// Reset all annotations after close
	useEffect(() => {
		return () => {
			setAiResponse([])
		};
	}, [])

	useEffect(() => {
		const inspectCurrentIndex = async () => {
			// IF it hasn't been inspected by ML in full view or small view
			if (!aiResponse[currentIndex]) {
				const _aiResponse = aiResponse;
				_aiResponse[currentIndex] = {};
				setAiResponse(_aiResponse);
				const _imageIndexInspected = imageIndexInspected;
				setLoading(true);
				const response = await inspect(annotations, dimensionAndPositions[currentIndex], currentIndex, imagesIds[currentIndex]?.name);
				setLoading(false);
				if (response.error) {
					_aiResponse[currentIndex] = false;
					showSnackbarMessage('An error occurred during AI Inspection.', 'error');
					_imageIndexInspected[currentIndex] = false;
				} else if (response.annotations) {
					_aiResponse[currentIndex] = response.metadata || {};
					setImageIndexInspected()
					_imageIndexInspected[currentIndex] = true;
					setAnnotations(response.annotations);
				} else {
					_aiResponse[currentIndex] = {} 
					_imageIndexInspected[currentIndex] = true;
					showSnackbarMessage('No objects detected.', 'warning');
				}
				setAiResponse(_aiResponse);
				setImageIndexInspected([..._imageIndexInspected]);
			} else if (!imageIndexInspected[currentIndex] && aiResponse[currentIndex] && Object.keys(aiResponse[currentIndex]).length > 0) { // if it already has a response stored but no annotation, create it for full view
				const _imageIndexInspected = imageIndexInspected;
				_imageIndexInspected[currentIndex] = true;
				setImageIndexInspected(_imageIndexInspected);
				const _annotations = createAnnotation(annotations, dimensionAndPositions[currentIndex], aiResponse[currentIndex], currentIndex, imagesIds[currentIndex]?.name);
				setAnnotations([..._annotations]);
			}
		};

		if (dimensionAndPositions[currentIndex] && Object.keys(dimensionAndPositions[currentIndex]).length)
			inspectCurrentIndex();
	}, [dimensionAndPositions]);
	
	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}
	
	return <>
		{loading && <ProgressBar styles={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1202	}} />}
		<div className={classes.containter}>		
			<Tooltip title="Close">
				<CloseTwoToneIcon onClick={() => setShowLightBox(false)} className={classes.closeIcon} />
			</Tooltip>
			<Tooltip title="Download Image">
				<a href={images[currentIndex]} download className={classes.downloadIcon}><DownloadTwoToneIcon /></a>
			</Tooltip>
			<LightBox
				images={images || []}
				dimensionAndPositions={dimensionAndPositions}
				setDimensionAndPositions={value => {
					const _dimensionAndPositions = dimensionAndPositionsRef.current;
					value.imageId = imagesIds[currentIndexRef.current]?.name;
					_dimensionAndPositions[currentIndexRef.current] = value;
					setDimensionAndPositions([..._dimensionAndPositions]);
					dimensionAndPositionsRef.current = [..._dimensionAndPositions];
				}}
				currentIndex={currentIndex}
				setCurrentIndex={index => {
					setCurrentIndex(index)
					currentIndexRef.current = index
				}}
				annotations={annotations}
				setAnnotations={setAnnotations}
				loading={loading}
			/>
			<DrawCanvas imagesIds={imagesIds} dimensionAndPosition={dimensionAndPositions[currentIndex]} currentIndex={currentIndex} selectShape={selectShape} annotations={annotations} setAnnotations={setAnnotations} selectedId={selectedId} newAnnotation={newAnnotation} setNewAnnotation={setNewAnnotation} />
			<AnnotationsList annotations={annotations} setAnnotations={setAnnotations} currentIndex={currentIndex} selectShape={selectShape} selectedId={selectedId} newAnnotation={newAnnotation} dimensionAndPositions={dimensionAndPositions} loading={loading} setLoading={setLoading} showSnackbarMessage={showSnackbarMessage} />
		</div>
	</>;
};

export default LightboxAI;
