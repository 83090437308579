import React from 'react';
import {
	MANHOLE_GRADE_EMERGENCY_REPAIR,
	MANHOLE_GRADE_NOTIFY_SUPERVISOR,
	MANHOLE_GRADE_REPAIR_NEEDED,
} from '../../../Constants';
import JointMeasurement from './JointMeasurement';
import CableSection from './CableSection';
import EquipmentSection from './EquipmentSection';
import InformationSection from './InformationSection';
import GradedSection from './GradedSection';
import YesNoSection from './YesNoSection';
import StructuralSection from './StructuralSection';
import GeographicSection from './GeographicSection';

export const showDetailInput = gradeSelected => gradeSelected === MANHOLE_GRADE_EMERGENCY_REPAIR || gradeSelected === MANHOLE_GRADE_NOTIFY_SUPERVISOR || gradeSelected === MANHOLE_GRADE_REPAIR_NEEDED;

const ManholeContainer = ({
	inspection,
	setInspection,
	onPlaceChange,
	manholeFromWorkItem,
	editable = true,
	detail = false
}) => {	
	const onChangeInput = (value, name, index = null) => {
		let _inspection = { ...inspection };
		if (index) {
			_inspection[name][index] = value;
		} else {
			_inspection = { ...inspection, [name]: value };
		}
		setInspection(_inspection);
	};

	const changeInfraredValue = (value, group, property, index) => {
		const _inspection = { ...inspection };
		const infraredGroup = _inspection[group] || [{}];
		if (!infraredGroup[index]) {
			infraredGroup[index] = {}
		}
		infraredGroup[index][property] = value;
		_inspection[group] = infraredGroup;
		setInspection(_inspection);
	};

	const deleteInfraredIndex = (index, group) => {
		const _inspection = { ...inspection };
		const infraredGroup = _inspection[group];
		if (infraredGroup?.[index]) {
			infraredGroup.splice(index, 1);
		}
		_inspection[group] = infraredGroup;
		setInspection(_inspection);
	}

	return inspection ? <>
		<GeographicSection inspection={inspection} onPlaceChange={onPlaceChange} setInspection={setInspection} onChangeInput={onChangeInput} manholeFromWorkItem={manholeFromWorkItem} editing={editable} detail={detail} />
		<YesNoSection inspection={inspection} onChangeInput={onChangeInput} showDetailInput={showDetailInput} editing={editable} />
		<GradedSection inspection={inspection} onChangeInput={onChangeInput} showDetailInput={showDetailInput} editing={editable} />
		<StructuralSection inspection={inspection} onChangeInput={onChangeInput} editing={editable} />
		<JointMeasurement inspection={inspection} changeInfraredValue={changeInfraredValue} deleteInfraredIndex={deleteInfraredIndex} editing={editable} />
		<InformationSection />
		<CableSection inspection={inspection} changeInfraredValue={changeInfraredValue} deleteInfraredIndex={deleteInfraredIndex} editing={editable} />
		<EquipmentSection inspection={inspection} changeInfraredValue={changeInfraredValue} deleteInfraredIndex={deleteInfraredIndex} editing={editable} />
	</> : '';
};

export default ManholeContainer;