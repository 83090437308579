import { BlobServiceClient } from '@azure/storage-blob';
import TokenService from '../TokenService';

class StorageService {
	async getBlob(resourceUri, containerName, blobName) {
		// eslint-disable-next-line no-undef
		const { REACT_APP_API_URL } = process.env;
		const token = await TokenService.getToken();
		const blobServiceClient = new BlobServiceClient(
			REACT_APP_API_URL + resourceUri,
			token
		);

		const containerClient = blobServiceClient.getContainerClient(containerName);
		const blobClient = containerClient.getBlockBlobClient(blobName);

		try {
			const blob = await blobClient.download(0);
			return await blob?.blobBody;
		} catch (err) {
			return null;
		}
	}
}

export default new StorageService();