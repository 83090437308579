import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialogSlide from '../../../Shared/Dialog';
import TextFieldComponent from '../../../Shared/atoms/TextInput';
import Button from '../../../Shared/atoms/Button';

const EquipmentSection = ({ inspection, changeInfraredValue, deleteInfraredIndex, editing }) => {
	const [equipmentSection, setEquipmentSection] = useState(-1);
	const [openDialog, setOpenDialog] = useState(false);
	const [indexToDelete, setIndexToDelete] = useState(false);

	useEffect(() => {
		setEquipmentSection(inspection?.equipmentSections?.length - 1);
	}, [inspection]);

	const equipmentSectionList = () => {
		const rows = [];
		for (let i = 0; i <= equipmentSection; i++) {
			rows.push(<Grid container key={`equipmentScan${i}`} spacing={1}>
				<Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<h4 style={{ ...indexToDelete === i && {color: 'red'} }}>Equipment Scan {i + 1}</h4>
					{editing && <DeleteIcon
					 	style={{ cursor: 'pointer', color: 'red' }}
						onClick={() => {
							setOpenDialog(true);
							setIndexToDelete(i);
						}}
					/>}
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent
						value={inspection.equipmentSections && inspection.equipmentSections[i] && inspection.equipmentSections[i].equipmentScanned || ''}
						label="Equipment Scanned"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={e => changeInfraredValue(e, 'equipmentSections', 'equipmentScanned', i)}
						multiline={false}
						required={true}
						disabled={!editing}
					/>
				</Grid>
				<Grid item xs={12}>
				<TextFieldComponent
						value={inspection.equipmentSections && inspection.equipmentSections[i] && inspection.equipmentSections[i].temperature || ''}
						label="Temperature"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={(e) => changeInfraredValue(e, 'equipmentSections', 'temperature', i)}
						multiline={false}
						required={true}
						disabled={!editing}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextFieldComponent
						value={inspection.equipmentSections && inspection.equipmentSections[i] && inspection.equipmentSections[i].referenceTemperature || ''}
						label="Reference Temperature"
						fullWidth
						variant="outlined"
						type="text"
						placeholder=""
						onChange={(e) => changeInfraredValue(e, 'equipmentSections', 'referenceTemperature', i)}
						multiline={false}
						required={true}
						disabled={!editing}
					/>
				</Grid>
			</Grid>);
		}
		return rows;
	}

	return <>
		<Grid item xs={12}>
			<h3>Equipment Section</h3>
			{equipmentSectionList()}
		</Grid>
		<Grid item xs={12}>
			<Button
				variant="outlined"
				color="primary"
				onClick={() => {
					const newEquipmentSectionIndex = equipmentSection + 1;
					setEquipmentSection(newEquipmentSectionIndex);					
					changeInfraredValue('', 'equipmentSections', 'equipmentScanned', newEquipmentSectionIndex);
				}}
				label="Add Equipment Scan"
				styles={{ marginTop: 10}}
				disabled={!editing}
			/>
		</Grid>
		<AlertDialogSlide
			openDialog={openDialog}
			handleConfirm={() => {
				setOpenDialog(false);
				const newEquipmentSectionIndex = equipmentSection - 1;
				setEquipmentSection(newEquipmentSectionIndex);
				deleteInfraredIndex(indexToDelete, 'equipmentSections');
				setIndexToDelete(false);
			}}
			title={`Delete Equipment Scan ${indexToDelete + 1}`}
			content="Are you sure you want to delete this equipment scan?"      
			handleClickClose={() => {
				setOpenDialog(false);
				setIndexToDelete(false);
			}}
		/>
	</>
}

export default EquipmentSection;