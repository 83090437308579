import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Home from '@material-ui/icons/Home';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import MainMenu from "../MainMenu"
import UserMenu from '../UserMenu';
import ImageContext from "../../../context/Image/ImageContext";
import { GetUserLocation } from '../../../Utils';

import './index.css';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 30,
    flexGrow: 1,
    justifyContent: 'space-between'
  },
  fabButton: {
    zIndex: 1,
    top: -15,
    right: 0,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    width: 70,
    height: 70,
    position: 'absolute',
    left: 'calc(50% - 35px)'
  },
  link: {
    display: 'flex',
    alignItems: 'center'
  },
  linkIcon: {
    padding: '0'
  },
  menuRight: {
    width: 'calc(50% - 35px)',
    marginLeft: 70,
    display: 'flex',
    justifyContent: 'space-around'
  },
  menuLeft: {
    width: 'calc(50% - 35px)',
    display: 'flex',
    justifyContent: 'space-around'
  }
}));

const MobileMenu = ({ username, roles, inspectionsOffline }) => {
  let history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const inputFileRef = React.useRef(null);
  const { setGpsLocation, setContextImage } = useContext(ImageContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOpenGallery = ()  => {
    inputFileRef.current.click(); 
  };

  const onChangeFile = async (evt) => {    
    evt.stopPropagation();
    evt.preventDefault();
    const files = Array.from(evt.target.files);

    try {
      const userLocation = await GetUserLocation();    
      if(userLocation.position) {
        const location= {
          latitude: userLocation.position.coords.latitude,
          longitude: userLocation.position.coords.longitude
        }
        setGpsLocation(location);
        setContextImage(files, location);
      }      
    } catch (error) {
      setGpsLocation({});
      setContextImage(files);
    }        
  }

  const closeMenu = () => {
    setAnchorEl(null);
  }

  return (
    <div data-testid="mobileMenu" className={classes.menuContainer}>
      <div className={classes.menuLeft}>
        { /* Home button */}
        <Link to="/" className={classes.link}>
          <Home color="secondary" />
        </Link>
        { /* Back button */}
        <IconButton
          aria-label="Go back"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => history.goBack()}
          color="inherit"
          className={classes.linkIcon}
        >
          &lt;
        </IconButton>
      </div>
      { /* Photo */ }
      <input className="file-input" accept="image/*" id="icon-button-file" type="file" ref={inputFileRef} onChange={onChangeFile} capture="environment"/>
      <Fab color="secondary" aria-label="add" className={classes.fabButton} onClick={onOpenGallery}>
        <AddAPhotoIcon color="primary" />
      </Fab>
      <div className={classes.menuRight}>
        {/* User menu */}
        <UserMenu inspectionsOffline={inspectionsOffline} username={username} />
        {/* Nav menu */}
        <IconButton
          aria-label="Nav menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          className={classes.linkIcon}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openProfile}
          onClose={handleClose}
        >
          <MainMenu closeMenu={ closeMenu } roles={roles}/>               
        </Menu>
      </div>
    </div>)
}

MobileMenu.propTypes = {
  username: PropTypes.string,
  inspectionsOffline: PropTypes.array
};

MobileMenu.defaultProps = {
  username: ""
}

export default MobileMenu;
