import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import AssetsService from '../../services/AssetsService';
import InspectionsService from '../../services/InspectionsService';
import TownsSummaryService from '../../services/TownsSummaryService';
import InspectionCard from './InspectionCard';
import Paginator from './Paginator';
import ListFilters from './Filters';
import Sorting from './Sorting';
import MobileActionContainer from './MobileActionContainer';
import Map from './Map';
import ImageContext from '../../context/Image/ImageContext';
import InspectionsDataContext from '../../context/InspectionsData/InspectionsDataContext';
import ProgressBar from '../Shared/ProgressBar';
import { useParams } from 'react-router-dom';
import { 
	FF_TRANSMISSION_INSPECTION_FORM,
	INSPECTION_TYPE_TRANSMISSION,
	LOCALSTORAGE_CURRENT_WORK_ITEM,
} from '../../Constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { showSnackbar } from '../../Utils';
import { useSnackbar } from 'notistack';
import VideoService from '../../services/VideoService';

const useStyles = makeStyles((theme) => ({
	mapListContainer: {
		position: 'relative',
		height: '100%',
	},
	mapContainer: {
		position: 'absolute',
		width: '50%',
		height: '100%',
		left: 0,
		top: 0,
		[theme.breakpoints.down('sm')]: {
			width: '30%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			display: 'none'
		},
	},
	mapContainerXsShow: {
		[theme.breakpoints.down('xs')]: {
			display: 'block !important',
			marginTop: 63,
			height: 'calc(100% - 63px)'
		}
	},
	mapFullscreen: {
		[theme.breakpoints.up('sm')]: {
			position: 'fixed',
			zIndex: 9999,
			width: '100%'
		}
	},
	filterSortingContainer: {
		borderBottom: `1px solid ${theme.palette.background.default}`,
		display: 'flex'
	},
	filterSortingContainerXsShow: {
		[theme.breakpoints.down('xs')]: {
			display: 'flex !important',
		}
	},
	listContainer: {
		position: 'absolute',
		right: 0,
		top: 0,
		overflow: 'auto',
		width: '50%',
		height: '100%',
		padding: '1rem',
		[theme.breakpoints.down('sm')]: {
			width: '70%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginTop: 63,      
			height: 'calc(100% - 63px)'
		},
	},
	listContainerXsHide: {
		[theme.breakpoints.down('xs')]: {
			display: 'none !important',
		}
	},
	avatar: {
		backgroundColor: theme.palette.primary.dark,
		marginRight: '1rem'
	},
	inspectionCardContainer: {
		'&:hover .MuiPaper-root': {
			boxShadow: '0 0 5px 2px rgb(0 0 0 / 90%)'
		}
	},
	inspectionCardContainerSelected: {
		'& .MuiPaper-root': {
			boxShadow: '0 0 5px 2px rgb(0 0 0 / 90%)'
		}
	},
	sortingContainer: {
		margin: '8px',
		display: 'flex',
		minWidth: 250,
		[theme.breakpoints.up('md')]: {      
			justifyContent: 'flex-end'
		},
		[theme.breakpoints.up('lg')]: {      
			justifyContent: 'flex-end'
		}
	}
}));

const initialFilterValues = {
	createdBy: '',
	submittedFrom: null,
	submittedTo: null,
	damageTypes: [],
	status: [],
	type: [],

  circuitId: "",
  lineIds: "",
  address: ""
}

const initialSortValues = {
	sortby: '', 
	sortorder: 'desc'
};

const InspectionsPage = (props) => {
	const classes = useStyles();
	const ref = useRef();  
	const { setBlobListByInspection, resetContextImage } = useContext(ImageContext);
	const { getContextState, setContextFilters, setContextSorting } = useContext(InspectionsDataContext);
	const [allInspections, setAllInspections] = useState([]);
	const [inspectionsToShow, setInspectionsToShow] = useState([]);
	const [inspectionsFiltered, setInspectionsFiltered] = useState([]);
	const [pageLoading, setPageLoading] = useState(true);
	const [highlighted, setHighlighted] = useState(null);
	const [markerSelected, setMarkerSelected] = useState(null);
	const [showMap, setShowMap] = useState(false);
	const [activeFilters, setActiveFilters] = useState(initialFilterValues);
	const [page, setPage] = useState(0);
	const [pageForced, setPageForced] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState();
	const [continuationToken, setContinuationToken] = useState('');   
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);      
	const [activeSort, setActiveSort] = useState(initialSortValues);
	const [townsSummary, setTownsSummary] = useState([]);
	const [circuitCenter, setCircuitCenter] = useState(null);
	const [fullscreen, setFullscreen] = useState(false);
	let { circuitIdParam, asset } = useParams();  
	const  ff_transmission_inspection_form = useFlags()[FF_TRANSMISSION_INSPECTION_FORM];
	const { enqueueSnackbar } = useSnackbar();
	const [onChangeAssets, setOnChangeAssets] = useState('');
	const [videosByAsset, setVideosByAsset] = useState([]);

	const loadingStyles = {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 99
	};

	useEffect(() => {
		resetContextImage();        
		const {filters, sortingBy, inspectionPerPage} = getContextState();
		setRowsPerPage(inspectionPerPage);
		const sortValues = sortingBy.sortby !== '' ? { ...sortingBy } : {};
		setActiveSort({...sortingBy});           
		onInitInspections({...filters, ...sortValues});    
		setShowMap(circuitIdParam && circuitIdParam !== '');
		getVideosByAsset();
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, []);

	// After selecting a marker, wait until page its changed and then scroll
	useEffect(() => setTimeout(scroll()), [pageForced]);
		

  	function onInitInspections(filters) {   
		let currentWorkItem = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENT_WORK_ITEM));
		let _filters = {...filters};
		if (currentWorkItem) {

      const {circuitId, lineIds, address} = currentWorkItem;

      const _lineId = lineIds ? decodeURIComponent(lineIds) : "";
      const _circuitId = circuitId ? decodeURIComponent(circuitId) : "";
      const _address = address ? decodeURIComponent(address): "";
      _filters = {..._filters, circuitId: _circuitId, lineIds: _lineId, address: _address}
			if (currentWorkItem && currentWorkItem.fromWorkItem) {
				_filters = {
					..._filters, 
					status: [],
					...currentWorkItem.assetLocation 
						&& currentWorkItem.workItemType === INSPECTION_TYPE_TRANSMISSION 
						&& {viewPort: currentWorkItem.assetLocation},
				};
			}
		} 
		changeFilters(_filters);
		setPageLoading(false);  
		TownsSummaryService.getTownsSummary().then((townsSummary) => {
			setTownsSummary(townsSummary);
		}).catch(() => {
			showSnackbarMessage('An error occurred getting town markers', 'error');
		});
	}

	const getVideosByAsset = async () => {
		try {
			const response = await VideoService.getVideos();
			const videoData = response.data ?? [];
			setVideosByAsset([...videoData]);
		} catch (error) {
			console.log(error);
		}
	 };

	async function onChangePagination() {    
		const inspections = await onChangeInspections({...activeFilters, ...activeSort}, true);    
		setAllInspections( [...allInspections,...inspections]);
		setInspectionsFiltered([...inspectionsFiltered, ...inspections]);
	}

	function changeFilters(filters=initialFilterValues) {    
		setContextFilters(filters);
		setLoading(true);
		const sortValues = activeSort.sortby !== '' ? {...activeSort} : {};  
		onChangeInspections({...filters, ...sortValues}).then(result => setInspectionsFiltered(result)).catch(() => {
			showSnackbarMessage('An error occurred', 'error');
		});

		// if circuitId has a value and it changed, get circuit center
		filters.circuitId !== '' && activeFilters.circuitId !== filters.circuitId
			&& getCenterMap(filters.circuitId, 'circuits');
		
		if(filters.lineIds !== '' && activeFilters.lineIds !== filters.lineIds){
			getCenterMap(encodeURIComponent(filters.lineIds), 'lines');
		}

		setActiveFilters(filters);
		setPage(0);
	}

	async function onChangeInspections(filters, useContinuationToken) {
		setLoading(true);
		const {inspections, newContinuationToken, totalItems} = await InspectionsService.getAllInspections(filters, continuationToken, useContinuationToken);
		setContinuationToken(newContinuationToken);
		setTotalItems(totalItems);   

		if (inspections && inspections.canceled) { 
			return [];  
		} else {
			setLoading(false);
		}
		return inspections;
	}

	function getCenterMap(id, workQueueType) {
		setLoading(true);
		AssetsService.getCenterMap(id, workQueueType).then(
			result => {
				setLoading(false);
				if (result && result.data) {
					setCircuitCenter({ lat: result.data.latitude, lng: result.data.longitude });
				} else {
					showSnackbarMessage('Not Found', 'error');
					setCircuitCenter(null);
				}
			}
		).catch(() => {
			showSnackbarMessage('An error occurred', 'error');
		});
	}

	function inspectionHover(inspectionParameter) {
		let inspection = null;
		if (inspectionParameter) {
			inspection = {};
			inspection.id = inspectionParameter.id;
			inspection.location = inspectionParameter.location;
			inspection.damageTypes = inspectionParameter.damageTypes;
			inspection.inspectionType = inspectionParameter.inspectionType;
		}
		
		setHighlighted(inspection);
	}

	function onChangePersistenceImage(list) {
		setBlobListByInspection(list);
	}

	function handleSortingBy(sort) {
		setContextSorting(sort);
		setActiveSort(sort);
		onChangeInspections({...activeFilters, ...sort}).then(
			inspectionsSorted => setInspectionsFiltered(inspectionsSorted)
		).catch(() => {
			showSnackbarMessage('An error occurred', 'error');
		});  
		setPage(0);
	}

	function selectMarker(id) {
		setMarkerSelected(id);
		const index = inspectionsFiltered.findIndex(inspection => inspection.id === id);
		const itemPage = Math.floor(index / rowsPerPage); // get corresponding page
		setPage(itemPage);
		setPageForced(itemPage);
		
		if (pageForced === page) { // if its on the same page
			setTimeout(scroll());
		}
	}

	function changeBoundaries(ne, sw, zoom) {
		const updatedValue = {};
		if (ne.lat() === sw.lat()) {
			updatedValue['viewPort'] = activeFilters.viewPort;
		} else {
			updatedValue['viewPort'] = `${ne.lat()}:${sw.lat()}:${ne.lng()}:${sw.lng()}`;
			const mergedValue = {
				...activeFilters,
				...updatedValue,
			};
			changeFilters(mergedValue, zoom, true);
		}
	}

	function scroll() {
		ref && ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });
	}

	function showSnackbarMessage(message, type) {
		showSnackbar(enqueueSnackbar, message, type);
	}


	return pageLoading ? (
		<ProgressBar styles={loadingStyles} />
	) : (
		<>
			<Hidden xsDown>
				<div className={classes.filterSortingContainer}>
					<ListFilters
						activeFilters={activeFilters}
						setLoading={setLoading}
						setActiveFiltersProp={(filters) => changeFilters(filters)}
						onChangeFilterWithOutAction={(filters) => setActiveFilters(filters)}
						onClearFilters={() => {
							changeFilters(initialFilterValues);
						}}
						inspectionContext={getContextState()}
						onChangeAssets={setOnChangeAssets}
						ff_transmission_inspection_form={ff_transmission_inspection_form}
						roles={props.roles}
						account={props.account}
					/>
					<div className={classes.sortingContainer}>
						<Sorting
							data={inspectionsFiltered}
							onChangeSort={handleSortingBy}
						/>
					</div>
					{loading && <ProgressBar styles={loadingStyles} />}
				</div>
			</Hidden>
			<div
				className={classes.mapListContainer}
				data-testid="inspectionListComponent"
			>
				<Hidden smUp>
					<MobileActionContainer
						setLoading={setLoading}
						showMap={showMap}
						setShowMap={setShowMap}
						activeFilters={activeFilters}
						changeFilters={changeFilters}
						inspectionsFiltered={inspectionsFiltered}
						changeSort={handleSortingBy}
						inspectionContext={getContextState()}
						onChangeFilterWithOutAction={(filters) => setActiveFilters(filters)}
						onClearFilters={changeFilters}
						onChangeAssets={setOnChangeAssets}
						ff_transmission_inspection_form={ff_transmission_inspection_form}
						roles= {props.roles}
					/>
					{loading && <ProgressBar styles={loadingStyles} />}
				</Hidden>
				<div
					className={clsx(classes.mapContainer, {
						[classes.mapContainerXsShow]: showMap,
						[classes.mapFullscreen]: fullscreen,            
					})}
				>
					<Map
						inspectionList={inspectionsFiltered || []}
						townsSummary={townsSummary || []}
						highlighted={highlighted}
						selectMarker={id => selectMarker(id)}
						changeBoundaries={(ne, sw, zoom) => changeBoundaries(ne, sw, zoom)}
						statuses={activeFilters.status}
						inspectionTypes={activeFilters.type}
						circuitCenter={circuitCenter}
						setFullscreen={setFullscreen}
						fullscreen={fullscreen}
						activeFilters={activeFilters}
						asset={asset}
						setLoading={setLoading}
						onChangeAssets={onChangeAssets}
						lineId={activeFilters.lineIds}   
						videosByAsset= {videosByAsset}         
					/>
				</div>
				<div
					className={clsx(classes.listContainer, {
						[classes.listContainerXsHide]: showMap,
					})}
				>
					<Grid
						container
						spacing={2}
						onMouseLeave={() => inspectionHover(null)}
					>
						{inspectionsToShow &&
							inspectionsToShow.map((inspection, index) => {               
								// If its the selected marker inspections, set specific class and create a ref for scrolling into
								const highlighted = markerSelected === inspection.id;              
								return (
									<Grid
										item
										xs={12}
										lg={6}
										key={index}
										id={inspection.id}
										onMouseEnter={() => inspectionHover(inspection)}
										className={highlighted
											? classes.inspectionCardContainerSelected
											: classes.inspectionCardContainer
										}
										{...(highlighted ? {ref: ref} : {})}
									>
										<InspectionCard
											inspection={inspection}
											inspectionsToShow={inspectionsToShow}
											onChangePersistenceImage={onChangePersistenceImage}
										/>
									</Grid>);
							})}
					</Grid>
					<Paginator
						inspectionPage={true}
						data={inspectionsFiltered}
						onChangePage={(data) => setInspectionsToShow(data)}
						page={page}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={rowsPerPage => setRowsPerPage(rowsPerPage)}
						setPage={(page) => setPage(page)}
						totalItems={totalItems}
						getMoreInspections={onChangePagination}
					/>
				</div>
			</div>
		</>
	);
};

export default InspectionsPage;
