import React from 'react';
import { Step, StepContent, StepButton } from '@material-ui/core';
import { default as MaterialStepper } from '@material-ui/core/Stepper';

export default function Stepper({
	steps = [],
	activeStep = 0,
	setActiveStep,
	editing,
	...props
}) {
	return (
		<MaterialStepper
			nonLinear
			style={{
				padding: '0px',
				marginRight: '0px',
				marginLeft: '0',
				position: 'sticky',
				top: 26,
			}}
			orientation="vertical"
			activeStep={activeStep}
		>
			{steps.map((label, index) => {
				return (
					<Step key={label}>
						<StepButton
							color="inherit"
							onClick={() => {
								if (!editing) {
									setActiveStep(index);
								}
							}}
						>
							{label}
						</StepButton>
						<StepContent>{props.children}</StepContent>
					</Step>
				);
			})}
		</MaterialStepper>
	);
}
