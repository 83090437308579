import { isMobile } from '.';

export const getLookup = (data, property) => {
	let lookup = {};
	data.forEach((e) => {
		if (e[property]) lookup = { ...lookup, [e[property]]: e[property] };
	});
	return lookup;
};

export const getLookups = (items, lookupKeys) => {
	let lookups = {};
	lookupKeys.forEach(
		property => lookups[property] = getLookup(items, property)
	);
	const sortedLookups = {};
	// Sort filter alphabetically
	Object.keys(lookups).map(lookUpKey => {
		const not_sorted = lookups[lookUpKey];
		let sorted = Object.keys(not_sorted)
			.sort()
			.reduce((acc, key) => ({
				...acc, [key]: not_sorted[key]
			}), {});
		sortedLookups[lookUpKey] = sorted;
	});
	return sortedLookups;
};

export const isTextInclude = (filter, field) => {
	return field?.toLowerCase().includes(filter?.toLowerCase());
};

export const redirectToDetailPage = (inspectionId, inspectionType, partitionKey) => {  
	const url = `Inspection/${inspectionId}/${inspectionType}/${partitionKey}`;
	if(isMobile()) {
		window.open(`#/${url}`, '_self');
	} else {
		window.open(`#/${url}`, '_blank');
	}
};
