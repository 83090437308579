import React, { useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { FormControl, InputLabel, makeStyles } from '@material-ui/core';
import InspectionsDataContext from '../../../context/InspectionsData/InspectionsDataContext';

const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: 160,
		'& .MuiInputLabel-root': {
			backgroundColor: '#fff',
		},
	},
	sortOrder: {
		marginLeft: '1rem',
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
			marginTop: '1rem',
		},
	},
	azSelect: {
		minWidth: 20,
	},
	dateCreatedSelect: {
		minWidth: 60,
	},
	dateCreatedFilterContainer: {
		'& .MuiSelect-selectMenu': {
			paddingTop: 14,
			paddingBottom: 14,
		},
	},
}));

const Sorting = ({ ...props }) => {
	const [sortingSelect, setSortingSelect] = React.useState('');
	const [descAsc, setDescAsc] = React.useState('desc');
	const [showAscDesc, setShowAscDesc] = React.useState(false);
	const { getContextState } = React.useContext(InspectionsDataContext);
	const classes = useStyles();

	useEffect(() => {
		const { sortingBy } = getContextState();
		setSortingSelect(sortingBy.sortby);
		setDescAsc(sortingBy.sortorder);
		setShowAscDesc(sortingBy.sortby !== '');
	}, [getContextState]);

	const handleChange = (event) => {
		const sortingBy = event.target.value;
		setSortingSelect(sortingBy);
		switch (sortingBy) {
			case 'submittedon':
				setShowAscDesc(true);
				break;
			case 'submittedby':
				setShowAscDesc(true);
				break;
			case 'damagetype':
				setShowAscDesc(true);
				break;
			default:
				setShowAscDesc(false);
				break;
		}
		applySort(sortingBy, descAsc);
	};

	const handleAscDescChange = (event) => {
		setDescAsc(event.target.value);
		applySort(sortingSelect, event.target.value);
	};

	const applySort = (sortingSelect, descAsc) => {
		props.onChangeSort({ sortby: sortingSelect, sortorder: descAsc });
	};

	return (
		<div>
			<FormControl variant="outlined" className={classes.formControl}>
				<InputLabel
					id="sort-type-select-label"
					className={classes.formControlLabel}
				>
					Sort By
				</InputLabel>
				<Select
					labelId="sort-type-select-label"
					id="sort-type-select"
					value={sortingSelect}
					onChange={handleChange}
				>
					<MenuItem value={'submittedon'}>Created On</MenuItem>
					<MenuItem value={'submittedby'}>Created By</MenuItem>
					<MenuItem value={'damagetype'}>Damage Type</MenuItem>
				</Select>
			</FormControl>

			{showAscDesc ? (
				<FormControl
					className={`${classes.sortOrder} ${
						sortingSelect === 'submittedon'
							? classes.dateCreatedSelect
							: classes.azSelect
					}`}
				>
					<Select
						labelId="sort-asc-desc-label"
						id="sort-asc-desc-select"
						value={descAsc}
						variant="outlined"
						onChange={handleAscDescChange}
						className={
							sortingSelect === 'submittedon' &&
							classes.dateCreatedFilterContainer
						}
					>
						<MenuItem value={'desc'}>
							{' '}
							{sortingSelect === 'submittedon' ? (
								<ArrowDownwardIcon></ArrowDownwardIcon>
							) : (
								'Z-A'
							)}
						</MenuItem>
						<MenuItem value={'asc'}>
							{sortingSelect === 'submittedon' ? (
								<ArrowUpwardIcon></ArrowUpwardIcon>
							) : (
								'A-Z'
							)}
						</MenuItem>
					</Select>
				</FormControl>
			) : null}
		</div>
	);
};

export default Sorting;
