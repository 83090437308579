import React from 'react';
//  import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Logout from "../Logout";
import PendingUploads from './PendingUploads';

const useStyles = makeStyles((theme) => ({
	userName: {
    marginRight: "1rem",
    marginLeft: "1rem",
    [theme.breakpoints.down('xs')]: {
      display: "none"
    }
  },
  userNameMobile: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      display: "none"
    },
    "& .MuiSvgIcon-root": {
      marginRight: "1rem",
    }

  },
  linkIcon: {
    padding: '0',
    [theme.breakpoints.down('xs')]: {
      '&:nth-of-type(1)': {
        marginTop: 5
      },
      '&:nth-of-type(2)': {
        marginLeft: 20
      } 
    }
  }
}))

const UserMenu = ({ username, inspectionsOffline }) => {
  const classes = useStyles();  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <div data-testid="userMenu">      
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="secondary"
        className={classes.linkIcon}
      >
        <PendingUploads title="Pending Uploads" inspectionsOffline={inspectionsOffline} />
      </IconButton>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="secondary"
        className={classes.linkIcon}
      >
        <Typography color="secondary" className={classes.userName}>
          {username}
        </Typography>
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openProfile}
        onClose={handleClose}
      >
        <MenuItem className={classes.userNameMobile}>
          <AccountCircle color="primary" />
          <Typography>{username}</Typography>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Logout />
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

// UserMenu.propTypes = {
//   username: PropTypes.string,
//   inspectionsOffline: PropTypes.array,
// };

// UserMenu.defaultProps = {
//   username: "",
//   inspectionsOffline: []
// }

export default UserMenu;
