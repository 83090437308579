import {
	SET_DAMAGE_TYPES,
	SET_STATUS,
	SET_FILTERS,
	SET_SORTING,
	SET_MAP,
	SET_INSPECTIONS_PER_PAGE,
	SET_IDB_INSPECTION_INSTANCE,
	SET_USERS_ACCEPTED_ACKNOWLEDGEMENT,
	SET_ISSUE_TYPES
} from '../types';

const InspectionsDataReducer = (state, action) => {
	const { type, payload } = action;  
	switch (type) {
	case SET_DAMAGE_TYPES:      
		return {
			...state,
			...payload,
		};
	case SET_STATUS:      
		return {
			...state,
			...payload,
		};
	case SET_ISSUE_TYPES:      
		return {
			...state,
			...payload,
		};      
	case SET_FILTERS:            
		return {
			...state,
			...payload,
		};
	case SET_SORTING:            
		return {
			...state,
			...payload,
		};
	case SET_MAP:            
		return {
			...state,
			...payload,
		};
	case SET_INSPECTIONS_PER_PAGE:            
		return {
			...state,
			...payload,
		};
	case SET_IDB_INSPECTION_INSTANCE:            
		return {
			...state,
			...payload,
		};
	case SET_USERS_ACCEPTED_ACKNOWLEDGEMENT:   
		return {
			...state,
			...payload,
		};
	default:
		return state;
	}
};
export default InspectionsDataReducer;
