import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  rootWithMargin: {
    marginBottom: "1rem"
  },
  percentage: {
    marginLeft: "1rem",
    width: 45
  }
});

const LinearProgressBar = (props) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.rootWithMargin]: !props.value,
      })}
    >
      <Box display="flex" alignItems="center">
        <div className={classes.root}>
          <LinearProgress {...props} />
        </div>
        {(props.value !== null && props.value !== undefined) && <Box minWidth={35} className={classes.percentage} >
            <Typography variant="body2" color="textSecondary" align="right" data-testid="linearProgressBar">{`${props.value}%`}</Typography>
        </Box>}
      </Box>
    </div>
  );
}

export default LinearProgressBar;